import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EmptyPlaceholder from 'common/images/empty_placeholder.png';
import useCommonStyles from 'common/styles/common.styles';
import useStyles from './StaffDirectory.styles';

const StaffDirectoryEmptyView: React.FC<{ onClick?: () => void; permitHolder: any }> = ({
  onClick,
  permitHolder
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  return (
    <Container className={classes.wrapper}>
      <Grid container rowSpacing={1}>
        <Grid container item lg={12} justifyContent="center">
          <Box
            component="img"
            sx={{
              height: 99,
              width: 120
            }}
            alt="Add New"
            src={EmptyPlaceholder}
          />
        </Grid>
        {permitHolder?.length > 0 ? (
          <>
            <Grid container item lg={12} justifyContent="center">
              <Typography variant="h4" color="secondary" width={'xl'}>
                Looks like you haven’t added any staff yet
              </Typography>
            </Grid>
            <Grid container item lg={12} justifyContent="center">
              <Typography variant="body2">
                Use the button below to add new staff members to your directory
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid container item lg={12} justifyContent="center">
              <Typography variant="h4" color="secondary" width={'xl'}>
                {`Looks like you haven’t added any staff yet`}
              </Typography>
            </Grid>
            <Grid container item lg={12} justifyContent="center">
              <Typography variant="body2">{`Use the button below to add new staff to your directory`}</Typography>
            </Grid>
          </>
        )}
        <Grid container item lg={12} justifyContent="center">
          {permitHolder?.length > 0 ? (
            <>
              <Button
                className={commonClasses.blueButton}
                startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                onClick={() => (onClick ? onClick() : null)}
              >
                Add Staff
              </Button>
            </>
          ) : (
            <Button
              className={commonClasses.blueButton}
              style={{ width: '140px' }}
              startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 20, height: 16 }} />}
              onClick={() => (onClick ? onClick() : null)}
            >
              Add staff
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default StaffDirectoryEmptyView;
