import React, { useState } from 'react';
import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import DatePicker from 'components/primitives/DatePicker';
import { ICertifiedFPManagers } from './CertifiedFPManagers.props';
import { Controller, useWatch } from 'react-hook-form';
import moment from 'moment';

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const CertifiedFPManagers: React.FC<ICertifiedFPManagers> = (props: ICertifiedFPManagers) => {
  const { control, errors, type, index, setValue, firstName, lastName, certificateNumber } = props;

  const data = useWatch({
    control,
    name: type
  });
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
        <Grid container item lg={12}>
          <Grid item lg={10} xl={10}>
            <Typography variant="h5">{props.title}</Typography>
          </Grid>
          <Grid container item lg={2} xl={2} alignItems="center" justifyContent="flex-end">
            <DeleteOutlinedIcon
              sx={{
                width: 20,
                height: 20,
                color: 'red',
                cursor: 'pointer'
              }}
              onClick={() => {
                const currentData = data;
                if (currentData) {
                  currentData.splice(index, 1);
                  setValue(type, currentData);
                }
                if (props?.onDelete) props?.onDelete(props.type, props.index);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`First Name`}>
            <Controller
              name={`${type}.${[index]}.firstName`}
              control={control}
              defaultValue={firstName}
              rules={{ required: 'Field is required.' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors[`${type}`]?.[index]?.firstName?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors[`${type}`]?.[index]?.firstName?.message}
                    variant="standard"
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Last Name`}>
            <Controller
              name={`${type}.${[index]}.lastName`}
              control={control}
              defaultValue={lastName}
              rules={{ required: 'Field is required.' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors[`${type}`]?.[index]?.lastName?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors[`${type}`]?.[index]?.lastName?.message}
                    variant="standard"
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Certificate Number`}>
            <Controller
              name={`${type}.${[index]}.certificateNumber`}
              control={control}
              defaultValue={certificateNumber}
              rules={{ required: 'Field is required.' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors[`${type}`]?.[index]?.certificateNumber?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors[`${type}`]?.[index]?.certificateNumber?.message}
                    variant="standard"
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Expiration Date`}>
            <Controller
              name={`${type}.${[index]}.expiredAt`}
              control={control}
              rules={{ required: `Expiration date should more than today's date.` }}
              render={({ field: { name, onChange } }) => {
                return (
                  <>
                    <DatePicker
                      disablePast
                      inputFormat="MM-DD-YYYY"
                      value={
                        data && data[index]?.expiredAt
                          ? moment.unix(data[index]?.expiredAt).toISOString()
                          : null
                      }
                      onChange={(d: any) => {
                        // const unix = moment(d).unix();
                        // onChange(unix);
                        onChange(d.unix());
                      }}
                      renderInput={(p) => <TextField {...p} />}
                      readOnly={false}
                    />
                    <p style={errorTextStyle}>{errors[`${type}`]?.[index]?.expiredAt?.message}</p>
                  </>
                );
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CertifiedFPManagers;
