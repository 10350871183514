import { Button, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import useStyles from './Library.styles';
import ReactTableContainer from '../../../fragments/ReactTable';
import LibraryHeaders from '../../../../common/arrays/LibraryHeaders';
import FormDialogAddEditLibrary from '../../../fragments/FormDialogAddEditLibrary';

import apiFetch from '../../../../services/apiFetch';
import {
  ApiAdminLibrarySearch,
  getLibrary,
  getLibraryCounter
} from '../../../../services/apiAdminLibrary';
import { ColumnsProperties } from '../../../fragments/ReactTable/ReactTable.props';
import { CellProps } from 'react-table';
import Image from '../../../primitives/Image';
import EditBlackIcon from '../../../../common/images/edit_black_icon.png';
import { ILibrary } from 'interfaces/ILibrary';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DocumentButtons from '../../../fragments/DocumentsButtons';
import parsTableHeader from 'common/Utils/parseTableHeader';

const localStorageKey = 'ADMIN_LIBRARY';

const Library: React.FC = (props) => {
  const classes = useStyles();

  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), LibraryHeaders)
    : LibraryHeaders;
  const [data, setData] = useState<ILibrary[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<{ id: string; desc: boolean }[]>([
    { id: 'updatedAt', desc: true }
  ]);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOffset, setCurrentOffset] = useState<number>(0);

  const [initialData, setInitialData] = useState<ILibrary | null>(null);
  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

  useEffect(() => {
    getAllLibraryAdmin();
  }, [currentOffset, currentKeyword, currentFilterParams]);

  useEffect(() => {
    getAllLibraryAdmin(true);
  }, [currentOrderBy]);

  const getAllLibraryAdmin = async (getTotalCount: boolean = true) => {
    setIsFetchingData(true);
    let filters: ApiAdminLibrarySearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getLibrary(filters)).then((data) => {
      setIsFetchingData(false);
      return data;
    });

    if (usersData) {
      setData(usersData.data?.response || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getLibraryCounter(filters));
      setTotal(total.data?.response || 0);
    }
  };

  useEffect(() => {
    if (defColTable) {
      const url = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'url');

      if (url > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[url].Cell = ({ cell }: CellProps<object, any>) => {
          const library: ILibrary = cell.row.original as ILibrary;
          return <DocumentButtons library={library} />;
        };
      }

      const options = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');

      if (options > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[options].Cell = ({ cell }: CellProps<object, any>) => {
          const library: ILibrary = cell.row.original as ILibrary;
          return (
            <Button
              variant="outlined"
              size="small"
              className={classes.editButton}
              startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setShowAddEdit(true);
                setInitialData(library);
              }}
            >
              Edit
            </Button>
          );
        };
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Container disableGutters sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
        <Grid container item alignItems="center">
          <ReactTableContainer
            columns={defColTable}
            primaryKey="id"
            data={data}
            isDataFetching={isFetchingData}
            module={localStorageKey}
            hasCheckSelection={false}
            totalPage={total}
            currSortBy={currentOrderBy}
            onPageChange={(offset, keyword) => {
              setCurrentOffset(offset);
            }}
            onSearch={(offset, keyword) => {
              setCurrentKeyword(keyword);
              setCurrentOffset(0);
            }}
            filtersData={(filters) => {
              setCurrentFilterParams(filters);
              setCurrentOffset(0);
            }}
            onChangeSort={(filter: string, isSortedDesc: boolean) => {
              const newSortValue = [{ id: filter, desc: isSortedDesc }];
              setCurrentOrderBy(newSortValue);
            }}
            suffix={
              <Button
                className={classes.blueButton}
                sx={{ width: '140px!important' }}
                startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                onClick={() => {
                  setShowAddEdit(true);
                }}
              >
                Add Library
              </Button>
            }
          />
        </Grid>
        {showAddEdit && (
          <FormDialogAddEditLibrary
            fullWidth
            show={showAddEdit}
            data={initialData}
            title={initialData ? 'Edit item' : 'Add new item'}
            handleClose={() => {
              setShowAddEdit(false);
              setTimeout(() => {
                setInitialData(null);
              }, 200);
            }}
            handleSave={(data) => {
              setShowAddEdit(false);
              setTimeout(() => {
                setInitialData(null);
              }, 200);
              getAllLibraryAdmin();
            }}
          ></FormDialogAddEditLibrary>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Library;
