/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  Box,
  Button,
  DialogActions,
  Step,
  StepLabel,
  Stepper,
  Alert,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import { Container } from '@mui/system';
import SelectRecipient from './SelectRecepient';
import ChooseTemplate from './ChooseTemplate';
import ReviewSend from './ReviewSend';
import AddRecepientIcon from 'common/images/add_recepient.png';
import AddRecepientActiveIcon from 'common/images/add_recepient_white.png';
import TemplateIcon from 'common/images/template.png';
import TemplateActiveIcon from 'common/images/template_white.png';
import MailIcon from 'common/images/mail.png';
import MailActiveIcon from 'common/images/mail_white.png';
import CompletedIcon from 'common/images/completed.png';
import useStyles from './SendEmail.styles';
import Image from 'components/primitives/Image';
import { ISendEmail } from './SendEmail.props';
import { ITemplate } from 'interfaces/ITemplates';
import { IUser } from 'interfaces/IUser';
import useCommonStyles from '../../../../../common/styles/common.styles';
import apiFetch from '../../../../../services/apiFetch';
import {
  ApiAdminCreateMailbox,
  ApiAdminCreateMailboxCounties,
  createMailboxAdmin,
  createMailboxAdminCounties
} from '../../../../../services/apiAdminMailbox';
import SelectRegionContainer from './SelectRegion/SelectRegion.container';
import useSelectRegionForm from './SelectRegion/hooks/useSelectRegionForm';
import RecipientsTabsEnum from '../../../../../common/enums/SelectRecipientsTabsEnum';
import RecipientsTabs from 'common/arrays/RecipientsTabs';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import SelectRegionView from './SelectRegion/SelectRegion.view';

// const steps = ['Select Recipients', 'Choose Template', 'Review & Send'];

const SendMail: React.FC<ISendEmail> = ({ steps, handleClose }) => {
  const commonClasses = useCommonStyles();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [recipients, setRecipients] = useState<(IUser & { id: string })[]>([]);
  const [newRecipients, setNewRecipients] = useState<(IUser & { id: string })[]>([]);

  const [template, setTemplate] = useState<ITemplate | null>(null);
  const [newTemplate, setNewTemplate] = useState<ITemplate | null>(null);

  const [counties, setCounties] = useState<any>([]);
  // const [recipientType, setRecipientType] = useState<any>('');

  const [subject, setSubject] = useState<string | null>(null);
  const [body, setBody] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedTab, setSelectedTab] = useState<RecipientsTabsEnum>(
    RecipientsTabs[0].type as RecipientsTabsEnum
  );

  const form = useSelectRegionForm();
  function displayTabContent(
    tab: RecipientsTabsEnum,
    form: any,
    setNewRecipients: any,
    recipients: (IUser & { id: string })[]
  ) {
    switch (tab) {
      case RecipientsTabsEnum.GENERALSELECTION:
        return (
          <FormProvider {...form}>
            <form>
              <SelectRegionView />
            </form>
          </FormProvider>
        );
      default:
        return <SelectRecipient setNewRecipients={setNewRecipients} selectedData={recipients} />;
    }
  }

  const recipientTabs = () => {
    return (
      <Container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleChange}>
              {RecipientsTabs.map((tab) => (
                <Tab key={`tab-${tab.type}`} label={tab.title} {...a11yProps(tab.type)} />
              ))}
            </Tabs>
          </Box>
          {displayTabContent(selectedTab, form, setNewRecipients, recipients)}
        </Box>
      </Container>
    );
  };

  const handleChange = (event: React.SyntheticEvent, tab: RecipientsTabsEnum) => {
    setSelectedTab(tab);
  };

  const a11yProps = (index: number) => {
    return {
      id: `dhec-tab-${index}`,
      'aria-controls': `dhec-tab-${index}`
    };
  };

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setCounties(data.counties);
  };

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  const displayContent = (step: number) => {
    if (step === 1) return recipientTabs();
    // return <SelectRecipient setNewRecipients={setNewRecipients} selectedData={recipients} />;
    if (step === 2)
      return (
        <ChooseTemplate
          setNewTemplate={setNewTemplate}
          template={template}
          selectedTab={selectedTab}
        />
      );
    if (step === 3)
      return <ReviewSend template={template} setSubject={setSubject} setBody={setBody} />;
  };

  const CustomStepIcon = (p: any) => {
    const { active, completed } = p;
    const stepIcons = [AddRecepientIcon, TemplateIcon, MailIcon];
    const stepActiveIcons = [AddRecepientActiveIcon, TemplateActiveIcon, MailActiveIcon];

    if (completed) return <Image src={CompletedIcon} className={classes.img} />;

    return (
      <Image
        src={active ? stepActiveIcons[p.icon - 1] : stepIcons[p.icon - 1]}
        className={classes.img}
      />
    );
  };

  const OptionalMessage = (props: any) => {
    return (
      <Typography variant="body2" sx={{ color: props?.isActive ? '#fff' : '#7B7B7B' }}>
        {props?.message || ''}
      </Typography>
    );
  };

  useEffect(() => {
    setErrorMessage(null);
  }, [recipients]);

  const onChangeStep = (step: number) => {
    if (step === 1) {
      if (selectedTab === 0) {
        form.handleSubmit((data) => {
          onSubmit(data);
          setStep(step);
          setErrorMessage(null);
        })();
      } else if (selectedTab === 1) {
        if (newRecipients.length > 0) {
          setRecipients(newRecipients);
          setStep(step);
          setErrorMessage(null);
        } else {
          setErrorMessage('You must select at least one recipient!');
        }
      }
    }

    if (step === 2) {
      if (newTemplate) {
        setTemplate(newTemplate);
        setStep(step);
        setErrorMessage(null);
      } else {
        setErrorMessage('You must select a template!');
      }
    }
  };

  const onSendEmail = async () => {
    let allowSend = true;
    if (!body) {
      setErrorMessage('You must set the email content!');
    }
    if (!subject) {
      setErrorMessage('You must set the email subject!');
    }

    if (template?.hasMagicURL && !body?.includes('{{magic_URL}}')) {
      setErrorMessage('The template requires a {{magic_URL}} tag');
      allowSend = false;
    }

    if (body && subject && template && allowSend) {
      setLoading(true);
      if (selectedTab === 0) {
        const data: ApiAdminCreateMailboxCounties = {
          templateID: template?.id ?? '',
          body: body,
          subject: subject,
          counties: counties,
          hasMagicURL: template.hasMagicURL
        };
        const newMailbox = await apiFetch(createMailboxAdminCounties(data));
        if (newMailbox && newMailbox.status === 200) {
          setLoading(false);
          handleClose();
        }
      } else if (selectedTab === 1) {
        const data: ApiAdminCreateMailbox = {
          templateID: template?.id ?? '',
          body: body,
          subject: subject,
          recipients: recipients,
          hasMagicURL: template.hasMagicURL
        };
        const newMailbox = await apiFetch(createMailboxAdmin(data));

        if (newMailbox && newMailbox.status === 200) {
          setLoading(false);
          handleClose();
        }
      }
      setErrorMessage(null);
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          padding: '0 0 10px 0',
          minHeight: '70vh',
          maxHeight: '70vh',
          overflowY: 'auto'
        }}>
        <Container sx={{ maxWidth: '100% !important' }}>
          <Box sx={{ width: '100%' }}>
            <Stepper
              nonLinear={false}
              activeStep={activeStep}
              connector={null}
              sx={{ display: 'flex', gap: '15px' }}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  completed={completed[index]}
                  sx={{ flexGrow: index === activeStep ? '2' : '1', padding: 0 }}>
                  <div
                    className={`${
                      index === activeStep
                        ? classes.activeStepContainer
                        : classes.inactiveStepContainer
                    }`}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      optional={<OptionalMessage message="" isActive={index === activeStep} />}>
                      <Typography
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            lg: 'block',
                            xl: 'block'
                          }
                        }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </div>
                </Step>
              ))}
            </Stepper>
            {errorMessage && (
              <Alert sx={{ marginTop: '20px' }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <>{displayContent(activeStep + 1)}</>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          autoFocus
          color="secondary">
          Cancel
        </Button>
        {step > 0 && (
          <Button
            onClick={() => {
              setStep(step - 1);
            }}
            color="primary">
            Back
          </Button>
        )}

        {step >= 0 && step < 2 && (
          <Button
            type="submit"
            className={commonClasses.blueButton}
            onClick={() => {
              onChangeStep(step + 1);
            }}>
            Next
          </Button>
        )}
        {step === 2 && (
          <Button
            type="submit"
            className={commonClasses.blueButton}
            disabled={loading}
            onClick={() => {
              onSendEmail();
            }}>
            Send
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default SendMail;
