import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        // borderColor: 'rgba(48, 48, 49, 0.08)',
        boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.20), 0px 0px 1px rgba(0, 0, 0, 0.040);'
      },
      rounded: {
        borderRadius: '8px'
      },
      elevation3: {
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)'
      }
    }
  };
};
