/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, TableRow, TableCell } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IStaffDirectory } from './StaffDirectory.props';
import SearchInput from 'components/primitives/SearchInput';
import useCommonStyles from 'common/styles/common.styles';
import FormDialog from 'components/fragments/FormDialog';
import AddStaffForm from './AddStaffForm';
import ReactTableContainer from 'components/fragments/ReactTable';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import StaffDirectoryHeaders from 'common/arrays/StaffDiretoryHeaders';
import StaffDirectoryEmptyView from './StaffDirectory.empty';
import apiFetch from 'services/apiFetch';
import {
  createNewStaff,
  getStaffMembersPermitHolder,
  getAllStaffsCounter,
  updateStaffByID
} from 'services/permitHolderEndPoints';
import { CellProps } from 'react-table';
import Image from 'components/primitives/Image';
import EditBlackIcon from 'common/images/edit_black_icon.png';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAccount } from 'store/reducers/account';
import { updateSnackbarMessage } from '../../../../store/reducers/snackbarMessage.reducer';
import parsTableHeader from '../../../../common/Utils/parseTableHeader';
import BusinessesHeaders from '../../../../common/arrays/BusinessesHeaders';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const localStorageKey = 'PH_STAFF_DIRECTORY';
const DEFAULT_ORDER_BY = 'facility asc';

interface IStaffDirectoryAppProps {
  businesses: [];
}

const StaffDirectory: React.FC<IStaffDirectoryAppProps> = ({ businesses }) => {
  const dispatch = useAppDispatch();
  const localCols = localStorage?.getItem(localStorageKey) || null;

  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), StaffDirectoryHeaders)
    : StaffDirectoryHeaders;

  const account = useAppSelector(selectAccount);

  const commonClasses = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddStaff, setOpenAddStaff] = useState<boolean>(false);
  const [columnsTable, setColumnsTable] = useState<ColumnsProperties[]>(defColTable);
  const [searchKeyword, setSearchKeyWord] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'facility', desc: false }]);
  const [toUpdateForm, setToUpdateForm] = useState<IStaffDirectory>({});
  const [data, setData] = useState([]);
  const [actionType, setActionType] = useState<string>('');
  const [currentFilterParams, setCurrentFilterParams] = useState<string>('');
  const [isFetchingDataStaff, setIsFetchingDataStaff] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleFieldChange = (field: string, value: any) => {
    const cloneObj: IStaffDirectory = { ...toUpdateForm };

    if (field === 'phoneNumber') {
      cloneObj[field] = value ? value.replace(/-/g, '') : value;
    } else {
      cloneObj[field] = value;
    }

    setToUpdateForm(cloneObj);
  };

  const handleSelectChange = (field: string, value: any) => {
    const cloneObj = { ...toUpdateForm };

    if (field === 'businessID') {
      cloneObj[field] = value.value;
      cloneObj['facility'] = value.label;
    } else cloneObj[field] = value.label;

    setToUpdateForm(cloneObj);
  };

  const getStaffMembers = async (
    filter: string,
    keyword: string | null,
    offset: number,
    orderBy: string,
    getTotalCount?: boolean
  ) => {
    try {
      let keywordString = '';
      if (keyword && keyword.length > 0) {
        setIsFetchingDataStaff(false);
        keywordString = `&keyword=${keyword}`;
      } else {
        setIsFetchingDataStaff(true);
      }
      if (getTotalCount) {
        const businessData = await apiFetch(
          getStaffMembersPermitHolder(filter, keywordString, offset, orderBy)
        );
        const businessDataCount = await apiFetch(getAllStaffsCounter(keyword, filter));
        if (
          businessData &&
          businessData.status === 200 &&
          businessDataCount &&
          businessDataCount.status === 200
        ) {
          const data = businessData.data.response;
          const totalCount = businessDataCount.data.response;
          setData(data);
          setTotalCount(totalCount);
        }
      } else {
        const businessData = await apiFetch(
          getStaffMembersPermitHolder(filter, keywordString, offset, orderBy)
        );
        if (businessData && businessData.status === 200) {
          const data = businessData.data.response;
          setData(data);
        }
      }
      setIsFetchingDataStaff(false);
    } catch (error: any) {
      console.log('ERROR GET STAFF MEMBERS', error);
    }
  };

  const onChangeSort = (filter: string, isSortedDesc: boolean, filterParams = '') => {
    const newSortValue = [{ id: filter, desc: isSortedDesc }];
    const sortString = `${filter} ${isSortedDesc ? 'desc' : 'asc'}`;
    setCurrentOrderBy(newSortValue);
    setCurrentFilterParams(filterParams);
    getStaffMembers(filterParams, currentKeyword, currentOffset, `&orderBy=${sortString}`, false);
  };

  const onSaveForm = async (data: any, resetForm: any) => {
    const toSave = Object.assign(data, { userID: account?.id });

    try {
      setLoading(true);
      let res = await apiFetch(createNewStaff(toSave));
      setLoading(false);

      if (res) {
        if (res.data.errorCode.code === 0) {
          let orderBy = '';
          if (currentOrderBy.length > 0) {
            orderBy = `&orderBy=${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`;
          }
          getStaffMembers('', '', 0, orderBy, true);
          setOpenAddStaff(false);
          resetForm();
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  useEffect(() => {
    if (defColTable) {
      const optidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');

      if (optidx > -1) {
        defColTable[optidx].Cell = ({ cell }: CellProps<object, any>) => {
          const b: IStaffDirectory = cell.row.original;

          return (
            <Button
              variant="outlined"
              size="small"
              className={commonClasses.editButton}
              startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setToUpdateForm(b);
                setOpenAddStaff(true);
                setActionType('edit');
              }}>
              Edit
            </Button>
          );
        };
      }
    }
  }, []);

  const renderEditButton = (dataToEdit: any) => {
    return (
      <Button
        variant="outlined"
        size="small"
        className={commonClasses.editButton}
        startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
        onClick={() => {
          setToUpdateForm(dataToEdit);
          setOpenAddStaff(true);
          setActionType('edit');
        }}>
        Edit
      </Button>
    );
  };

  const updateStaffHandler = async (data: any, resetForm: any) => {
    if (typeof data.businessID === 'object') {
      Object.assign(data, { businessID: data.businessID.value });
    }
    try {
      setLoading(true);
      const result = await apiFetch(updateStaffByID(data.id, data));
      setLoading(false);
      if (result) {
        if (result.data.errorCode.code === 0) {
          let orderBy = '';
          if (currentOrderBy.length > 0) {
            orderBy = `&orderBy=${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`;
          }
          setToUpdateForm({});
          setOpenAddStaff(false);
          setActionType('');
          getStaffMembers('', '', 0, orderBy, true);
          resetForm();
        } else {
          dispatch(updateSnackbarMessage({ show: true, message: result.data.errorCode.message }));
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  return (
    <Container disableGutters sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
      <Grid container alignItems="center" spacing={2} rowSpacing={2}>
        <Grid item container alignItems="center">
          {!isFetchingDataStaff &&
          data.length === 0 &&
          currentKeyword?.length === 0 &&
          currentFilterParams.length === 0 ? (
            <StaffDirectoryEmptyView
              permitHolder={businesses}
              onClick={() => setOpenAddStaff(true)}
            />
          ) : (
            <ReactTableContainer
              isDataFetching={isFetchingDataStaff}
              columns={columnsTable}
              data={data}
              module={localStorageKey}
              primaryKey="id"
              currSortBy={currentOrderBy}
              totalPage={totalCount}
              onChangeSort={onChangeSort}
              renderEditButton={renderEditButton}
              onChangeFilter={(offset, keyword, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }
                setCurrentOffset(offset);
                setCurrentFilterParams(filterParams);
                getStaffMembers(filterParams, keyword || '', offset, orderBy, true);
              }}
              onPageChange={(offset, keyword, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }
                setCurrentOffset(offset);
                setCurrentFilterParams(filterParams);
                getStaffMembers(filterParams, keyword || '', offset, orderBy, false);
              }}
              onSearch={(offset, keyword, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }
                setCurrentKeyword(keyword);
                setCurrentFilterParams(filterParams);
                getStaffMembers(filterParams, keyword, 0, orderBy, true);
              }}
              suffix={
                <Button
                  className={commonClasses.blueButton}
                  sx={{
                    width: isMobile ? '100% !important' : '140px!important',
                    lineHeight: '15px'
                  }}
                  startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                  onClick={() => {
                    setOpenAddStaff(true);
                    setActionType('add');
                  }}>
                  Add Staff
                </Button>
              }
            />
          )}
        </Grid>
      </Grid>

      <FormDialog
        show={openAddStaff}
        loading={loading}
        fullWidth
        actionType={actionType}
        initialData={toUpdateForm}
        form="staff-form"
        title={`${actionType} Staff Member`}
        handleClose={() => {
          setOpenAddStaff(false);
          setActionType('');
        }}
        handleSaveEdit={(values: any, reset: any) => {
          updateStaffHandler(values, reset);
        }}
        handleSave={(values: any, reset: any) => {
          onSaveForm(values, reset);
        }}>
        <AddStaffForm
          data={toUpdateForm}
          handleFieldChange={handleFieldChange}
          handleSelectChange={handleSelectChange}
        />
      </FormDialog>
    </Container>
  );
};

export default StaffDirectory;
