import React from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      variant="outlined"
      placeholder={`Search`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchInput;
