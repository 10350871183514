import React from 'react';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

const BusinessDirectoryHeaders: ColumnsProperties[] = [
  {
    Header: 'Permit Number',
    accessor: 'permitNumbers',
    isSelected: true,
    disableSortBy: true,
    width: 200,
    Cell: (props: any) => {
      if (props.value && props.value.length > 0) {
        const permitNumbers = props.value.map((i: any, key: number) => {
          if (props.value.length - 1 === key) {
            return <p key={key}>{i.permit.PERMIT_NUMBER}</p>;
          }
          return <p key={key}>{i.permit.PERMIT_NUMBER},</p>;
        });
        return <div style={{ display: 'flex', overflowX: 'scroll' }}>{permitNumbers}</div>;
      }
      return 'N/A';
    }
  },
  {
    Header: 'DBA Name',
    accessor: 'doingBusiness',
    isSelected: true
  },
  {
    Header: 'Company Name',
    accessor: 'corporateName',
    isSelected: true,
    width: 200
  },
  {
    Header: 'Facility Type',
    accessor: 'facilityType',
    isSelected: true
  },
  {
    Header: 'County',
    accessor: 'county',
    isSelected: true,
    filterHidden: true,
    disableSortBy: true,
    Cell: (props: any) => {
      if (props.value) {
        return props.value.name;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Regional Office',
    accessor: 'regionalOffice',
    isSelected: true,
    disableSortBy: true,
    width: 200,
    Cell: (props: any) => {
      if (props.origin) {
        return props.origin.county.region.name;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Process',
    accessor: 'process',
    isSelected: true
  },
  {
    Header: 'Employees',
    accessor: 'employessNumber',
    isSelected: true,
    disableSortBy: true,
    filterHidden: true
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSelected: true,
    Cell: (props: any) => {
      if (props.value) {
        const color = props.value === 'accepted' ? 'green' : '#E48833';
        return (
          <span style={{ color, textTransform: 'capitalize', fontWeight: '500' }}>
            {props.value.toUpperCase()}
          </span>
        );
      }
      return 'N/A';
    }
  },
  {
    Header: 'Options',
    accessor: 'options',
    isSelected: true,
    filterHidden: true,
    width: 100
  }
];

export default BusinessDirectoryHeaders;
