import React, { useState, useEffect } from 'react';
import { FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme, Checkbox, Paper } from '@mui/material';
import { uniq } from 'lodash';
import { Typography } from '@mui/material';

const RegionsCardView: React.FC<
  {
    name: string;
    title: string;
    counties: {
      label: string;
      value: string;
    }[];
    watch: UseFormWatch<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
    checked: boolean[];
  } & BoxProps
> = ({ name, title, counties, watch, setValue, setChecked, checked, ...props }) => {
  const theme = useTheme();
  const countiesValues = counties.map((item) => item.value);
  const countiesLabels = counties.map((item) => item.label);
  const classes = {
    root: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxHeight: theme.spacing(25),
      minHeight: theme.spacing(15)
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    countiesWrapper: {
      display: 'flex',
      textAlign: 'left'
    }
  };
  // const [checked, setChecked] = useState([false, false]);
  const selectedCounties = watch(name);
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const checkHasAll = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));
    const checkSome = (arr: string[], target: string[]) => target.some((v) => arr.includes(v));

    const updateCheckBoxStatus = () => {
      if (selectedCounties.length === 0) return setChecked([false, false]);

      if (checkHasAll(selectedCounties, countiesValues)) {
        return setChecked([true, true]);
      } else if (checkSome(selectedCounties, countiesValues)) {
        return setChecked([true, false]);
      } else {
        return setChecked([false, false]);
      }
    };

    updateCheckBoxStatus();
  }, [selectedCounties, counties]);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
    let currCountyValue: string[] = watch(name); // get the current county list
    if (event.target.checked) {
      // Add all counties from checked Region
      currCountyValue = currCountyValue.concat(countiesValues);
      currCountyValue = uniq(currCountyValue);
      setValue(name, currCountyValue);
    } else {
      //  Remove all counties from specified Region
      const filteredCounties: string[] = [];
      currCountyValue.map((option) => {
        const idx = countiesValues.findIndex((county) => county === option);
        if (idx === -1) filteredCounties.push(option);
      });
      setValue(name, filteredCounties);
    }
    currCountyValue = currCountyValue.concat(countiesValues);
  };

  return (
    <Box {...props}>
      <Paper sx={classes.root}>
        <Box sx={classes.titleContainer}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            {title}
          </Typography>
          <Checkbox
            size="small"
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleCheckBox}
          />
        </Box>
        <Box sx={classes.countiesWrapper}>
          <Typography variant="body2">{countiesLabels.join(', ')}</Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default RegionsCardView;
