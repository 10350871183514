import React from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { ImanageColumns } from './ManageColumns.props';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import useStyles from './ManageColumns.styles';

const ManageColumns: React.FC<ImanageColumns> = ({
  title = '',
  subtitle = '',
  items = [],
  handleChange
}) => {
  const classes = useStyles();
  const totalEnabled = items.filter((c: ColumnsProperties) => c.isSelected)?.length || 0;

  return (
    <Container maxWidth="lg" sx={{ padding: '15px!important' }}>
      {/* Header Title */}
      <Box className={classes.headerContainer}>
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 300 }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {/* Body */}
      <Box>
        {items
          .filter((item) => {
            return !item.disableColumnSettings;
          })
          .map((item: ColumnsProperties, idx: number) => {
            return (
              <Stack
                key={idx}
                direction="row"
                className={classes.listItemContainer}
                sx={{ display: item?.categoryHidden ? 'none' : '' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      key={idx}
                      checked={item?.isSelected}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (handleChange) {
                          if (!e.target.checked && totalEnabled === 1) return;

                          handleChange(e.target.checked, item.accessor);
                        }
                      }}
                    />
                  }
                  label={<Typography variant="body2">{item?.Header}</Typography>}
                />
                {/* <Checkbox
                key={idx}
                checked={item?.isSelected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (handleChange) handleChange(e.target.checked, item.accessor);
                }}
              />
              {item?.Header} */}
              </Stack>
            );
          })}
      </Box>
    </Container>
  );
};

export default ManageColumns;
