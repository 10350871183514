import { Nullable } from 'common/Utils/Nullable';
import { IUser } from "interfaces/IUser";


export const initialSurveysRecipientsState: SurveysRecipientsState = {
  users: []
};

type SurveysRecipientsState = Nullable<{
  users: (IUser & { id: string; select: boolean })[]
}>;

export default SurveysRecipientsState;
