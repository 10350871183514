import { SurveyState } from '../index';
import { SurveyTriggerOptionsInterface } from '../../interfaces/survey.interface';

export const surveysTemplateGetSelector = (state: SurveyState) => state.surveyTemplateGet.entities;
export const surveysTemplateGetSelectorSelect = (state: SurveyState) => {
  if (state.surveyTemplateGet.entities && state.surveyTemplateGet.entities.length > 0) {
    return state.surveyTemplateGet.entities.map((options: any, idx: number) => {
      return { value: idx, label: options.title };
    });
  }
  return [];
};

export const surveysTemplateGetLoading = (state: SurveyState) => state.surveyTemplateGet.loading;
