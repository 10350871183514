import {
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  FormGroup as MuiFormGroup,
  Typography,
  Box
} from '@mui/material';
import FormGroup from 'components/primitives/FormGroup';
import RadioSelection from 'components/primitives/RadioSelection';
import React, { useEffect, useState } from 'react';
import TextField from 'components/primitives/TextField';
import Access from 'common/arrays/Access';
import AccessAreas from 'common/arrays/AccessAreas';
import AccessType from 'common/enums/AccessType';
import AccessAreaType from 'common/enums/AccessAreasType';
import { IEditAdminUserForm } from './EditAdminUserForm.props';
import { formatPhone } from 'common/Utils/String';
import ConfirmDialog from 'components/fragments/ConfirmDialog';
import { loadingStyle } from '../SurveysResponse/components/SurveyContainer/SurveyContainer.styles';
import CircularProgress from '@mui/material/CircularProgress';

const DEFAULT_ACCESS_VALUE = [
  AccessAreaType.BUSINESS_DIRECTORY,
  AccessAreaType.MAILBOX,
  AccessAreaType.USERS,
  AccessAreaType.LIBRARY,
  AccessAreaType.SURVEYS
];

const EditUserForm: React.FC<IEditAdminUserForm> = ({
  errors,
  toUpdateData = {},
  handleInputChange,
  handleSavePermissions,
  showConfirmDialog = false,
  handleConfirmDialogClose,
  handleAgree,
  isLoading = false
}) => {
  const [access, setAccess] = useState<number>(Access[0].value);
  const [accessAreas, setAccessAreas] = useState<string[]>(DEFAULT_ACCESS_VALUE);
  const [allDisabled, setAllDisabled] = useState<boolean>(false);
  const [userManagementDisabled, setUserManagementDisabled] = useState<boolean>(true);
  const getAccessLevelStatus = (permissions: string[]) => {
    if (toUpdateData.role === 'superadmin') return Access[0].value;
    else if ((permissions || []).length === DEFAULT_ACCESS_VALUE.length) return Access[1].value;
    else if (
      (permissions || []).length > 0 &&
      (permissions || []).length < DEFAULT_ACCESS_VALUE.length
    )
      return Access[2].value;

    return Access[3].value;
  };

  const initData = () => {
    const access = getAccessLevelStatus(toUpdateData?.permissions || []);
    let accessAreas;
    const isSuperAdmin = toUpdateData?.role === 'superadmin' ? true : false;
    if (isSuperAdmin) {
      accessAreas = [...DEFAULT_ACCESS_VALUE, AccessAreaType.USER_MANAGEMENT];
      setUserManagementDisabled(false);
    } else {
      accessAreas = toUpdateData?.permissions || [];
    }
    const isDisabled = access === AccessType.DISABLED;
    setAccess(access);
    setAccessAreas(accessAreas);
    setAllDisabled(isDisabled);
    const stringAccess = getAccessLevelValues(accessAreas);
    handleSavePermissions(stringAccess);
  };

  const getAccessLevelValues = (accAreas: (string | number)[] = []) => {
    const stringAccess: string[] = [];

    if (accAreas.length > 0) {
      for (let i = 0; i < accAreas.length; i++) {
        stringAccess.push(accAreas[i] as string);
      }
    }

    return stringAccess;
  };

  const removeUserManagementAccess = (fields: string[]) => {
    return fields.filter((el) => el !== AccessAreaType.USER_MANAGEMENT);
  };
  const handleRadioChange = (value: number) => {
    setAccess(value);
    let stringAccess: string[] = [];
    let newVal: string[] = [];

    if (value === AccessType.SUPER_ADMIN) {
      newVal = [...DEFAULT_ACCESS_VALUE, AccessAreaType.USER_MANAGEMENT];
      setAllDisabled(false);
      setUserManagementDisabled(false);
    } else if (value === AccessType.FULL_ACCESS) {
      newVal = DEFAULT_ACCESS_VALUE;
      setAllDisabled(false);
      setUserManagementDisabled(true);
    } else if (value === AccessType.DISABLED) {
      newVal = [];
      setAllDisabled(true);
      setUserManagementDisabled(true);
    } else if (value === AccessType.CUSTOM) {
      newVal = removeUserManagementAccess(accessAreas);
      setAllDisabled(false);
      setUserManagementDisabled(true);
    }
    setAccessAreas(newVal);
    stringAccess = getAccessLevelValues(newVal);
    handleSavePermissions(stringAccess);
  };

  const handleCheckboxChange = (value: string) => {
    let cloneAccessArea = [...accessAreas];
    if (cloneAccessArea.indexOf(AccessAreaType.USER_MANAGEMENT) > -1) {
      cloneAccessArea = removeUserManagementAccess(cloneAccessArea);
    }
    const idx = accessAreas.findIndex((a) => a === value);

    if (idx === -1) cloneAccessArea.push(value);
    else cloneAccessArea.splice(idx, 1);

    const stringAccess: string[] = getAccessLevelValues(cloneAccessArea);

    handleSavePermissions(stringAccess);
    setAccessAreas(cloneAccessArea);
    if (cloneAccessArea.length === DEFAULT_ACCESS_VALUE.length) {
      setAccess(AccessType.FULL_ACCESS);
      setUserManagementDisabled(true);
    } else {
      setAccess(AccessType.CUSTOM);
      setUserManagementDisabled(true);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container>
      {isLoading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item lg={12}>
          <Typography variant="h6" color="textSecondary">
            General
          </Typography>
        </Grid>
      </Grid>
      <form noValidate>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item lg={4}>
                <FormGroup error={errors?.firstName || null} label={'First Name'}>
                  <TextField
                    error={!!errors?.firstName}
                    value={toUpdateData.firstName || ''}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={4}>
                <FormGroup error={errors?.lastName || null} label={'Last Name'}>
                  <TextField
                    error={!!errors?.lastName}
                    value={toUpdateData.lastName || ''}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={4}>
                <FormGroup error={errors?.phoneNumber || null} label={'Phone Number'}>
                  <TextField
                    error={!!errors?.phoneNumber}
                    value={formatPhone(toUpdateData.phoneNumber) || ''}
                    onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={6}>
                <FormGroup error={errors?.email || null} label={'Email Address'}>
                  <TextField
                    error={!!errors?.email}
                    value={toUpdateData.email || ''}
                    autoComplete="new-email"
                    onChange={(e) => handleInputChange('email', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={6}>
                <FormGroup error={errors?.password || null} label={'Password'}>
                  <TextField
                    error={!!errors?.password}
                    type="password"
                    autoComplete="new-password"
                    value={toUpdateData.password || ''}
                    onChange={(e) => handleInputChange('password', e.target.value)}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={1} rowSpacing={2}>
            <Grid item lg={12}>
              <Typography variant="h6" color="textSecondary">
                Access
              </Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12}>
            <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
              <RadioSelection
                selections={Access}
                value={access}
                handleChange={(e: number) => handleRadioChange(e)}
                lgStyle={3}
              />
              <Typography variant="body1" color="textSecondary" sx={{ m: '10px 0' }}>
                Access Areas
              </Typography>
              <Divider />
              <Container disableGutters>
                <MuiFormGroup row={false}>
                  {AccessAreas.map((a: { value: string; label: string }) => {
                    if (a.value !== AccessAreaType.USER_MANAGEMENT) {
                      return (
                        <FormControlLabel
                          key={a.value}
                          control={
                            <Checkbox
                              disableRipple
                              disabled={allDisabled}
                              checked={accessAreas.includes(a.value)}
                              onChange={() => handleCheckboxChange(a.value)}
                            />
                          }
                          label={a.label}
                        />
                      );
                    }
                    return (
                      <FormControlLabel
                        key={a.value}
                        control={
                          <Checkbox
                            disableRipple
                            disabled={userManagementDisabled}
                            checked={accessAreas.includes(a.value)}
                            onChange={() => handleCheckboxChange(a.value)}
                          />
                        }
                        label={
                          accessAreas.includes(a.value)
                            ? a.label
                            : `User Management - Only Super Admins can manage users`
                        }
                      />
                    );
                  })}
                </MuiFormGroup>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <ConfirmDialog
        handleAgree={handleAgree}
        handleConfirmDialogClose={handleConfirmDialogClose}
        isOpen={showConfirmDialog}
      />
    </Container>
  );
};

export default EditUserForm;
