import React from 'react';
import { ITabWithBadge } from './TabWithBadge.props';
import useStyles from './TabWithBadge.styles';

const TabWithBadge: React.FC<ITabWithBadge> = ({ title = '', count = 0 }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {title} <div className={classes.badge}>{count}</div>
    </div>
  );
};

export default TabWithBadge;
