import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';
import { useDispatch } from 'react-redux';

import { surveyListSlice } from './surveys-list/surveys-list.reduce';
import { surveyListCountSlice } from './surveys-list-count/surveys-list-count.reduce';
import { surveyTriggerOptionsSlice } from './surveys-trigger-options/surveys-trigger-options.reduce';
import { surveyTriggerQuestionsSlice } from './surveys-trigger-questions/surveys-trigger-options.reduce';
import { surveyTemplateCreateSlice } from './surveys-template-create/surveys-template.reduce';
import { surveyTemplateGetSlice } from './surveys-template-get/surveys-template-get.reduce';
import { snackbarMessageSurveyStateSlice } from './snackbar-message/snackbar-message.reducer';
import { surveysRecipientsStateSlice } from './surveys-recipients/surveys-recipients.reducer';
import { surveysCountiesStateSlice } from './survey-counties/surveys-counties.reducer';
import { surveyCampaignCreateSlice } from './surveys-campaign-create/surveys-campaign.reduce';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage
};

const childReducer = combineReducers({
  surveyList: surveyListSlice.reducer,
  surveyListCount: surveyListCountSlice.reducer,
  surveyTriggerOptions: surveyTriggerOptionsSlice.reducer,
  surveyTriggerQuestions: surveyTriggerQuestionsSlice.reducer,
  surveysTemplateCreate: surveyTemplateCreateSlice.reducer,
  surveyTemplateGet: surveyTemplateGetSlice.reducer,
  snackbarMessageSurvey: snackbarMessageSurveyStateSlice.reducer,
  surveysRecipients: surveysRecipientsStateSlice.reducer,
  surveysCounties: surveysCountiesStateSlice.reducer,
  surveyCampaignCreate: surveyCampaignCreateSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, childReducer);

export const storeSurvey = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type AppSurveyDispatch = typeof storeSurvey.dispatch;
export const useSurveyDispatch = () => useDispatch<AppSurveyDispatch>();

export type SurveyState = ReturnType<typeof storeSurvey.getState>;
export type surveyTriggerOptionsState = ReturnType<typeof storeSurvey.getState>;
export type surveyTriggerQuestionsState = ReturnType<typeof storeSurvey.getState>;
