import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

const StaffDirectoryHeaders: ColumnsProperties[] = [
  {
    Header: 'Facility Name',
    accessor: 'facility',
    isSelected: true
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    isSelected: true
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    isSelected: true
  },
  {
    Header: 'Position',
    accessor: 'jobTitle',
    isSelected: true
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    isSelected: true
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    isSelected: true,
    width: 200
  },
  {
    Header: 'Options',
    accessor: 'options',
    disableSortBy: true,
    isSelected: true,
    filterHidden: true,
    categoryHidden: true,
    width: 100
  }
];

export default StaffDirectoryHeaders;
