import AccessType from 'common/enums/AccessType';
import { IRadioSelection } from 'interfaces/IRadioSelection';

const Access: IRadioSelection[] = [
  {
    value: AccessType.SUPER_ADMIN,
    label: 'Super Admin'
  },
  {
    value: AccessType.FULL_ACCESS,
    label: 'Full Access'
  },
  {
    value: AccessType.CUSTOM,
    label: 'Custom'
  },
  {
    value: AccessType.DISABLED,
    label: 'Disabled'
  }
];

export default Access;
