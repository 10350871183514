import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Provider, useSelector } from 'react-redux';

import ReactTableContainer from 'components/fragments/ReactTable';
import useCommonStyles from 'common/styles/common.styles';
import AddCampaignForm from './components/AddCampaignForm';
import { getSurveyList } from './store/surveys-list/surveys-list.trunk';
import { storeSurvey, useSurveyDispatch } from './store';
import { surveysListSelector } from './store/surveys-list/surveys-list.selector';
import { getSurveyListCount } from './store/surveys-list-count/surveys-list-count.trunk';
import { surveysListCountSelector } from './store/surveys-list-count/surveys-list-count.selector';
import SurveyHeaders from './arrays/SurveyHeaders';
import { ApiAdminMailboxFilters } from './interfaces/survey.interface';
import { ISurveys } from './surveys.props';
import CampaignQuestions from './components/CampaignQuestions';
import SurveyFormDialog from './Fragments/SurveyFormDialog';
import CampaignSurveyFormDialog from './Fragments/CampaignSurveyFormDialog';
import SnackbarMessage from './Fragments/SnackbarMessage';
import parsTableHeader from 'common/Utils/parseTableHeader';
import { ColumnsProperties } from '../../../fragments/ReactTable/ReactTable.props';
import { CellProps } from 'react-table';
import { ISurvey } from 'interfaces/ISurvey';
import Image from '../../../primitives/Image';
import PrinterIcon from '../../../../common/images/printer.png';
import useStyles from '../Library/Library.styles';
import { useAppSelector } from '../../../../app/hooks';
import { selectTokens } from '../../../../store/reducers/token';

const localStorageKey = 'ADMIN_SURVEYS';

const SurveysView: React.FC<ISurveys> = ({}) => {
  const classes = useStyles();
  const token = useAppSelector(selectTokens) || null;
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), SurveyHeaders)
    : SurveyHeaders;
  const commonClasses = useCommonStyles();

  const [openCreateSurvey, setOpenCreateSurvey] = useState<boolean>(false);
  const [openCreateCampaign, setOpenCreateCampaign] = useState<boolean>(false);

  const dispatch = useSurveyDispatch();

  const [currentOrderBy, setCurrentOrderBy] = useState<{ id: string; desc: boolean }[]>([
    { id: 'updatedAt', desc: true }
  ]);

  const surveysList = useSelector(surveysListSelector);
  const surveysListCount = useSelector(surveysListCountSelector);
  const [surveyFilter, setSurveyFilter] = useState<ApiAdminMailboxFilters>({
    limit: 10,
    offset: 0
  });
  const [isFetchingSurveys, setIsFetchingSurveys] = useState<boolean>(true);

  useEffect(() => {
    const surveyListDispatch = dispatch(getSurveyList(surveyFilter));
    const surveyListCountDispatch = dispatch(getSurveyListCount(surveyFilter));
    return () => {
      surveyListDispatch.abort();
      surveyListCountDispatch.abort();
    };
  }, [surveyFilter]);

  useEffect(() => {
    if (surveysList?.length > 0) {
      setIsFetchingSurveys(false);
    }
  }, [surveysList]);

  useEffect(() => {
    if (defColTable) {
      const options = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');

      if (options > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[options].Cell = ({ cell }: CellProps<object, any>) => {
          const survey: ISurvey = cell.row.original as ISurvey;
          return (
            <Button
              variant="outlined"
              size="small"
              className={classes.editButton}
              startIcon={<Image src={PrinterIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                const tokenId = token.id;
                window.open(
                  `${process.env.REACT_APP_PALMETTO_API}api/admin/survey/export/${survey.id}?access_token=${tokenId}`,
                  '_blank'
                );
              }}>
              Export
            </Button>
          );
        };
      }
    }
  }, []);

  return (
    <Provider store={storeSurvey}>
      <Container disableGutters sx={{ padding: '20px 0 10px 0' }}>
        <Grid container alignItems="center" spacing={2} rowSpacing={2}>
          <Grid item container alignItems="center">
            <ReactTableContainer
              columns={defColTable}
              primaryKey="id"
              module={localStorageKey}
              data={surveysList ?? []}
              isDataFetching={isFetchingSurveys}
              totalPage={surveysListCount}
              currSortBy={currentOrderBy}
              filters={false}
              onChangeSort={(filter: string, isSortedDesc: boolean) => {
                const newSortValue = [{ id: filter, desc: isSortedDesc }];
                setCurrentOrderBy(newSortValue);
                setSurveyFilter((currentState) => {
                  return {
                    ...currentState,
                    offset: 0,
                    orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
                  };
                });
              }}
              onPageChange={(offset) => {
                setSurveyFilter((currentState) => {
                  return {
                    ...currentState,
                    offset: offset
                  };
                });
              }}
              onSearch={(offset, keyword) => {
                setSurveyFilter((currentState) => {
                  return {
                    ...currentState,
                    offset: 0,
                    keyword: keyword
                  };
                });
              }}
              suffix={
                <div className={commonClasses.suffixWrapper}>
                  <Grid item>
                    <Button
                      className={commonClasses.whiteButton}
                      startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                      sx={{ width: '160px!important' }}
                      onClick={() => setOpenCreateSurvey(true)}>
                      Create Survey
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={commonClasses.blueButton}
                      startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                      sx={{ width: '190px!important' }}
                      onClick={() => setOpenCreateCampaign(true)}>
                      Create Campaign
                    </Button>
                  </Grid>
                </div>
              }
            />
          </Grid>
        </Grid>
        {openCreateCampaign && (
          <CampaignSurveyFormDialog
            show={true}
            fullWidth
            title={'Create Campaign'}
            handleClose={() => setOpenCreateCampaign(false)}>
            <AddCampaignForm handleClose={() => setOpenCreateCampaign(false)} />
          </CampaignSurveyFormDialog>
        )}
        {openCreateSurvey && (
          <SurveyFormDialog
            show={true}
            fullWidth
            title={'Create Survey'}
            handleClose={() => setOpenCreateSurvey(false)}>
            <CampaignQuestions />
          </SurveyFormDialog>
        )}
      </Container>
      <SnackbarMessage />
    </Provider>
  );
};

export default SurveysView;
