import { ErrorInterface } from '../../interfaces/genera.interface';
import {
  SurveyTriggerOptionsInterface,
  SurveyTriggerQuestionsInterface
} from '../../interfaces/survey.interface';

export interface SurveysTriggerQuestionsState {
  entities: SurveyTriggerQuestionsInterface[];
  error: false | ErrorInterface;
  loading: boolean;
  update: boolean;
}

export const initialSurveysTriggerQuestionsState: SurveysTriggerQuestionsState = {
  entities: [],
  error: false,
  loading: false,
  update: false
};
