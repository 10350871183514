import DefaultPaletteOptions from '../defaultPalette';

const palette: DefaultPaletteOptions = {
  primary: {
    light: '#0CACCF', // Light Blue
    main: '#126DA2', // Dark Blue
    dark: '#303031'
  },
  secondary: {
    light: '#7B7B7B',
    main: '#303031', // Dark Gray
    dark: '#49494A',
    // dark: will be calculated from palette.secondary.main,
    contrastText: '#ffcc00'
  },
  text: {
    primary: '#303031',
    secondary: '#49494A' // '#7B7B7B;'
  },
  common: {
    white: '#fff'
  },
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2
};

export default palette;
