import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { DocumentCardProps } from './DocumentCard.props';
import useStyles from './DocumentCard.styles';
import DocumentButtons from '../DocumentsButtons';

const DocumentCard: React.FC<DocumentCardProps> = ({ library }) => {
  const classes = useStyles();

  const [title, setTitle] = useState<String>('');
  const [description, setDescription] = useState<String>('');

  useEffect(() => {
    const data: any = library;
    setTitle(data[`title_EN`]);
    setDescription(data[`description_EN`]);
  }, []);

  return (
    <Card sx={{ maxWidth: 345, height: '100%' }} className={classes.card}>
      <CardContent
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography gutterBottom variant="h5" color="text.secondary" className={classes.linkTitle}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" className={classes.description}>
          {description}
        </Typography>
        <DocumentButtons library={library} />
      </CardContent>
    </Card>
  );
};

export default DocumentCard;
