import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { Checkbox, Grid } from '@mui/material';

import ReactTableContainer from 'components/fragments/ReactTable';
import { ISelectRecipientSurvey } from './SelectRecipientSurvey.props';
import { IUser } from 'interfaces/IUser';
import UserStatusEnum from '../../../../../../common/interfaces/IUser';

import {
  ApiAdminPermitHolderSearch,
  getPermitHolders,
  getPermitHoldersCounter
} from '../../../../../../services/apiAdminPermitHolder';
import apiFetch from '../../../../../../services/apiFetch';
import { ColumnsProperties } from '../../../../../fragments/ReactTable/ReactTable.props';
import { CellProps } from 'react-table';

import MailboxUsersHeader from '../../../../../../common/arrays/MailboxUsersHeaders';
import { useAppDispatch } from '../../../../../../app/hooks';

const localStorageKey = 'ADMIN_MAILBOX_USERS';

const SelectRecipientSurvey: React.FC<ISelectRecipientSurvey> = ({
  selectedData,
  setNewRecipients
}) => {
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols ? JSON.parse(localCols) : MailboxUsersHeader;

  let users: Partial<IUser>[] = [];
  let [data, setData] = useState<Partial<IUser>[]>(users);
  let [dataChange, setDataChange] = useState<any>();
  let [dataFetching, setDataFetching] = useState<boolean>(true);

  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'email', desc: false }]);

  const [checkAll, setCheckAll] = useState<any>();
  const [selectedCheckAll, setSelectedCheckAll] = useState<boolean>(false);
  const [checkedDataState, setCheckedDataState] = useState<
    (IUser & { id: string; select: boolean })[]
  >([]);

  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');

  useEffect(() => {
    const checkValue = checkAll?.checked;

    if (checkValue === true || checkValue === false) {
      const fetchData = async () => {
        let filters: ApiAdminPermitHolderSearch = await getAllUsersFilters(false);
        return await apiFetch(getPermitHolders(filters));
      };

      fetchData().then((res) => {
        if (res.data.response && res.data.response.length > 0) {
          let users = res.data.response;

          if (users && users.length) {
            users = users.map((user: IUser) => {
              user.selected = checkValue;
              return user;
            });

            if (checkedDataState && checkedDataState.length === 0) {
              setCheckedDataState(
                users.filter((item: IUser) => {
                  return item.selected;
                })
              );

              setNewRecipients(
                users.filter((item: IUser) => {
                  return item.selected;
                })
              );

              setData(
                data.map((item) => {
                  item.selected = checkValue;
                  return item;
                })
              );
            } else {
              if (checkValue) {
                // todo add all the users to new and remove duplicates
                for (let currentItem of checkedDataState) {
                  users = users.filter((element: IUser) => {
                    return element.id !== currentItem.id;
                  });
                }

                let userToUpdate = [
                  ...checkedDataState,
                  ...users.filter((item: IUser) => {
                    return item.selected;
                  })
                ];

                setCheckedDataState(userToUpdate);

                setNewRecipients(userToUpdate);
              } else {
                // todo check all the users and remove them from the selected list

                let removeUser = [...checkedDataState];
                for (let user of users) {
                  removeUser = removeUser.filter((element: IUser) => {
                    return element.id !== user.id;
                  });
                }
                setCheckedDataState(removeUser);

                setNewRecipients(removeUser);
              }

              setData(
                data.map((item) => {
                  item.selected = checkValue;
                  return item;
                })
              );
            }
          }
        }
      });
    }
  }, [checkAll]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      let dataItems = structuredClone(data);

      dataItems.map((item: any) => {
        const find = selectedData.filter((element: IUser) => {
          return element.id === item.id;
        });
        if (find && find.length > 0) {
          item.selected = true;
        }

        return item;
      });

      setData(dataItems);

      setCheckedDataState([...(selectedData as any)]);
    }
  }, [selectedData, dataFetching]);

  useEffect(() => {
    getAllUsers();
  }, [currentOffset, currentKeyword, currentFilterParams, currentOrderBy]);

  const getAllUsers = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminPermitHolderSearch = await getAllUsersFilters(true);

    const usersData = await apiFetch(getPermitHolders(filters));

    if (usersData) {
      let userInfo = usersData.data?.response || [];

      if (checkedDataState && checkedDataState.length > 0) {
        userInfo = userInfo.map((user: IUser) => {
          let findSelectedUser = checkedDataState.find((selectedUser) => {
            return selectedUser.id == user.id;
          });

          if (findSelectedUser) {
            user.selected = findSelectedUser.selected;
          }

          return user;
        });
      }
      setData(userInfo);
      setDataFetching(false);
      // users = userInfo;
    }

    if (getTotalCount) {
      const total = await apiFetch(getPermitHoldersCounter(filters));
      setTotalUsers(total.data?.response || 0);
    }
  };

  const getAllUsersFilters = async (
    addLimitAndOffset = true
  ): Promise<ApiAdminPermitHolderSearch> => {
    let filters: ApiAdminPermitHolderSearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`,
      status: UserStatusEnum.active
    };

    if (!addLimitAndOffset) {
      delete filters.limit;
      delete filters.offset;
    }

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    return filters;
  };

  useEffect(() => {
    if (dataChange) {
      setSelectedUsers(dataChange);
      selectedData;
    }
  }, [dataChange]);

  const setSelectedUsers = (dataChangeUser: IUser) => {
    let usersData: any = data.map((item) => {
      if (item.id == dataChangeUser.id) {
        return dataChangeUser;
      }
      return item;
    });

    setData(usersData);

    let checkedDataStatePars: any = [];
    if (checkedDataState && checkedDataState.length > 0) {
      checkedDataStatePars = checkedDataState.filter((user) => {
        return user.id != dataChangeUser.id;
      });
    }

    let newDataSelected: any = checkedDataStatePars.concat([dataChangeUser]).filter((user: any) => {
      return user.selected;
    });

    setCheckedDataState(newDataSelected);
    setNewRecipients(newDataSelected);
  };

  let handleChecked = (value: boolean, user: IUser & { id: string }) => {
    let newUser = { ...user };
    newUser.selected = value;
    setSelectedCheckAll(false);
    setDataChange(newUser);
  };

  useEffect(() => {
    if (defColTable) {
      const id = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'id');
      if (id > -1) {
        const celFunction = ({ cell }: CellProps<object, any>) => {
          const user: IUser & { id: string } = cell.row.original as IUser & { id: string };
          return (
            <Checkbox
              key={user.id}
              checked={user.selected ?? false}
              value={user.id}
              onClick={(event: any) => {
                handleChecked(event.target.checked, user);
              }}
            />
          );
        };
        defColTable[id].Cell = celFunction;
      }
    }
  }, []);

  return (
    <Container
      disableGutters
      maxWidth="lg"
      sx={{
        padding: '20px'
      }}>
      <Grid container alignItems="center" spacing={2} rowSpacing={2}>
        <Grid item container alignItems="center">
          <ReactTableContainer
            primaryKey="id"
            columns={defColTable}
            data={data}
            isDataFetching={dataFetching}
            module={localStorageKey}
            currSortBy={currentOrderBy}
            hasCheckSelection={true}
            setCheckAll={setCheckAll}
            selectedCheckAll={selectedCheckAll}
            setSelectedCheckAll={setSelectedCheckAll}
            totalPage={totalUsers}
            onPageChange={(offset, keyword) => {
              setCurrentOffset(offset);
            }}
            onSearch={(offset, keyword) => {
              setSelectedCheckAll(false);
              setCurrentKeyword(keyword ?? null);
            }}
            filtersData={(filters) => {
              setCurrentFilterParams(filters);
              setCurrentOffset(0);
              setSelectedCheckAll(false);
            }}
            onChangeSort={(filter: string, isSortedDesc: boolean) => {
              const newSortValue = [{ id: filter, desc: isSortedDesc }];
              setCurrentOrderBy(newSortValue);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectRecipientSurvey;
