import { ITabs, UserRoles } from 'common/interfaces/ITabs';
import PermitHolderTabsEnum from 'common/enums/PermitHolderTabsEnum';

const PermitHolderTabs: ITabs[] = [
  {
    type: PermitHolderTabsEnum.PERSONAL_INFORMATION,
    title: 'Personal Information',
    query: 'personal-information',
    locale: 'Personal Information',
    role: UserRoles.permitHolder,
    enabled: true
  },
  {
    type: PermitHolderTabsEnum.BUSINESSES,
    title: 'Businesses',
    query: 'businesses',
    locale: 'Businesses',
    role: UserRoles.permitHolder,
    enabled: true
  },
  {
    type: PermitHolderTabsEnum.STAFF_DIRECTORY,
    title: 'Staff Directory',
    query: 'staff-directory',
    locale: 'Staff Directory',
    role: UserRoles.permitHolder,
    enabled: true
  }
];

export default PermitHolderTabs;
