import { makeCreateActions, presetActions } from 'store/utils/Redux';
import SnackbarMessageState from './snackbar-message.state';
import { snackbarMessageSurveyResponseSlice } from './snackbar-message.reducer';

const ns = 'SNACKBAR_MESSAGE_RESPONSE';
const createTokenActions = makeCreateActions(ns);

const SnackbarMessageActions = createTokenActions({
  update: presetActions.makeIdentity<SnackbarMessageState>(),
  clear: presetActions.noPayload
});

export default SnackbarMessageActions;

export const { updateSnackbarSurveyMessage, clearSnackbarSurveyMessage } =
  snackbarMessageSurveyResponseSlice.actions;
