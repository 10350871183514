import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    editButton: {
      height: '30px',
      minHeight: 'auto',
      border: 0,
      boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039)',
      fontWeight: '400',
      color: '#49494A'
    }
  };
});

export default useStyles;
