import React from 'react';
import { Provider } from 'react-redux';

import SurveyHeader from 'components/snippets/SurveysResponse/components/SurveyHeader';
import SurveyFooter from 'components/snippets/SurveysResponse/components/SurveyFooter';
import { storeSurveyView } from '../store';
import SurveyContainer from '../components/SurveyContainer';

const SurveyLayout: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  // const classes = useStyles();
  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <SurveyHeader />
      <Provider store={storeSurveyView}>
        <SurveyContainer />
      </Provider>
      <SurveyFooter />
    </div>
  );
};

export default SurveyLayout;
