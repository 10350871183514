import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAccountInfo, getProfileToEdit } from 'services/apiEndPoints';
import apiFetch from 'services/apiFetch';
import { selectTokens, updateMagicToken } from 'store/reducers/token';
import { selectAccount, updateAccount } from 'store/reducers/account';
import { updateMyAccount } from 'store/reducers/myAccount';

const ProtectedPage: React.FC = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectTokens) || null;
  const account = useAppSelector(selectAccount) || null;

  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('t');
  const accessToken = searchParams.get('access_token');
  const fetchAccountInfo = async () => {
    let data = await apiFetch(getAccountInfo());
    if (data && data.status === 200) {
      const name = [data?.data?.response?.firstName, data?.data?.response?.lastName].join(' ');
      dispatch(updateAccount(data?.data.response));
      dispatch(updateMyAccount({ show: false, name, email: data?.data?.response?.email }));
    }
  };

  useEffect(() => {
    const urlPath = location.pathname;

    if (urlPath === '/editProfile' && accessToken) {
      dispatch(updateMagicToken({ token: accessToken }));
      apiFetch(getProfileToEdit(accessToken)).then((result) => {
        if (result && !account.role) dispatch(updateAccount(result.data.response));
      });
      return navigate('/editProfile');
    } else if (!account.role && !token?.id) {
      navigate('/');
    } else if (!account.role) {
      fetchAccountInfo();
    } else {
      if (account && account?.role === 'permitholder') {
        navigate(`/permit-holder${query ? `?t=${query}` : ''}`);
      } else {
        navigate(`/dhec-admin${query ? `?t=${query}` : ''}`);
      }
    }
  }, [token]);

  if (!token?.id || !account) {
    return null;
  }

  return <Outlet />;
};

export default ProtectedPage;
