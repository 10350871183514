import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ConfirmDialogProps from "./ConfirmDialog.props"
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
   handleAgree,
   handleConfirmDialogClose,
   isOpen,
   dialogTitle = "Are you sure?",
   dialogDescription = "This will permanently delete the item.",
   textBtnDisagree = "No",
   textBtnAgree = "Yes"
 }) => {
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} autoFocus>
                        {textBtnDisagree}
                    </Button>
                    <Button onClick={handleAgree}>
                        {textBtnAgree}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ConfirmDialog;
