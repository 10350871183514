import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../../services/apiFetch';
import { getSurveyAdmin } from '../../services/apiAdminSurvey';
import { ApiAdminMailboxFilters, SurveyInterface } from '../../interfaces/survey.interface';

export const getSurveyList = createAsyncThunk(
  'survey/list',
  async (filters: ApiAdminMailboxFilters, { rejectWithValue }) => {
    const res = await apiFetch(getSurveyAdmin(filters));
    if (res.data?.errorCode.code === 0) {
      return res.data?.response || ([] as SurveyInterface[]);
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
