/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import apiFetch from '../services/apiFetch';
import { resetPassword } from '../services/apiEndPoints';
import { Nullable } from '../common/Utils/Nullable';
import { FormHelperText, Grid } from '@mui/material';

const InfoErrMessage = {
  MISSING: 'Please enter an email',
  INVALID: 'You have entered an invalid email',
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const Test: React.FC = (props) => {
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [infoMessage, setInfoMessage] = useState<Nullable<string>>(null);

  const onSubmit = async () => {
    let email = 'zawarski@gmail.com';
    try {
      if (email) {
        const reset = await apiFetch(resetPassword(email));

        if (reset.data?.errorCode.code > 0) {
          setErrMessage(reset.data?.errorCode.message || InfoErrMessage.SERVER);
          return;
        } else {
          setInfoMessage('A verification reset password link was sent to your email.');
        }
      } else {
        setErrMessage(InfoErrMessage.MISSING);
      }
    } catch (e) {
      console.error(e);
      setErrMessage(InfoErrMessage.SERVER);
    }
  };

  useEffect(() => {
    setInfoMessage('pending');
    onSubmit().then();
  }, []);

  // @ts-ignore
  return (
    <Grid item container xs={12} sm={12} md={12} lg={12}>
      <>Email</>
      {errMessage && (
        <FormHelperText sx={{ mt: 1, lineHeight: '12px', textAlign: 'center' }} error>
          {errMessage}
        </FormHelperText>
      )}
      {infoMessage && (
        <FormHelperText sx={{ mt: 1, lineHeight: '12px', textAlign: 'center' }}>
          {infoMessage}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default Test;
