import { ErrorInterface } from '../../interfaces/genera.interface';
import { SurveyInterface, SurveyTemplateInterface } from '../../interfaces/survey.interface';

export interface SurveysTemplateGetState {
  entities: SurveyTemplateInterface[] | null;
  clean: SurveyTemplateInterface[] | null;
  error: false | ErrorInterface;
  loading: boolean;
  update: boolean;
}

export const initialSurveysTemplateGetState: SurveysTemplateGetState = {
  entities: null,
  clean: null,
  error: false,
  loading: false,
  update: false
};
