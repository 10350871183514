import { ErrorInterface } from '../../interfaces/genera.interface';
import { SurveyInterface, SurveyTemplateInterface } from '../../interfaces/survey.interface';

export interface SurveysCampaignState {
  entities: SurveyTemplateInterface | null;
  error: false | ErrorInterface;
  loading: boolean;
  success: boolean | null;
}

export const initialSurveysCampaignCreateState: SurveysCampaignState = {
  entities: null,
  error: false,
  loading: false,
  success: null
};
