import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      background: '#fff',
      height: '78px'
      // boxShadow: 'unset'
    }
  }
});
