import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Paper, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import TextField from 'components/primitives/TextField';
import { errorTextStyle } from 'common/styles/common.styles';
import SurveyQuestionActionTypes, {
  ACTIONS_OPTIONS
} from '../../../enums/SurveyQuestionActionTypesEnum';
import { surveysTriggerQuestionsSelector } from '../../../store/surveys-trigger-questions/surveys-trigger-options.selector';
import { surveysTriggerOptionsSelector } from '../../../store/surveys-trigger-options/surveys-trigger-options.selector';
import {
  SurveyQuestionTypes,
  SurveyQuestionTypesLabels
} from '../../../enums/SurveyQuestionTypesEnum';

const SingleChoiceQuestionItem: React.FC<any> = (props) => {
  const questionUuid = props.getValues('template.surveyQuestions')[props.questionIndex].uuid;
  const TRIGGER_QUESTIONS = useSelector(surveysTriggerQuestionsSelector(questionUuid));
  const [triggerSurvey, setTriggerSurvey] = useState<boolean>(false);
  const TRIGGER_OPTIONS = useSelector(surveysTriggerOptionsSelector);

  useEffect(() => {
    const action = props.getValues(
      `template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].action`
    );

    if (action === SurveyQuestionActionTypes.QUESTION) {
      setTriggerSurvey(true);
    } else {
      setTriggerSurvey(false);
    }
  }, [
    props.getValues(
      `template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].action`
    )
  ]);

  return (
    <Grid container alignItems="center" sx={{ marginBottom: '12px' }}>
      <Grid item lg={1}>
        <Radio readOnly={true} disabled={true} />
      </Grid>

      <Grid item container lg={11} key={props.itemField.id} alignItems="center">
        <Grid item lg={12}>
          <Controller
            name={`template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].question`}
            control={props.control}
            rules={{ required: 'Field is required.' }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
              return (
                <>
                  <TextField
                    defaultValue={value}
                    name={name}
                    onChange={onChange}
                    error={Boolean(error)}
                    variant="standard"
                    placeholder="Enter Option"
                  />
                  <p style={errorTextStyle}>
                    <ErrorMessage errors={props.errors} name={name} />
                  </p>
                </>
              );
            }}
          />
        </Grid>
        <Grid lg={12} item container alignItems="center" sx={{ display: 'flex', gap: '12px' }}>
          {TRIGGER_OPTIONS && TRIGGER_OPTIONS.length > 0 && (
            <Grid item>
              <Controller
                name={`template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].triggerOptionID`}
                control={props.control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  let dV = undefined;
                  if (TRIGGER_OPTIONS && TRIGGER_OPTIONS.length && value) {
                    dV = TRIGGER_OPTIONS.find((item: any) => item.value === value);
                  }
                  return (
                    <SelectInput
                      defaultValue={dV}
                      placeholder={'Select Email'}
                      error={Boolean(error)}
                      options={TRIGGER_OPTIONS}
                      onChange={(e) => {
                        if (e === null) {
                          props.setValue(name, null);
                          return onChange(null);
                        }
                        props.setValue(name, e.value);
                        return onChange(e.value);
                      }}
                      isClearable={true}
                    />
                  );
                }}
              />
            </Grid>
          )}

          <Grid item sx={{ display: 'inline-flex', gap: '12px' }}>
            <Controller
              name={`template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].action`}
              control={props.control}
              rules={{ required: 'Field is required.' }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                let dV = undefined;
                if (ACTIONS_OPTIONS && ACTIONS_OPTIONS.length && value) {
                  dV = ACTIONS_OPTIONS.find((item: any) => item.value === value);
                }

                return (
                  <>
                    <SelectInput
                      defaultValue={dV}
                      placeholder={'Select Survey Triggers'}
                      error={Boolean(error)}
                      options={ACTIONS_OPTIONS}
                      minWidth={'220px'}
                      onChange={(e) => {
                        if (e.value && e.value === SurveyQuestionActionTypes.QUESTION) {
                          setTriggerSurvey(true);
                        } else {
                          setTriggerSurvey(false);
                        }
                        return onChange(e.value);
                      }}
                    />
                    <p style={errorTextStyle}>
                      <ErrorMessage errors={props.errors} name={name} />
                    </p>
                  </>
                );
              }}
            />
            {triggerSurvey && (
              <Controller
                key={props.getValues(
                  `template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].triggerActionQuestionUUID`
                )}
                name={`template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].triggerActionQuestionUUID`}
                control={props.control}
                rules={{ required: 'Field is required.' }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  let dV = undefined;
                  if (TRIGGER_QUESTIONS && TRIGGER_QUESTIONS.length && value) {
                    dV = TRIGGER_QUESTIONS.find((item: any) => item.value === value);
                  }

                  return (
                    <>
                      <SelectInput
                        placeholder={'Select Question'}
                        defaultValue={dV}
                        error={Boolean(error)}
                        options={TRIGGER_QUESTIONS}
                        minWidth={'220px'}
                        onChange={(e) => {
                          props.setValue(name, e.value);
                          return onChange(e.value);
                        }}
                      />
                      <p style={errorTextStyle}>
                        <ErrorMessage errors={props.errors} name={name} />
                      </p>
                    </>
                  );
                }}
              />
            )}
          </Grid>
          <Grid item lg={1} sx={{ marginLeft: 'auto', textAlign: 'right' }}>
            <DeleteOutlinedIcon
              sx={{
                width: 20,
                height: 20,
                cursor: 'pointer'
              }}
              onClick={() => props.itemRemove(props.itemIndex)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleChoiceQuestionItem;
