import React, { useState } from 'react';
import { Box, Button, FormHelperText, Grid, Paper, Stack, Typography } from '@mui/material';
import LoginLogo from 'common/images/Login_logo.png';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import { Nullable } from 'common/Utils/Nullable';
import { ForgotPasswordProps } from './ForgotPassword.props';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import apiFetch from '../../../services/apiFetch';
import { resetPassword } from '../../../services/apiEndPoints';
import { loadingStyle } from '../SurveysResponse/components/SurveyContainer/SurveyContainer.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const InfoErrMessage = {
  MISSING: 'Please enter an email',
  INVALID: 'You have entered an invalid email',
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClose, ...props }) => {
  const commonClasses = useCommonStyles();

  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [infoMessage, setInfoMessage] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true
  });

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    setDisableSubmit(true);
    let email = formData.email;
    try {
      if (email) {
        const reset = await apiFetch(resetPassword(email));

        if (reset.data?.errorCode.code > 0) {
          setErrMessage(reset.data?.errorCode.message || InfoErrMessage.SERVER);
          return;
          setIsLoading(false);
        } else {
          setInfoMessage('A verification reset password link was sent to your email.');
          setIsLoading(false);
        }
      } else {
        setErrMessage(InfoErrMessage.MISSING);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setErrMessage(InfoErrMessage.SERVER);
      setIsLoading(false);
    }
  };

  const paperStyle = {
    position: 'absolute',
    top: '10px',
    right: isMobile ? '10px' : '50px',
    width: isMobile ? 'calc(100vw - 80px)' : 550,
    minHeight: isMobile ? '280px' : '380px',
    color: '#555',
    padding: '20px 30px',
    zIndex: 15,
    overflowY: 'visible'
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflowY: 'visible'
      }}>
      {isLoading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      {!isMobile && (
        <>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
              width: '92%',
              height: '400px',
              margin: '0 auto',
              borderRadius: '10px'
            }}
          />
          <Box
            component="img"
            sx={{
              width: 450,
              height: 480,
              position: 'absolute',
              top: '-55px',
              left: '-50px',
              zIndex: 10
            }}
            alt="DHEC"
            src={LoginLogo}
          />
          <Box
            sx={{
              background: '#126DA2',
              borderRadius: '32px',
              transform: 'rotate(-32.16deg)',
              width: '320px',
              height: '320px',
              position: 'absolute',
              top: '37px',
              left: '50px'
            }}
          />
        </>
      )}
      <Paper sx={paperStyle}>
        <Typography variant="h5">{`Forgot Password`}</Typography>
        <form style={{ marginTop: '20px', position: 'relative' }} onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            style={{
              position: 'absolute',
              left: 0,
              zIndex: 20,
              width: '100%'
            }}>
            {!infoMessage && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormGroup label={`Email Address`}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextField
                              value={getValues(`email`) || ''}
                              error={!!errors.email?.message}
                              name={name}
                              onChange={onChange}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    className={commonClasses.blueButton}
                    sx={{ width: '100%!important' }}
                    type="submit"
                    disabled={disableSubmit}>
                    {`Send Verification`}
                  </Button>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              {errMessage && (
                <FormHelperText sx={{ mt: 1, lineHeight: '12px', textAlign: 'center' }} error>
                  {errMessage}
                </FormHelperText>
              )}
              {infoMessage && (
                <FormHelperText sx={{ mt: 1, lineHeight: '12px', textAlign: 'center' }}>
                  {infoMessage}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default ForgotPassword;
