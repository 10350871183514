import React, { useEffect, useState } from 'react';
import { IGoogleTranslate } from './GoogleTranslate.props';
import Image from 'components/primitives/Image';
import Locales from 'common/arrays/Locales';
import SelectInput from 'components/primitives/SelectInput';
import { ISelectOption } from 'interfaces/ISelectOptions';
import { useAppSelector } from 'app/hooks';
import { selectAccount } from 'store/reducers/account';

const GoogleTranslate: React.FC<IGoogleTranslate> = () => {
  const account = useAppSelector(selectAccount);
  const getLangVal: ISelectOption =
    Locales.find((l) => l.value === localStorage.getItem('lang')) || Locales[0];
  const [language, setLanguage] = useState<ISelectOption>(getLangVal);

  useEffect(() => {
    if (account) {
      // const idx = Locales.findIndex((l) => l.value === account?.language);
      // console.log(idx);
      // console.log(Locales);
      // console.log(account?.language);
      // setLanguage(Locales[idx]);
    }
  }, [account]);

  const handleLanguageChange = (e: ISelectOption) => {
    localStorage.setItem('lang', e.value);
    setLanguage(e);
    let googleTranslateSelect = document.querySelector('#google_translate_element select') as any;
    if (googleTranslateSelect) {
      googleTranslateSelect.value = e?.value ?? 'en';
      GTranslateFireEvent(googleTranslateSelect);
    } else {
      setTimeout(function () {
        handleLanguageChange(e);
      }, 500);
    }
  };

  function GTranslateFireEvent(a: any) {
    try {
      if (document.createEvent) {
        let c = document.createEvent('HTMLEvents');
        c.initEvent('change', true, true);
        a.dispatchEvent(c);
      } else {
        let doc = document as any;
        let c = doc.createEventObject();
        a.fireEvent('onchange', c);
      }
    } catch (e) {}
  }

  return (
    <SelectInput
      options={Locales}
      defaultValue={language}
      value={language}
      onChange={handleLanguageChange}
      formatOptionLabel={(data: ISelectOption) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Avatar src={data.icon} sx={{ width: '12px', height: '12px' }} /> */}
            <Image alt="DHEC Language" src={data.icon || ''} sx={{ width: 16, height: 16 }} />
            <span style={{ marginLeft: 5 }}>{data.label}</span>
          </div>
        );
      }}
    />
  );
};

export default GoogleTranslate;
