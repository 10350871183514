import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: '10',
      border: 'border: 1px solid rgba(48, 48, 49, 0.1)',
      borderRadius: '5px',
      alignItems: 'center',
      cursor: 'pointer'
    },
    radio: {
      '&$checked': {
        color: '#fff'
      }
    },
    checked: {}
  })
);

export default useStyles;
