import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import moment from 'moment';
import React from 'react';

const TIME_OFFSET_MS = {
  NewYork: 18000
};

const SurveyHeaders: ColumnsProperties[] = [
  {
    Header: 'Survey Name',
    accessor: 'title',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
    isSelected: true,
    filterHidden: true,
    Cell: (props: any) => {
      if (props.value) {
        return <span>{moment.unix(Number(props.value)).format('MMMM Do YYYY')}</span>;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Date Start',
    accessor: 'startDate',
    isSelected: true,
    filterHidden: true,
    Cell: (props: any) => {
      if (props.value) {
        return (
          <span>
            {moment.unix(Number(props.value) - TIME_OFFSET_MS.NewYork).format('MMMM Do YYYY')}
          </span>
        );
      }
      return 'N/A';
    }
  },
  {
    Header: 'Date End',
    accessor: 'endDate',
    isSelected: true,
    filterHidden: true,
    Cell: (props: any) => {
      if (props.value) {
        return (
          <span>
            {moment.unix(Number(props.value) - TIME_OFFSET_MS.NewYork).format('MMMM Do YYYY')}
          </span>
        );
      }
      return 'N/A';
    }
  },
  {
    Header: 'Responses',
    accessor: 'totalResponses',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'Options',
    accessor: 'options',
    isSelected: true,
    filterHidden: true,
    width: 80
  }
];

export default SurveyHeaders;
