import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const SurveyQuestion: React.FC<any> = ({ index, question, answerRequired }) => {
  return (
    <Grid item lg={12} xl={12}>
      <Typography variant="h5" color="#126DA2">
        Question {index + 1}
      </Typography>
      <Box sx={{ marginTop: '5px' }}>
        <Typography variant="subtitle1" color="#303031B8" sx={{ fontFamily: 'Urbane' }}>
          {question}
        </Typography>
      </Box>
      <Box sx={{ margin: '5px 0' }}>
        {answerRequired === 1 && (
          <Typography variant="caption" color="#303031B8" sx={{ fontWeight: '300' }}>
            *Answer is required
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default SurveyQuestion;
