import AuthProtect from 'common/enums/AuthProtect';
import { IEndpoint } from 'interfaces/IEndPoint';
import { IUser } from 'interfaces/IUser';
import { Nullable } from 'common/Utils/Nullable';

const PAGE_LIMIT = 10;

export const authLogin: (email: string, password: string) => IEndpoint = (
  email: string,
  password: string
) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'login',
    method: 'POST',
    data: {
      email,
      password
    }
  };
};

export const signup: (data: any) => IEndpoint = (data: any) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/guest/register',
    method: 'POST',
    data
  };
};

export const changePassword: (data: any) => IEndpoint = (data: any) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'api/user/me/changePassword',
  method: 'PUT',
  data
});

export const setPassword: (data: { access_token: string; password: string }) => IEndpoint = (
  data: any
) => ({
  authorization: AuthProtect.NOT_REQUIRED,
  url: 'api/guest/setPassword?access_token=' + data.access_token,
  method: 'POST',
  data: { password: data.password }
});

export const resetPassword: (email: Nullable<string>) => IEndpoint = (email: Nullable<string>) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/guest/resetPassword',
    method: 'POST',
    data: { email }
  };
};

export const getAccountInfo: () => IEndpoint = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'api/user/me',
    method: 'GET'
  };
};

export const updateAccountInfo: (data: IUser) => IEndpoint = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'api/user/me',
    method: 'PUT',
    data
  };
};

/*
 * Get Total List of users from Custom Filter API
 */
export const getItemsForFilter: (filter: string, keyword: string, offset?: number) => IEndpoint = (
  filter = '',
  keyword = '',
  offset = 0
) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/admin/users/filters/${filter}?limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

/*
 * Update status of users from Custom Filter API
 */
export const updateUsersStatus: (
  data: Nullable<{ ids: string[]; data: { status: string; validEmail: string } }>
) => IEndpoint = (data = null) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: 'api/admin/users/bulk',
  data
});

export const getAllRegionalOffice: () => IEndpoint = () => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/guest/regionalOffice',
    method: 'GET'
  };
};

export const getAllCounties: () => IEndpoint = () => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/guest/regionalOffice/counties',
    method: 'GET'
  };
};

export const updateRegionalOffice: (data: any[]) => IEndpoint = (data: any[]) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'api/admin/regionalOffice',
    method: 'POST',
    data: data
  };
};

export const getAllBusinessDir: (
  offset: number,
  orderBy: Nullable<string>,
  keyword: string
) => IEndpoint = (offset = 0, orderBy = 'corporateName desc', keyword = '') => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/admin/businesses?limit=${PAGE_LIMIT}&offset=${offset}${
      orderBy ? `&orderBy=${orderBy}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}`,
    method: 'GET'
  };
};

export const getAllBusinessDirCounter: (keyword: string) => IEndpoint = (keyword = '') => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/admin/businesses/counter${keyword ? `?keyword=${keyword}` : ''}`,
    method: 'GET'
  };
};

export const getAllBusinessDirItemsForFilter: (
  filter: string,
  keyword: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/admin/businesses/filters/${filter}?limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

export const getAllBusinessDirWithFilter: (
  filter: string,
  keyword?: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/admin/businesses?limit=${PAGE_LIMIT}&${filter}&offset=${offset}`
});

export const updateBusinessesStatus: (
  accountId: string,
  data: Nullable<{ status: string; businessIds: { id: string }[] }>
) => IEndpoint = (accountId, data = null) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: `api/admin/businesses/${accountId}`,
  data
});
export const getProfileToEdit = (accessToken: string): IEndpoint => {
  const params = { access_token: accessToken }; // eslint-disable-line
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'api/editProfile',
    params
  };
};

export const updateProfileToEdit = (data: IUser, accessToken: string): IEndpoint => {
  const params = { access_token: accessToken };
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/editProfile',
    method: 'PUT',
    data,
    params
  };
};

export const changePasswordEditProfile = (data: any, accessToken: string): IEndpoint => {
  const params = { access_token: accessToken };
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'api/editProfile/changePassword',
    method: 'PUT',
    data,
    params
  };
};
