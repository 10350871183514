import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center'
    },
    badge: {
      padding: '0px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '2px',
      height: '24px',
      backgroundColor: '#EEEEEF',
      color: '#49494A'
    }
  };
});

export default useStyles;
