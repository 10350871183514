import React, { useEffect, useState } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';

import {
  SurveyQuestionTypes,
  SurveyQuestionTypesLabels
} from '../../../enums/SurveyQuestionTypesEnum';
import TextChoiceFormView from '../TextChoiceQuestion/TextChoiceQuestion.view';
import SingleChoiceFormView from '../SingleChoiceQuestion/SingleChoiceQuestion.view';
import MultipleChoiceFormView from '../MultipleChoiceQuestion/MultipleChoiceQuestion.view';

const QuestionForm: React.FC<any> = (props) => {
  const [formType, setFormType] = useState<SurveyQuestionTypes | null>(null);
  const [formTypeLabel, setFormTypeLabel] = useState<SurveyQuestionTypesLabels | null>(null);

  useEffect(() => {
    let setType = props.getValues('template').type;
    const formValuesItem = props.getValues(`template.surveyQuestions[${props.index}].type`);
    if (formValuesItem) {
      setType = formValuesItem;
    }
    setFormType(setType);
  }, []);

  useEffect(() => {
    switch (formType) {
      case SurveyQuestionTypes.TEXT:
        setFormTypeLabel(SurveyQuestionTypesLabels.TEXT);
        break;
      case SurveyQuestionTypes.SINGLE:
        setFormTypeLabel(SurveyQuestionTypesLabels.SINGLE);
        break;
      case SurveyQuestionTypes.MULTIPLE:
        setFormTypeLabel(SurveyQuestionTypesLabels.MULTIPLE);
        break;
      default:
    }
  }, [formType]);

  return (
    <>
      <Grid container item lg={12}>
        <Paper elevation={1} sx={{ width: '100%', padding: 2, mt: '10px' }}>
          <Grid container item lg={12}>
            <Typography variant="subtitle2" sx={{ padding: '0', marginBottom: '12px' }}>
              Question #{props.index + 1} - {formTypeLabel && formTypeLabel}
            </Typography>
          </Grid>
          <Grid item lg={12} sx={{ marginBottom: '12px' }}>
            <Divider />
          </Grid>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            {formType === SurveyQuestionTypes.TEXT && <TextChoiceFormView {...props} />}
            {formType === SurveyQuestionTypes.SINGLE && <SingleChoiceFormView {...props} />}
            {formType === SurveyQuestionTypes.MULTIPLE && <MultipleChoiceFormView {...props} />}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default QuestionForm;
