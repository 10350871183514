import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Grid, Paper, Stack, useTheme } from '@mui/material';
import RegionsCardView from 'components/primitives/RegionsCard';
import MultiSelectCard from 'components/fragments/MultiSelectCard';
import SelectCard from 'components/fragments/SelectCard';
import { useFetchCounties } from 'hooks';
import { RegionName } from 'common/enums/EnumRegionName';
import { ISelectProp } from 'interfaces/ISelectProp';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import SelectRegionStyles from './SelectRegion.styles';

const SelectRegionView: React.FC<any> = () => {
  const theme = useTheme();
  const styles = SelectRegionStyles();
  const [countyList, setCountyList] = useState<ISelectProp[]>([]);
  const peeDeeCounties = useFetchCounties(RegionName.PEEDEECOUNTIES);
  const lowCountryCounties = useFetchCounties(RegionName.LOWCOUNTRYCOUNTIES);
  const midlandsCounties = useFetchCounties(RegionName.MIDLANDSCOUNTIES);
  const upstateCounties = useFetchCounties(RegionName.UPSTATECOUNTIES);
  const [checkedPeeDee, setCheckedPeeDee] = useState([false, false]);
  const [checkedLow, setCheckedLow] = useState([false, false]);
  const [checkedMid, setCheckedMid] = useState([false, false]);
  const [checkedUpdate, setCheckedUpdate] = useState([false, false]);

  const { control, watch, setValue } = useFormContext();

  type IRegion = {
    label: string;
    value: string;
  };
  useEffect(() => {
    const updateCountyList = () => {
      let tempCounties: IRegion[] = [];
      tempCounties = tempCounties.concat(peeDeeCounties);
      tempCounties = tempCounties.concat(lowCountryCounties);
      tempCounties = tempCounties.concat(midlandsCounties);
      tempCounties = tempCounties.concat(upstateCounties);

      const selectList = tempCounties.map((item) => ({ label: item.label, value: item.value }));
      setCountyList(selectList);
    };
    updateCountyList();
  }, [peeDeeCounties, lowCountryCounties, midlandsCounties, upstateCounties]);

  return (
    <Box height="100%">
      <Box sx={styles.root}>
        <Box sx={styles.mainWrapper}>
          <Paper elevation={0} sx={styles.detailItem} variant="outlined">
            <Typography variant="h6" sx={{ mb: theme.spacing(1) }}>
              Select regions
            </Typography>
            <Stack rowGap={2}>
              <Grid item xs={12} lg={12}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12} lg={6}>
                    <RegionsCardView
                      name={`counties`}
                      watch={watch}
                      setValue={setValue}
                      title={RegionName.PEEDEECOUNTIES}
                      counties={peeDeeCounties}
                      setChecked={setCheckedPeeDee}
                      checked={checkedPeeDee}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RegionsCardView
                      name={`counties`}
                      watch={watch}
                      setValue={setValue}
                      title={RegionName.LOWCOUNTRYCOUNTIES}
                      counties={lowCountryCounties}
                      setChecked={setCheckedLow}
                      checked={checkedLow}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RegionsCardView
                      name={`counties`}
                      watch={watch}
                      setValue={setValue}
                      title={RegionName.MIDLANDSCOUNTIES}
                      counties={midlandsCounties}
                      setChecked={setCheckedMid}
                      checked={checkedMid}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RegionsCardView
                      name={`counties`}
                      watch={watch}
                      setValue={setValue}
                      title={RegionName.UPSTATECOUNTIES}
                      counties={upstateCounties}
                      setChecked={setCheckedUpdate}
                      checked={checkedUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <MultiSelectCard
            name={`counties`}
            control={control}
            title="Individual Counties"
            label="Counties"
            optionsValue={countyList}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectRegionView;
