import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';

export interface ApiAdminPermitNumberSearch {
  keyword?: string;
  NAME?: { index: number; value: string }[];
  CITY?: { index: number; value: string }[];
  STATE?: { index: number; value: string }[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminPermitNumberFilters {
  keyword?: string;
  NAME?: string[];
  CITY?: string[];
  STATE?: string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getPermitNumberAdmin = (filters: ApiAdminPermitNumberSearch): IEndpoint => {
  const params = setPermitNumberParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/permits`,
    params: params
  };
};

export const getPermitNumberAdminCounter = (filters: ApiAdminPermitNumberSearch): IEndpoint => {
  const params = setPermitNumberParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/permits/counter`,
    params: params
  };
};

export const getAdminPermitNumberForFilter = (
  filter: string,
  keyword: string,
  offset?: number,
  limit: number = 10
): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/permits/filters/${filter}`,
    params: {
      limit: limit,
      offset: offset,
      keyword: keyword
    }
  };
};

function setPermitNumberParams(
  filters: ApiAdminPermitNumberSearch,
  counter = false
): ApiAdminPermitNumberFilters {
  const params: ApiAdminPermitNumberFilters = {};

  if (filters.NAME && filters.NAME.length > 0) {
    params.NAME = filters.NAME.map((data) => data.value);
  }
  if (filters.CITY && filters.CITY.length > 0) {
    params.CITY = filters.CITY.map((data) => data.value);
  }
  if (filters.STATE && filters.STATE.length > 0) {
    params.STATE = filters.STATE.map((data) => data.value);
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
