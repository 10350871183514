import React from 'react';
import { Container } from '@mui/system';
import { Grid, Paper, Typography } from '@mui/material';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { IViewStaffForm } from './ViewStaffForm.props';

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const ViewStaffForm: React.FC<IViewStaffForm> = ({ data, disabled = true }) => {
  return (
    <Container>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item lg={12} alignItems="center" columnSpacing={12}>
          <Typography variant="h6" color="textSecondary">
            General
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'First Name'}>
                  <TextField
                    value={data.firstName || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Last Name'}>
                  <TextField
                    value={data.lastName || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Phone Number'}>
                  <TextField
                    value={data.phoneNumber || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Email'}>
                  <TextField
                    value={data.email || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Facility'}>
                  <TextField
                    value={data.facility || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Position / Job Title'}>
                  <TextField
                    value={data.jobTitle || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item lg={12} alignItems="center" columnSpacing={4}>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              Mailing Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Street Address'}>
                  <TextField
                    value={data.mailingAddressStreet || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                  <TextField
                    value={data.mailingAddressNumber || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'State'}>
                  <TextField
                    value={data.mailingAddressState || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'County'}>
                  <TextField
                    value={data.mailingAddressCounty || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'City'}>
                  <TextField
                    value={data.mailingAddressCity || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Zip Code'}>
                  <TextField
                    value={data.mailingAddressZip || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewStaffForm;
