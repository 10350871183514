import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import moment from 'moment/moment';
import React from 'react';

const MailboxHeaders: ColumnsProperties[] = [
  {
    Header: 'Template',
    accessor: 'templateName',
    isSelected: true
  },
  {
    Header: 'Subject',
    accessor: 'subject',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'Recipients',
    accessor: 'totalSenders',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    isSelected: true,
    filterHidden: true,
    Cell: (props: any) => {
      if (props.value) {
        return <span>{moment.unix(Number(props.value)).format('MMMM Do YYYY')}</span>;
      }
      return 'N/A';
    }
  }
];

export default MailboxHeaders;
