enum UserStatusEnum {
  active = 'active',
  removed = 'removed'
}

export default UserStatusEnum;

export interface IUserProps {
  [key: string]: any;
  id?: string;
  email?: string;
  validEmail?: boolean;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressState?: string;
  addressCounty?: string;
  addressCity?: string;
  addressZip?: string;
  role?: string;
  permissions?: string[];
  status?: UserStatusEnum;
  createdAt?: number;
  updatedAt?: number | string;
  permits?: string;
  phoneNumber?: string;
  fullName?: string;
  password?: string;
  language?: string;
}

export type IUser = IUserProps;
