import React, { useState } from 'react';
import LoginView from './Login.view';
import { ILogin, LoginPublicProps, UserLogin } from './Login.props';
import { Nullable } from 'common/Utils/Nullable';
import apiFetch from 'services/apiFetch';
import TokenState from 'store/state/TokenState';
import { authLogin } from 'services/apiEndPoints';
import { useAppDispatch } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import { clearTokenState, updateToken } from 'store/reducers/token';
import { clearAccount } from 'store/reducers/account';
import { updatehomepageModals } from 'store/reducers/homepageModals';

const LoginErrMessage = {
  MISSING: 'Please enter the username and password',
  INVALID: 'You have entered an invalid username and/or password',
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const LoginContainer: React.FC<LoginPublicProps> = (props: LoginPublicProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [login, setLogin] = useState<UserLogin>({ username: null, password: null });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (field: keyof UserLogin, value: string) => {
    setLogin({ ...login, [field]: value });
    setErrMessage(null);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    dispatch(clearTokenState(null));
    dispatch(clearAccount());

    const { username, password } = login;

    if (username && password) {
      // setErrMessage(LoginErrMessage.INVALID);
      await authenticate(username, password).then(() => {
        setIsLoading(false);
      });
    } else {
      setErrMessage(LoginErrMessage.MISSING);
      setIsLoading(false);
    }
  };

  const authenticate = async (username: string, password: string) => {
    setErrMessage(null);
    try {
      const login = await apiFetch(authLogin(username, password));

      if (login.data?.errorCode.code > 0) {
        setErrMessage(login.data?.errorCode.message);
        return;
      }

      if (login?.data.response) {
        const { createdAt, id, ttl, userID, user } = login?.data.response || {};
        const t: TokenState = {
          expiresIn: new Date().getTime() + 60 * 60 * 24 * 1000,
          createdAt,
          id,
          ttl,
          userID
        };
        // dispatch(loginActions.update({ show: false }));
        dispatch(updateToken(t));
        // dispatch(tokenActions.authorize(t));
        // dispatch(accountActions.update({ ...user }));
        dispatch(
          updatehomepageModals({
            showLogin: false,
            showRegister: false,
            showContact: false,
            showResetPassword: false,
            showFinishSignup: false
          })
        );

        if (user && user.role) {
          if (user?.role === 'superadmin' || user?.role === 'admin') {
            navigate('/dhec-admin');
          } else {
            navigate('/permit-holder');
          }
        }
      } else {
        setErrMessage(LoginErrMessage.INVALID);
      }
    } catch (e) {
      setErrMessage(LoginErrMessage.SERVER);
      console.error(e);
    }
  };

  const handleModalChange = (value: boolean, name: string) => {
    if (props.cbModal) props.cbModal(value, name);
  };

  const combinedProps: ILogin = {
    ...props,
    errMessage,
    login,
    isLoading,
    handleChange,
    handleLogin,
    handleModalChange
  };

  return <LoginView {...combinedProps} />;
};

export default LoginContainer;
