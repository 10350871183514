import React, { useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Typography,
  Alert,
  Snackbar
} from '@mui/material';
import SinupLogo from 'common/images/Logo_Signup.png';
import FormGroup from '../../primitives/FormGroup';
import useCommonStyles from 'common/styles/common.styles';
import apiFetch from 'services/apiFetch';
import { signup } from 'services/apiEndPoints';
import TextField from 'components/primitives/TextField';
import { Nullable } from 'common/Utils/Nullable';
import _ from 'lodash';
import { formatPhone, isValidEmail } from 'common/Utils/String';
import { ISignup } from './Signup.props';
import { loadingStyle } from '../SurveysResponse/components/SurveyContainer/SurveyContainer.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SignUpErrMessage = {
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const ErrorMessageType: any = {
  businessName: 'Business Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  email: 'Email Address',
  password: 'Password',
  confirmPassword: 'Confirm Password'
};

const Signup: React.FC<ISignup> = ({ onClose, ...props }) => {
  const commonClasses = useCommonStyles();
  const [info, setInfo] = useState<any>({});
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [errors, setErrors] = useState<any>({});
  const [openError, setOpenError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const validate = () => {
    const fieldsToCheck = [
      'businessName',
      'firstName',
      'lastName',
      'phoneNumber',
      'email',
      'password',
      'confirmPassword'
    ];

    const hasError = fieldsToCheck.some((f) => !_.has(info, f) || !info[f]);

    if (hasError) {
      const errors = fieldsToCheck.reduce((obj: any, o: string) => {
        if (!info[o]) {
          obj[o] = { message: `${ErrorMessageType[o]} is required.` };
        } else if (o === 'email' && info[o]) {
          if (!isValidEmail(info[o] || '')) {
            obj[o] = { message: `Invalid email address` };
          }
        }

        return obj;
      }, {});

      setErrors(errors);
    } else {
      let shouldUpdate = true;
      const err: any = {};

      if (!isValidEmail(info.email)) {
        err.email = { message: `Invalid email address` };
        shouldUpdate = false;
      }

      if (info.password.length < 8) {
        err.password = { message: 'Must contain atleast 8 characters' };
        shouldUpdate = false;
      }

      if (info.password !== info.confirmPassword) {
        err.confirmPassword = { message: "Password doesn't match" };
        shouldUpdate = false;
      }

      if (shouldUpdate && !hasRegistered) {
        setHasRegistered(true);
        setIsLoading(true);
        signUp();
      } else setErrors(err);
    }
  };

  const signUp = async () => {
    const registerData = {
      businessName: info.businessName,
      firstName: info.firstName,
      lastName: info.lastName,
      phoneNumber: info.phoneNumber,
      email: info.email,
      password: info.password
    };

    try {
      const res = await apiFetch(signup(registerData));
      if (res) {
        if (res.data.errorCode.code === 0) {
          onClose();
          props.cbModal(true, 'showFinishSignup');
          return res;
        } else {
          setIsLoading(false);
          setHasRegistered(false);
          setErrMessage(res.data.errorCode.message);
          setOpenError(true);
        }
      }
    } catch (e) {
      setIsLoading(false);
      setHasRegistered(false);
      setErrMessage(SignUpErrMessage.SERVER);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    const cloneObj = { ...info };
    cloneObj[field] = value;

    if (errors[field]) {
      delete errors[field];
    }

    setInfo(cloneObj);
  };

  const paperStyle = {
    position: 'absolute',
    top: '10px',
    right: isMobile ? '10px' : '50px',
    width: isMobile ? 'calc(100vw - 80px)' : 550,
    height: 'auto',
    color: '#555',
    padding: '20px'
  };

  return (
    <Box
      sx={{
        minHeight: '500px',
        position: 'relative'
      }}>
      {isLoading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      {!isMobile && (
        <>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
              width: '85%',
              height: '525px',
              margin: '0 auto',
              mt: '50px',
              mr: '40px',
              borderRadius: '10px'
            }}
          />
          <Box
            component="img"
            sx={{
              height: 550,
              width: 534,
              position: 'absolute',
              top: '-17px',
              left: '100px'
            }}
            alt="DHEC"
            src={SinupLogo}
          />
        </>
      )}

      <Paper sx={paperStyle}>
        <Typography variant="h5">{`Signup`}</Typography>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {errMessage && (
            <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
              {errMessage}
            </FormHelperText>
          )}
        </Grid>
        <div style={{ marginTop: '20px' }}>
          <form noValidate>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12} lg={12}>
                <FormGroup error={errors?.businessName || null} label={`Business Name`}>
                  <TextField
                    error={!!errors?.businessName}
                    onChange={(e) => handleInputChange('businessName', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.firstName || null} label={`First Name`}>
                  <TextField
                    error={!!errors?.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.lastName || null} label={`Last Name`}>
                  <TextField
                    error={!!errors?.lastName}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.phoneNumber || null} label={`Phone Number`}>
                  <TextField
                    error={!!errors?.phoneNumber}
                    value={formatPhone(info.phoneNumber) || ''}
                    onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.email || null} label={`Email Address`}>
                  <TextField
                    error={!!errors?.email}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.password || null} label={`Password`}>
                  <TextField
                    required
                    type="password"
                    error={!!errors?.password}
                    autoComplete="new-password"
                    helperText={isMobile ? '' : `Must contain at least 8 characters`}
                    onChange={(e) => handleInputChange('password', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormGroup error={errors?.confirmPassword || null} label={`Confirm Password`}>
                  <TextField
                    type="password"
                    error={!!errors?.confirmPassword}
                    autoComplete="new-password-confirm"
                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Button
                  className={commonClasses.blueButton}
                  sx={{ width: '100%!important' }}
                  onClick={() => validate()}>
                  {`Submit`}
                </Button>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ pt: '10px!important' }}>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  <Typography variant="h6">{`Already have an account?`}</Typography>
                  <Button
                    onClick={() => {
                      props.cbModal(true, 'showLogin');
                    }}>{`Get Started`}</Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
    </Box>
  );
};

export default Signup;
