import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { SurveyFormDialogProps } from './SurveyFormDialog.props';
import useCommonStyles from 'common/styles/common.styles';
import { saveSurveyTemplateCreate } from '../../store/surveys-template-create/surveys-template.trunk';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
  surveysTemplateCreateError,
  surveysTemplateCreateLoading,
  surveysTemplateCreateSuccess
} from '../../store/surveys-template-create/surveys-template.selector';
import { clearSurveyTemplateCreate } from '../../store/surveys-template-create/surveys-template.reduce';
import {
  clearSnackbarSurveyMessage,
  updateSnackbarSurveyMessage
} from '../../store/snackbar-message/snackbar-message.reducer';
import { loadingStyle } from '../../styles/loading.style';

const SurveyFormDialog: React.FC<SurveyFormDialogProps> = ({
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'lg',
  noScrollY = false,
  overflowVisible = false,
  handleClose,
  backgroundTransparent = false,
  children
}) => {
  const commonClasses = useCommonStyles();
  const dispatch = useAppDispatch();
  const templateForm = useForm();

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { handleClose, templateForm });
    }
    return child;
  });

  const loading = useSelector(surveysTemplateCreateLoading);
  const error = useSelector(surveysTemplateCreateError);
  const success = useSelector(surveysTemplateCreateSuccess);

  useEffect(() => {
    if (error) {
      dispatch(updateSnackbarSurveyMessage({ show: true, message: 'Something went wrong' }));
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      handleClose?.();
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(clearSnackbarSurveyMessage({ show: false, message: null }));
      dispatch(clearSurveyTemplateCreate());
    };
  }, []);

  const onSubmit = (data: any) => {
    const formData = templateForm.getValues();

    dispatch(
      saveSurveyTemplateCreate({
        ...formData.template,
        display: 1
      })
    );
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVisible ? 'visible' : 'auto'
        }
      }}>
      {loading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={templateForm.handleSubmit(onSubmit)}>
        {title && (
          <>
            <Typography variant="h4" sx={{ padding: '16px 24px', textTransform: 'capitalize' }}>
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                handleClose?.();
              }}
              aria-label="dhec-close"
              sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
              <Close sx={{ width: 25, height: 25 }} />
            </IconButton>
          </>
        )}

        <DialogContent
          sx={{
            padding: '0',
            minHeight: '70vh',
            overflowY: noScrollY ? 'hidden' : overflowVisible ? 'visible' : 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}>
          {childrenWithProps}
        </DialogContent>
        <DialogActions
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            gap: '12px'
          }}>
          <Button
            autoFocus
            color="secondary"
            onClick={() => {
              handleClose?.();
            }}>
            Cancel
          </Button>

          <Button
            className={commonClasses.blueButton}
            color="primary"
            type={'submit'}
            disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SurveyFormDialog;
