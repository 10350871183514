import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { Grid, Paper, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import SelectInput from 'components/primitives/SelectInput';
import { ISelectOption } from 'interfaces/ISelectOptions';
import apiFetch from 'services/apiFetch';
import { getAcceptedFacilityPermitHolder } from 'services/permitHolderEndPoints';
import { IBusinesses } from 'interfaces/IBusinesses';
import { getAllCounties } from 'services/apiEndPoints';
import { ICounty } from 'interfaces/ICounty';
import { IAddStaffForm } from './AddStaffForm.props';
import { formatPhone, limitZipLength } from 'common/Utils/String';

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const AddStaffForm: React.FC<IAddStaffForm> = ({ control, errors, setValue, getValues, watch }) => {
  const [counties, setCounties] = useState<ISelectOption[]>([]);
  const [bussinesses, setBusinesses] = useState<ISelectOption[]>([]);
  const [jobTitle] = useState<any>([
    { label: 'General Manager', value: 'General Manager' },
    { label: 'Shift Manager', value: 'Shift Manager' },
    { label: 'Executive Chef', value: 'Executive Chef' },
    { label: 'Cooks', value: 'Cooks' },
    { label: 'Bakers', value: 'Bakers' },
    { label: 'Wait Staff', value: 'Wait Staff' },
    { label: 'Dish Washers', value: 'Dish Washers' },
    { label: 'Cashiers', value: 'Cashiers' },
    { label: 'Culinary Student', value: 'Culinary Student' },
    { label: 'Other', value: 'other' }
  ]);
  const parseBusinesses = (businesses: IBusinesses[] = []) => {
    const b = businesses.reduce((arr: ISelectOption[], o) => {
      arr.push({ value: o.id, label: o.doingBusiness || '' });
      return arr;
    }, []);

    return b;
  };

  const parseCounties = (businesses: ICounty[] = []) => {
    const b = businesses.reduce((arr: ISelectOption[], o) => {
      arr.push({ value: o.id, label: o.name || '' });
      return arr;
    }, []);

    return b;
  };

  const getDropDownData = async () => {
    const res = await Promise.all([
      apiFetch(getAcceptedFacilityPermitHolder()),
      apiFetch(getAllCounties())
    ]);

    if (res) {
      // Bussinessses
      if (res[0].data.errorCode.code === 0) {
        const b: ISelectOption[] = parseBusinesses(res[0]?.data?.response || []);
        setBusinesses(b);
      }

      // Counties
      if (res[1].data.errorCode.code === 0) {
        const c: ISelectOption[] = parseCounties(res[1]?.data?.response || []);
        setCounties(c);
      }
    }
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  return (
    <Container>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item lg={12}>
          <Typography variant="h6" color="textSecondary">
            General
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'First Name'}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('firstName') || ''}
                          error={errors.firstName?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.firstName?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Last Name'}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('lastName') || ''}
                          error={errors.lastName?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.lastName?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Phone Number'}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 },
                      pattern: {
                        value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
                        message: 'Invalid phone number.'
                      }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('phoneNumber') || ''}
                          error={errors.phoneNumber?.message ? true : false}
                          name={name}
                          onChange={(e) => onChange(formatPhone(e.target.value))}
                          helperText={errors.phoneNumber?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Email Address'}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('email') || ''}
                          error={errors.email?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.email?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Facility'}>
                  <Controller
                    name="businessID"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <SelectInput
                            defaultValue={value}
                            error={!!errors.businessID?.message}
                            options={bussinesses}
                            placeholder={`Select`}
                            onChange={(e) => {
                              setValue('facility', e.label);
                              return onChange(e.value);
                            }}
                          />
                          <p style={errorTextStyle}>{errors.businessID?.message}</p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label="Position / Job Title">
                  <Controller
                    name="jobTitle"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      const dV = { name: value, label: value };
                      return (
                        <>
                          <SelectInput
                            defaultValue={dV}
                            error={!!errors.jobTitle?.message}
                            options={jobTitle}
                            placeholder={`Select`}
                            onChange={(e) => {
                              setValue('jobTitle', e.label);
                              return onChange(e.value);
                            }}
                          />
                          <p style={errorTextStyle}>{errors.jobTitle?.message}</p>
                        </>
                      );
                    }}
                  />
                  {/* <SelectInput
                    options={JOB_TITLES}
                    defaultValue={JOB_TITLES[0].label}
                    placeholder="Select Position/Job..."
                  /> */}
                </FormGroup>
              </Grid>
              {watch('jobTitle') === 'other' && (
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label="Please fill in details">
                    <Controller
                      name="jobTitleDetails"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Field is required.',
                        maxLength: { message: 'Value in this input is too long.', value: 255 }
                      }}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            value={getValues('jobTitleDetails') || ''}
                            error={!!errors.jobTitleDetails?.message}
                            name={name}
                            onChange={onChange}
                            helperText={errors.jobTitleDetails?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Mailing Address
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label="Street Address">
                  <Controller
                    name="mailingAddressStreet"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressStreet') || ''}
                          error={!!errors.mailingAddressStreet?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.mailingAddressStreet?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label="Apartment, Suite, Unit, etc.">
                  <Controller
                    name="mailingAddressNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressNumber') || ''}
                          error={!!errors.mailingAddressNumber?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.mailingAddressNumber?.message}
                          variant="standard"
                          placeholder={'Optional'}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label="State">
                  <Controller
                    name="mailingAddressState"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressState') || ''}
                          error={!!errors.mailingAddressState?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.mailingAddressState?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label="County">
                  <Controller
                    name="mailingAddressCounty"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressCounty') || ''}
                          error={!!errors.mailingAddressCounty?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.mailingAddressCounty?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label="City">
                  <Controller
                    name="mailingAddressCity"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressCity') || ''}
                          error={!!errors.mailingAddressCity?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.mailingAddressCity?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label="Zip Code">
                  <Controller
                    name="mailingAddressZip"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      minLength: { value: 5, message: 'Zip code must be 5 digits' },
                      maxLength: { value: 5, message: '' }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('mailingAddressZip') || ''}
                          error={!!errors.mailingAddressZip?.message}
                          name={name}
                          onChange={(e) => onChange(limitZipLength(e.target.value, 5))}
                          helperText={errors.mailingAddressZip?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddStaffForm;
