import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)'
    },
    linkTitle: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      color: '#126DA2;',
      textDecoration: 'none'
    }
  })
);

export default useStyles;
