import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../../../../common/enums/AuthProtect';
import { SaveSurveyResponseDataInterface } from '../interfaces/survey.interfaces';

export const getSurveyByToken = (token: string): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `api/guest/survey/${token}`
  };
};

export const saveSurveyResponseByToken = (
  token: string,
  data: SaveSurveyResponseDataInterface
): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: `api/guest/survey/${token}`,
    data: data
  };
};
