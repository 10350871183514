import React, { ChangeEvent, useEffect, useState } from 'react';
import { Container, Box, Grid } from '@mui/material';
import SearchInput from 'components/primitives/SearchInput';
import RegionalSettingsItem from '../RegionalSettingsItem';
import apiFetch from 'services/apiFetch';
import { getAllCounties, getAllRegionalOffice, updateRegionalOffice } from 'services/apiEndPoints';
import { IRegionalSettingsItem } from '../RegionalSettingsItem/RegionalSettingsItem.props';
import { CountyProps, ICounty, IRegion } from 'interfaces/ICounty';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Nullable } from 'common/Utils/Nullable';
import { IRegionalSettings } from './RegionalSettings.props';
import { selectAdmin, updateAdmin } from 'store/reducers/admin';
import { updateregionalOffice } from 'store/reducers/regionalOffice';

const RegionalSettings: React.FC<IRegionalSettings> = ({
  canEditRegions = false,
  shouldSaveSettings = false,
  setShouldSaveSettings
}) => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(selectAdmin);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');

  const [data, setData] = useState<IRegion[]>(admin?.regionalOfficeSettings?.data || []);
  const [initialData, setInitialData] = useState<IRegion[]>(
    admin?.regionalOfficeSettings?.data || []
  );

  const [listCounties, setListCounties] = useState<ICounty[]>(
    admin?.regionalOfficeSettings?.counties || []
  );

  const getRegionalOffice = async () => {
    const res = await Promise.all([apiFetch(getAllRegionalOffice()), apiFetch(getAllCounties())]);

    if (res) {
      // setData(res[0].data.response);
      // setListCounties(res[1].data.response);
      dispatch(
        updateAdmin({
          ...admin,
          regionalOfficeSettings: {
            data: res[0].data.response,
            counties: res[1].data.response || []
          }
        })
      );
    }
  };

  const update = async () => {
    const res = await apiFetch(updateRegionalOffice(data));

    if (res) {
      if (res.data.errorCode.code > 0) {
        // do something
      } else {
        getRegionalOffice();
      }
    }
  };

  useEffect(() => {
    if (shouldSaveSettings) {
      dispatch(updateregionalOffice({ data }));

      update();
      // Reset Trigger
      setShouldSaveSettings(false);
    }
  }, [shouldSaveSettings]);

  const upstateCounties = (
    region: Nullable<IRegion>,
    county: CountyProps,
    counties: CountyProps[]
  ) => {
    const cloneData: IRegion[] = JSON.parse(JSON.stringify(initialData));
    const idx = cloneData.findIndex((d) => d.id === region?.id);

    // Remove the selected counties on other regions
    if (idx > -1) {
      const newData: IRegion[] = cloneData.reduce((arr: IRegion[], region) => {
        if (region && region.counties) {
          const i = region.counties.findIndex((c) => c.id === county.id);

          if (i > -1) region.counties.splice(i, 1);
        }

        arr.push(region);
        return arr;
      }, []);

      newData[idx].counties = counties;
      setData(newData);
      setInitialData(newData);
    }
  };

  useEffect(() => {
    getRegionalOffice();
  }, []);

  useEffect(() => {
    if (searchInput) {
      const delayKeyPressFn = setTimeout(() => {
        filterData();
      }, 1000);

      return () => clearTimeout(delayKeyPressFn);
    } else {
      setData(initialData);
    }
  }, [searchInput]);

  const filterData = () => {
    const newData = data.filter((item) => {
      if (item.counties) {
        return item.counties.find((item) => {
          return !!item.name.toLowerCase().match(searchInput.toLowerCase());
        });
      }
      return false;
    });
    setData(newData);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    setSearchInput(target.value);
  };

  return (
    <Container sx={{ maxWidth: '100% !important', height: '100%', padding: '20px 0 10px 0' }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid container item lg={6} xl={6} columnSpacing={1}>
          <Grid item lg={9} xl={9}>
            <SearchInput
              fullWidth
              value={searchInput || ''}
              onChange={(data) => handleSearchChange(data)}
            />
          </Grid>
        </Grid>
      </Grid>
      {data.length > 0 &&
        data.map((region: IRegion) => {
          return (
            <RegionalSettingsItem
              key={region.id + region.counties?.length}
              {...{ selectedRegion, setSelectedRegion }}
              canEditRegions={canEditRegions}
              regionOffice={region}
              listCounties={listCounties}
              updateCounties={upstateCounties}
            />
          );
        })}
    </Container>
  );
};

export default RegionalSettings;
