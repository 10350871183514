import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: '#303031'
    },
    wrapperWhite: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: '#f2f2f2'
    },
    grid: {
      padding: '0px 10%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignContent: 'center'
      }
    },
    gridItem: {
      paddingTop: '20px!important'
    },
    item: {
      border: 'none',
      backgroundColor: 'transparent',
      padding: '8px 16px',
      color: '#f2f2f2',
      cursor: 'pointer'
    },
    whiteGridItem: {
      paddingTop: '0px!important'
    }
  };
});

export default useStyles;
