import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  const inputHeight = '40px';

  return {
    styleOverrides: {
      root: {
        paddingRight: 0,
        height: inputHeight,
        backgroundColor: '#FAFAFA',
        fontSize: '12px',
        fontWeight: '600',
        color: palette.text?.secondary,
        '&.MuiInputBase-inputAdornedEnd': {
          paddingRight: 0
        },
        '& .MuiInputAdornment-positionEnd': {
          position: 'absolute',
          right: '12px'
        },
        '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
          paddingTop: 0,
          paddingBottom: 0,
          height: inputHeight,
          fontSize: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(48, 48, 49, 0.08)'
        },
        '& .MuiSvgIcon-root': {
          color: '#D8D8D8'
        },
        '&.MuiInputBase-multiline': {
          paddingTop: 0,
          paddingBottom: 0,
          height: 'auto',
          '& .MuiInputBase-input': {
            paddingTop: '7px',
            paddingBottom: '7px',
            height: '150px !important',
            fontSize: '14px'
          }
        },
        '&.MuiOutlinedInput-root.Mui-focused fieldset': {
          borderColor: '#0CACCF'
        }
      }
    }
  };
};
