import React from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { Paper, useTheme } from '@mui/material';
import { ISelectProp } from 'interfaces/ISelectProp';
// import useStyles from './MultiSelectCard.styles';
import MultiSelect from 'components/primitives/MultiSelect';
import FormGroup from 'components/primitives/FormGroup';
import { Typography } from '@mui/material';
const MultiSelectCardView: React.FC<
  {
    name: string;
    control: Control<FieldValues, any>; // eslint-disable-line
    title: string;
    label: string;
    optionsValue: ISelectProp[];
  } & BoxProps
> = ({ name, control, title, label, optionsValue, ...props }) => {
  const theme = useTheme();
  const classes = {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    paperWrapper: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.common.white
    }
  };

  const selectLabel = (value: string[]) => {
    return optionsValue?.filter((option) => value.includes(option.value || ''));
  };

  const handleChange =
    (onChange: (value: string | string[] | undefined | null) => void) =>
    (newValue: ISelectProp[]) => {
      const allServices = optionsValue.map((item) => {
        return item['value'].slice(1);
      });
      const tempValue = newValue.map((item) => item['value']);
      const isAll = tempValue.findIndex((val) => val === 'all');

      if (isAll > -1) {
        onChange(allServices);
      } else {
        onChange(tempValue);
      }
    };

  return (
    <Box {...props}>
      <Box sx={classes.root}>
        <Paper elevation={0} variant="outlined" sx={classes.paperWrapper}>
          <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: theme.spacing(1) }}>
            {title}
          </Typography>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label={label} error={error}>
                <MultiSelect
                  white={true}
                  error={Boolean(error)}
                  options={optionsValue}
                  onChange={handleChange(onChange)}
                  value={selectLabel(value)}
                />
              </FormGroup>
            )}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default MultiSelectCardView;
