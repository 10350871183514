import {
  Container,
  Grid,
  Paper,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import apiFetch from '../../../../../../services/apiFetch';
import { getMailboxAdminTemplates } from '../../../../../../services/apiAdminMailbox';
import { ITemplate } from 'interfaces/ITemplates';
import { toShortDateOnly } from '../../../../../../common/Utils/DateUtils';
import { ITemplateProps } from './ChooseTemplate.props';
import { IUser } from 'interfaces/IUser';

const ChooseTemplate: React.FC<ITemplateProps> = ({ setNewTemplate, template, selectedTab }) => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const isGeneralSelection = selectedTab === 0;

  useEffect(() => {
    getTemplates(isGeneralSelection);
  }, []);

  async function getTemplates(excludeMagicUrlTemplates: boolean) {
    const res = await apiFetch(getMailboxAdminTemplates());
    if (res && res.status === 200) {
      if (res.data.response.length > 0) {
        if (excludeMagicUrlTemplates) {
          const result = res.data.response.filter(
            (template: { hasMagicURL: any }) => !template.hasMagicURL
          );
          setTemplates(result);
        } else {
          setTemplates(res.data.response);
        }
      }
    }
  }

  const handleChecked = (template: ITemplate & { id: string }) => {
    setNewTemplate(template);
  };

  return (
    <Container disableGutters maxWidth="lg" sx={{ padding: '20px 0 10px 0' }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}
        name="template">
        {templates.map((template, index) => {
          return (
            <Grid container key={template.id} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
                <Grid item container alignItems="center" spacing={2} rowSpacing={2}>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <FormControlLabel
                      value={template.id}
                      control={
                        <Radio
                          name="template"
                          value={template.id}
                          onClick={() => {
                            handleChecked(template);
                          }}
                        />
                      }
                      label=""
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography>{template.title}</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent={'flex-end'}
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}>
                    <Typography variant="body2">
                      Created On: {toShortDateOnly(template.createdAt * 1000)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </RadioGroup>
    </Container>
  );
};

export default ChooseTemplate;
