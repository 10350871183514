/* eslint-disable react/display-name */
import React, { useState, useEffect, memo } from 'react';
import { Button, Container, Grid } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import useCommonStyles from 'common/styles/common.styles';
import FormDialog from 'components/fragments/FormDialog';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import ReactTableContainer from 'components/fragments/ReactTable';
import AddBusinessForm from './AddBusinessForm';
import BusinessesHeaders from 'common/arrays/BusinessesHeaders';
import {
  createNewBusiness,
  getAllBusinessCounter,
  getBusinessesPermitHolder,
  updateBusiness
} from 'services/permitHolderEndPoints';
import apiFetch from 'services/apiFetch';
import {
  IBusinesses,
  ICertificateHolders,
  IFoodProtectionManagers,
  IContact
} from 'interfaces/IBusinesses';
import { CellProps } from 'react-table';
import Image from 'components/primitives/Image';
import EditBlackIcon from 'common/images/edit_black_icon.png';
import moment from 'moment';
import { getAllCounties } from 'services/apiEndPoints';
import { ICounty } from 'interfaces/ICounty';
import BusinessEmptyState from './BusinessesEmpty.view';
import { updateSnackbarMessage } from '../../../../store/reducers/snackbarMessage.reducer';
import { useAppDispatch } from '../../../../app/hooks';
import parsTableHeader from '../../../../common/Utils/parseTableHeader';
import BusinessDirectoryHeaders from '../../../../common/arrays/BusinessDirectoryHeaders';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const localStorageKey = 'PH_BUSINESSES';
const DEFAULT_ORDER_BY = 'corporateName desc';
interface BusinessProps {
  businessData: {
    data: [];
    offset: number;
    keyword: string;
    filter: string;
    totalCount: number;
    orderBy: string;
  };
  selectedTab: any;
  isFetchingDataBusiness: boolean;
  onSaveBusiness?: () => void;
}

interface IDataState {
  data: [];
  offset: number;
  keyword: string | null;
  filter: string;
  totalCount: number;
  orderBy: string;
}

const Businesses = () => {
  const newCH = {
    firstName: '',
    lastName: '',
    certificateNumber: '',
    expiredAt: moment().unix()
  };
  const newFP = {
    firstName: '',
    lastName: '',
    certificateNumber: '',
    expiredAt: moment().unix()
  };

  const newContact = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  };
  const dispatch = useAppDispatch();

  const localCols = localStorage?.getItem(localStorageKey) || null;

  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), BusinessesHeaders)
    : BusinessesHeaders;

  const commonClasses = useCommonStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [openAddBusinessForm, setOpenAddBusinessForm] = useState<boolean>(false);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>(null);
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([
    { id: 'corporateName', desc: false }
  ]);

  const [actionType, setActionType] = useState<string>('');
  const [editUser, setIsEditUser] = useState<boolean>(false);
  const [toUpdateForm, setToUpdateForm] = useState<IBusinesses>({ sameMailingAddress: 'yes' });
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [certificateHolders, setCertificateHolders] = useState<ICertificateHolders[]>([]);
  const [foodProtectionManagers, setFoodProtectionManagers] = useState<IFoodProtectionManagers[]>(
    []
  );
  const [currentFilterParams, setCurrentFilterParams] = useState<string>('');
  const [isFetchingDataBusiness, setIsFetchingDataBusiness] = useState<boolean>(true);
  const [scrollToEl, setScrollToEl] = useState<{ section: string; isScroll: boolean }>({
    section: '',
    isScroll: false
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dataBusiness, setDataBusiness] = useState<IDataState>({
    data: [],
    offset: 0,
    keyword: '',
    filter: '',
    totalCount: 0,
    orderBy: DEFAULT_ORDER_BY
  });

  const getBusinessPermitHolder = async (
    filter: string,
    keyword: string | null,
    offset: number,
    orderBy: string,
    getTotalCount?: boolean
  ) => {
    try {
      let keywordString = '';
      if (keyword && keyword.length > 0) {
        setIsFetchingDataBusiness(false);
        keywordString = `&keyword=${keyword}`;
      } else {
        setIsFetchingDataBusiness(true);
      }
      if (getTotalCount) {
        const businessData = await apiFetch(
          getBusinessesPermitHolder(filter, keywordString, offset, orderBy)
        );
        const businessDataCount = await apiFetch(getAllBusinessCounter(keyword, filter));
        if (
          businessData &&
          businessData.status === 200 &&
          businessDataCount &&
          businessDataCount.status === 200
        ) {
          const data = businessData.data.response;
          const totalCount = businessDataCount.data.response;
          setDataBusiness({ data, filter, offset, keyword, totalCount, orderBy });
        }
      } else {
        const businessData = await apiFetch(
          getBusinessesPermitHolder(filter, keywordString, offset, orderBy)
        );
        if (businessData && businessData.status === 200) {
          const data = businessData.data.response;
          setDataBusiness({ ...dataBusiness, data, filter, offset, keyword, orderBy });
        }
      }
      setIsFetchingDataBusiness(false);
    } catch (error: any) {
      console.log(error, 'ERROR GET BUSINESS PERMIT HOLDER');
      setIsFetchingDataBusiness(false);
    }
  };

  useEffect(() => {
    // getBusinessPermitHolder('', '', 0, `&orderBy=${DEFAULT_ORDER_BY}`, true);
    if (defColTable) {
      const optidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');
      const permitIdx = defColTable.findIndex(
        (d: ColumnsProperties) => d.accessor === 'permitNumbers'
      );
      const accessidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'status');

      if (permitIdx > -1) {
        defColTable[permitIdx].Cell = ({ cell }: CellProps<object, any>) => {
          const obj: IBusinesses = cell.row.original;
          const permitBusiness = obj.permitNumbers;
          if (permitBusiness && permitBusiness.length > 0) {
            return (
              <p>
                {permitBusiness
                  .map((i: any) => {
                    return i.permit.PERMIT_NUMBER.toString();
                  })
                  .join()}
              </p>
            );
          }
          return <span>N/A</span>;
        };
      }

      if (accessidx > -1) {
        defColTable[accessidx].Cell = ({ cell }: CellProps<object, any>) => {
          const color = cell.value === 'accepted' ? 'green' : '#E48833';
          return (
            <span style={{ color, textTransform: 'capitalize', fontWeight: '500' }}>
              {cell.value.toUpperCase()}
            </span>
          );
        };
      }

      if (optidx > -1) {
        defColTable[optidx].Cell = ({ cell }: CellProps<object, any>) => {
          const business: IBusinesses = cell.row.original;
          return (
            <Button
              variant="outlined"
              size="small"
              className={commonClasses.editButton}
              startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setToUpdateForm(business);
                setCertificateHolders(business?.certificateHolders || []);
                setFoodProtectionManagers(business?.foodProtectionManagers || []);
                setContacts(business?.contacts || []);
                setOpenAddBusinessForm(true);
                setIsEditUser(true);
                setActionType('edit');
              }}>
              Edit
            </Button>
          );
        };
      }
    }
  }, []);

  const renderEditButton = (dataToEdit: any) => {
    return (
      <Button
        variant="outlined"
        size="small"
        className={commonClasses.editButton}
        // sx={{ width: '100%' }}
        startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
        onClick={() => {
          setToUpdateForm(dataToEdit);
          setCertificateHolders(dataToEdit?.certificateHolders || []);
          setFoodProtectionManagers(dataToEdit?.foodProtectionManagers || []);
          setContacts(dataToEdit?.contacts || []);
          setOpenAddBusinessForm(true);
          setIsEditUser(true);
          setActionType('edit');
        }}>
        Edit
      </Button>
    );
  };

  const handleFieldChange = (field: string, value: any) => {
    const cloneObj: IBusinesses = { ...toUpdateForm };

    if (field === 'phone' || field === 'phoneNumber') {
      cloneObj[field] = value ? value.replace(/-/g, '') : value;
    } else {
      cloneObj[field] = value;
    }
    setToUpdateForm(cloneObj);
  };

  const handleSelectChange = (field: string, value: any) => {
    const cloneObj = { ...toUpdateForm };

    if (field === 'county') {
      cloneObj.county = value.data;
      cloneObj.countyID = value.value;
    } else {
      cloneObj[field] = value.label;
    }
    setToUpdateForm(cloneObj);
  };

  const handleCertsFieldChange = (type: string, field: string, value: any, index: number) => {
    if (type === 'foodProtectionManagers') {
      const certClone = [...foodProtectionManagers];
      if (field === 'firstName') {
        certClone[index].firstName = value;
      }
      if (field === 'lastName') {
        certClone[index].lastName = value;
      }

      if (field === 'certificateNumber') {
        certClone[index].certificateNumber = value;
      }

      if (field === 'expiredAt') {
        certClone[index].expiredAt = value;
      }
      setFoodProtectionManagers([...certClone]);
    }
    if (type === 'certificateHolders') {
      const certClone = [...certificateHolders];
      if (field === 'firstName') {
        certClone[index].firstName = value;
      }
      if (field === 'lastName') {
        certClone[index].lastName = value;
      }

      if (field === 'certificateNumber') {
        certClone[index].certificateNumber = value;
      }

      if (field === 'expiredAt') {
        certClone[index].expiredAt = value;
      }
      setCertificateHolders([...certClone]);
    }
    if (type === 'contacts') {
      const certClone = [...contacts];
      if (field === 'firstName') {
        certClone[index].firstName = value;
      }
      if (field === 'lastName') {
        certClone[index].lastName = value;
      }

      if (field === 'phoneNumber') {
        certClone[index].phoneNumber = value;
      }

      if (field === 'email') {
        certClone[index].email = value;
      }
      setContacts([...certClone]);
    }
  };
  useEffect(() => {
    if (scrollToEl.isScroll) {
      handleScrollTo(scrollToEl.section);
      setScrollToEl({
        section: '',
        isScroll: false
      });
    }
  });

  const handleScrollTo = (section: string) => {
    let index: number;
    if (section === 'certificateHolders') {
      index = certificateHolders.length - 1;
    } else if (section === 'foodProtectionManagers') {
      index = foodProtectionManagers.length - 1;
    } else if (section === 'contacts') {
      index = contacts.length - 1;
    }
    setTimeout(() => {
      const selectedEl = document.getElementById(`${section}${index}`);
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 15);
  };

  const handleAddForm = (type: string) => {
    // type = (certificateHolders, foodProtectionManagers, contacts)
    if (type === 'certificateHolders') {
      setCertificateHolders([...certificateHolders, newCH]);
      setScrollToEl({ section: type, isScroll: true });
    } else if (type === 'foodProtectionManagers') {
      setFoodProtectionManagers([...foodProtectionManagers, newFP]);
      setScrollToEl({ section: type, isScroll: true });
    } else {
      setContacts([...contacts, newContact]);
      setScrollToEl({ section: type, isScroll: true });
    }
  };

  const onDelete = (type: string, index: number) => {
    if (type === 'contacts') {
      const data = contacts;
      data.splice(index, 1);
      setContacts([...data]);
    }
    if (type === 'manager') {
      const data = certificateHolders;
      data.splice(index, 1);
      setCertificateHolders([...data]);
    }
    if (type === 'foodProtectionManagers') {
      const data = foodProtectionManagers;
      data.splice(index, 1);
      setFoodProtectionManagers([...data]);
    }
  };

  const onSaveForm = async (values: any, resetForm: any) => {
    try {
      setLoading(true);
      const res = await apiFetch(createNewBusiness(values));
      setLoading(false);
      if (res) {
        if (res.data.errorCode.code === 0) {
          let orderBy = '';
          if (currentOrderBy.length > 0) {
            orderBy = `&orderBy=${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`;
          }
          getBusinessPermitHolder('', '', 0, orderBy, true);
          setToUpdateForm({});
          setContacts([]);
          setCertificateHolders([]);
          setFoodProtectionManagers([]);
          setOpenAddBusinessForm(false);
          resetForm({});
        } else {
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  const onChangeSort = (filter: string, isSortedDesc: boolean, filterParams = '') => {
    const newSortValue = [{ id: filter, desc: isSortedDesc }];

    const sortString = `${filter} ${isSortedDesc ? 'desc' : 'asc'}`;

    setCurrentOrderBy(newSortValue);
    setCurrentFilterParams(filterParams);
    getBusinessPermitHolder(
      filterParams,
      currentKeyword,
      currentOffset,
      `&orderBy=${sortString}`,
      false
    );
  };

  const updateBusinessHandler = async (data: any, resetForm: any) => {
    if (typeof data.employessNumber === 'object') {
      Object.assign(data, { employessNumber: data.employessNumber.value });
    }
    try {
      setLoading(true);
      const result = await apiFetch(updateBusiness(data));
      setLoading(false);
      if (result) {
        if (result.data.errorCode.code === 0) {
          let orderBy = '';
          if (currentOrderBy.length > 0) {
            orderBy = `&orderBy=${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`;
          }
          getBusinessPermitHolder('', currentKeyword, currentOffset, orderBy, true);
          setToUpdateForm({});
          setContacts([]);
          setCertificateHolders([]);
          setFoodProtectionManagers([]);
          setOpenAddBusinessForm(false);
          setIsEditUser(false);
          setActionType('');
          resetForm();
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container disableGutters sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
        <Grid
          container
          item
          alignItems="center"
          lg={12}
          sx={{ marginTop: isMobile ? '0' : '16px' }}>
          {!isFetchingDataBusiness &&
          dataBusiness?.data?.length === 0 &&
          currentKeyword?.length === 0 &&
          currentFilterParams?.length === 0 ? (
            <BusinessEmptyState
              businesses={[]}
              onClick={() => {
                setOpenAddBusinessForm(true);
                setToUpdateForm({});
                setContacts([]);
                setCertificateHolders([]);
                setFoodProtectionManagers([]);
                setActionType('add');
              }}
            />
          ) : (
            <ReactTableContainer
              isDataFetching={isFetchingDataBusiness}
              columns={defColTable}
              primaryKey="id"
              data={dataBusiness.data}
              module={localStorageKey}
              totalPage={dataBusiness.totalCount || 0}
              currSortBy={currentOrderBy}
              onChangeSort={onChangeSort}
              renderEditButton={renderEditButton}
              onChangeFilter={(offset, keyword, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }
                setCurrentOffset(offset);
                setCurrentFilterParams(filterParams);
                getBusinessPermitHolder(filterParams, keyword || '', offset, orderBy, true);
              }}
              onPageChange={(offset, keyword, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }

                setCurrentOffset(offset);
                setCurrentFilterParams(filterParams);
                getBusinessPermitHolder(filterParams, keyword || '', offset, orderBy, false);
              }}
              onSearch={(offset: any, keyword: string | null, filterParams = '') => {
                let orderBy = '';
                if (currentOrderBy.length > 0) {
                  orderBy = `&orderBy=${currentOrderBy[0].id} ${
                    currentOrderBy[0].desc ? 'DESC' : 'ASC'
                  }`;
                }
                setCurrentFilterParams(filterParams);
                getBusinessPermitHolder(filterParams, keyword, 0, orderBy, true);
                setCurrentKeyword(keyword);
              }}
              suffix={
                <Button
                  className={commonClasses.blueButton}
                  sx={{
                    width: isMobile ? '100% !important' : '140px!important',
                    lineHeight: '15px'
                  }}
                  startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                  onClick={() => {
                    setOpenAddBusinessForm(true);
                    setToUpdateForm({});
                    setContacts([]);
                    setCertificateHolders([]);
                    setFoodProtectionManagers([]);
                    setActionType('add');
                  }}>
                  Add New Business
                </Button>
              }
            />
          )}
        </Grid>
      </Container>

      <FormDialog
        show={openAddBusinessForm}
        fullWidth
        actionType={actionType}
        form="business-form"
        loading={loading}
        initialData={toUpdateForm}
        title={actionType === 'edit' ? 'Edit business' : 'Add New Business'}
        handleClose={() => {
          if (actionType === 'edit') {
            setIsEditUser(false);
            setActionType('');
            setContacts([]);
            setCertificateHolders([]);
            setFoodProtectionManagers([]);
          }
          setOpenAddBusinessForm(false);
          setToUpdateForm({ sameMailingAddress: 'yes' });
        }}
        handleSave={(values: any, reset) => {
          onSaveForm(values, reset);
        }}
        handleSaveEdit={(values: any, reset) => {
          updateBusinessHandler(values, reset);
        }}>
        <AddBusinessForm
          data={toUpdateForm}
          certificateHolders={certificateHolders}
          foodProtectionManagers={foodProtectionManagers}
          contacts={contacts}
          onDelete={onDelete}
          handleFieldChange={handleFieldChange}
          handleSelectChange={handleSelectChange}
          handleCertsFieldChange={handleCertsFieldChange}
          handleAddForm={handleAddForm}
        />
      </FormDialog>
    </React.Fragment>
  );
};

export default memo(Businesses);
