import AccessAreaType from 'common/enums/AccessAreasType';
import { IRadioSelection } from 'interfaces/IRadioSelection';

const AccessAreas: {
  value: string;
  label: string;
}[] = [
  {
    value: AccessAreaType.BUSINESS_DIRECTORY,
    label: 'Business Directory'
  },
  {
    value: AccessAreaType.MAILBOX,
    label: 'MailBox'
  },
  {
    value: AccessAreaType.USERS,
    label: 'Permit Holders'
  },
  {
    value: AccessAreaType.LIBRARY,
    label: 'Library'
  },
  {
    value: AccessAreaType.SURVEYS,
    label: 'Surveys'
  },
  {
    value: AccessAreaType.USER_MANAGEMENT,
    label: 'User Management'
  }
];

export default AccessAreas;
