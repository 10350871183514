import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSurveyGetState } from './survey.state';
import { getSurveyGuest } from './survey.trunk';

export const surveyGetSlice = createSlice({
  name: 'surveyGet',
  initialState: initialSurveyGetState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSurveyGuest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSurveyGuest.rejected, (state, action) => {
        const error = {
          code: 0,
          message: 'Something went wrong!'
        };

        if (action.error.message) {
          error.message = action.error.message;
        }

        state.loading = false;
        state.error = error;
      })
      .addCase(getSurveyGuest.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.entity = action.payload;
      });
  }
});
