/* eslint-disable max-len */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Container, Typography, Grid } from '@mui/material';
import LandingPageLayout from 'components/layouts/LandingPageLayout';
import MediaCard from 'components/fragments/MediaCard';
import useStyles from './videos.styles';
import SearchInput from 'components/primitives/SearchInput';
import { ILibrary } from 'interfaces/ILibrary';
import { ApiGuestLibrarySearch, getLibrary } from '../../services/apiGuestLibrary';
import apiFetch from '../../services/apiFetch';
import CircularProgress from '@mui/material/CircularProgress';

const Videos: React.FC = (props) => {
  const classes = useStyles();

  const [data, setData] = useState<ILibrary[]>([]);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [searchInput, setSearchInput] = useState<string | null>('');
  const [isDataFetching, setIsDataFetching] = useState<boolean>(true);

  useEffect(() => {
    const delayKeyPressFn = setTimeout(() => {
      setCurrentKeyword(searchInput);
    }, 200);

    return () => clearTimeout(delayKeyPressFn);
  }, [searchInput]);

  useEffect(() => {
    setIsDataFetching(true);
    getAllLibrary();
  }, [currentKeyword]);

  const getAllLibrary = async (getTotalCount: boolean = true) => {
    let filters: ApiGuestLibrarySearch = {
      type: 'video',
      orderBy: `createdAt DESC`,
      language: `EN`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    const usersData = await apiFetch(getLibrary(filters));

    setIsDataFetching(false);
    if (usersData) {
      setData(usersData.data?.response || []);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    setSearchInput(target.value);
  };

  return (
    <LandingPageLayout>
      <div>
        <Box className={classes.titleWrapper}>
          <Box sx={{ width: { lg: '50%', xl: '40%' }, textAlign: 'center', color: '#fff' }}>
            <Typography variant="h6" className={classes.textTitle}>
              {`Educational videos`}
            </Typography>
          </Box>
        </Box>
        <Container maxWidth="xl" sx={{ p: '20px 0' }}>
          <SearchInput
            fullWidth
            value={searchInput}
            onChange={(data) => handleSearchChange(data)}
          />
          <Grid container spacing={2} sx={{ mt: '10px' }} justifyContent="center">
            {isDataFetching ? (
              <Grid container justifyContent="center">
                <Box className={classes.loading}>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              <>
                {data && data.length > 0 ? (
                  data.map((library) => (
                    <Grid key={library.id} item xl={3} lg={3}>
                      <MediaCard
                        alt="DHEC video content"
                        title={library.title_EN}
                        height="208"
                        isVideo
                        description={library.description_EN}
                        link={library.url_EN}
                      />
                    </Grid>
                  ))
                ) : (
                  <div className={classes.noResultsDiv}>
                    <div>
                      <Typography className={classes.titleNoResults}>
                        No results for “{currentKeyword}”
                      </Typography>
                      <Typography className={classes.descriptionNoResults}>
                        Please try using other terms
                      </Typography>
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
        </Container>
      </div>
    </LandingPageLayout>
  );
};

export default Videos;
