import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import apiFetch from './apiFetch';
import { IUser } from 'interfaces/IUser';

export interface ApiAdminMailboxSearch {
  keyword?: string;
  subject?: { index: number; value: string }[];
  templateID?: { index: number; value: string }[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminMailboxFilters {
  keyword?: string;
  templateID?: string[];
  subject?: string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminCreateMailbox {
  templateID: string;
  body: string;
  subject: string;
  recipients: IUser & { id: string }[];
  hasMagicURL: number;
}

export interface ApiAdminCreateMailboxCounties {
  templateID: string;
  body: string;
  subject: string;
  counties: string;
  hasMagicURL: number;
}

export const createMailboxAdmin = (data: ApiAdminCreateMailbox): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/admin/mailbox`,
    data: data
  };
};

export const createMailboxAdminCounties = (data: ApiAdminCreateMailboxCounties): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/admin/mailbox/counties`,
    data: data
  };
};

export const getMailboxAdmin = (filters: ApiAdminMailboxSearch): IEndpoint => {
  const params = setMailboxParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/mailbox`,
    params: params
  };
};

export const getMailboxAdminCounter = (filters: ApiAdminMailboxSearch): IEndpoint => {
  const params = setMailboxParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/mailbox/counter`,
    params: params
  };
};

export const getMailboxAdminTemplates = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/mailbox/templates`
  };
};

export const getAdminMailboxForFilter = (
  filter: string,
  keyword: string,
  offset?: number,
  limit: number = 10
): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/mailbox/filters/${filter}`,
    params: {
      limit: limit,
      offset: offset,
      keyword: keyword
    }
  };
};

function setMailboxParams(filters: ApiAdminMailboxSearch, counter = false): ApiAdminMailboxFilters {
  const params: ApiAdminMailboxFilters = {};

  if (filters.subject && filters.subject.length > 0) {
    params.subject = filters.subject.map((data) => data.value.toString());
  }
  if (filters.templateID && filters.templateID.length > 0) {
    params.templateID = filters.templateID.map((data) => data.value.toString());
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
