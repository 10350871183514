import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../services/apiFetch';
import { getSurveyByToken } from '../../services/survey.service';

export const getSurveyGuest = createAsyncThunk(
  'survey/get',
  async (filters: { token: string }, { rejectWithValue }) => {
    const res = await apiFetch(getSurveyByToken(filters.token));

    if (res.data?.errorCode.code === 0) {
      return res.data?.response || null;
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
