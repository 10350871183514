import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { ILibrary } from 'interfaces/ILibrary';
import { ISendContactSubject } from 'interfaces/IContact';

export const getContactSubjects = (params: { type: 0 | 1 }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `api/guest/contact/subjects`,
    params: params
  };
};

export const sendContactRequest = (params: ISendContactSubject): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/guest/contact/`,
    data: params
  };
};
