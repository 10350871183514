import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import useStyles from './SurveyFooter.styles';
import AppLogo from 'common/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { SurveyFooterProps } from './SurveyFooter.props';
import ContactForm from 'components/fragments/ContactForm';
import HomepageModalsState from 'store/state/HomepageModalsState';
import { selecthomepageModals, updatehomepageModals } from 'store/reducers/homepageModals';
import SnackbarMessage from '../SnackbarMessage';

const SurveyFooter: React.FC<SurveyFooterProps> = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const homepageModals = useAppSelector(selecthomepageModals);

  const defaultModal = {
    showLogin: false,
    showRegister: false,
    showContact: false,
    showResetPassword: false,
    showFinishSignup: false
  };
  const [modalStatus, setModalStatus] = useState<HomepageModalsState>(defaultModal);

  useEffect(() => {
    setModalStatus(homepageModals);
  }, [homepageModals, modalStatus]);

  const cbModal = (value: boolean, name: string) => {
    const payload = {
      ...defaultModal,
      [name]: value
    };
    setModalStatus(payload);
    dispatch(updatehomepageModals(payload));
  };

  return (
    <Box className={classes.wrapperWhite}>
      <Grid alignItems="center" container spacing={10} sx={{ padding: '20px 10%', marginTop: '0' }}>
        <Grid item md={2} lg={2} className={classes.whiteGridItem}>
          <Box
            component="img"
            alt="DHEC"
            src={AppLogo}
            sx={{ width: '122px', height: '45px' }}
            onClick={() => navigate('/')}
          />
        </Grid>
        <Grid item lg={4} className={classes.whiteGridItem} />
        <Grid container item lg={6} justifyContent="flex-end" className={classes.whiteGridItem}>
          <Button
            variant="text"
            onClick={() => {
              cbModal(true, 'showContact');
            }}>
            Contact DHEC
          </Button>
        </Grid>
      </Grid>
      {modalStatus?.showContact && (
        <ContactForm
          show={modalStatus?.showContact}
          handleClose={() => {
            cbModal(false, 'showContact');
          }}
          handleSave={() => {
            cbModal(false, 'showContact');
          }}></ContactForm>
      )}
    </Box>
  );
};

export default SurveyFooter;
