import React, { useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectSnackbarMessageMessage,
  selectSnackbarMessageShow,
  updateSnackbarMessage
} from '../../../store/reducers/snackbarMessage.reducer';

const SnackbarMessage: React.FC = () => {
  const show = useAppSelector(selectSnackbarMessageShow);
  const message = useAppSelector(selectSnackbarMessageMessage);

  const dispatch = useAppDispatch();

  const closeMessage = () => {
    dispatch(updateSnackbarMessage({ show: false, message: null }));
  };

  useEffect(() => {}, [show, message]);

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={() => {
        closeMessage();
      }}
    >
      <Alert
        onClose={() => {
          closeMessage();
        }}
        severity="error"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
