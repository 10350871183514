import { ErrorInterface } from '../../interfaces/genera.interface';

export interface SurveyViewState {
  entity: null | any;
  error: false | ErrorInterface;
  loading: boolean;
}

export const initialSurveyGetState: SurveyViewState = {
  entity: null,
  error: false,
  loading: false
};
