/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { Settings } from 'components/primitives/Icons';
import ReactTableContainer from 'components/fragments/ReactTable';
import RegionalSettings from '../../RegionalSettings';
import BusinessDirectoryHeaders from 'common/arrays/BusinessDirectoryHeaders';
import { BusinessDirProps } from './BusinessDirectory.props';
import { useAppSelector } from 'app/hooks';
import { IUser } from 'interfaces/IUser';
import apiFetch from 'services/apiFetch';
import { IBusinesses } from 'interfaces/IBusinesses';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import { CellProps } from 'react-table';
import useCommonStyles from 'common/styles/common.styles';
import Image from 'components/primitives/Image';
import EditBlackIcon from 'common/images/edit_black_icon.png';
import {
  ApiAdminBusinessesSearch,
  getBusinesses,
  getBusinessesCounter
} from '../../../../services/apiAdminBusiness';
import FormDialogRegions from '../../../fragments/FormDialogAdminRegions';
import ViewBusiness from './ViewBusiness';
import { selectAccount } from 'store/reducers/account';
import parsTableHeader from 'common/Utils/parseTableHeader';


const localStorageKey = 'ADMIN_BUSINESS_DIRECTORY';
const DEFAULT_ORDER_BY = 'title_en asc';

const validateAdminPermission = (account: IUser) => {
  if (account?.role === 'admin' && (account?.permissions || []).length > 0) {
    const idx = (account?.permissions || []).findIndex((p) => p === 'businessDirectory');
    return idx > -1;
  }

  return false;
};

const BusinessDirectoryView: React.FC<BusinessDirProps> = () => {
  const [data, setData] = useState<Partial<IUser>[]>([]);
  const [totalBusinesses, setTotalBusinesses] = useState<number>(0);

  // START filters
  const [isFetchingBusiness, setIsFetchingBusiness] = useState<boolean>(true);

  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<{ id: string; desc: boolean }[]>([
    { id: 'corporateName', desc: false }
  ]);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  // END filters

  const localCols = localStorage?.getItem(localStorageKey) || null;

  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), BusinessDirectoryHeaders)
    : BusinessDirectoryHeaders;

  const [showRegionalSettings, setShowRegionalSettings] = useState<boolean>(false);
  const [shouldSaveSettings, setShouldSaveSettings] = useState<boolean>(false);
  const account = useAppSelector(selectAccount);
  const canEdit = account?.role === 'superadmin' ? true : validateAdminPermission(account || {});
  const canEditRegions = account?.role === 'superadmin';
  const commonClasses = useCommonStyles();

  // START BUSINESS FORM //
  const [openBusinessForm, setOpenBusinessForm] = useState<boolean>(false);
  const [business, setBusiness] = useState<IBusinesses | null>(null);

  // END BUSINESS FORM //

  useEffect(() => {
    getAllBusinessesAdmin();
  }, [currentOffset, currentKeyword, currentFilterParams]);

  useEffect(() => {
    getAllBusinessesAdmin(true);
  }, [currentOrderBy]);

  const getAllBusinessesAdmin = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminBusinessesSearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getBusinesses(filters)).then((data) => {
      setIsFetchingBusiness(false);
      return data;
    });

    if (usersData) {
      setData(usersData.data?.response || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getBusinessesCounter(filters));
      setTotalBusinesses(total.data?.response || 0);
    }
  };

  useEffect(() => {
    if (defColTable) {
      const regionalOffice = defColTable.findIndex(
        (d: ColumnsProperties) => d.accessor === 'regionalOffice'
      );

      if (regionalOffice > -1) {
        defColTable[regionalOffice].Cell = ({ cell }: CellProps<object, any>) => {
          const business: IBusinesses = cell.row.original;
          return business.county?.region?.name;
        };
      }

      const options = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');

      if (options > -1) {
        defColTable[options].Cell = ({ cell }: CellProps<object, any>) => {
          const business: IBusinesses = cell.row.original;
          return (
            <Button
              variant="outlined"
              size="small"
              className={commonClasses.editButton}
              startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setOpenBusinessForm(true);
                setBusiness(business);
              }}>
              Edit
            </Button>
          );
        };
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Container disableGutters sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
        <Grid container item alignItems="center">
          <ReactTableContainer
            columns={defColTable}
            primaryKey="id"
            data={data}
            isDataFetching={isFetchingBusiness}
            module={localStorageKey}
            hasCheckSelection={false}
            totalPage={totalBusinesses}
            currSortBy={currentOrderBy}
            onPageChange={(offset, keyword) => {
              setCurrentOffset(offset);
            }}
            onSearch={(offset, keyword) => {
              setCurrentKeyword(keyword);
              setCurrentOffset(0);
            }}
            filtersData={(filters) => {
              setCurrentFilterParams(filters);
              setCurrentOffset(0);
            }}
            onChangeSort={(filter: string, isSortedDesc: boolean) => {
              const newSortValue = [{ id: filter, desc: isSortedDesc }];
              setCurrentOrderBy(newSortValue);
            }}
            suffix={
              <div>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      color="primary"
                      sx={{ width: '219px!important' }}
                      startIcon={<Settings color="#126DA2" width="12px" height="12px" />}
                      onClick={() => setShowRegionalSettings(true)}>
                      Regional Settings
                    </Button>
                  </Grid>
                </Grid>
              </div>
            }
          />
        </Grid>
      </Container>
      <FormDialogRegions
        fullWidth
        show={showRegionalSettings}
        showActionButtons={canEditRegions}
        title={'Regional Settings'}
        handleClose={() => setShowRegionalSettings(false)}
        hasSave={canEditRegions}
        handleSave={() => {
          setShouldSaveSettings(true);
          setShowRegionalSettings(false);
          getAllBusinessesAdmin();
        }}>
        <RegionalSettings
          canEditRegions={canEditRegions}
          shouldSaveSettings={shouldSaveSettings}
          setShouldSaveSettings={setShouldSaveSettings}
        />
      </FormDialogRegions>
      {openBusinessForm && (
        <ViewBusiness
          businessInfo={business}
          handleClose={() => {
            setOpenBusinessForm(false);
            getAllBusinessesAdmin();
          }}
          handleSave={() => {
            setOpenBusinessForm(false);
            getAllBusinessesAdmin();
          }}></ViewBusiness>
      )}
    </React.Fragment>
  );
};

export default BusinessDirectoryView;
