import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

export const PermitNumbersHeaders: ColumnsProperties[] = [
  {
    Header: '',
    accessor: 'id',
    isSelected: true,
    filterHidden: true,
    disableSortBy: true,
    disableColumnSettings: true
  },
  {
    Header: 'PERMIT_NUMBER',
    accessor: 'PERMIT_NUMBER',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'COUNTY',
    accessor: 'COU_CODE',
    isSelected: true
  },
  {
    Header: 'PC_CODE',
    accessor: 'PC_CODE',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'PERMIT_ID',
    accessor: 'PERMIT_ID',
    isSelected: true,
    filterHidden: true
  },
  {
    Header: 'NAME',
    accessor: 'NAME',
    isSelected: true
  },
  {
    Header: 'CITY',
    accessor: 'CITY',
    isSelected: true
  },
  {
    Header: 'STATE',
    accessor: 'STATE',
    isSelected: false,
    filterHidden: true
  },
  {
    Header: 'VerifiedDate',
    accessor: 'VerifiedDate',
    isSelected: true,
    filterHidden: true
  }
];
