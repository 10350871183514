enum AdminTabsEnum {
  UNDEFINED = -1,
  BUSINESS_DIRECTORY = 0,
  MAILBOX = 1,
  PERMIT_HOLDERS = 2,
  LIBRARY = 3,
  SURVEYS = 4,
  USERS = 5
}

export default AdminTabsEnum;
