import React, { useState } from 'react';
import useStyles from './MainLayout.styles';
import Header from 'components/snippets/Header';
import Footer from 'components/snippets/Footer';
import SnackbarMessage from '../../fragments/SnackbarMessage';

const MainLayoutView: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Header isLoggedIn={true} />
      <div className={classes.page}>{props.children}</div>
      <Footer isLoggedIn={true} />
      <SnackbarMessage></SnackbarMessage>
    </div>
  );
};

export default MainLayoutView;
