import ProtectedPage from 'components/core/ProtectedPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Documents from './Documents';
import Home from './Home';
import PermitHolder from './PermitHolder';
import Videos from './Videos';
import Test from './test.view';
import SurveyPageView from './Survey';
import EditProfileMagicContainer from './EditProfileMagic';
const Pages: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/test-email" element={<Test />} />
      <Route path="/documents" element={<Documents />} />
      <Route path="/surveys" element={<SurveyPageView />} />
      {/*<Route path="/editProfile" element={<EditProfile />} />*/}
      <Route path="/" element={<ProtectedPage />}>
        <Route path="permit-holder" element={<PermitHolder />} />
        <Route path="dhec-admin" element={<Admin />} />
        <Route path="editProfile" element={<EditProfileMagicContainer />} />
      </Route>
    </Routes>
  );
};

export default Pages;
