import React from 'react';
import { DatePicker as MuiDP, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField from '../TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePicker: React.FC<DatePickerProps<any, Date>> = (props: DatePickerProps<any, Date>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDP {...props} renderInput={(params) => <TextField {...params} />} />
    </LocalizationProvider>
  );
};

export default DatePicker;
