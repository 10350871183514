import token from './token';
import admin from './admin';
import account from './account';
import permitHolder from './permitHolder';
import myAccount from './myAccount';
import homepageModals from './homepageModals';
import regionalOffice from './regionalOffice';
import snackbarMessage from './snackbarMessage.reducer';

export default {
  token,
  admin,
  account,
  myAccount,
  permitHolder,
  homepageModals,
  regionalOffice,
  snackbarMessage
};
