import React, { useEffect } from 'react';
import { Grid, Radio, Checkbox, RadioGroup, FormControlLabel } from '@mui/material';
import { SurveyQuestionTypes } from '../../constants/survey.constants';
import TextArea from '../../../../primitives/TextArea';
import FormGroup from '../../../../primitives/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { errorTextStyle } from 'common/styles/common.styles';

interface ISurveyAnswerProp {
  index: number;
  type: string;
  options: any;
  isRequired: number;
}

const SurveyAnswer: React.FC<ISurveyAnswerProp> = (props: ISurveyAnswerProp) => {
  const { type, index, options, isRequired } = props;

  const {
    control,
    formState: { errors },
    setValue,
    reset
  } = useFormContext();

  useEffect(() => {
    reset();
  }, []);

  return (
    <Grid item lg={12} xl={12}>
      {type === SurveyQuestionTypes.TEXT && (
        <Grid item lg={12} xl={12} key={index}>
          <FormGroup label={'Please provide an answer'}>
            <Controller
              name={`answerText`}
              defaultValue={null}
              control={control}
              rules={isRequired ? { required: 'Field is required.' } : {}}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <>
                    <TextArea
                      name={name}
                      placeholder={'Please provide an answer'}
                      variant="standard"
                      onChange={onChange}
                      error={!!errors[name]?.message}
                    />
                    <p style={errorTextStyle}>
                      <ErrorMessage errors={errors} name={name} />
                    </p>
                  </>
                );
              }}
            />
          </FormGroup>
        </Grid>
      )}
      {type === SurveyQuestionTypes.SINGLE && (
        <Controller
          control={control}
          name={`answer`}
          rules={isRequired ? { required: 'Field is required.' } : {}}
          render={({ field: { name, onChange } }) => {
            return (
              <RadioGroup name={name} onChange={onChange}>
                {options.map((option: any) => {
                  return (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.question}
                    />
                  );
                })}
              </RadioGroup>
            );
          }}
        />
      )}
      {type === SurveyQuestionTypes.MULTIPLE && (
        <Controller
          control={control}
          defaultValue={[]}
          name={`answer`}
          rules={isRequired ? { required: 'Field is required.' } : {}}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                {options.map((option: any) => {
                  return (
                    <Grid item lg={12} xl={12} key={option.id}>
                      <FormControlLabel
                        key={option.id}
                        control={
                          <Checkbox
                            value={option.id}
                            checked={value?.includes(option.id)}
                            onChange={(event, checked) => {
                              if (checked) {
                                let newValue = value;
                                if (Array.isArray(value)) {
                                  newValue = [...value, event.target.value];
                                } else {
                                  newValue = [value, event.target.value];
                                }
                                onChange(newValue);
                              } else {
                                onChange(value?.filter((v: string) => v !== event.target.value));
                              }
                            }}
                          />
                        }
                        label={option.question}
                      />
                    </Grid>
                  );
                })}
              </>
            );
          }}
        />
      )}
    </Grid>
  );
};

export default SurveyAnswer;
