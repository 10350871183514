import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { ISelectInput } from './SearchInput.props';

const SelectInput: React.FC<ISelectInput> = ({
  defaultValue = '',
  value,
  options = [],
  placeholder,
  styles,
  components,
  onChange,
  formatOptionLabel,
  error,
  isClearable = false,
  minWidth = null,
  searchable = true,
  menuPlacement = 'auto'
}) => {
  const customStyles: StylesConfig = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      minWidth: minWidth ? minWidth : 'auto',
      width: '100%',
      cursor: 'pointer',
      backgroundColor: '#fbfcfc',
      borderColor: error ? 'red' : 'rgba(48, 48, 49, 0.08)'
    }),
    option: (base) => ({
      ...base,
      color: '#7B7B7B',
      cursor: 'pointer',
      background: '#fbfcfc',
      '&:hover, &:active': {
        backgroundColor: '#0CACCF',
        color: '#fff'
      }
    }),
    placeholder: (base) => ({
      ...base,
      color: '#c7c7c7'
    }),
    ...styles
  };

  return (
    <Select
      styles={customStyles}
      defaultValue={defaultValue}
      placeholder={placeholder}
      isSearchable={searchable}
      menuPlacement={menuPlacement}
      options={options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      isClearable={isClearable}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          text: '#7B7B7B',
          primary: '#0CACCF',
          primary25: '#ECF8FB',
          neutral80: '#7B7B7B'
        }
      })}
      // formatOptionLabel={formatOptionLabel}
      // getOptionLabel={getOptionLabel}
    />
  );
};

export default SelectInput;
