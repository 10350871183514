import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { ILibrary } from 'interfaces/ILibrary';

export interface ApiAdminBusinessesSearch {
  keyword?: string;
  corporateName?: { index: number; value: string }[];
  doingBusiness?: { index: number; value: string }[];
  facilityType?: { index: number; value: string }[];
  countyID?: { index: number; value: string }[];
  userID?: { index: number; value: string }[];
  status?: { index: number; value: string }[];
  permitNumbers?: { index: number; value: string }[];
  regionalOffice?: { index: number; value: string }[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminBusinessesFilters {
  keyword?: string;
  corporateName?: string[];
  doingBusiness?: string[];
  facilityType?: string[];
  countyID?: string[];
  permitNumbers?: string[];
  regionalOffice?: string[];
  status?: string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getBusinesses = (filters: ApiAdminBusinessesSearch): IEndpoint => {
  const params = setBusinessParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/businesses`,
    params: params
  };
};

export const getBusinessesCounter = (filters: ApiAdminBusinessesSearch): IEndpoint => {
  const params = setBusinessParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/businesses/counter`,
    params: params
  };
};

export const updateBusiness = (businessId: string, params: any): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `api/admin/businesses/${businessId}`,
    data: params
  };
};

export const getBusinessesById = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/businesses/${id}`
  };
};

function setBusinessParams(
  filters: ApiAdminBusinessesSearch,
  counter = false
): ApiAdminBusinessesFilters {
  const params: ApiAdminBusinessesFilters = {};

  if (filters.permitNumbers && filters.permitNumbers.length > 0) {
    params.permitNumbers = filters.permitNumbers.map((data) => data.value);
  }

  if (filters.regionalOffice && filters.regionalOffice.length > 0) {
    params.regionalOffice = filters.regionalOffice.map((data) => data.index.toString());
  }

  if (filters.corporateName && filters.corporateName.length > 0) {
    params.corporateName = filters.corporateName.map((data) => data.value);
  }

  if (filters.doingBusiness && filters.doingBusiness.length > 0) {
    params.doingBusiness = filters.doingBusiness.map((data) => data.value);
  }

  if (filters.facilityType && filters.facilityType.length > 0) {
    params.facilityType = filters.facilityType.map((data) => data.value);
  }

  if (filters.countyID && filters.countyID.length > 0) {
    params.countyID = filters.countyID.map((data) => data.value);
  }

  if (filters.status && filters.status.length > 0) {
    params.status = filters.status.map((data) => data.value);
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
