import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Paper, Switch } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Controller, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';
import { surveysTriggerOptionsSelector } from '../../../store/surveys-trigger-options/surveys-trigger-options.selector';
import SurveyQuestionActionTypes, {
  ACTIONS_OPTIONS
} from '../../../enums/SurveyQuestionActionTypesEnum';
import { surveysTriggerQuestionsSelector } from '../../../store/surveys-trigger-questions/surveys-trigger-options.selector';
import MultipleChoiceQuestionItem from './MultipleChoiceQuestionItem.view';
import TextArea from 'components/primitives/TextArea';
import { SurveyQuestionTypes } from '../../../enums/SurveyQuestionTypesEnum';

const MultipleChoiceFormView: React.FC<any> = (props) => {
  const commonClasses = useCommonStyles();

  const TRIGGER_OPTIONS = useSelector(surveysTriggerOptionsSelector);
  const [triggerSurvey, setTriggerSurvey] = useState<boolean>(false);

  const questionUuid = props.getValues('template.surveyQuestions')[props.index].uuid;
  const TRIGGER_QUESTIONS = useSelector(surveysTriggerQuestionsSelector(questionUuid));

  useEffect(() => {
    props.setValue(`template.surveyQuestions[${props.index}].type`, SurveyQuestionTypes.MULTIPLE);
  }, []);

  useEffect(() => {
    const action = props.getValues(`template.surveyQuestions[${props.index}].action`);
    if (action === SurveyQuestionActionTypes.QUESTION) {
      setTriggerSurvey(true);
    } else {
      setTriggerSurvey(false);
    }
  }, [props.getValues(`template.surveyQuestions[${props.questionIndex}].action`)]);

  useEffect(() => {
    const getValue = props.getValues(
      `template.surveyQuestions[${props.index}].triggerActionQuestionUUID`
    );
    // if (getValue) {
    //   props.setValue(`template.surveyQuestions[${props.index}].triggerActionQuestionUUID`, null);
    // }
  }, [TRIGGER_QUESTIONS]);

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control: props.control,
    name: `template.surveyQuestions[${props.index}].surveyQuestionsOptions`
  });

  return (
    <Grid container item lg={12}>
      <Paper elevation={1} sx={{ width: '100%', padding: 2, mt: '10px' }}>
        <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
          <Grid item lg={12}>
            <FormGroup label={'Question'}>
              <Controller
                name={`template.surveyQuestions[${props.index}].question`}
                control={props.control}
                rules={{ required: 'Field is required.' }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <>
                      <TextArea
                        defaultValue={value}
                        name={name}
                        onChange={onChange}
                        error={Boolean(error)}
                        variant="standard"
                        placeholder="Enter Question"
                      />
                      <p style={errorTextStyle}>
                        <ErrorMessage errors={props.errors} name={name} />
                      </p>
                    </>
                  );
                }}
              />
            </FormGroup>
          </Grid>
          <Grid item lg={12}>
            <FormGroup label={'Email Notification'}>
              <Controller
                name={`template.surveyQuestions[${props.index}].triggerOptionID`}
                control={props.control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  let dV = undefined;
                  if (TRIGGER_OPTIONS && TRIGGER_OPTIONS.length && value) {
                    dV = TRIGGER_OPTIONS.find((item: any) => item.value === value);
                  }
                  return (
                    <>
                      <SelectInput
                        key={dV}
                        defaultValue={dV}
                        placeholder={'Select Email'}
                        error={Boolean(error)}
                        options={TRIGGER_OPTIONS}
                        onChange={(e) => {
                          if (e === null) {
                            props.setValue(name, null);
                            return onChange(null);
                          }
                          props.setValue(name, e.value);
                          return onChange(e.value);
                        }}
                        isClearable={true}
                      />
                      <p style={errorTextStyle}>
                        <ErrorMessage errors={props.errors} name={name} />
                      </p>
                    </>
                  );
                }}
              />
            </FormGroup>
          </Grid>
          <Grid item lg={12}>
            {fields.map((field, index) => (
              <div key={field.id}>
                <MultipleChoiceQuestionItem
                  getValues={props.getValues}
                  questionIndex={props.index}
                  control={props.control}
                  itemIndex={index}
                  itemRemove={remove}
                  itemField={field}
                  errors={props.errors}
                  setValue={props.setValue}
                />
              </div>
            ))}
          </Grid>
          <Grid container item lg={12} justifyContent="center">
            <Button
              className={commonClasses.blueButton}
              size="small"
              startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
              onClick={() => {
                append({});
              }}>
              Add New
            </Button>
          </Grid>
          <Grid item lg={12}>
            <Divider />
          </Grid>
          <Grid
            container
            sx={{
              marginTop: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              paddingLeft: '8px'
            }}>
            <Grid
              item
              sx={{
                flexGrow: '1'
              }}>
              <FormGroup label={'Required'}>
                <Controller
                  name={`template.surveyQuestions[${props.index}].required`}
                  control={props.control}
                  defaultValue={0}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    let dV = !!value;
                    return (
                      <Switch
                        checked={dV}
                        value={false}
                        name={name}
                        onChange={(e) => {
                          props.setValue(name, e.target.checked ? 1 : 0);
                        }}
                      />
                    );
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item sx={{ display: 'inline-flex', gap: '12px' }}>
              <FormGroup>
                <Controller
                  name={`template.surveyQuestions[${props.index}].triggerActionType`}
                  control={props.control}
                  rules={{ required: 'Field is required.' }}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    let dV = undefined;
                    if (ACTIONS_OPTIONS && ACTIONS_OPTIONS.length && value) {
                      dV = ACTIONS_OPTIONS.find((item: any) => item.value === value);
                    }

                    return (
                      <>
                        <SelectInput
                          defaultValue={dV}
                          placeholder={'Select Survey Triggers'}
                          error={Boolean(error)}
                          options={ACTIONS_OPTIONS}
                          minWidth={'220px'}
                          onChange={(e) => {
                            props.setValue(name, e.value);
                            if (e.value && e.value === SurveyQuestionActionTypes.QUESTION) {
                              setTriggerSurvey(true);
                            } else {
                              setTriggerSurvey(false);
                            }
                            return onChange(e.value);
                          }}
                        />
                        <p style={errorTextStyle}>
                          <ErrorMessage errors={props.errors} name={name} />
                        </p>
                      </>
                    );
                  }}
                />
              </FormGroup>
              {triggerSurvey && (
                <FormGroup
                  key={props.getValues(
                    `template.surveyQuestions[${props.index}].triggerActionQuestionUUID`
                  )}>
                  <Controller
                    name={`template.surveyQuestions[${props.index}].triggerActionQuestionUUID`}
                    control={props.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      let dV = undefined;
                      if (TRIGGER_QUESTIONS && TRIGGER_QUESTIONS.length && value) {
                        dV = TRIGGER_QUESTIONS.find((item: any) => item.value === value);
                      }

                      return (
                        <>
                          <SelectInput
                            defaultValue={dV}
                            placeholder={'Select Question'}
                            error={Boolean(error)}
                            options={TRIGGER_QUESTIONS}
                            minWidth={'220px'}
                            onChange={(e) => {
                              props.setValue(name, e.value);
                              return onChange(e.value);
                            }}
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={props.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              )}
            </Grid>
            <Grid item>
              <DeleteOutlinedIcon
                sx={{
                  width: 20,
                  height: 20,
                  cursor: 'pointer'
                }}
                onClick={() => props.remove(props.index)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MultipleChoiceFormView;
