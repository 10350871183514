import { Nullable } from 'common/Utils/Nullable';

export const initialSurveysCountiesState: SurveysCountiesState = {
  counties: []
};

type SurveysCountiesState = Nullable<{
  counties: { label: string; value: string }[];
}>;

export default SurveysCountiesState;
