import React, { useEffect } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
  Dialog,
  Grid,
  Paper,
  IconButton
} from '@mui/material';

import { Box } from '@mui/system';
import { IViewBusiness } from './ViewBusiness.props';
import StaffDirectoryView from '../StaffDirectory';
import apiFetch from '../../../../../services/apiFetch';
import {
  ApiAdminStaffDirectorySearch,
  getStaffDirectoryAdminCounter
} from '../../../../../services/apiAdminStaffDirectory';
import ViewBusinessForm from '../ViewBusinessForm';
import useCommonStyles, { errorTextStyle } from '../../../../../common/styles/common.styles';
import FormGroup from '../../../../primitives/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../../../primitives/TextField';
import { ErrorMessage } from '@hookform/error-message';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SelectInput from '../../../../primitives/SelectInput';
import { Close } from '@mui/icons-material';
import FormDialogListPermitNumbers from '../../../../fragments/FormDialogListPermitNumbers/FormDialogListPermitNumbers.view';
import { getBusinessesById, updateBusiness } from '../../../../../services/apiAdminBusiness';
import { IPermit } from 'interfaces/IBusinesses';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>{children}</Box>}
    </div>
  );
}

const ViewBusiness: React.FC<IViewBusiness> = ({ businessInfo, handleClose, handleSave }) => {
  const commonClasses = useCommonStyles();
  const [valueTab, setValueTab] = React.useState<number>(0);
  const [staffNumber, setStaffNumber] = React.useState<number>(0);

  const [rejected, setRejected] = React.useState<boolean>(businessInfo?.status === 'rejected');
  const [showPermitsNumbers, setShowPermitsNumbers] = React.useState<boolean>(false);
  const [permits, setPermits] = React.useState<IPermit[]>();

  const statuses = [
    { value: 'accepted', label: 'Accept' },
    { value: 'rejected', label: 'Deny' }
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const {
    control,
    getValues,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<any>();

  const onSubmit = async (formData: any) => {
    const data = {
      status: formData.status,
      rejectMessage: formData.rejectMessage ?? null,
      permitNumbers: permits ?? []
    };

    if (businessInfo && businessInfo.id) {
      await apiFetch(updateBusiness(businessInfo.id, data));
    }
    handleSave?.();
  };

  useEffect(() => {
    getStaffMembersCount();
  }, []);

  useEffect(() => {
    getStaffMembersCount();
    getBusinessById();
  }, []);

  async function getStaffMembersCount() {
    let filters: ApiAdminStaffDirectorySearch = {
      businessID: businessInfo?.id
    };
    const total = await apiFetch(getStaffDirectoryAdminCounter(filters));
    setStaffNumber(total.data?.response || 0);
  }

  async function getBusinessById() {
    if (businessInfo?.id) {
      const business = await apiFetch(getBusinessesById(businessInfo?.id));

      if (business.data.response.permitNumbers) {
        business.data.response.permitNumbers.map((permit: any) => {
          setPermits((current) => {
            if (current) {
              return [...current, permit.permit];
            } else {
              return [permit.permit];
            }
          });
        });
      }
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#FAFAFA',
          boxShadow: 'inherit',
          overflowY: 'auto'
        }
      }}
    >
      {businessInfo?.corporateName && (
        <>
          <Typography sx={{ padding: '16px 24px', fontSize: '28px' }}>
            {businessInfo?.corporateName}
          </Typography>
          <IconButton
            onClick={() => {
              reset({});
              handleClose?.();
            }}
            aria-label="dhec-close"
            sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}
          >
            <Close sx={{ width: 25, height: 25 }} />
          </IconButton>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ maxWidth: '100%!important', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueTab} onChange={handleChange}>
                <Tab label={'Business information'} />;
                <Tab label={'Staff directory'} disabled={staffNumber == 0} />;
              </Tabs>
            </Box>
            <TabPanel value={valueTab} index={0}>
              <Grid container rowSpacing={2}>
                <Grid item lg={12} alignItems="center" columnSpacing={12}>
                  <Typography variant="h6" color="textSecondary">
                    Info
                  </Typography>
                </Grid>
                <Grid container item lg={12}>
                  <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
                    <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                      <Grid item container xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Grid item sx={{ flexGrow: 1 }}>
                          <FormGroup label={'Assign permit number'}>
                            <Controller
                              name={`permits`}
                              control={control}
                              rules={{
                                required: {
                                  message: 'Field is required.',
                                  value: false
                                }
                              }}
                              render={({ field: { name, onChange } }) => {
                                let dv = '';
                                if (permits) {
                                  dv = permits
                                    .map((permit: IPermit) => {
                                      return permit.PERMIT_NUMBER;
                                    })
                                    .join();
                                }

                                return (
                                  <>
                                    <TextField
                                      value={dv}
                                      error={!!errors[`permits`]?.message}
                                      name={name}
                                      onChange={onChange}
                                      variant="standard"
                                    />
                                    <p style={errorTextStyle}>
                                      <ErrorMessage errors={errors} name={`permits`} />
                                    </p>
                                  </>
                                );
                              }}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item>
                          <Button
                            className={commonClasses.addButton}
                            onClick={(e) => {
                              setShowPermitsNumbers(true);
                              // handleOpenPopOver(e, 'column');
                            }}
                          >
                            <ControlPointIcon />
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormGroup label={`Current status`}>
                          <TextField
                            value={businessInfo?.status || ''}
                            inputProps={{ readOnly: true }}
                            variant="standard"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormGroup label={'Update status'}>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue={businessInfo?.status || null}
                            rules={{ required: 'Field is required.' }}
                            render={({ field: { name, onChange, value } }) => {
                              let dV = null;
                              if (businessInfo && value) {
                                dV = statuses.filter((option) => value.includes(option.value));
                              }

                              return (
                                <>
                                  <SelectInput
                                    defaultValue={dV}
                                    error={!!errors.type?.message}
                                    options={statuses}
                                    placeholder={`Select`}
                                    onChange={(e) => {
                                      setValue('status', e.value);
                                      setRejected(e.value === 'rejected');
                                      return onChange(e.value);
                                    }}
                                  />
                                  <p style={errorTextStyle}>
                                    <ErrorMessage errors={errors} name="status" />
                                  </p>
                                </>
                              );
                            }}
                          />
                        </FormGroup>
                      </Grid>
                      {rejected && (
                        <Grid item sx={{ flexGrow: 1 }}>
                          <FormGroup label={'Reason for denial'}>
                            <Controller
                              name={`rejectMessage`}
                              control={control}
                              defaultValue={businessInfo?.rejectMessage || null}
                              rules={{
                                required: {
                                  message: 'Field is required.',
                                  value: true
                                }
                              }}
                              render={({ field: { name, onChange } }) => {
                                return (
                                  <>
                                    <TextField
                                      value={getValues(`rejectMessage`) || ''}
                                      error={!!errors[`rejectMessage`]?.message}
                                      name={name}
                                      onChange={onChange}
                                      variant="standard"
                                      placeholder={'Please type in a short reason'}
                                    />
                                    <p style={errorTextStyle}>
                                      <ErrorMessage errors={errors} name={`rejectMessage`} />
                                    </p>
                                  </>
                                );
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
                <DialogActions sx={{ marginLeft: 'auto' }}>
                  <Button type="submit" className={commonClasses.blueButton}>
                    {'Save'}
                  </Button>
                </DialogActions>
              </Grid>
              <ViewBusinessForm businessInfo={businessInfo} />
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <StaffDirectoryView businessInfo={businessInfo} />
            </TabPanel>
          </Box>
        </DialogContent>

        <DialogActions sx={{ paddingRight: '25px' }}>
          <Button
            onClick={() => {
              handleClose?.();
              clearErrors();
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>

      {showPermitsNumbers && businessInfo && (
        <FormDialogListPermitNumbers
          show={showPermitsNumbers}
          selectedPermits={permits}
          businessInfo={businessInfo}
          setSelectedPermits={setPermits}
          title={'Search for permits'}
          handleClose={() => {
            setShowPermitsNumbers(false);
          }}
          handleSave={(permits) => {
            setPermits(permits);
            setShowPermitsNumbers(false);
          }}
        ></FormDialogListPermitNumbers>
      )}
    </Dialog>
  );
};

export default ViewBusiness;
