import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { Container } from '@mui/system';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Controller, useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import QuestionForm from './QuestionForm';
import { QUESTION_TYPES } from '../../enums/SurveyQuestionTypesEnum';
import { getSurveyTriggerOptions } from '../../store/surveys-trigger-options/surveys-trigger-options.trunk';
import { useAppDispatch } from '../../../../../../app/hooks';

import {
  clearTriggerQuestions,
  updateTriggerQuestions
} from '../../store/surveys-trigger-questions/surveys-trigger-options.actions';
import { SurveyQuestionInterface } from '../../interfaces/survey.interface';
import TextField from '../../../../../primitives/TextField';
import { ErrorMessage } from '@hookform/error-message';

const CampaignQuestions: React.FC<any> = ({ disableGutters, templateForm, campaign = false }) => {
  const commonClasses = useCommonStyles();
  const dispatch = useAppDispatch();
  const [scrollToEl, setScrollToEl] = useState<{ isScroll: boolean }>({ isScroll: false });

  useEffect(() => {
    const surveyListDispatch = dispatch(getSurveyTriggerOptions(null));
    return () => {
      surveyListDispatch.abort();
    };
  }, []);

  useEffect(() => {
    if (scrollToEl.isScroll) {
      handleScrollTo();
      setScrollToEl({
        isScroll: false
      });
    }
  });

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray<any>({
    control: templateForm.control,
    name: 'template.surveyQuestions'
  });

  const handleScrollTo = () => {
    setTimeout(() => {
      const selectedEl = document.getElementById('templateQuestions')?.lastElementChild;
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 500);
  };

  const handleAddQuestion = () => {
    append({ uuid: uuidv4(), type: templateForm.getValues('type') });
    setScrollToEl({ isScroll: true });
  };

  useEffect(() => {
    const getSurveys = templateForm
      .getValues('template.surveyQuestions')
      .filter((survey: SurveyQuestionInterface, index: number) => {
        return !!survey.uuid;
      })
      .map((survey: SurveyQuestionInterface, index: number) => {
        return { id: survey.uuid, name: `Question ${index + 1}` };
      });

    dispatch(updateTriggerQuestions(getSurveys));
    return () => {
      dispatch(clearTriggerQuestions());
    };
  }, [fields]);

  return (
    <Container
      disableGutters={disableGutters}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100% !important',
        height: '100%',
        padding: '20px 0 10px 0',
        flexGrow: 1
      }}>
      {!campaign && (
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2, mt: '10px' }}>
            <Grid container item alignItems="flex-end">
              <Grid item lg={12} sx={{ width: '100%', padding: 0, mt: '10px' }}>
                <FormGroup label={'Template Name'}>
                  <Controller
                    name={`template.title`}
                    control={templateForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return (
                        <>
                          <TextField
                            defaultValue={value}
                            name={name}
                            onChange={onChange}
                            error={Boolean(error)}
                            variant="standard"
                            placeholder="Enter Template Name"
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={templateForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Grid
        id={'templateQuestions'}
        container
        spacing={1}
        rowSpacing={2}
        sx={{
          height: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        {fields.map((field, index) => (
          <QuestionForm
            key={field.id}
            index={index}
            control={templateForm.control}
            getValues={templateForm.getValues}
            setValue={templateForm.setValue}
            field={field}
            errors={templateForm.formState.errors}
            remove={remove}
          />
        ))}

        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2, mt: '10px' }}>
            <Grid container item alignItems="flex-end">
              <Grid item lg={9} sx={{ width: '100%', padding: 0, mt: '10px' }}>
                <FormGroup label={'Select Question Type'}>
                  <Controller
                    name={'template.type'}
                    control={templateForm.control}
                    rules={{ required: true }}
                    defaultValue={QUESTION_TYPES[0].value}
                    render={({ field: { onChange, value, name } }) => {
                      let dV = null;
                      if (QUESTION_TYPES && QUESTION_TYPES.length && value) {
                        dV = QUESTION_TYPES.filter((option: any) =>
                          value.includes(option.value)
                        )[0];
                      }
                      return (
                        <SelectInput
                          defaultValue={dV}
                          placeholder={'Select type List'}
                          options={QUESTION_TYPES}
                          onChange={(e) => {
                            templateForm.setValue(name, e.value);
                            return onChange(e.value);
                          }}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid container item lg={3} justifyContent="flex-end">
                <Button
                  className={commonClasses.whiteButton}
                  startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                  sx={{ width: '160px!important' }}
                  onClick={handleAddQuestion}>
                  {'Add Question'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CampaignQuestions;
