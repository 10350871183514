import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: '#FAFAFA'
    },
    page: {
      width: '100%',
      marginTop: '80px',
      marginBottom: '80px',
      minHeight: 'calc(100vh - 170px)',
      // minHeight: 'calc(100vh - 260px)',
      overflow: 'auto'
    }
  })
);

export default useStyles;
