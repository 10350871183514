import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSurveysTemplateGetState } from './surveys-template-get.state';
import { SurveyTemplateInterface } from '../../interfaces/survey.interface';
import { getSurveyTemplate } from './surveys-template-get.trunk';

export const surveyTemplateGetSlice = createSlice({
  name: 'surveyTemplateGet',
  initialState: initialSurveysTemplateGetState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSurveyTemplate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSurveyTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(
        getSurveyTemplate.fulfilled,
        (state, action: PayloadAction<SurveyTemplateInterface[]>) => {
          state.loading = false;
          state.error = false;
          state.entities = action.payload;

          state.clean = action.payload.map((item: any) => {
            // @ts-ignore
            delete item['id'];
            delete item['createdAt'];
            delete item['display'];
            item.surveyQuestions = item.surveyQuestions.map((question: any) => {
              delete question['id'];
              delete question['createdAt'];
              delete question['surveyTemplateID'];
              delete question['triggerOption'];
              return question;
            });

            return item;
          });
        }
      );
  }
});
