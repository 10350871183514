import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../../services/apiFetch';
import { getSurveyAdminCounter } from '../../services/apiAdminSurvey';
import { ApiAdminMailboxFilters, SurveyInterface } from '../../interfaces/survey.interface';

export const getSurveyListCount = createAsyncThunk(
  'survey/list-count',
  async (filters: ApiAdminMailboxFilters, { rejectWithValue }) => {
    const res = await apiFetch(getSurveyAdminCounter(filters));
    if (res.data?.errorCode.code === 0) {
      return res.data?.response || (0 as number);
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
