/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { CellProps } from 'react-table';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import UserManagementHeaders from 'common/arrays/UserManagementHeaders';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import useCommonStyles from 'common/styles/common.styles';
import ReactTableContainer from 'components/fragments/ReactTable';
import FormDialog from 'components/fragments/FormDialog';
import EditAdminUserForm from 'components/snippets/EditAdminUserForm';
import { IUser } from 'interfaces/IUser';
import apiFetch from 'services/apiFetch';
import EditBlackIcon from 'common/images/edit_black_icon.png';
import _ from 'lodash';
import {
  createNewAdminAccount,
  getAdminUsers,
  getAdminUsersCounter,
  getAdminUsersForSearch,
  updateAdminInfo,
  deleteAdminAccount
} from 'services/superAdminEndPoints';
import Image from 'components/primitives/Image';
import parsTableHeader from 'common/Utils/parseTableHeader';

const localStorageKey = 'ADMIN_USER_MGMNT';
const DEFAULT_ORDER_BY = 'email ASC';
const PERMISSIONS: string[] = [
  AccessAreaType.BUSINESS_DIRECTORY,
  AccessAreaType.MAILBOX,
  AccessAreaType.USERS,
  AccessAreaType.LIBRARY,
  AccessAreaType.SURVEYS
];
const DEFAULT_DATA = {
  permissions: PERMISSIONS
};
import useStyles from './userManagement.styles';
import moment from 'moment';
import { isValidEmail } from 'common/Utils/String';
import AccessAreaType from '../../../../common/enums/AccessAreasType';
import { updateSnackbarMessage } from '../../../../store/reducers/snackbarMessage.reducer';
import { useAppDispatch } from '../../../../app/hooks';

const ErrorMessageType: any = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  email: 'Email',
  password: 'Password'
};

const UserManagement: React.FC = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const commonClasses = useCommonStyles();
  const [openEditUserForm, setOpenEditUserForm] = useState<boolean>(false);
  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), UserManagementHeaders)
    : UserManagementHeaders;
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [data, setData] = useState<Partial<IUser>[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [toUpdateUserData, setToUpdateUserData] = useState<IUser>(DEFAULT_DATA);
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'email', desc: false }]);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [errors, setErrors] = useState<any>({});
  const [isFetchingAdminUsers, setIsFetchingAdminUsers] = useState<boolean>(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errors.api) {
      dispatch(updateSnackbarMessage({ show: true, message: errors.api }));
    }
  }, [errors]);

  useEffect(() => {
    if (defColTable) {
      const optidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');
      const accessidx = defColTable.findIndex(
        (d: ColumnsProperties) => d.accessor === 'permissions'
      );

      if (accessidx > -1) {
        defColTable[accessidx].Cell = ({ cell }: CellProps<object, any>) => {
          const user: IUser = cell.row.original;
          let permission = 'Disabled';
          if (user.role === 'superadmin') permission = 'Super Admin';
          else if (user.permissions?.length === PERMISSIONS.length) permission = 'Full Access';
          else if (
            (user.permissions || []).length > 0 &&
            (user.permissions || []).length < PERMISSIONS.length
          )
            permission = 'Custom Access';

          return <span>{permission}</span>;
        };
      }

      if (optidx > -1) {
        defColTable[optidx].Cell = ({ cell }: CellProps<object, any>) => {
          const user: IUser = cell.row.original;
          return (
            <Button
              variant="outlined"
              size="small"
              className={classes.editButton}
              startIcon={<Image src={EditBlackIcon} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setIsEditUser(true);
                setToUpdateUserData(user);
                setOpenEditUserForm(true);
              }}>
              Edit
            </Button>
          );
        };
      }
    }
  }, []);

  const parseUser = (users: IUser[] = []) => {
    if (users.length > 0) {
      const parsedUsers = users.reduce((arr: Partial<IUser>[], o) => {
        const user: Partial<IUser> = {
          id: o.id,
          firstName: o.firstName,
          permissions: o.permissions,
          email: o.email,
          lastName: o.lastName,
          permits: o.permits || '',
          phoneNumber: o.phoneNumber || '',
          status: o?.status,
          updatedAt: moment.unix(o?.updatedAt as number).format('MM/DD/YYYY'),
          role: o.role
        };

        arr.push(user);

        return arr;
      }, []);

      return parsedUsers;
    }

    return [];
  };

  const getAllUsers = async (
    offset: number = 0,
    keyword: string | null = '',
    orderBy = DEFAULT_ORDER_BY
  ) => {
    setIsFetchingAdminUsers(true);
    const usersData = await Promise.all([
      keyword
        ? apiFetch(getAdminUsersForSearch(keyword || '', orderBy, offset))
        : apiFetch(getAdminUsers(offset, orderBy)),
      apiFetch(getAdminUsersCounter(keyword, offset))
    ]).then((data) => {
      setIsFetchingAdminUsers(false);
      return data;
    });
    if (usersData) {
      const parsedUsers = parseUser(usersData[0]?.data?.response || []);
      setData(parsedUsers);
      setTotalUsers(usersData[1]?.data?.response || 0);
    }
  };

  const handleInputChange = (field: string, value: any) => {
    const cloneObj = { ...toUpdateUserData };

    cloneObj[field] = value;
    setToUpdateUserData(cloneObj);
  };

  const savePermissions = (permissions: string[]) => {
    const cloneObj = { ...toUpdateUserData };
    cloneObj.permissions = permissions;

    setToUpdateUserData(cloneObj);
  };

  const handleSaveNewAdminAccount = () => {
    const fieldsToCheck = ['firstName', 'lastName', 'phoneNumber', 'email'];

    if (!isEditUser) fieldsToCheck.push('password');

    const hasError = fieldsToCheck.some((f) => !_.has(toUpdateUserData, f) || !toUpdateUserData[f]);

    if (!hasError) {
      const email = toUpdateUserData.email || '';
      const errs: any = {};
      let shouldSave = true;

      if (!isEditUser) {
        const password = toUpdateUserData.password || '';

        if (password.length < 8) {
          shouldSave = false;
          errs['password'] = { message: 'Must contain atleast 8 characters' };
        }
      }

      if (!isValidEmail(email)) {
        shouldSave = false;
        errs['email'] = { message: 'Invalid email address' };
      }

      if (shouldSave) {
        setErrors({});
        if (isEditUser)
          updateAdmin().then(() => {
            setIsLoading(false);
          });
        else
          createNewAdmin().then(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        setErrors(errs);
      }
    } else {
      const errors = fieldsToCheck.reduce((obj: any, o: string) => {
        if (!toUpdateUserData[o]) {
          obj[o] = { message: `${ErrorMessageType[o]} is required.` };
        } else if (o === 'email' && toUpdateUserData[o]) {
          if (!isValidEmail(toUpdateUserData[o] || '')) {
            obj[o] = { message: `Invalid email address` };
          }
        }

        return obj;
      }, {});
      setErrors(errors);
      setIsLoading(false);
    }
  };
  const checkRole = (d: any) => {
    let dataToCheck = { ...d };
    let isSuperAdmin = dataToCheck.permissions?.includes('userManagement');
    if (isSuperAdmin) {
      dataToCheck.permissions = dataToCheck.permissions?.filter(
        (permission: any) => permission !== 'userManagement'
      );
      dataToCheck.role = 'superadmin';
    } else dataToCheck.role = 'admin';
    return dataToCheck;
  };
  const updateAdmin = async () => {
    let cloneUpdateUserData = checkRole(toUpdateUserData);

    const res = await apiFetch(updateAdminInfo(cloneUpdateUserData));

    if (res) {
      if (res.data.errorCode.code === 0) {
        setToUpdateUserData(DEFAULT_DATA);
        getAllUsers();
        setOpenEditUserForm(false);
      }
    }
  };

  const createNewAdmin = async () => {
    let cloneUpdateUserData = checkRole(toUpdateUserData);
    const res = await apiFetch(createNewAdminAccount(cloneUpdateUserData));
    if (res) {
      if (res.data.errorCode.code === 0) {
        setToUpdateUserData(DEFAULT_DATA);
        getAllUsers();
        setOpenEditUserForm(false);
      } else {
        setErrors({ api: res.data.errorCode.message });
      }
    }
  };

  const onChangeSort = (filter: string, isSortedDesc: boolean) => {
    const newSortValue = [{ id: filter, desc: isSortedDesc }];
    setCurrentOrderBy(newSortValue);
    getAllUsers(currentOffset, currentKeyword, `${filter} ${isSortedDesc ? 'DESC' : 'ASC'}`);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
  };

  const handleAgree = () => {
    setShowConfirmDialog(false);
    setIsLoading(true);
    onDelete({ id: toUpdateUserData?.id }).then((res) => {
      setIsLoading(false);
      setToUpdateUserData(DEFAULT_DATA);
      getAllUsers();
      setOpenEditUserForm(false);
    });
  };

  const onDelete = async (data: any) => {
    let res = null;

    if (data.id) {
      res = await apiFetch(deleteAdminAccount(data));
    } else {
      return;
    }
    return res;
  };

  return (
    <React.Fragment>
      <Container disableGutters sx={{ maxWidth: '100% !important', padding: '20px 0 10px 0' }}>
        <Grid container item alignItems="center" lg={12} sx={{ marginTop: '16px' }}>
          <ReactTableContainer
            columns={defColTable}
            primaryKey="id"
            data={data}
            isDataFetching={isFetchingAdminUsers}
            module={localStorageKey}
            currSortBy={currentOrderBy}
            totalPage={totalUsers}
            suffix={
              <Button
                className={commonClasses.blueButton}
                sx={{ width: '140px!important' }}
                startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                onClick={() => {
                  setIsEditUser(false);
                  setOpenEditUserForm(true);
                }}>
                Add User
              </Button>
            }
            onChangeSort={onChangeSort}
            onPageChange={(offset, keyword) => {
              setCurrentOffset(offset);
              getAllUsers(offset, keyword || '');
            }}
            onSearch={(offset, keyword) => {
              setCurrentKeyword(keyword);
              getAllUsers(offset, keyword || '');
            }}
          />
        </Grid>
      </Container>
      <FormDialog
        show={openEditUserForm}
        fullWidth
        title={toUpdateUserData?.id ? 'Edit User' : 'Add User'}
        handleClose={() => {
          setOpenEditUserForm(false);
          setToUpdateUserData({ ...DEFAULT_DATA });
          setErrors({});
        }}
        handleSave={() => {
          setIsLoading(true);
          handleSaveNewAdminAccount();
        }}
        hasDelete={toUpdateUserData?.id ? true : false}
        handleDelete={() => {
          setShowConfirmDialog(true);
        }}>
        <>
          <EditAdminUserForm
            key={JSON.stringify(errors)}
            errors={errors}
            toUpdateData={toUpdateUserData}
            handleSavePermissions={savePermissions}
            handleInputChange={handleInputChange}
            showConfirmDialog={showConfirmDialog}
            handleConfirmDialogClose={handleConfirmDialogClose}
            handleAgree={handleAgree}
            isLoading={isLoading}
          />
        </>
      </FormDialog>
    </React.Fragment>
  );
};

export default UserManagement;
