import { createTheme } from '@mui/material/styles';
import MuiAppBarStyles from './overrides/MuiAppBar';
import MuiButtonStyles from './overrides/MuiButton';
import MuiCheckbox from './overrides/MuiCheckbox';
import MuiContainer from './overrides/MuiContainer';
import MuiLinkStyles from './overrides/MuiLink';
import MuiOutlinedInputStyles from './overrides/MuiOutlinedInput';
import MuiPaperStyles from './overrides/MuiPaper';
import MuiStepLabel from './overrides/MuiStepLabel';
import MuiSwitch from './overrides/MuiSwitch';

import lightPalette from './palettes/lightPallete';
import typography from './typography';

const theme = createTheme({
  palette: lightPalette,
  components: {
    MuiAppBar: MuiAppBarStyles(lightPalette),
    MuiButton: MuiButtonStyles(lightPalette),
    MuiLink: MuiLinkStyles(lightPalette),
    MuiOutlinedInput: MuiOutlinedInputStyles(lightPalette),
    MuiPaper: MuiPaperStyles(lightPalette),
    MuiCheckbox: MuiCheckbox(lightPalette),
    MuiSwitch: MuiSwitch(lightPalette),
    MuiStepLabel: MuiStepLabel(lightPalette),
    MuiContainer: MuiContainer(lightPalette)
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography
});

export default theme;
