import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { IPointsOfContact } from 'interfaces/IPointsOfContact';
import { Controller, useWatch } from 'react-hook-form';
import { formatPhone } from '../../../../../../common/Utils/String';

const PointsOfContactForm: React.FC<IPointsOfContact> = (props: IPointsOfContact) => {
  const { control, errors, index, setValue, email, phoneNumber, lastName, firstName } = props;

  const data = useWatch({
    control,
    name: 'contacts'
  });
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Grid container item columnSpacing={1} rowSpacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid container item lg={12}>
          <Grid item lg={10} xl={10}>
            <Typography variant="h5">{props.title}</Typography>
          </Grid>
          <Grid container item lg={2} xl={2} alignItems="center" justifyContent="flex-end">
            <DeleteOutlinedIcon
              sx={{
                width: 20,
                height: 20,
                color: 'red',
                cursor: 'pointer'
              }}
              onClick={() => {
                const currentData = data;
                if (currentData) {
                  currentData.splice(index, 1);
                  setValue('contacts', currentData);
                }
                if (props?.onDelete) props?.onDelete('contacts', props.index);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`First Name`}>
            <Controller
              name={`contacts.${props.index}.firstName`}
              control={control}
              defaultValue={firstName}
              rules={{ required: 'Field is required.' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors.contacts?.[index]?.firstName?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors.contacts?.[index]?.firstName?.message}
                    variant="standard"
                  />
                );
              }}
            />
            {/* <TextField
              value={props?.firstName}
              onChange={(e) =>
                props.handleCertsFieldChange('contacts', 'firstName', e.target.value, props.index)
              }
            /> */}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Last Name`}>
            <Controller
              name={`contacts.${props.index}.lastName`}
              control={control}
              defaultValue={lastName}
              rules={{ required: 'Field is required.' }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors.contacts?.[index]?.lastName?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors.contacts?.[index]?.lastName?.message}
                    variant="standard"
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Phone Number`}>
            <Controller
              name={`contacts.${props.index}.phoneNumber`}
              control={control}
              defaultValue={phoneNumber}
              rules={{
                required: 'Field is required.',
                maxLength: { message: 'Value in this input is too long.', value: 45 },
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
                  message: 'Invalid phone number.'
                }
              }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value || ''}
                    error={errors.contacts?.[index]?.phoneNumber?.message ? true : false}
                    name={name}
                    onChange={(e) => onChange(formatPhone(e.target.value))}
                    helperText={errors.contacts?.[index]?.phoneNumber?.message}
                    variant="standard"
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormGroup label={`Email Address`}>
            <Controller
              name={`contacts.${props.index}.email`}
              control={control}
              defaultValue={email}
              rules={{
                required: 'Field is required.',
                maxLength: { message: 'Value in this input is too long.', value: 45 },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <TextField
                    value={value}
                    error={errors.contacts?.[index]?.email?.message ? true : false}
                    name={name}
                    onChange={onChange}
                    helperText={errors.contacts?.[index]?.email?.message}
                    variant="standard"
                  />
                );
              }}
            />
            {/* <TextField
              value={props?.email}
              onChange={(e) =>
                props.handleCertsFieldChange('contacts', 'email', e.target.value, props.index)
              }
            /> */}
          </FormGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PointsOfContactForm;
