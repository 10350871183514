import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

export default function parsTableHeader(storageData: any, header: any) {
    const data = storageData.map((cellItem: ColumnsProperties) => {
      const initialData = header.find((header: ColumnsProperties) => {
        return cellItem.accessor === header.accessor;
      });
      initialData.isSelected = cellItem.isSelected;

      return initialData;
    });
    return data;
  }