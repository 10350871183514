import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useStyles from './SurveyHeader.styles';
import AppLogo from '../../../../../common/images/logo.png';
import Image from 'components/primitives/Image';

const SurveyHeader: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <AppBar className={classes.appBar} sx={{ position: 'relative' }}>
      <Toolbar className={classes.toolBar}>
        <div style={{ flexGrow: 1 }}>
          <Image
            sx={{
              height: 42,
              width: 122,
              cursor: 'pointer'
            }}
            alt="DHEC"
            src={AppLogo}
            onClick={() => navigate('/')}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            onClick={() => {
              navigate('/');
            }}>
            Return to website
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default SurveyHeader;
