import { Avatar, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CloseIcon from 'common/images/close_icon.png';
import useCommonStyles from 'common/styles/common.styles';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { IUser } from 'interfaces/IUser';
import LockIcon from 'common/images/lock_icon.png';
import { IMyAccount } from './MyAccount.props';
import apiFetch from 'services/apiFetch';
import { updateAccountInfo } from 'services/apiEndPoints';
import { selectmyAccount, updateMyAccount } from 'store/reducers/myAccount';
import { selectAccount, updateAccount } from 'store/reducers/account';

export interface ISecurity {
  [key: string]: any;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const MyAccount: React.FC<IMyAccount> = (props) => {
  const myAccount = useAppSelector(selectmyAccount);
  const account = useAppSelector(selectAccount);
  const dispatch = useAppDispatch();
  const commonClasses = useCommonStyles();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [toEditForm, setToEditForm] = useState<IUser>(props);
  const [security, setSecurity] = useState<ISecurity>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleInputChange = (field: string, value: any) => {
    const cloneObj = { ...toEditForm };
    cloneObj[field] = value;

    setToEditForm(cloneObj);
  };

  // const handlePasswordInputChange = (field: String, value: string) => {
  //   const cloneObj: ISecurity = { ...security };
  //   security[field] = value;
  //   // cloneObj[field] = value;

  //   setToEditForm(cloneObj);
  // };

  const handleSavePIForm = async () => {
    // if (_.isEqual(userInfo, toUpdateUserInfo)) return;

    const res = await apiFetch(updateAccountInfo(toEditForm));

    if (res) {
      dispatch(updateAccount(res.data.user));
      setIsEdit(!isEdit);
    }
  };

  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ padding: '20px 0 10px 0', display: myAccount?.show ? '' : 'none' }}
    >
      <Grid container alignItems="center" rowSpacing={2}>
        <Grid item container xs={12} md={12} xl={8} alignItems="center" direction="row">
          <IconButton onClick={() => dispatch(updateMyAccount({ ...myAccount, show: false }))}>
            <Avatar src={CloseIcon} sx={{ width: 20, height: 20 }} />
          </IconButton>
          <Typography variant="h5">My Account</Typography>
        </Grid>
        <Grid xs={12} md={12} xl={4} item container justifyContent="flex-end">
          <Button variant="outlined" onClick={() => handleSavePIForm()}>
            {isEdit ? 'Save' : 'Edit'}
          </Button>
        </Grid>
        <Grid item lg={12}>
          <Divider />
        </Grid>
        <Grid item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Contact Information
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`First Name`}>
                <TextField
                  value={toEditForm.firstName || ''}
                  onChange={(e) => (isEdit ? handleInputChange('firstName', e.target.value) : null)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Last Name`}>
                <TextField
                  InputProps={{ readOnly: true }}
                  value={toEditForm.lastName || ''}
                  onChange={(e) => (isEdit ? handleInputChange('lastName', e.target.value) : null)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Phone Number`}>
                <TextField
                  value={toEditForm.phoneNumber || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('phoneNumber', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Email Address`}>
                <TextField
                  value={toEditForm.email || ''}
                  onChange={(e) => (isEdit ? handleInputChange('email', e.target.value) : null)}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Divider />
        </Grid>
        <Grid container item lg={12} alignItems="center" columnSpacing={4}>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              Mailing Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Street Address`}>
                <TextField
                  value={toEditForm.addressStreet || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressStreet', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Apartment, Suite, Unit, etc.`}>
                <TextField
                  value={toEditForm.addressNumber || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressNumber', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`State`}>
                <TextField
                  value={toEditForm.addressState || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressState', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`County`}>
                <TextField
                  value={toEditForm.addressCounty || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressCounty', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`City`}>
                <TextField
                  value={toEditForm.addressCity || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressCity', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`Zip Code`}>
                <TextField
                  value={toEditForm.addressZip || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('addressZip', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Divider />
        </Grid>
        <Grid container item lg={12} alignItems="center" direction="row">
          <Typography variant="h6" color="textSecondary">
            Secruity
          </Typography>
          <Avatar src={LockIcon} sx={{ width: 20, height: 20 }} />
        </Grid>
        <Grid container item lg={12}>
          <FormGroup label="Current Password">
            <TextField
              type="password"
              value={toEditForm.oldPassword || ''}
              onChange={(e) => (isEdit ? handleInputChange('oldPassword', e.target.value) : null)}
            />
          </FormGroup>
        </Grid>
        {isEdit && (
          <>
            <Grid container item lg={12}>
              <FormGroup label="Current Password">
                <TextField
                  type="password"
                  value={toEditForm.newPassword || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('currentPassword', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid container item lg={12}>
              <FormGroup label="Current Password">
                <TextField
                  type="password"
                  value={toEditForm.conFirmPassword || ''}
                  onChange={(e) =>
                    isEdit ? handleInputChange('confirmPassword', e.target.value) : null
                  }
                />
              </FormGroup>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default MyAccount;
