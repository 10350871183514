import PersonalInformation from '../../components/snippets/PermitHolders/PersonalInformation';
import React from 'react';
import { useAppSelector } from 'app/hooks';
import { Container } from '@mui/system';

import { selectAccount } from 'store/reducers/account';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import useCommonStyles from '../../common/styles/common.styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Image from '../../components/primitives/Image';
import AppLogo from '../../common/images/logo.png';
import Footer from 'components/snippets/Footer';
const EditProfileMagicContainer = () => {
  const account = useAppSelector(selectAccount) || null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh'
  };
  const containerStyle = {
    padding: isMobile ? '16px 16px' : '20px 10%!important',
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    display: 'block',
    backgroundColor: '#FAFAFA',
    maxWidth: '100%!important',
    height: '100%',
    overflow: 'auto',
    minHeight: 'calc(100vh - 147px)'
  };
  const commonClasses = useCommonStyles();
  const navigate = useNavigate();
  return (
    <>
      <Container sx={containerStyle}>
        {!account?.role ? (
          <Box sx={loadingStyle}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div style={{ flexGrow: 1 }}>
              <Image
                sx={{
                  height: 42,
                  width: 122,
                  cursor: 'pointer'
                }}
                alt="DHEC"
                src={AppLogo}
                onClick={() => navigate('/')}
              />
            </div>
            <PersonalInformation {...account} />
            <Grid lg={12} item container justifyContent="flex-end">
              <Button
                className={commonClasses.blueButton}
                style={{ width: '200px' }}
                onClick={() => navigate('/')}>
                Back to Homepage
              </Button>
            </Grid>
          </>
        )}
      </Container>
      <Grid sx={{}}>
        <Footer />
      </Grid>
    </>
  );
};

export default EditProfileMagicContainer;
