import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import { surveyListSlice } from '../components/snippets/Admin/Surveys/store/surveys-list/surveys-list.reduce';
import { surveyListCountSlice } from '../components/snippets/Admin/Surveys/store/surveys-list-count/surveys-list-count.reduce';
import { snackbarMessageStateSlice } from './reducers/snackbarMessage.reducer';
import { surveyTriggerOptionsSlice } from '../components/snippets/Admin/Surveys/store/surveys-trigger-options/surveys-trigger-options.reduce';
import { surveyTemplateCreateSlice } from '../components/snippets/Admin/Surveys/store/surveys-template-create/surveys-template.reduce';
import { surveyTemplateGetSlice } from '../components/snippets/Admin/Surveys/store/surveys-template-get/surveys-template-get.reduce';
import { snackbarMessageSurveyStateSlice } from '../components/snippets/Admin/Surveys/store/snackbar-message/snackbar-message.reducer';
import { surveysRecipientsStateSlice } from '../components/snippets/Admin/Surveys/store/surveys-recipients/surveys-recipients.reducer';
import { snackbarMessageSurveyResponseSlice } from '../components/snippets/SurveysResponse/store/snackbar-message/snackbar-message.reducer';

const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['token']
};

const rootReducer = combineReducers({
  token: reducers.token,
  admin: reducers.admin,
  account: reducers.account,
  myAccount: reducers.myAccount,
  permitHolder: reducers.permitHolder,
  homepageModals: reducers.homepageModals,
  regionalOffice: reducers.regionalOffice,
  surveyList: surveyListSlice.reducer,
  surveyListCount: surveyListCountSlice.reducer,
  snackbarMessage: snackbarMessageStateSlice.reducer,
  surveyTriggerOptions: surveyTriggerOptionsSlice.reducer,
  surveysTemplateCreate: surveyTemplateCreateSlice.reducer,
  surveyTemplateGet: surveyTemplateGetSlice.reducer,
  snackbarMessageSurvey: snackbarMessageSurveyStateSlice.reducer,
  surveysRecipients: surveysRecipientsStateSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
