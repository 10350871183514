import React, { useEffect, useState } from 'react';
import { Divider, Grid, Paper, Switch, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import { errorTextStyle } from 'common/styles/common.styles';
import TextArea from 'components/primitives/TextArea';
import { surveysTriggerOptionsSelector } from '../../../store/surveys-trigger-options/surveys-trigger-options.selector';
import SurveyQuestionActionTypes, {
  ACTIONS_OPTIONS
} from '../../../enums/SurveyQuestionActionTypesEnum';
import { surveysTriggerQuestionsSelector } from '../../../store/surveys-trigger-questions/surveys-trigger-options.selector';
import {
  SurveyQuestionTypes,
  SurveyQuestionTypesLabels
} from '../../../enums/SurveyQuestionTypesEnum';

const TextChoiceFormView: React.FC<any> = (props) => {
  const TRIGGER_OPTIONS = useSelector(surveysTriggerOptionsSelector);

  const [triggerSurvey, setTriggerSurvey] = useState<boolean>(false);

  const questionUuid = props.getValues('template.surveyQuestions')[props.index].uuid;
  const TRIGGER_QUESTIONS = useSelector(surveysTriggerQuestionsSelector(questionUuid));

  useEffect(() => {
    props.setValue(`template.surveyQuestions[${props.index}].type`, SurveyQuestionTypes.TEXT);
  }, []);

  useEffect(() => {
    const action = props.getValues(`template.surveyQuestions[${props.index}].triggerActionType`);
    if (action === SurveyQuestionActionTypes.QUESTION) {
      setTriggerSurvey(true);
    } else {
      setTriggerSurvey(false);
    }
  }, [props.getValues(`template.surveyQuestions[${props.index}].triggerActionType`)]);

  useEffect(() => {
    const getValue = props.getValues(
      `template.surveyQuestions[${props.index}].triggerActionQuestionUUID`
    );
    // if (getValue) { // todo if i add this the prepopulation if the question is not working
    //   props.setValue(`template.surveyQuestions[${props.index}].triggerActionQuestionUUID`, null);
    // }
  }, [TRIGGER_QUESTIONS]);

  return (
    <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
      <Grid item lg={12}>
        <FormGroup label={'Add the question here'}>
          <Controller
            name={`template.surveyQuestions[${props.index}].question`}
            control={props.control}
            rules={{ required: 'Field is required.' }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
              return (
                <>
                  <TextArea
                    defaultValue={value}
                    name={name}
                    onChange={onChange}
                    error={Boolean(error)}
                    variant="standard"
                    placeholder="Enter Question"
                  />
                  <p style={errorTextStyle}>
                    <ErrorMessage errors={props.errors} name={name} />
                  </p>
                </>
              );
            }}
          />
        </FormGroup>
      </Grid>
      <Grid item lg={12}>
        <FormGroup label={'Email Notification'}>
          <Controller
            name={`template.surveyQuestions[${props.index}].triggerOptionID`}
            control={props.control}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
              let dV = undefined;
              if (TRIGGER_OPTIONS && TRIGGER_OPTIONS.length && value) {
                dV = TRIGGER_OPTIONS.find((item: any) => item.value === value);
              }
              return (
                <>
                  <SelectInput
                    defaultValue={dV}
                    placeholder={'Select Email'}
                    error={Boolean(error)}
                    options={TRIGGER_OPTIONS}
                    onChange={(e) => {
                      if (e === null) {
                        props.setValue(name, null);
                        return onChange(null);
                      }
                      props.setValue(name, e.value);
                      return onChange(e.value);
                    }}
                    isClearable={true}
                  />
                  <p style={errorTextStyle}>
                    <ErrorMessage errors={props.errors} name={name} />
                  </p>
                </>
              );
            }}
          />
        </FormGroup>
      </Grid>
      <Grid item lg={12}>
        <Divider />
      </Grid>
      <Grid
        container
        sx={{
          marginTop: '12px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          paddingLeft: '8px'
        }}>
        <Grid
          item
          sx={{
            flexGrow: '1'
          }}>
          <FormGroup label={'Required'}>
            <Controller
              name={`template.surveyQuestions[${props.index}].required`}
              control={props.control}
              defaultValue={0}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                let dV = false;
                if (value) {
                  dV = true;
                }
                return (
                  <Switch
                    checked={dV}
                    value={true}
                    name={name}
                    onChange={(e) => {
                      props.setValue(name, e.target.checked ? 1 : 0);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item sx={{ display: 'inline-flex', gap: '12px' }}>
          <FormGroup>
            <Controller
              name={`template.surveyQuestions[${props.index}].triggerActionType`}
              control={props.control}
              rules={{ required: 'Field is required.' }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                let dV = undefined;
                if (ACTIONS_OPTIONS && ACTIONS_OPTIONS.length && value) {
                  dV = ACTIONS_OPTIONS.find((item: any) => item.value === value);
                }
                return (
                  <>
                    <SelectInput
                      defaultValue={dV}
                      placeholder={'Select Survey Triggers'}
                      error={Boolean(error)}
                      options={ACTIONS_OPTIONS}
                      minWidth={'220px'}
                      onChange={(e) => {
                        props.setValue(name, e.value);
                        if (e.value && e.value === SurveyQuestionActionTypes.QUESTION) {
                          setTriggerSurvey(true);
                        } else {
                          setTriggerSurvey(false);
                        }
                        return onChange(e.value);
                      }}
                    />
                    <p style={errorTextStyle}>
                      <ErrorMessage errors={props.errors} name={name} />
                    </p>
                  </>
                );
              }}
            />
          </FormGroup>
          {triggerSurvey && (
            <FormGroup
              key={props.getValues(
                `template.surveyQuestions[${props.index}].triggerActionQuestionUUID`
              )}>
              <Controller
                name={`template.surveyQuestions[${props.index}].triggerActionQuestionUUID`}
                control={props.control}
                rules={{ required: 'Field is required.' }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  let dV = undefined;
                  if (TRIGGER_QUESTIONS && TRIGGER_QUESTIONS.length && value) {
                    dV = TRIGGER_QUESTIONS.find((item: any) => item.value === value);
                  }

                  return (
                    <>
                      <SelectInput
                        defaultValue={dV}
                        placeholder={'Select Question'}
                        error={Boolean(error)}
                        options={TRIGGER_QUESTIONS}
                        minWidth={'220px'}
                        onChange={(e) => {
                          props.setValue(name, e.value);
                          return onChange(e.value);
                        }}
                      />
                      <p style={errorTextStyle}>
                        <ErrorMessage errors={props.errors} name={name} />
                      </p>
                    </>
                  );
                }}
              />
            </FormGroup>
          )}
        </Grid>
        <Grid item>
          <DeleteOutlinedIcon
            sx={{
              width: 20,
              height: 20,
              cursor: 'pointer'
            }}
            onClick={() => props.remove(props.index)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextChoiceFormView;
