import React from 'react';
import { Box, Button } from '@mui/material';
import { MenuProps } from './Menu.props';
import { useNavigate } from 'react-router-dom';

const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, ml: 10 }}>
      {props.pages.map((page) => (
        <Button
          key={`page-${page.label}`}
          onClick={() => navigate(page.url)}
          sx={{ width: 100, margin: '0 10px' }}
          color="secondary"
        >
          {`${page.locale}`}
        </Button>
      ))}
    </Box>
  );
};

export default Menu;
