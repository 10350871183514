import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      flexWrap: 'wrap'
    },
    linkTitle: {
      display: 'flex',
      background: '#EEEEEF',
      gap: '5px',
      alignItems: 'center',
      color: '#49494A;',
      textDecoration: 'none',
      fontSize: '12px',
      padding: '4px 12px',
      borderRadius: '2px'
    }
  })
);

export default useStyles;
