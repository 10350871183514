import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      color: palette.common?.white,
      textDecoration: 'none'
    }
  }
});
