import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';
import { useDispatch } from 'react-redux';
import { surveyGetSlice } from './survey/survey.reduce';
import { saveSurveyResponseSlice } from './save-survey-response/save-survey-response.reduce';
import { snackbarMessageSurveyResponseSlice } from './snackbar-message/snackbar-message.reducer';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage
};

const childReducer = combineReducers({
  survey: surveyGetSlice.reducer,
  saveSurvey: saveSurveyResponseSlice.reducer,
  snackbarMessageResponse: snackbarMessageSurveyResponseSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, childReducer);

export const storeSurveyView = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type AppSurveyGuestDispatch = typeof storeSurveyView.dispatch;
export const useSurveyGuestDispatch = () => useDispatch<AppSurveyGuestDispatch>();

export type SurveyGuestState = ReturnType<typeof storeSurveyView.getState>;
