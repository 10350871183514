import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SnackbarMessageState from './snackbar-message.state';

const initialState: SnackbarMessageState = {
  show: false,
  message: null
};

export const snackbarMessageSurveyResponseSlice = createSlice({
  name: 'snackbarMessageResponse',
  initialState,
  reducers: {
    updateSnackbarSurveyMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    },
    clearSnackbarSurveyMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    }
  }
});

export default snackbarMessageSurveyResponseSlice.reducer;
