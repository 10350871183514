import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';

export interface ApiGuestLibrarySearch {
  language: string;
  keyword?: string;
  category?: { index: number; value: string }[];
  type?: 'document' | 'video';
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiGuestLibraryFilters {
  language: string;
  keyword?: string;
  category?: string[];
  type?: string;
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getLibrary = (filters: ApiGuestLibrarySearch): IEndpoint => {
  const params = setBusinessParams(filters);
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `api/guest/Library`,
    params: params
  };
};

export const getLibraryCounter = (filters: ApiGuestLibrarySearch): IEndpoint => {
  const params = setBusinessParams(filters, true);
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `api/guest/library/counter`,
    params: params
  };
};

function setBusinessParams(
  filters: ApiGuestLibrarySearch,
  counter = false
): ApiGuestLibraryFilters {
  const params: ApiGuestLibraryFilters = {
    language: filters.language
  };

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.category && filters.category.length > 0) {
    params.category = filters.category.map((data) => data.value);
  }

  if (filters.type && filters.type.length > 0) {
    params.type = filters.type;
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
