import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    activeStepContainer: {
      cursor: 'pointer',
      padding: '10px',
      borderRadius: '4px',
      backgroundColor: '#126DA2'
    },
    inactiveStepContainer: {
      cursor: 'pointer',
      padding: '10px',
      borderRadius: '4px',
      backgroundColor: '#EEEEEF'
    },
    img: {
      objectFit: 'none'
    }
  };
});

export default useStyles;
