import AuthProtect from 'common/enums/AuthProtect';
import { Nullable } from 'common/Utils/Nullable';
import { IStaffDirectory } from 'components/snippets/PermitHolders/StaffDirectory/StaffDirectory.props';
import { IBusinesses } from 'interfaces/IBusinesses';
import { IEndpoint } from 'interfaces/IEndPoint';

const PAGE_LIMIT = 10;

export const getAllBusinesses: (
  offset: number,
  orderBy: Nullable<string>,
  keyword: string
) => IEndpoint = (offset = 0, orderBy = 'corporateName desc', keyword = '') => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/businesses?limit=${PAGE_LIMIT}&offset=${offset}${
      orderBy ? `&orderBy=${orderBy}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}`,
    method: 'GET'
  };
};

export const getAllBusinessCounter: (keyword: string | null, filter?: string) => IEndpoint = (
  keyword = '',
  filter = ''
) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/businesses/counter?keyword=${keyword}${filter}`,
    method: 'GET'
  };
};

export const getAllBusinessesItemsForFilter: (
  filter: string,
  keyword: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/businesses/filters/${filter}?limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

export const getAllBusinessesWithFilter: (
  filter: string,
  keyword?: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/businesses?limit=${PAGE_LIMIT}&${filter}&offset=${offset}`
});

export const getBusinessesPermitHolder: (
  filter?: string,
  keyword?: string,
  offset?: number,
  orderBy?: string
) => IEndpoint = (filter = '', keyword = '', offset = 0, orderBy = '') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/businesses?limit=${PAGE_LIMIT}&offset=${offset}${keyword}${orderBy}${filter}`
});

export const getAcceptedFacilityPermitHolder: () => IEndpoint = () => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: 'api/permitHolder/staff/accepted/facility'
});

export const createNewBusiness: (data: IBusinesses) => IEndpoint = (data: IBusinesses) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'api/permitHolder/businesses',
    method: 'POST',
    data: data
  };
};

export const updateBusiness: (data: IBusinesses) => IEndpoint = (data: IBusinesses) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/businesses/${data.id}`,
    method: 'PUT',
    data: data
  };
};

export const getRegionalOffice: () => IEndpoint = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/admin/regionalOffice`,
    method: 'GET'
  };
};

export const getRegionalOfficeCounties: () => IEndpoint = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/admin/regionalOffice/counties`,
    method: 'GET'
  };
};

export const getAllStaffs: (
  offset: number,
  orderBy: Nullable<string>,
  keyword: string
) => IEndpoint = (offset = 0, orderBy = 'corporateName desc', keyword = '') => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/staff?limit=${PAGE_LIMIT}&offset=${offset}${
      orderBy ? `&orderBy=${orderBy}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}`,
    method: 'GET'
  };
};

export const getStaffMembersPermitHolder: (
  filter?: string,
  keyword?: string,
  offset?: number,
  orderBy?: string
) => IEndpoint = (filter = '', keyword = '', offset = 0, orderBy = '') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/staff?limit=${PAGE_LIMIT}&offset=${offset}${keyword}${orderBy}${filter}`
});

export const getAllStaffsCounter: (keyword: string | null, filter?: string) => IEndpoint = (
  keyword = '',
  filter = ''
) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/staff/counter?keyword=${keyword}${filter}`,
    method: 'GET'
  };
};

export const getAllStaffsForFilter: (
  filter: string,
  keyword: string,
  offset?: number,
  businessID?: string
) => IEndpoint = (filter = '', keyword = '', offset = 0, businessID = '') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/staff/filters/${filter}?&businessID=${businessID}limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

export const getAdminStaffsForFilter: (
  filter: string,
  keyword: string,
  offset?: number,
  businessID?: string
) => IEndpoint = (filter = '', keyword = '', offset = 0, businessID = '') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/admin/staff/filters/${filter}?&businessID=${businessID}&limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

export const getAllStaffsWithFilter: (
  filter: string,
  keyword?: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/permitHolder/staff?limit=${PAGE_LIMIT}&${filter}&offset=${offset}`
});

export const createNewStaff: (data: IStaffDirectory) => IEndpoint = (data: IStaffDirectory) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/staff`,
    method: 'POST',
    data: data
  };
};

export const updateStaffByID: (staffID: string, data: IStaffDirectory) => IEndpoint = (
  staffID: string,
  data: IStaffDirectory
) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/permitHolder/staff/${staffID}`,
    method: 'PUT',
    data: data
  };
};
