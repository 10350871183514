import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import SnackbarMessageState from '../state/SnackbarMessageState';

const initialState: SnackbarMessageState = {
  show: false,
  message: null
};

export const snackbarMessageStateSlice = createSlice({
  name: 'snackbarMessage',
  initialState,
  reducers: {
    updateSnackbarMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    },
    clearSnackbarMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    }
  }
});

export const { updateSnackbarMessage, clearSnackbarMessage } = snackbarMessageStateSlice.actions;

export const selectSnackbarMessageShow = (state: RootState) => state.snackbarMessage.show;
export const selectSnackbarMessageMessage = (state: RootState) => state.snackbarMessage.message;

export default snackbarMessageStateSlice.reducer;
