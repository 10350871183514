import { ILink } from 'interfaces/ILink';

const MenuPages: ILink[] = [
  {
    label: 'Documents',
    url: '/documents',
    locale: 'Documents'
  },
  {
    label: 'Videos',
    url: '/videos',
    locale: 'Videos'
  }
];

export default MenuPages;
