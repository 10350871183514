import { ErrorInterface } from '../../interfaces/genera.interface';

export interface SurveysListCountState {
  count: number;
  error: false | ErrorInterface;
  loading: boolean;
  update: boolean;
}

export const initialSurveysListCountState: SurveysListCountState = {
  count: 0,
  error: false,
  loading: false,
  update: false
};
