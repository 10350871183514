import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSurveysTemplateCreateState } from './surveys-template.state';
import { saveSurveyTemplateCreate } from './surveys-template.trunk';
import { SurveyInterface } from '../../interfaces/survey.interface';

export const surveyTemplateCreateSlice = createSlice({
  name: 'surveyTemplateCreate',
  initialState: initialSurveysTemplateCreateState,
  reducers: {
    clearSurveyTemplateCreate: () => {
      return initialSurveysTemplateCreateState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(saveSurveyTemplateCreate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveSurveyTemplateCreate.rejected, (state, action) => {
        const error = {
          code: 0,
          message: 'Something went wrong!'
        };
        if (action.error.message) {
          error.message = action.error.message;
        }

        state.loading = false;
        state.error = error;
      })
      .addCase(
        saveSurveyTemplateCreate.fulfilled,
        (state, action: PayloadAction<SurveyInterface>) => {
          state.loading = false;
          state.error = false;
          state.entities = action.payload;
          state.success = true;
        }
      );
  }
});
export const { clearSurveyTemplateCreate } = surveyTemplateCreateSlice.actions;
