// @ts-nocheck
import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box
} from '@mui/material';

import { FormDialogRegionsProps } from './FormDialogAdminRegions.props';
import useCommonStyles from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';

const FormDialogRegions: React.FC<FormDialogRegionsProps> = ({
  t,
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'md',
  noScrollY = false,
  overflowVissible = false,
  handleClose,
  handleSave,
  handleSaveEdit,
  backgroundTransparent = false,
  showActionButtons = true,
  hasCancel = true,
  hasSave = true,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  children,
  initialData,
  actionType,
  form
}) => {
  const commonClasses = useCommonStyles();

  const {
    control,
    getValues,
    trigger,
    clearErrors,
    setValue,
    watch,
    resetField,
    formState: { errors, isValid }
  } = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true
  });

  useEffect(() => {
    if (actionType && actionType === 'edit') {
      if (initialData) {
        const fieldKeys = Object.keys(initialData);
        fieldKeys.forEach((i: string) => {
          if (i === 'county' && form === 'business-form') {
            setValue(
              'county',
              { label: initialData?.countyID, value: initialData?.countyID },
              { shouldValidate: true }
            );
          }
        });
      }
    }
  }, [actionType]);

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setValue, control, errors, getValues, watch, resetField });
    }
    return child;
  });

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      onClose={() => {
        handleClose?.();
        clearErrors();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVissible ? 'visible' : 'auto'
        }
      }}
    >
      {title && (
        <>
          <Typography variant="MediumTitle" sx={{ padding: '16px 24px' }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              handleClose?.();
              clearErrors();
            }}
            aria-label="dhec-close"
            sx={{ width: 25, height: 25, position: 'absolute', top: '25px', right: '25px' }}
          >
            <Close sx={{ width: 25, height: 25 }} />
          </IconButton>
        </>
      )}

      <Box>
        <DialogContent
          sx={{
            padding: '0 0 10px 0',
            minHeight: fullWidth ? '70vh' : 'auto',
            overflowY: noScrollY ? 'hidden' : overflowVissible ? 'visible' : 'auto'
          }}
        >
          <>{childrenWithProps}</>
        </DialogContent>
      </Box>

      <DialogActions sx={{ display: showActionButtons ? '' : 'none', paddingRight: '25px' }}>
        {hasCancel && (
          <Button
            onClick={() => {
              handleClose?.();
              clearErrors();
            }}
            autoFocus
            color="secondary"
          >
            {cancelLabel}
          </Button>
        )}
        {hasSave && (
          <Button
            type="submit"
            className={commonClasses.blueButton}
            onClick={() => {
              const formValues = getValues();
              if (actionType === 'edit') {
                if (!isValid) {
                  trigger();
                } else {
                  handleSaveEdit?.(formValues);
                }
              } else {
                if (!isValid) {
                  trigger();
                } else {
                  handleSave?.(formValues);
                }
              }
            }}
          >
            {saveLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialogRegions;
