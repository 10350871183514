import React from 'react';
import { Box } from '@mui/system';
import { useAppDispatch } from 'app/hooks';
import { Button, Typography, Grid, Container } from '@mui/material';

import LandingPageLayout from '../../components/layouts/LandingPageLayout';
import useStyles from './Home.styles';
import LoginLogo from 'common/images/Login_logo.png';
import { updatehomepageModals } from 'store/reducers/homepageModals';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Home: React.FC = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultModal = {
    showLogin: false,
    showRegister: false,
    showContact: false,
    showResetPassword: false,
    showFinishSignup: false
  };

  const cbModal = (value: boolean, name: string) => {
    const payload = {
      ...defaultModal,
      [name]: value
    };
    dispatch(updatehomepageModals(JSON.parse(JSON.stringify(payload))));
  };

  return (
    <LandingPageLayout>
      <>
        <Box
          className={classes.wrapper}
          sx={{ height: { xs: '350px', sm: '280px', md: '300px', lg: '300px', xl: '450px' } }}
        />
        <Grid container className={classes.container} sx={{ top: { lg: '0px', xl: '-30px' } }}>
          <Grid item xs={12} sm={6} sx={{ mt: '-50px', color: '#fff' }}>
            <Typography variant="h6" className={classes.textTitle}>
              DHEC Food Program
            </Typography>
            <Typography className={classes.textDescription}>
              Providing the food service industry and its partners with access to the latest updates
              and information.
            </Typography>
            {!isSm && (
              <Button
                className={classes.btnGetStarted}
                onClick={() => {
                  cbModal(true, 'showLogin');
                }}>
                Get Started
              </Button>
            )}
          </Grid>
          {isSm && (
            <Grid item xs={6}>
              <Button
                className={classes.btnGetStarted}
                onClick={() => {
                  cbModal(true, 'showLogin');
                }}>
                Get Started
              </Button>
            </Grid>
          )}
          <Grid item xs={6} sm={6}>
            <Box
              component="img"
              alt="DHEC Program"
              src={LoginLogo}
              sx={{
                width: { xs: '192px', sm: '400px', xl: '615px' },
                height: 'auto'
              }}
            />
          </Grid>
        </Grid>
      </>
      <Container sx={{ p: { xs: '15px', md: '10%' } }}>
        <Grid container columnSpacing={2}>
          <Grid item lg={12} sx={{ paddingBottom: '44px', paddingTop: '44px' }}>
            <Typography variant="h6" className={classes.infoTitle}>
              Food Safety is our #1 Priority
            </Typography>
            <Typography className={classes.infoDescription}>
              Excellent food-safety practices are essential to preventing foodborne illnesses. Stay
              informed and up to date to keep you, your employees, and your customers as safe as
              possible.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LandingPageLayout>
  );
};

export default Home;
