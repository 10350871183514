import React from 'react';
import { Typography } from '@mui/material';
import { DocumentButtonsProps } from './DocumentButtons.props';
import useStyles from './DocumentButtons.styles';

const DocumentButtons: React.FC<DocumentButtonsProps> = ({ library }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      <Typography gutterBottom variant="h5" color="text.secondary">
        <a href={library?.url_EN} target="_blank" rel="noreferrer" className={classes.linkTitle}>
          English - EN
        </a>
      </Typography>
      {library?.url_ES && (
        <Typography gutterBottom variant="h5" color="text.secondary">
          <a href={library?.url_ES} target="_blank" rel="noreferrer" className={classes.linkTitle}>
            Español - ES
          </a>
        </Typography>
      )}
      {library?.url_FR && (
        <Typography gutterBottom variant="h5" color="text.secondary">
          <a href={library?.url_FR} target="_blank" rel="noreferrer" className={classes.linkTitle}>
            Français - FR
          </a>
        </Typography>
      )}
      {library?.url_ZH && (
        <Typography gutterBottom variant="h5" color="text.secondary">
          <a href={library?.url_ZH} target="_blank" rel="noreferrer" className={classes.linkTitle}>
            中文 – ZH
          </a>
        </Typography>
      )}
    </div>
  );
};

export default DocumentButtons;
