import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Box, Tabs, Tab } from '@mui/material';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { ISendEmailRecipient } from './ReviewSend.props';
import parse from 'html-react-parser';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import TabPanel from 'components/fragments/TabPanel';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ReviewSend: React.FC<ISendEmailRecipient> = ({ template, setSubject, setBody }) => {
  const [valueTab, setValueTab] = React.useState<number>(0);
  const [emailContent, setEmailContent] = React.useState<string>('');
  const [emailBody, setEmailBody] = React.useState<string>('');
  const [emailSubject, setEmailSubject] = React.useState<string>(template?.title ?? '');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (document) {
      const element = document.getElementById('removeTabIndex');
      element?.parentElement?.removeAttribute('tabindex');
    }
  });
  useEffect(() => {
    let email = template?.content ?? '';
    setEmailContent(email);
    setBody(email);

    setSubject(template?.title ?? '');
  }, []);

  useEffect(() => {
    let email = template?.html.html ?? '';
    email = email.replace('{{content}}', emailContent);

    setEmailBody(email);
  }, [emailContent]);

  return (
    <Container disableGutters maxWidth="lg" sx={{ padding: '20px 0 10px 0' }}>
      <Grid container alignItems="center" spacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item columnSpacing={1} rowSpacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label="Subject">
                  <TextField
                    value={emailSubject}
                    placeholder="Enter Subject"
                    onChange={(value) => {
                      setEmailSubject(value.target.value);
                      setSubject(value.target.value);
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Content" />
                    <Tab label="View" />
                  </Tabs>
                </Box>
                <TabPanel value={valueTab} index={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={emailContent}
                      config={{
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'link',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'insertTable'
                          ],
                          shouldNotGroupWhenFull: true
                        }
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setEmailContent(data);
                        setBody(data);
                      }}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {parse(emailBody)}
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReviewSend;
