import moment from 'moment';

export function formatTo(dt: string | Date, format: string): string {
  return dt ? moment(dt).format(format) : '';
}
export function toShortDateOnly(dt: string | Date | number): string {
  return dt ? moment(dt).format('YYYY-MM-DD') : '';
}

export function toShortDateTime(dt: string | Date): string {
  return dt ? moment(dt).format('YYYY-MM-DD LTS') : '';
}

export default {
  formatTo,
  toShortDateOnly,
  toShortDateTime
};
