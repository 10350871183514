import { IEndpoint } from 'interfaces/IEndPoint';

import { IUser } from 'interfaces/IUser';
import AuthProtect from '../../../../../common/enums/AuthProtect';
import {
  ApiAdminMailboxFilters,
  ApiAdminSurveySearch,
  SurveyCampaignInterface,
  SurveyTemplateInterface
} from '../interfaces/survey.interface';

// export const createMailboxAdmin = (data: ApiAdminCreateMailbox): IEndpoint => {
//   return {
//     authorization: AuthProtect.REQUIRED,
//     method: 'POST',
//     url: `api/admin/mailbox`,
//     data: data
//   };getSurveyAdmin
// };

export const getSurveyAdmin = (filters: ApiAdminSurveySearch): IEndpoint => {
  const params = setSurveyParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/survey`,
    params: params
  };
};

export const getSurveyAdminCounter = (filters: ApiAdminSurveySearch): IEndpoint => {
  const params = setSurveyParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/survey/counter`,
    params: params
  };
};

export const getSurveyTriggerOptionsApi = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/survey/triggerOptions`
  };
};

export const saveSurveyTemplateAdmin = (data: SurveyTemplateInterface): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/admin/survey/templates`,
    data: data
  };
};

export const saveSurveyCampaignAdmin = (data: SurveyCampaignInterface): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/admin/survey`,
    data: data
  };
};

export const getSurveyTemplatesAdmin = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/survey/templates`
  };
};

function setSurveyParams(filters: ApiAdminSurveySearch, counter = false): ApiAdminMailboxFilters {
  const params: ApiAdminMailboxFilters = {};

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
