import { surveyTriggerQuestionsState } from '../index';
import { SurveyTriggerQuestionsInterface } from '../../interfaces/survey.interface';

export const surveysTriggerQuestionsSelector =
  (uuid: string) => (state: surveyTriggerQuestionsState) => {
    return state.surveyTriggerQuestions.entities
      .filter((options: SurveyTriggerQuestionsInterface) => {
        return options.id !== uuid;
      })
      .map((options: SurveyTriggerQuestionsInterface) => {
        return { value: options.id, label: options.name };
      });
  };
