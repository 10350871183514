import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Controller, useWatch } from 'react-hook-form';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import TextField from 'components/primitives/TextField';
import TextArea from 'components/primitives/TextArea';
import { ISelectOption } from 'interfaces/ISelectOptions';
import CertifiedFPManagers from '../CertifiedFPManagers';
import useCommonStyles from 'common/styles/common.styles';
import PointsOfContactForm from './PointsOfContactForm';
import apiFetch from 'services/apiFetch';
import { getAllCounties } from 'services/apiEndPoints';
import { IAddBusinessForm } from './AddBusinessForm.props';
import { ICounty } from 'interfaces/ICounty';
import { formatPhone, limitZipLength } from '../../../../../common/Utils/String';

const EMPLOYEES: ISelectOption[] = [
  { value: '1', label: '1-50' },
  { value: '2', label: '50-100' },
  { value: '3', label: '100-500' },
  { value: '4', label: '500+' }
];

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const AddBusinessForm: React.FC<IAddBusinessForm> = ({
  data = {},
  certificateHolders,
  foodProtectionManagers,
  contacts,
  onDelete,
  handleAddForm,
  handleFieldChange,
  handleCertsFieldChange,
  control,
  errors,
  setValue,
  getValues
}) => {
  const commonClasses = useCommonStyles();
  const [samePhysicalAddress, setSamePhysicalAddress] = useState<boolean>(
    getValues('sameMailingAddress') === 'yes' ? true : false
  );
  const [countyList, setCountyList] = useState<any[]>([]);
  const [dialogContent, setDialogContent] = useState<HTMLElement | null>(null);
  const countyID = useWatch({
    control,
    name: 'countyID'
  });

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById('dialogContent');
    setDialogContent(element);
  }, []);

  const getCountyList = async () => {
    try {
      const res = await apiFetch(getAllCounties());
      if (res.status === 200) {
        return res.data.response;
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (data.permitNumbers && data.permitNumbers.length > 0) {
      data.permitNumber = data.permitNumbers
        .map((i: any) => {
          return i.permit.PERMIT_NUMBER.toString();
        })
        .join();
    }

    getCountyList()
      .then((res) => {
        const countyFormat = res?.map((v: ICounty) => ({
          value: v.id,
          label: v.name,
          data: { ...v }
        }));

        setCountyList(countyFormat);
      })
      .catch((e) => console.error(e));
  }, []);

  const findCounty = () => {
    const result = countyList.find((i: any) => i?.value === countyID);
    if (result) {
      return result.data.region.name;
    }
    return '';
  };

  return (
    <Container>
      <Grid container spacing={1} rowSpacing={2}>
        {data.rejectMessage && data.status === 'rejected' && (
          <>
            <Grid item lg={12}>
              <Typography variant="h6" color="textSecondary">
                Reject Message
              </Typography>
            </Grid>
            <Grid container item lg={12}>
              <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
                <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormGroup>
                      <TextArea disabled error={true} value={data?.rejectMessage || ''} />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
        <Grid item lg={12}>
          <Typography variant="h6" color="textSecondary">
            General
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Permit Number`}>
                  <TextField
                    disabled
                    value={data?.permitNumber || ''}
                    onChange={(e) => handleFieldChange('permitNumber', e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Facility Type`}>
                  <Controller
                    name="facilityType"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('facilityType') || ''}
                          error={errors.facilityType?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.facilityType?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Process`}>
                  <Controller
                    name="process"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('process') || ''}
                          error={errors.process?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.process?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Employees`}>
                  <Controller
                    name="employessNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <SelectInput
                            defaultValue={value}
                            searchable={false}
                            error={errors.employessNumber?.message ? true : false}
                            options={EMPLOYEES}
                            placeholder={`Select`}
                            onChange={(e) => {
                              return onChange(e.label);
                            }}
                          />
                          <p style={errorTextStyle}>{errors.employessNumber?.message}</p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`Corporate Name`}>
                  <Controller
                    name="corporateName"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('corporateName') || ''}
                          error={errors.corporateName?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.corporateName?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`Doing Business As:`}>
                  <Controller
                    name="doingBusiness"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('doingBusiness') || ''}
                          error={errors.doingBusiness?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.doingBusiness?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`County`}>
                  <Controller
                    name="county"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      let dV = undefined;
                      if (countyList && countyList.length && value) {
                        dV = countyList.find((item: any) => item.value === value);
                      }
                      return (
                        <>
                          <SelectInput
                            key={dV}
                            defaultValue={dV}
                            error={errors.county?.message ? true : false}
                            options={countyList}
                            placeholder={`Select`}
                            onChange={(e) => {
                              setValue('countyID', e.value);
                              return onChange(e.data);
                            }}
                          />
                          <p style={errorTextStyle}>{errors.county?.message}</p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Regional Office`}>
                  <TextField disabled={true} value={findCounty()} />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Phone Number`}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 },
                      pattern: {
                        value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
                        message: 'Invalid phone number.'
                      }
                    }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <TextField
                          value={getValues('phone') || ''}
                          error={errors.phone?.message ? true : false}
                          name={name}
                          onChange={(e) => onChange(formatPhone(e.target.value))}
                          helperText={errors.phone?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={`Email Address`}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('email') || ''}
                          error={errors.email?.message ? true : false}
                          name={name}
                          onChange={onChange}
                          helperText={errors.email?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Physical Address
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Street Address'}>
                  <Controller
                    name="addressStreet"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          error={errors.addressStreet?.message ? true : false}
                          name={name}
                          value={getValues('addressStreet') || ''}
                          onChange={onChange}
                          helperText={errors.addressStreet?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                  <Controller
                    name="addressNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          error={errors.addressNumber?.message ? true : false}
                          name={name}
                          value={getValues('addressNumber') || ''}
                          onChange={onChange}
                          helperText={errors.addressNumber?.message}
                          variant="standard"
                          placeholder={`Optional`}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'State'}>
                  <Controller
                    name="addressState"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          error={errors.addressState?.message ? true : false}
                          name={name}
                          value={getValues('addressState') || ''}
                          onChange={onChange}
                          helperText={errors.addressState?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'County'}>
                  <Controller
                    name="addressCounty"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          error={errors.addressCounty?.message ? true : false}
                          name={name}
                          value={getValues('addressCounty') || ''}
                          onChange={onChange}
                          helperText={errors.addressCounty?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'City'}>
                  <Controller
                    name="addressCity"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          error={errors.addressCity?.message ? true : false}
                          name={name}
                          value={getValues('addressCity') || ''}
                          onChange={onChange}
                          helperText={errors.addressCity?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Zip code'}>
                  <Controller
                    name="addressZip"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Field is required.',
                      minLength: { value: 5, message: 'Zip code must be 5 digits' },
                      maxLength: { value: 5, message: '' }
                    }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <TextField
                          error={errors.addressZip?.message ? true : false}
                          name={name}
                          value={getValues('addressZip') || ''}
                          onChange={(e) => onChange(limitZipLength(e.target.value, 5))}
                          helperText={errors.addressZip?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item lg={12} alignItems="center" columnSpacing={4}>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              Mailing Address
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={samePhysicalAddress}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setValue('sameMailingAddress', 'yes', { shouldValidate: true });
                      // setValue('mailingAddressStreet', watch('addressStreet'), {
                      //   shouldValidate: true
                      // });
                      // setValue('mailingAddressNumber', watch('addressNumber'), {
                      //   shouldValidate: true
                      // });
                      // setValue('mailingAddressState', watch('addressState'), {
                      //   shouldValidate: true
                      // });
                      // setValue('mailingAddressCounty', watch('addressCounty'), {
                      //   shouldValidate: true
                      // });
                      // setValue('mailingAddressCity', watch('addressCity'), {
                      //   shouldValidate: true
                      // });
                      // setValue('mailingAddressZip', watch('addressZip'), { shouldValidate: true });
                    } else {
                      setValue('sameMailingAddress', 'no', { shouldValidate: true });
                      setValue('mailingAddressStreet', '', { shouldValidate: true });
                      setValue('mailingAddressNumber', '', { shouldValidate: true });
                      setValue('mailingAddressState', '', { shouldValidate: true });
                      setValue('mailingAddressCounty', '', { shouldValidate: true });
                      setValue('mailingAddressCity', '', { shouldValidate: true });
                      setValue('mailingAddressZip', '', { shouldValidate: true });
                    }
                    setSamePhysicalAddress(event.target.checked);
                  }}
                />
              }
              label={`same as Physical Address`}
            />
          </Grid>
        </Grid>
        {!samePhysicalAddress && (
          <Grid container item lg={12}>
            <Paper sx={{ width: '100%', padding: 2 }}>
              <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'Street Address'}>
                    <Controller
                      name="mailingAddressStreet"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            disabled={samePhysicalAddress}
                            value={getValues('mailingAddressStreet') || ''}
                            error={errors.mailingAddressStreet?.message ? true : false}
                            name={name}
                            onChange={onChange}
                            helperText={errors.mailingAddressStreet?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                    <Controller
                      name="mailingAddressNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            value={getValues('mailingAddressNumber') || ''}
                            disabled={samePhysicalAddress}
                            error={errors.mailingAddressNumber?.message ? true : false}
                            name={name}
                            onChange={onChange}
                            helperText={errors.mailingAddressNumber?.message}
                            variant="standard"
                            placeholder={'Optional'}
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={'State'}>
                    <Controller
                      name="mailingAddressState"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            value={getValues('mailingAddressState') || ''}
                            disabled={samePhysicalAddress}
                            error={errors.mailingAddressState?.message ? true : false}
                            name={name}
                            onChange={onChange}
                            helperText={errors.mailingAddressState?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={'County'}>
                    <Controller
                      name="mailingAddressCounty"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            value={getValues('mailingAddressCounty') || ''}
                            disabled={samePhysicalAddress}
                            error={errors.mailingAddressCounty?.message ? true : false}
                            name={name}
                            onChange={onChange}
                            helperText={errors.mailingAddressCounty?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={'City'}>
                    <Controller
                      name="mailingAddressCity"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <TextField
                            value={getValues('mailingAddressCity') || ''}
                            disabled={samePhysicalAddress}
                            error={errors.mailingAddressCity?.message ? true : false}
                            name={name}
                            onChange={onChange}
                            helperText={errors.mailingAddressCity?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={'Zip Code'}>
                    <Controller
                      name="mailingAddressZip"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Field is required.',
                        minLength: { value: 5, message: 'Zip code must be 5 digits' },
                        maxLength: { value: 5, message: '' }
                      }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <TextField
                            value={getValues('mailingAddressZip') || ''}
                            disabled={samePhysicalAddress}
                            error={errors.mailingAddressZip?.message ? true : false}
                            name={name}
                            onChange={(e) => onChange(limitZipLength(e.target.value, 5))}
                            helperText={errors.mailingAddressZip?.message}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Certified Food Protection Manager
          </Typography>
        </Grid>
        <Grid container item lg={12} rowSpacing={2}>
          {certificateHolders.map((c, index) => {
            return (
              <Grid key={index} item lg={12} id={`certificateHolders${index}`}>
                <CertifiedFPManagers
                  control={control}
                  errors={errors}
                  index={index}
                  setValue={setValue}
                  type="certificateHolders"
                  onDelete={() => onDelete('manager', index)}
                  handleCertsFieldChange={handleCertsFieldChange}
                  title={`Certified Manager ${index + 1}`}
                  {...c}
                />
              </Grid>
            );
          })}
          <Grid container item lg={12} justifyContent="center">
            <Button
              sx={{ display: certificateHolders.length > 2 ? 'none' : '' }}
              className={commonClasses.whiteButton}
              size="small"
              startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
              onClick={() => handleAddForm('certificateHolders')}>
              Add New
            </Button>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Food Handler Certificate Holder
          </Typography>
        </Grid>
        <Grid container item lg={12} rowSpacing={2}>
          {foodProtectionManagers.map((p, index) => {
            return (
              <Grid key={index} item lg={12} id={`foodProtectionManagers${index}`}>
                <CertifiedFPManagers
                  control={control}
                  errors={errors}
                  index={index}
                  setValue={setValue}
                  type="foodProtectionManagers"
                  title={`Food Handler ${index + 1}`}
                  onDelete={onDelete}
                  handleCertsFieldChange={handleCertsFieldChange}
                  {...p}
                />
              </Grid>
            );
          })}
          <Grid container item lg={12} justifyContent="center">
            <Button
              sx={{ display: foodProtectionManagers.length > 2 ? 'none' : '' }}
              className={commonClasses.whiteButton}
              size="small"
              startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
              onClick={() => handleAddForm('foodProtectionManagers')}>
              Add New
            </Button>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Points of Contact
          </Typography>
        </Grid>
        <Grid container item lg={12} rowSpacing={2}>
          {contacts.map((p, index) => {
            return (
              <Grid key={index} item lg={12} id={`contacts${index}`}>
                <PointsOfContactForm
                  index={index}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  title={`Contact ${index + 1}`}
                  {...p}
                  onDelete={onDelete}
                  handleCertsFieldChange={handleCertsFieldChange}
                />
              </Grid>
            );
          })}
          <Grid container item lg={12} justifyContent="center">
            <Button
              sx={{ display: contacts.length > 2 ? 'none' : '' }}
              className={commonClasses.whiteButton}
              size="small"
              startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
              onClick={() => handleAddForm('contacts')}>
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddBusinessForm;
