import React, { useState, useEffect } from 'react';
import { Box, Divider, Button, Stack, Typography, Avatar } from '@mui/material';
import { Container } from '@mui/system';
import { ImanageColumns } from './SortCards.props';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import useStyles from './SortCards.styles';
import SortDESC from '../../../../common/images/sort_desc.png';
import SortASC from '../../../../common/images/sort_asc.png';
import SortNeutral from '../../../../common/images/sort_neutral.png';

const SortCards: React.FC<ImanageColumns> = ({
  title = '',
  items = [],
  handleClick,
  cardSortField,
  cardOrderDesc
}) => {
  const classes = useStyles();
  const [sortingFieldName, setSortingFieldName] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<any>(null);

  useEffect(() => {
    const filterItems = items.filter((item) => {
      return !item.disableSortBy;
    });
    setFilteredItems(filterItems);
  }, [items]);

  useEffect(() => {
    if (filteredItems) {
      filteredItems.map((i: ColumnsProperties) => {
        if (i.accessor === cardSortField) {
          setSortingFieldName(i.Header);
        }
      });
    }
  }, [filteredItems]);

  useEffect(() => {
    if (sortingFieldName) {
      setSortOrder(cardOrderDesc ? 'descending' : 'ascending');
    }
  });

  return (
    <Container maxWidth="lg" sx={{ padding: '15px!important' }}>
      {/* Header Title */}
      <Box className={classes.headerContainer}>
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 300 }}>
            {`${sortingFieldName} ${sortOrder}`}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {/* Body */}
      <Box>
        {filteredItems?.map((item: ColumnsProperties, idx: number) => {
          return (
            <Stack
              key={idx}
              direction="row"
              className={classes.listItemContainer}
              sx={{ display: item?.categoryHidden ? 'none' : '' }}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              <Button
                key={idx}
                variant={'text'}
                value={item.accessor}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (handleClick) {
                    setSortingFieldName(item?.Header);
                    handleClick(e.currentTarget.value);
                  }
                }}>
                {item.accessor === cardSortField ? (
                  cardOrderDesc ? (
                    <Avatar src={SortDESC} className={classes.sortIcon} />
                  ) : (
                    <Avatar src={SortASC} className={classes.sortIcon} />
                  )
                ) : (
                  <Avatar src={SortNeutral} className={classes.sortIcon} />
                )}
                <Typography sx={{ marginLeft: '10px' }}>{item?.Header}</Typography>
              </Button>
            </Stack>
          );
        })}
      </Box>
    </Container>
  );
};

export default SortCards;
