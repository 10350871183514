import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    maxWidthXs: {
      maxWidth: '552px !important'
    },
    maxWidthSm: {
      maxWidth: '552px !important'
    },
    maxWidthMd: {
      maxWidth: '852 px !important'
    },
    maxWidthLg: {
      maxWidth: '100% !important'
    },
    maxWidthXl: {
      maxWidth: '100% !important',
      paddingLeft: '10% !important',
      paddingRight: '10% !important'
    },
    maxWidthXxl: {
      maxWidth: '100% !important'
    }
  }
});
