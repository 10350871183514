import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Checkbox
} from '@mui/material';
import { Container } from '@mui/system';

import useCommonStyles from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { FormDialogListPermitNumbersProps } from './FormDialogListPermitNumbers.props';
import ReactTableContainer from '../ReactTable';
import { ColumnsProperties } from '../ReactTable/ReactTable.props';
import { IStaffDirectory } from '../../snippets/PermitHolders/StaffDirectory/StaffDirectory.props';
import apiFetch from '../../../services/apiFetch';
import { PermitNumbersHeaders } from '../../../common/arrays/PermitNumbersHeaders';
import {
  ApiAdminPermitNumberSearch,
  getPermitNumberAdmin,
  getPermitNumberAdminCounter
} from '../../../services/apiAdminPermitNumbers';
import { CellProps } from 'react-table';
import { IPermit } from 'interfaces/IBusinesses';
import FormGroup from '../../primitives/FormGroup';
import * as Counties from 'data/Counties';

const { PeeDeeCounties, LowCounty, MidlandsCounties, UpstateCounties } = Counties.default;
const allCounties = [...PeeDeeCounties, ...LowCounty, ...MidlandsCounties, ...UpstateCounties];
const localStorageKey = 'ADMIN_PERMIT_NUMBERS';

const FormDialogListPermitNumbers: React.FC<FormDialogListPermitNumbersProps> = ({
  show = false,
  title,
  noScrollY = false,
  overflowVissible = false,
  handleClose,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  selectedPermits,
  setSelectedPermits,
  handleSave,
  businessInfo
}) => {
  const commonClasses = useCommonStyles();

  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols ? JSON.parse(localCols) : PermitNumbersHeaders;

  const [columnsTable] = useState<ColumnsProperties[]>(defColTable);
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'PERMIT_ID', desc: false }]);
  const [initialData, setInitialData] = useState<IPermit[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOffset, setCurrentOffset] = useState<number>(0);

  let checkedData: { id: string; value: boolean; permit: IPermit }[] = [];

  const [checkedDataState, setCheckedDataState] = useState<any>();

  const handleChecked = (value: boolean, permit: IPermit) => {
    const key = permit.id.toString();

    let find = false;
    let data = checkedData.map((item: { id: string; value: boolean; permit: IPermit }) => {
      if (item && item.id == key) {
        find = true;
        item.value = value;
      }
      return item;
    });

    if (!find) {
      data.push({ id: key, value, permit: permit });
    }
    checkedData = [...data];
    setCheckedDataState(checkedData);
    reset();
  };

  useEffect(() => {
    if (selectedPermits && selectedPermits.length > 0) {
      selectedPermits.map((permit) => {
        handleChecked(true, { ...permit });
      });
    }
  }, [selectedPermits]);

  useEffect(() => {
    getPermitNumbers();
  }, [currentOffset, currentKeyword, currentFilterParams, currentOrderBy]);

  const changePage = () => {
    getPermitNumbers(false);
  };

  const returnCountyNameById = (id: string) => {
    const county = allCounties.find(
      (county: { label: string; value: string }) => county.value === id
    );
    return county ? county.label : '';
  };

  const getPermitNumbers = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminPermitNumberSearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getPermitNumberAdmin(filters));

    if (usersData) {
      //change county code to county name
      const newData = usersData.data.response.map((permit: any) => {
        permit.COU_CODE = returnCountyNameById(permit.COU_CODE.toString());
        return permit;
      });
      setInitialData(newData || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getPermitNumberAdminCounter(filters));
      setTotalData(total.data?.response || 0);
    }
  };

  useEffect(() => {
    if (defColTable) {
      const id = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'id');

      if (id > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[id].Cell = ({ cell }: CellProps<object, any>) => {
          const permit: IPermit = cell.row.original as IPermit;
          let checked: boolean | undefined = undefined;
          let data = checkedData.find((o) => o.id === permit.id);

          if (data) {
            checked = data.value;
          }

          return (
            <FormGroup>
              <Controller
                key={permit.id + checked}
                name={`permits${permit.id}`}
                control={control}
                defaultValue={[]}
                render={({ field: { name, onChange } }) => {
                  return (
                    <>
                      <Checkbox
                        key={permit.id}
                        checked={checked}
                        disabled={
                          !!permit.userPermitNumber && permit.userPermitNumber !== businessInfo.id
                        }
                        onClick={(event: any) => {
                          handleChecked(event.target.checked, permit);
                        }}
                      />
                    </>
                  );
                }}
              />
            </FormGroup>
          );
        };
      }
    }
  }, []);

  const { control, clearErrors, reset, handleSubmit } = useForm<any>();

  const onSubmit = async (formData: any) => {
    const data = checkedDataState
      .filter((item: any) => item.value)
      .map((item: any) => {
        return item.permit;
      });

    handleSave?.(data);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={show}
      scroll={'paper'}
      onClose={() => {
        reset({});
        handleClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#FAFAFA',
          boxShadow: 'inherit',
          overflowY: 'auto'
        }
      }}
      disableScrollLock>
      <form onSubmit={handleSubmit(onSubmit)}>
        {title && (
          <>
            <Typography variant="h4" sx={{ padding: '16px 24px' }}>
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                reset({});
                handleClose?.();
              }}
              aria-label="dhec-close"
              sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
              <Close sx={{ width: 25, height: 25 }} />
            </IconButton>
          </>
        )}

        <DialogContent
          // sx={{ padding: '0', maxHeight: '70vh', minHeight: fullWidth ? '70vh' : 'auto' }}>
          sx={{
            padding: '0 0 10px 0',
            minHeight: 'auto',
            overflowY: noScrollY ? 'hidden' : overflowVissible ? 'visible' : 'auto'
          }}>
          <Container sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
            {initialData && initialData && (
              <ReactTableContainer
                columns={columnsTable}
                data={initialData}
                module={localStorageKey}
                primaryKey="id"
                currSortBy={currentOrderBy}
                totalPage={totalData}
                onPageChange={(offset, keyword) => {
                  setCurrentOffset(offset);
                }}
                onSearch={(offset, keyword) => {
                  setCurrentOffset(0);
                  setCurrentKeyword(keyword ?? null);
                }}
                filtersData={(filters) => {
                  setCurrentOffset(0);
                  setCurrentFilterParams(filters);
                }}
                onChangeSort={(filter: string, isSortedDesc: boolean) => {
                  const newSortValue = [{ id: filter, desc: isSortedDesc }];
                  setCurrentOrderBy(newSortValue);
                }}
                returnCountyNameById={returnCountyNameById}
              />
            )}
          </Container>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '25px' }}>
          <Button
            onClick={() => {
              handleClose?.();
              clearErrors();
            }}
            color="secondary">
            {cancelLabel}
          </Button>
          <Button type="submit" className={commonClasses.blueButton}>
            {saveLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormDialogListPermitNumbers;
