import React, { useEffect, useState } from 'react';

import StaffDirectoryHeaders from '../../../../../common/arrays/StaffDiretoryHeaders';
import { ColumnsProperties } from '../../../../fragments/ReactTable/ReactTable.props';
import { IStaffDirectory } from '../../../PermitHolders/StaffDirectory/StaffDirectory.props';
import ReactTableContainer from 'components/fragments/ReactTable';
import { IStaffDirectoryProps } from './StaffDirectory.props';
import apiFetch from '../../../../../services/apiFetch';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import Image from '../../../../primitives/Image';
import ViewBlackButton from '../../../../../common/images/view_black_button.png';
import useCommonStyles from 'common/styles/common.styles';
import { CellProps } from 'react-table';
import FormDialog from 'components/fragments/FormDialog';
import { Container } from '@mui/system';
import { IUser } from 'interfaces/IUser';
import ViewStaffForm from './ViewStaffForm';
import Tooltip from '@mui/material/Tooltip';

import {
  ApiAdminStaffDirectorySearch,
  getStaffDirectoryAdmin,
  getStaffDirectoryAdminCounter
} from '../../../../../services/apiAdminStaffDirectory';

const localStorageKey = 'PH_STAFF_DIRECTORY_ADMIN';

const StaffDirectoryView: React.FC<IStaffDirectoryProps> = ({ businessInfo }) => {
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols ? JSON.parse(localCols) : StaffDirectoryHeaders;

  const [columnsTable] = useState<ColumnsProperties[]>(defColTable);
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'facility', desc: false }]);
  const [initialData, setInitialData] = useState<IStaffDirectory[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [openViewStaff, setOpenViewStaff] = useState<boolean>(false);
  const [staffInfo, setStaffInfo] = useState<IUser>();
  const [actionType, setActionType] = useState<string>('');

  const commonClasses = useCommonStyles();

  useEffect(() => {
    // getBusinessPermitHolder('', '', 0, `&orderBy=${DEFAULT_ORDER_BY}`, true);
    if (defColTable) {
      const optidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');
      const email = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'email');
      const business = defColTable.findIndex(
        (d: ColumnsProperties) => d.accessor === 'businessName'
      );

      if (email > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[email].Cell = ({ cell }: CellProps<object, any>) => {
          const userInfo: IUser = cell.row.original as IUser;
          return (
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{userInfo.email}</span>}
              placement="left"
            >
              <Typography>{userInfo.email ?? '-'}</Typography>
            </Tooltip>
          );
        };
      }

      if (business > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[business].Cell = ({ cell }: CellProps<object, any>) => {
          const userInfo: IUser = cell.row.original as IUser;

          let name = '-';
          if (userInfo.businessName) {
            name =
              userInfo.businessName.length > 20
                ? userInfo.businessName.slice(0, 20 - 1) + '...'
                : userInfo.businessName;
          }

          return (
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{userInfo.businessName}</span>}
              placement="left"
            >
              <Typography>{name}</Typography>
            </Tooltip>
          );
        };
      }
      if (optidx > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[optidx].Cell = ({ cell }: CellProps<object, any>) => {
          const staffInfo: IUser = cell.row.original as IUser;
          return (
            <Button
              variant="outlined"
              size="small"
              className={commonClasses.editButton}
              startIcon={<Image src={ViewBlackButton} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setOpenViewStaff(true);
                setStaffInfo(staffInfo);
              }}
            >
              View
            </Button>
          );
        };
      }
    }
  }, []);

  useEffect(() => {
    getStaffDirectory();
  }, [currentOffset, currentKeyword, currentFilterParams]);

  useEffect(() => {
    getStaffDirectory(false);
  }, [currentOrderBy]);

  const getStaffDirectory = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminStaffDirectorySearch = {
      businessID: businessInfo?.id,
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getStaffDirectoryAdmin(filters));

    if (usersData) {
      setInitialData(usersData.data?.response || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getStaffDirectoryAdminCounter(filters));
      setTotalData(total.data?.response || 0);
    }
  };

  return (
    <Container>
      {initialData && initialData && (
        <ReactTableContainer
          columns={columnsTable}
          data={initialData}
          customData={{ businessID: businessInfo?.id }}
          module={localStorageKey}
          primaryKey="id"
          currSortBy={currentOrderBy}
          totalPage={totalData}
          onPageChange={(offset) => {
            setCurrentOffset(offset);
          }}
          onSearch={(offset, keyword) => {
            setCurrentKeyword(keyword ?? null);
          }}
          filtersData={(filters) => {
            setCurrentFilterParams(filters);
            setCurrentOffset(0);
          }}
          onChangeSort={(filter: string, isSortedDesc: boolean) => {
            const newSortValue = [{ id: filter, desc: isSortedDesc }];
            setCurrentOrderBy(newSortValue);
          }}
        />
      )}

      <FormDialog
        show={openViewStaff}
        fullWidth
        initialData={{}}
        hasSaveAndNew={false}
        hasSave={false}
        form="user-form"
        actionType={actionType}
        title={'View Staff'}
        handleClose={() => {
          setActionType('');
          setOpenViewStaff(false);
          setStaffInfo({});
        }}
      >
        <ViewStaffForm data={staffInfo!} />
      </FormDialog>
    </Container>
  );
};

export default StaffDirectoryView;
