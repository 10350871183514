import React, { useEffect } from 'react';
import { Container } from '@mui/system';
import { Grid, Paper, Typography } from '@mui/material';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { IViewUserForm } from './ViewUserForm.props';
import { Controller } from 'react-hook-form';
import SelectInput from '../../../../primitives/SelectInput';
import { ErrorMessage } from '@hookform/error-message';
import UserStatusEnum from '../../../../../common/interfaces/IUser';

export const UserStatusSelect = [
  { value: UserStatusEnum.active, label: 'Active' },
  { value: UserStatusEnum.removed, label: 'Removed' }
];

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const ViewUserForm: React.FC<IViewUserForm> = ({ data, form, disabled = true }) => {
  useEffect(() => {
    form.setValue('id', data.id);
  }, []);

  return (
    <Container>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormGroup label={'Status'}>
                  <Controller
                    name="status"
                    control={form.control}
                    defaultValue={data?.status || null}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      value = data?.status;
                      let dV = null;
                      if (data && value) {
                        dV = UserStatusSelect.filter((option) => option.value.includes(value));
                      }

                      return (
                        <>
                          <SelectInput
                            defaultValue={dV}
                            error={!!form.formState.errors.type?.message}
                            options={UserStatusSelect}
                            placeholder={`Select`}
                            onChange={(e) => {
                              form.setValue('status', e.value);
                              return onChange(e.value);
                            }}
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={form.formState.errors} name="status" />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} alignItems="center" columnSpacing={12}>
          <Typography variant="h6" color="textSecondary">
            General
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'First Name'}>
                  <TextField
                    value={data.firstName || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Last Name'}>
                  <TextField
                    value={data.lastName || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Email'}>
                  <TextField
                    value={data.email || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Business Name'}>
                  <TextField
                    value={data.businessName || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Phone Number'}>
                  <TextField
                    value={data.phoneNumber || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item lg={12}>
          <Typography variant="h6" color="textSecondary">
            Mailing Address
          </Typography>
        </Grid>
        <Grid container item lg={12}>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Street Address'}>
                  <TextField
                    value={data.addressStreet || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                  <TextField
                    value={data.addressNumber || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'State'}>
                  <TextField
                    value={data.addressState || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'County'}>
                  <TextField
                    value={data.addressCounty || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'City'}>
                  <TextField
                    value={data.addressCity || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <FormGroup label={'Zip Code'}>
                  <TextField
                    value={data.addressZip || ''}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    disabled={disabled}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewUserForm;
