import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import HomepageModalsState from '../state/HomepageModalsState';

const DEFAULT_STATE: HomepageModalsState = {
  showLogin: false,
  showRegister: false,
  showContact: false,
  showResetPassword: false,
  showFinishSignup: false
};

export const homepageModalsSlice = createSlice({
  name: 'homepageModals',
  initialState: DEFAULT_STATE,
  reducers: {
    updatehomepageModals: (state, action: PayloadAction<HomepageModalsState>) => {
      return action.payload || DEFAULT_STATE;
    }
  }
});

export const { updatehomepageModals } = homepageModalsSlice.actions;

export const selecthomepageModals = (state: RootState) => state.homepageModals;
export const selectHomepageModalsLogin = (state: RootState) => state.homepageModals.showLogin;

export default homepageModalsSlice.reducer;
