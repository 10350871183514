import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import PermitHolderState from 'store/state/PermitHolderState';

const DEFAULT_STATE: PermitHolderState = {
  businesses: null,
  personalInfo: undefined,
  staffDirectory: null
};

export const permitHolderSlice = createSlice({
  name: 'permitHolder',
  initialState: DEFAULT_STATE,
  reducers: {
    updatePermitHolder: (state, action: PayloadAction<PermitHolderState>) => {
      return action.payload || DEFAULT_STATE;
    },
    clearPermitHolder: (state, action: PayloadAction<PermitHolderState>) => {
      return action.payload || DEFAULT_STATE;
    }
  }
});

export const { updatePermitHolder, clearPermitHolder } = permitHolderSlice.actions;

export const selectpermitHolder = (state: RootState) => state.permitHolder;

export default permitHolderSlice.reducer;
