import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSurveysListCountState } from './surveys-list-count.state';
import { getSurveyListCount } from './surveys-list-count.trunk';
import { ErrorInterface } from '../../interfaces/genera.interface';

export const surveyListCountSlice = createSlice({
  name: 'surveyListCount',
  initialState: initialSurveysListCountState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSurveyListCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSurveyListCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as ErrorInterface;
      })
      .addCase(getSurveyListCount.fulfilled, (state, action: PayloadAction<number>) => {
        state.loading = false;
        state.error = false;
        state.count = action.payload;
      });
  }
});
