import React from 'react';
import { Box } from '@mui/material';
import { IImage } from './Image.props';

const Image: React.FC<IImage> = ({
  onClick,
  sx = {
    height: 24,
    cursor: 'pointer'
  },
  ...props
}) => {
  return <Box component="img" {...props} sx={sx} onClick={() => (onClick ? onClick() : null)} />;
};

export default Image;
