import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, List, ListItem, Typography } from '@mui/material';
import useStyles from './Footer.styles';
import Logo_BW from 'common/images/Logo_BW.png';
import AppLogo from 'common/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { ILink } from 'common/interfaces';
import { FooterProps } from './Footer.props';
import ContactForm from 'components/fragments/ContactForm';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import HomepageModalsState from 'store/state/HomepageModalsState';
import { selecthomepageModals, updatehomepageModals } from 'store/reducers/homepageModals';
import { selectAccountRole } from '../../../store/reducers/account';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const FOOTER_LINKS: any = {
  discovery: [
    {
      label: 'Videos',
      url: '/videos',
      localization: 'videos'
    },
    {
      label: 'Documents',
      url: '/documents',
      localization: 'documents'
    }
  ],
  resources: [
    {
      label: 'Request Infomation',
      url: '#',
      localization: 'requestInformation'
    },
    {
      label: 'Site Map',
      url: '#',
      localization: 'siteMap'
    },
    {
      label: 'Privacy & Terms',
      url: '#',
      localization: 'privacyAndTerms'
    }
  ],
  user: [
    {
      label: 'Permit Holder',
      url: '/permit-holder'
    },
    {
      label: 'DHEC Admin',
      url: '/dhec-admin'
    }
  ]
};

const Footer: React.FC<FooterProps> = ({ isLoggedIn = false, setShowChangePassword }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const homepageModals = useAppSelector(selecthomepageModals);
  const userRole = useAppSelector(selectAccountRole);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultModal = {
    showLogin: false,
    showRegister: false,
    showContact: false,
    showResetPassword: false,
    showFinishSignup: false
  };
  const [modalStatus, setModalStatus] = useState<HomepageModalsState>(defaultModal);

  useEffect(() => {
    setModalStatus(homepageModals);
  }, [homepageModals, modalStatus]);

  const cbModal = (value: boolean, name: string) => {
    const payload = {
      ...defaultModal,
      [name]: value
    };
    setModalStatus(payload);
    dispatch(updatehomepageModals(payload));
  };

  //const openContact = (value: boolean) => {
  //  dispatch(
  //    homepageModalsActions.update({
  //      showLogin: false,
  //      showRegister: false,
  //      showContact: value,
  //      showResetPassword: false
  //    })
  //  );
  //};

  if (isLoggedIn) {
    return (
      <Box className={classes.wrapperWhite}>
        {/* <Grid alignItems="baseline" container spacing={10} sx={{ padding: '20px 10%' }}> */}
        <Grid
          alignItems="center"
          justifyContent={'space-between'}
          container
          sx={{ padding: isMobile ? '16px' : '20px 10%' }}>
          <Grid item xs={6} className={classes.whiteGridItem}>
            <Box
              component="img"
              alt="DHEC"
              src={AppLogo}
              sx={{ width: '122px', height: '45px' }}
              onClick={() => navigate('/')}
            />
          </Grid>
          {/*<Grid item lg={4} className={classes.whiteGridItem} />*/}
          <Grid container item xs={6} className={classes.whiteGridItem} justifyContent={'flex-end'}>
            {userRole === 'permitholder' && (
              <Button
                variant="text"
                onClick={() => {
                  //openContact(true);
                  cbModal(true, 'showContact');
                }}>
                Contact DHEC
              </Button>
            )}
          </Grid>
        </Grid>
        {homepageModals?.showContact && homepageModals?.showContact && (
          <ContactForm
            show={homepageModals?.showContact}
            handleClose={() => {
              //openContact(false);
              cbModal(false, 'showContact');
            }}
            handleSave={() => {
              //openContact(false);
              cbModal(false, 'showContact');
            }}></ContactForm>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper} sx={{ color: '#fff' }}>
      <Grid container className={classes.grid}>
        <Grid item className={classes.gridItem}>
          <Box
            component="img"
            alt="DHEC"
            src={Logo_BW}
            sx={{ width: '122px', height: '45px' }}
            onClick={() => navigate('/')}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <List>
            <ListItem>
              <Typography variant="h5">{`Discover`}</Typography>
            </ListItem>
            {FOOTER_LINKS.discovery.map((l: ILink) => (
              <ListItem key={`item-${l.label}`} button component={Link} to={l.url}>
                <Typography variant="body1">{`${l.locale || l.label}`}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item className={classes.gridItem}>
          <List>
            <ListItem>
              <Typography variant="h5">{`Resources`}</Typography>
            </ListItem>
            <button
              className={classes.item}
              onClick={() => {
                //openContact(true);
                cbModal(true, 'showContact');
              }}>
              <Typography variant="body1">Request Information</Typography>
            </button>
          </List>
        </Grid>
        {modalStatus?.showContact && (
          <ContactForm
            show={modalStatus?.showContact}
            handleClose={() => {
              //openContact(false);
              cbModal(false, 'showContact');
            }}
            handleSave={() => {
              //openContact(false);
              cbModal(false, 'showContact');
            }}></ContactForm>
        )}
        {/*<Grid item className={classes.gridItem}>*/}
        {/*  <List>*/}
        {/*    <ListItem>*/}
        {/*      <Typography variant="h5">User Page</Typography>*/}
        {/*    </ListItem>*/}
        {/*    {FOOTER_LINKS.user.map((l: ILink) => (*/}
        {/*      <ListItem key={`item-${l.label}`} button component={Link} to={l.url}>*/}
        {/*        <Typography variant="body1">{l.label}</Typography>*/}
        {/*      </ListItem>*/}
        {/*    ))}*/}
        {/*    <ListItem button component={Link} to={'#'}>*/}
        {/*      <Typography*/}
        {/*        variant="body1"*/}
        {/*        onClick={() => (setShowChangePassword ? setShowChangePassword(true) : null)}>*/}
        {/*        Change Password*/}
        {/*      </Typography>*/}
        {/*    </ListItem>*/}
        {/*  </List>*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  );
};

export default Footer;
