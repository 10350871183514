export const LowCounty = [
  { label: 'Allendale', value: '3' },
  { label: 'Bamberg', value: '5' },
  { label: 'Beaufort', value: '7' },
  { label: 'Berkeley', value: '8' },
  { label: 'Calhoun', value: '9' },
  { label: 'Charleston', value: '10' },
  { label: 'Colleton', value: '15' },
  { label: 'Dorchester', value: '18' },
  { label: 'Hampton', value: '25' },
  { label: 'Jasper', value: '27' },
  { label: 'Orangeburg', value: '38' }
];
export const PeeDeeCounties = [
  { label: 'Clarendon', value: '14' },
  { label: 'Chesterfield', value: '13' },
  { label: 'Darlington', value: '16' },
  { label: 'Dillon', value: '17' },
  { label: 'Florence', value: '21' },
  { label: 'Georgetown', value: '22' },
  { label: 'Horry', value: '26' },
  { label: 'Lee', value: '31' },
  { label: 'Marion', value: '33' },
  { label: 'Marlboro', value: '34' },
  { label: 'Sumter', value: '43' },
  { label: 'Williamsburg', value: '45' }
];
export const MidlandsCounties = [
  { label: 'Aiken', value: '2' },
  { label: 'Barnwell', value: '6' },
  { label: 'Chester', value: '12' },
  { label: 'Edgefield', value: '19' },
  { label: 'Fairfield', value: '20' },
  { label: 'Kershaw', value: '28' },
  { label: 'Lancaster', value: '29' },
  { label: 'Lexington', value: '32' },
  { label: 'Newberry', value: '36' },
  { label: 'Richland', value: '40' },
  { label: 'Saluda', value: '41' },
  { label: 'York', value: '46' }
];
export const UpstateCounties = [
  { label: 'Abbeville', value: '1' },
  { label: 'Anderson', value: '4' },
  { label: 'Cherokee', value: '11' },
  { label: 'Greenwood', value: '24' },
  { label: 'Greenville', value: '23' },
  { label: 'Laurens', value: '30' },
  { label: 'McCormick', value: '35' },
  { label: 'Oconee', value: '37' },
  { label: 'Pickens', value: '39' },
  { label: 'Spartanburg', value: '42' },
  { label: 'Union', value: '44' }
];
