import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import AppLogo from '../../../common/images/logo.png';

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {
      justifyContent: 'center',
      overflowY: 'visible'
    },
    toolBar: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: '16px'
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10%',
        paddingRight: '10%'
      }
    },
    appLogo: {
      width: '122px',
      height: '45px',
      backgroundImage: `url(${AppLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center'
    }
  };
});

export default useStyles;
