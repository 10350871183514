import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { CampaignSurveyFormDialogProps } from './CampaignSurveyFormDialog.props';
import useCommonStyles from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../../app/hooks';
import { saveSurveyCampaignCreate } from '../../store/surveys-campaign-create/surveys-campaign.trunk';
import { useSelector } from 'react-redux';
import {
  clearSnackbarSurveyMessage,
  updateSnackbarSurveyMessage
} from '../../store/snackbar-message/snackbar-message.reducer';

import { clearSurveysRecipients } from '../../store/surveys-recipients/surveys-recipients.reducer';
import {
  surveysCampaignCreateError,
  surveysCampaignCreateLoading,
  surveysCampaignCreateSuccess
} from '../../store/surveys-campaign-create/surveys-campaign.selector';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { loadingStyle } from '../../styles/loading.style';
import { clearSurveyCampaignCreate } from '../../store/surveys-campaign-create/surveys-campaign.actions';
import { SurveyQuestionTypes } from '../../enums/SurveyQuestionTypesEnum';
import { clearSurveysCounties } from '../../store/survey-counties/surveys-counties.reducer';
const CampaignSurveyFormDialog: React.FC<CampaignSurveyFormDialogProps> = ({
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'lg',
  noScrollY = false,
  overflowVisible = false,
  handleClose,
  backgroundTransparent = false,
  children
}) => {
  const commonClasses = useCommonStyles();
  const dispatch = useAppDispatch();

  const campaignForm = useForm();

  const loading = useSelector(surveysCampaignCreateLoading);
  const error = useSelector(surveysCampaignCreateError);
  const success = useSelector(surveysCampaignCreateSuccess);

  useEffect(() => {
    return () => {
      dispatch(clearSurveysRecipients(null));
      dispatch(clearSurveysCounties(null));
    };
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(updateSnackbarSurveyMessage({ show: true, message: 'Something went wrong' }));
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      handleClose?.();
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(clearSnackbarSurveyMessage({ show: false, message: null }));
      dispatch(clearSurveyCampaignCreate());
    };
  }, []);

  const questionHasOptions = (dataToCheck: any) => {
    let questionsWithNoOptions = '';
    dataToCheck.map((item: any, idx: number) => {
      if (
        (item.type === SurveyQuestionTypes.SINGLE || item.type === SurveyQuestionTypes.MULTIPLE) &&
        item.surveyQuestionsOptions.length < 2
      )
        questionsWithNoOptions += !questionsWithNoOptions.length ? idx + 1 : ', ' + `${idx + 1}`;
    });
    return questionsWithNoOptions;
  };

  const onSubmit = async (formData: any) => {
    if (formData.survey.counties.length > 0) {
      formData.survey.hasSelectByCounty = true;
    }
    const dataToSave = structuredClone(formData);
    const questionsWithNoOptions = questionHasOptions(dataToSave.template.surveyQuestions);
    const origMainUUID: any[] = [];
    if (
      !dataToSave.hasOwnProperty('template') ||
      dataToSave?.template?.surveyQuestions.length === 0
    ) {
      dispatch(
        updateSnackbarSurveyMessage({
          show: true,
          message: 'You need to add a template question!'
        })
      );

      return;
    } else if (questionsWithNoOptions.length > 0) {
      dispatch(
        updateSnackbarSurveyMessage({
          show: true,
          message: `You need to add at least two options for question${
            questionsWithNoOptions.length > 1 ? 's' : ''
          } number: ${questionsWithNoOptions}!`
        })
      );

      return;
    } else {
      const currentStartDate = dataToSave.survey.startDate.$d;
      const currentEndDate = dataToSave.survey.endDate.$d;

      const timeZoneOffset = currentStartDate.getTimezoneOffset();
      currentStartDate.setHours(currentStartDate.getHours() - timeZoneOffset / 60);
      currentEndDate.setHours(currentEndDate.getHours() - timeZoneOffset / 60);

      dataToSave.survey.startDate = (currentStartDate.setUTCHours(0, 0, 0, 0) / 1000) | 0;
      dataToSave.survey.endDate = (currentEndDate.setUTCHours(23, 59, 59, 999) / 1000) | 0;

      dataToSave.template.title = dataToSave.survey.title;
      dataToSave.template.surveyQuestions.map((item: any) => {
        origMainUUID.push({ oldUUID: item.uuid, newUUID: uuidv4() });
      });
      dataToSave.template.surveyQuestions = dataToSave.template.surveyQuestions.map(
        (item: any, idx: number) => {
          delete item.triggerOption;
          delete item.surveyTemplateID;
          item.uuid = origMainUUID[idx].newUUID; // Pass the new uuid
          if (item.triggerActionQuestionUUID) {
            const foundID = origMainUUID.find(
              (o: any) => o.oldUUID === item.triggerActionQuestionUUID
            );
            item.triggerActionQuestionUUID = foundID.newUUID;
          }
          // Replace surveyQuestionsOptions id
          if (item?.surveyQuestionsOptions && item?.surveyQuestionsOptions.length) {
            item.surveyQuestionsOptions = item.surveyQuestionsOptions.map((s: any) => {
              delete s['id'];
              delete s['surveyQuestionsId'];

              if (s.triggerActionQuestionUUID) {
                const foundMainID = origMainUUID.find(
                  (or: any) => or.oldUUID === s.triggerActionQuestionUUID
                );
                s.triggerActionQuestionUUID = foundMainID.newUUID;
              }
              return s;
            });
          }
          // Return formated item
          return item;
        }
      );

      delete dataToSave.template.id;

      await dispatch(saveSurveyCampaignCreate(dataToSave));
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { handleClose, campaignForm });
    }
    return child;
  });

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVisible ? 'visible' : 'auto',
          position: 'relative'
        }
      }}>
      {loading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={campaignForm.handleSubmit(onSubmit)}>
        {title && (
          <>
            <Typography variant="h4" sx={{ padding: '16px 24px', textTransform: 'capitalize' }}>
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                handleClose?.();
              }}
              aria-label="dhec-close"
              sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
              <Close sx={{ width: 25, height: 25 }} />
            </IconButton>
          </>
        )}

        <DialogContent
          sx={{
            padding: '0',
            minHeight: '70vh',
            overflowY: noScrollY ? 'hidden' : overflowVisible ? 'visible' : 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}>
          {childrenWithProps}
        </DialogContent>
        <DialogActions
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            gap: '12px'
          }}>
          <Button
            autoFocus
            color="secondary"
            onClick={() => {
              handleClose?.();
            }}>
            Cancel
          </Button>

          <Button
            className={commonClasses.blueButton}
            color="primary"
            onClick={campaignForm.handleSubmit(onSubmit)}
            disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CampaignSurveyFormDialog;
