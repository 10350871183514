import axios, { AxiosRequestHeaders, AxiosRequestConfig } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import AuthProtect from 'common/enums/AuthProtect';
import { store } from 'store';
import { IEndpoint } from 'interfaces/IEndPoint';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

const apiFetch = (api: IEndpoint) => {
  const headers: AxiosRequestHeaders = {};
  const params: any = {};
  let baseURL = apiConfig.baseURL;

  if (api.authorization === AuthProtect.REQUIRED) {
    const { token } = store.getState();

    if (token) {
      // headers.Authorization = token.id;
      params.access_token = token.id;
    } else {
      throw new Error('missing token');
    }
  }

  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params
    }
  ]);

  const axiosCreate = axios.create(apiConfigMerge);

  axiosCreate.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosCreate({
    ...apiConfigMerge,
    validateStatus: (status: number) => {
      return status < 500;
    }
  })
    .catch((e) => {
      throw {
        message: genericErrorMessage
      };
    })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('persist:root');
        window.location.href = '/';
      }

      return {
        status: response.status,
        data: response.data
      };
    });
};

export default apiFetch;
