import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import EditIcon from 'common/images/edit_icon.png';
import useCommonStyles from 'common/styles/common.styles';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import FormDialog from 'components/fragments/FormDialogEditProfile';
import EditUserForm from '../../EditUserForm';
import { IUser } from 'interfaces/IUser';
import apiFetch from 'services/apiFetch';
import { updateAccountInfo, updateProfileToEdit } from 'services/apiEndPoints';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { selectAccount, updateAccount } from 'store/reducers/account';
import { updateSnackbarMessage } from '../../../../store/reducers/snackbarMessage.reducer';
import { selectTokens } from '../../../../store/reducers/token';

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh'
};
const PersonalInformation: React.FC<IUser> = (props: IUser) => {
  const token = useAppSelector(selectTokens) || null;
  const dispatch = useAppDispatch();
  const commonClasses = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const [openEditUserForm, setOpenEditUserForm] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser>(props);
  const [toUpdateUserInfo, setToUpdateUserInfo] = useState<IUser>({});

  useEffect(() => {
    if (Object.keys(props).length > 0) {
      const userInfo = {
        firstName: props.firstName,
        lastName: props.lastName,
        phoneNumber: props.phoneNumber,
        email: props.email,
        addressStreet: props.addressStreet,
        addressNumber: props.addressNumber,
        addressCounty: props.addressCounty,
        addressState: props.addressState,
        addressCity: props.addressCity,
        addressZip: props.addressZip,
        role: props.role
      };
      setUserInfo(userInfo);
      setToUpdateUserInfo(userInfo);
    }
  }, [props]);

  const handleSaveForm = async (values: any) => {
    try {
      if (_.isEqual(userInfo, values)) {
        setOpenEditUserForm(false);
        return;
      }
      setLoading(true);
      const res = await apiFetch(updateProfileToEdit(values, token.id));
      setLoading(false);
      if (res) {
        dispatch(updateAccount(res.data.response));
        setOpenEditUserForm(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  return (
    <Container
      disableGutters
      sx={{ maxWidth: '100%!important', padding: '20px 0 20px 0' }}
      id="TEST JSJSJSJS">
      {!userInfo?.role ? (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container alignItems="center" spacing={2} rowSpacing={2}>
          <Grid item lg={6}>
            <Typography variant="h4">{'Account Information'}</Typography>
          </Grid>
          <Grid container item alignItems="center" justifyContent="flex-end" lg={6}>
            <Button
              className={commonClasses.blueButton}
              startIcon={<Avatar src={EditIcon} sx={{ width: 16, height: 16 }} />}
              onClick={() => setOpenEditUserForm(true)}>
              Edit
            </Button>
          </Grid>
          <Grid container item lg={12}>
            <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
              <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`First Name`}>
                    <TextField disabled value={userInfo.firstName || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`Last Name`}>
                    <TextField disabled value={userInfo.lastName || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`Phone Number`}>
                    <TextField disabled value={userInfo.phoneNumber || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`Email Address`}>
                    <TextField disabled value={userInfo.email || ''} />
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container item lg={12}>
            <Typography variant="h6" color="textSecondary">
              Mailing Address
            </Typography>
          </Grid>
          <Grid container item lg={12}>
            <Paper sx={{ width: '100%', padding: 2 }}>
              <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`Street Address`}>
                    <TextField disabled value={userInfo.addressStreet || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={`Apartment, Suite, Unit, etc.`}>
                    <TextField disabled value={userInfo.addressNumber || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={`State`}>
                    <TextField disabled value={userInfo.addressState || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={`County`}>
                    <TextField disabled value={userInfo.addressCounty || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={`City`}>
                    <TextField disabled value={userInfo.addressCity || ''} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <FormGroup label={`Zip Code`}>
                    <TextField disabled value={userInfo.addressZip || ''} />
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FormDialog
            show={openEditUserForm}
            fullWidth
            loading={loading}
            title={`Edit User`}
            hasCancel={false}
            handleClose={() => setOpenEditUserForm(false)}
            hasSave={false}>
            <EditUserForm {...toUpdateUserInfo} handleSaveForm={handleSaveForm} />
          </FormDialog>
        </Grid>
      )}
    </Container>
  );
};

export default PersonalInformation;
