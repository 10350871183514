import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  Paper, Box
} from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';

import { FormDialogProps } from './FormDialogAddEditLibrary.props';
import useCommonStyles from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import FormGroup from '../../primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import TextArea from 'components/primitives/TextArea';
import SelectInput from '../../primitives/SelectInput';
import apiFetch from '../../../services/apiFetch';
import { createLibrary, updateLibrary, deleteLibrary } from '../../../services/apiAdminLibrary';
import ConfirmDialog from "components/fragments/ConfirmDialog";
import { loadingStyle } from "../../snippets/SurveysResponse/components/SurveyContainer/SurveyContainer.styles";
import CircularProgress from "@mui/material/CircularProgress";

const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};
interface formLanguagesInterface {
  title: string;
  code: string;
  mandatory: boolean;
}

const FormDialogAddEditLibrary: React.FC<FormDialogProps> = ({
  data,
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'md',
  noScrollY = false,
  overflowVissible = false,
  handleClose,
  handleSave,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  deleteLabel = 'Delete'
}) => {
  const commonClasses = useCommonStyles();
  const [formType, setFormType] = useState<'video' | 'document'>('document');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const types = [
    { value: 'video', label: 'Video File' },
    { value: 'document', label: 'Document File' }
  ];

  const categories = [
    { value: 'Fact Sheets', label: 'Fact Sheets' },
    { value: 'Print Your Own Signage', label: 'Print Your Own Signage' },
    { value: 'Guidance Tools', label: 'Guidance Tools' }
  ];

  const {
    control,
    getValues,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<any>({
    defaultValues: { type: 'document' }
  });

  const handleAgree = () => {
    setShowConfirmDialog(false);
    setIsLoading(true);
    onDelete().then(res => {
      setIsLoading(false);
      if (res && res.status === 200) {
        handleSave?.(res);
      }
    });
  };
  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
  };

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    let res = null;

    if (data && data.id) {
      res = await apiFetch(updateLibrary(data.id, formData));
    } else {
      res = await apiFetch(createLibrary(formData));
    }

    if (res && res.status === 200) {
      setIsLoading(false);
      handleSave?.(res);
    }
  };

  const handleConfirmation = () => {
    setShowConfirmDialog(true)
  }

  const onDelete = async () => {
    let res = null;

    if (data && data.id) {
      res = await apiFetch(deleteLibrary(data.id));
    } else {
      return;
    }
    return res
  }

  const formLanguages: formLanguagesInterface[] = [
    {
      title: 'Details for English',
      code: 'EN',
      mandatory: true
    },
    {
      title: 'Details for Spanish',
      code: 'ES',
      mandatory: false
    },
    {
      title: 'Details for Chinese',
      code: 'ZH',
      mandatory: false
    },
    {
      title: 'Details for French',
      code: 'FR',
      mandatory: false
    }
  ];

  useEffect(() => {
    if (data?.type) {
      setFormType(data.type);
    }
  }, [data]);

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      scroll={'paper'}
      onClose={() => {
        reset({});
        handleClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#FAFAFA',
          boxShadow: 'inherit',
          overflowY: 'auto'
        }
      }}
      disableScrollLock
    >
      <>
        {isLoading && (
            <Box sx={loadingStyle}>
              <CircularProgress />
            </Box>
        )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {title && (
          <>
            <Typography variant="h4" sx={{ padding: '16px 24px' }}>
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                reset({});
                handleClose?.();
              }}
              aria-label="dhec-close"
              sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}
            >
              <Close sx={{ width: 25, height: 25 }} />
            </IconButton>
          </>
        )}

        <DialogContent
          // sx={{ padding: '0', maxHeight: '70vh', minHeight: fullWidth ? '70vh' : 'auto' }}>
          sx={{
            padding: '0 0 10px 0',
            minHeight: 'auto',
            overflowY: noScrollY ? 'hidden' : overflowVissible ? 'visible' : 'auto'
          }}
        >
          <Container sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item lg={12}>
                <Typography variant="h6" color="textSecondary">
                  General details
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
                  <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <FormGroup label={'Select type'}>
                        <Controller
                          name="type"
                          control={control}
                          defaultValue={data?.type ?? null}
                          rules={{ required: 'Field is required.' }}
                          render={({ field: { name, onChange } }) => {
                            let defaultValue = null;
                            let value = data?.type ?? null;
                            if (categories && value) {
                              defaultValue = types.find((option) => option.value === value);
                            } else {
                              defaultValue = types.find((option) => option.value === 'document');
                            }

                            return (
                              <>
                                <SelectInput
                                  defaultValue={defaultValue}
                                  error={!!errors.type?.message}
                                  options={types}
                                  placeholder={`Select`}
                                  onChange={(e) => {
                                    setValue('type', e.value);
                                    setFormType(e.value);

                                    return onChange(e.value);
                                  }}
                                />
                                <p style={errorTextStyle}>
                                  <ErrorMessage errors={errors} name="type" />
                                </p>
                              </>
                            );
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <FormGroup label={'Select Category'}>
                        <Controller
                          name="category"
                          control={control}
                          rules={{ required: 'Field is required.' }}
                          defaultValue={data?.category ?? null}
                          render={({ field: { name, onChange, value } }) => {
                            let dV = null;
                            if (categories && value) {
                              dV = categories.filter((option) => value.includes(option.value));
                            }
                            return (
                              <>
                                <SelectInput
                                  defaultValue={dV}
                                  error={!!errors.category?.message}
                                  options={categories}
                                  placeholder={`Select`}
                                  onChange={(e) => {
                                    setValue('category', e.value);
                                    return onChange(e.value);
                                  }}
                                />
                                <p style={errorTextStyle}>
                                  <ErrorMessage errors={errors} name="category" />
                                </p>
                              </>
                            );
                          }}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <>
                {formLanguages.map((item: formLanguagesInterface, i: number) => {
                  if (watch() && formType === 'video' && item.code !== 'EN') {
                    return;
                  }
                  return (
                    <Grid item lg={12} key={i}>
                      <Grid
                        item
                        lg={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '8px'
                        }}
                      >
                        <Typography variant="h6" color="textSecondary">
                          {item.title}
                        </Typography>
                        {item.mandatory && (
                          <Typography variant="h6" color="#ACACAD">
                            Mandatory
                          </Typography>
                        )}
                      </Grid>
                      <Grid container item lg={12}>
                        <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
                          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                            {item.code === 'EN' && (
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup label={'Title'}>
                                  <Controller
                                    name={`title_${item.code}`}
                                    defaultValue={data ? data[`title_${item.code}`] : null}
                                    control={control}
                                    rules={{
                                      required: {
                                        message: 'Field is required.',
                                        value: item.mandatory
                                      },
                                      maxLength: {
                                        message: 'Value in this input is too long.',
                                        value: 255
                                      }
                                    }}
                                    render={({ field: { name, onChange } }) => {
                                      return (
                                        <>
                                          <TextField
                                            value={getValues(`title_${item.code}`) || ''}
                                            error={!!errors[`title_${item.code}`]?.message}
                                            name={name}
                                            onChange={onChange}
                                            variant="standard"
                                          />
                                          <p style={errorTextStyle}>
                                            <ErrorMessage
                                              errors={errors}
                                              name={`title_${item.code}`}
                                            />
                                          </p>
                                        </>
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Grid>
                            )}
                            {watch() &&
                              ((formType === 'video' && item.code === 'EN') ||
                                formType === 'document') && (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <FormGroup label={'Url'}>
                                    <Controller
                                      name={`url_${item.code}`}
                                      control={control}
                                      defaultValue={data ? data[`url_${item.code}`] : null}
                                      rules={{
                                        required: {
                                          message: 'Field is required.',
                                          value: item.mandatory
                                        },
                                        maxLength: {
                                          message: 'Value in this input is too long.',
                                          value: 255
                                        }
                                      }}
                                      render={({ field: { name, onChange } }) => {
                                        return (
                                          <>
                                            <TextField
                                              value={getValues(`url_${item.code}`) || ''}
                                              error={!!errors[`url_${item.code}`]?.message}
                                              name={name}
                                              onChange={onChange}
                                              variant="standard"
                                            />
                                            <p style={errorTextStyle}>
                                              <ErrorMessage
                                                errors={errors}
                                                name={`url_${item.code}`}
                                              />
                                            </p>
                                          </>
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </Grid>
                              )}

                            {item.code === 'EN' && (
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup label={'Description'}>
                                  <Controller
                                    name={`description_${item.code}`}
                                    control={control}
                                    defaultValue={data ? data[`description_${item.code}`] : null}
                                    rules={{
                                      required: {
                                        message: 'Field is required.',
                                        value: item.mandatory
                                      },
                                      maxLength: {
                                        message: 'Value in this input is too long.',
                                        value: 2500
                                      }
                                    }}
                                    render={({ field: { name, onChange } }) => {
                                      return (
                                        <>
                                          <TextArea
                                            value={getValues(`description_${item.code}`) || ''}
                                            error={!!errors[`description_${item.code}`]?.message}
                                            name={name}
                                            onChange={onChange}
                                            variant="standard"
                                          />
                                          <p style={errorTextStyle}>
                                            <ErrorMessage
                                              errors={errors}
                                              name={`description_${item.code}`}
                                            />
                                          </p>
                                        </>
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '25px', paddingLeft: '25px' }}>
          { data && <Button
              onClick={() => {
                handleConfirmation?.();
                clearErrors();
              }}
              color="secondary"
          >
            {deleteLabel}
          </Button> }
          <div style={ { flex: '1 0 0' } } />
          <Button
            onClick={() => {
              handleClose?.();
              clearErrors();
            }}
            color="secondary"
          >
            {cancelLabel}
          </Button>
          <Button type="submit" className={commonClasses.blueButton}>
            {saveLabel}
          </Button>
        </DialogActions>
      </form>
        <ConfirmDialog
          handleAgree={handleAgree}
          handleConfirmDialogClose={handleConfirmDialogClose}
          isOpen={showConfirmDialog}
          />
      </>
    </Dialog>
  );
};

export default FormDialogAddEditLibrary;
