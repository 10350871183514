import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSurveysTriggerOptionsState } from './surveys-trigger-options.state';
import { getSurveyTriggerOptions } from './surveys-trigger-options.trunk';
import { SurveyTriggerOptionsInterface } from '../../interfaces/survey.interface';

export const surveyTriggerOptionsSlice = createSlice({
  name: 'surveyTriggerOptions',
  initialState: initialSurveysTriggerOptionsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSurveyTriggerOptions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSurveyTriggerOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(
        getSurveyTriggerOptions.fulfilled,
        (state, action: PayloadAction<SurveyTriggerOptionsInterface[]>) => {
          state.loading = false;
          state.error = false;
          state.entities = action.payload;
        }
      );
  }
});
