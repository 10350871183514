import { ISelectOption } from 'interfaces/ISelectOptions';
import FLAG_US from 'common/images/flag_us.png';
import FLAG_ES from 'common/images/flag_es.png';
import FLAG_FR from 'common/images/flag_fr.png';

const Locales: ISelectOption[] = [
  { label: 'English', value: 'en', icon: FLAG_US },
  { label: 'Spanish', value: 'es', icon: FLAG_ES },
  { label: 'French', value: 'fr', icon: FLAG_FR }
];

export default Locales;
