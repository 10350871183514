import { ErrorInterface } from '../../interfaces/genera.interface';

export interface SaveSurveyResponseState {
  entity: null | any;
  error: false | ErrorInterface;
  loading: boolean;
  success: boolean;
}

export const initialSaveSurveyResponseState: SaveSurveyResponseState = {
  entity: null,
  error: false,
  loading: false,
  success: false
};
