import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Container } from '@mui/system';
import ReactTableContainer from 'components/fragments/ReactTable';
import { IUser } from 'interfaces/IUser';
import UserStatusEnum from '../../../../common/interfaces/IUser';
import { UserProps } from './User.props';
import apiFetch from 'services/apiFetch';
import ViewUserForm from './ViewUserForm';

import useCommonStyles from 'common/styles/common.styles';
import {
  ApiAdminPermitHolderSearch,
  getPermitHolders,
  getPermitHoldersCounter,
  updatePermitHolders
} from '../../../../services/apiAdminPermitHolder';
import { ColumnsProperties } from '../../../fragments/ReactTable/ReactTable.props';
import FormDialog from '../../../fragments/FormDialog';
import { CellProps } from 'react-table';
import Image from '../../../primitives/Image';
import ViewBlackButton from '../../../../common/images/view_black_button.png';
import { useForm } from 'react-hook-form';
import { updateSnackbarMessage } from '../../../../store/reducers/snackbarMessage.reducer';
import { useAppDispatch } from '../../../../app/hooks';
import parsTableHeader from 'common/Utils/parseTableHeader';

const localStorageKey = 'ADMIN_USERS';
const DEFAULT_ORDER_BY = 'email ASC';

const TableHeaders = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    isSelected: true
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    isSelected: true
  },
  {
    Header: 'Email',
    accessor: 'email',
    isSelected: true
  },
  {
    Header: 'Business Name',
    accessor: 'businessName',
    isSelected: true
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    isSelected: true,
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSelected: true,
    Cell: (props: any) => {
      if (props.value) {
        const color = props.value === 'active' ? 'green' : '#E48833';
        return (
          <span style={{ color, textTransform: 'capitalize', fontWeight: '500' }}>
            {props.value.toUpperCase()}
          </span>
        );
      }
      return 'N/A';
    }
  },
  {
    Header: 'Options',
    accessor: 'options',
    isSelected: true,
    filterHidden: true
  }
];

const Users: React.FC<UserProps> = ({ users = null }) => {
  const commonStyles = useCommonStyles();
  const dispatch = useAppDispatch();
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols
    ? parsTableHeader(JSON.parse(localCols), TableHeaders)
    : TableHeaders;
  const [data, setData] = useState<Partial<IUser>[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<any[]>([{ id: 'email', desc: false }]);
  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(true);
  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [openViewUserForm, setOpenViewUserForm] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser>({});
  const [actionType, setActionType] = useState<string>('');

  const commonClasses = useCommonStyles();

  const formEdit = useForm<{
    id: string;
    status: UserStatusEnum;
  }>();

  useEffect(() => {
    // getBusinessPermitHolder('', '', 0, `&orderBy=${DEFAULT_ORDER_BY}`, true);
    if (defColTable) {
      const optidx = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'options');
      const email = defColTable.findIndex((d: ColumnsProperties) => d.accessor === 'email');
      const business = defColTable.findIndex(
        (d: ColumnsProperties) => d.accessor === 'businessName'
      );

      if (email > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[email].Cell = ({ cell }: CellProps<object, any>) => {
          const userInfo: IUser = cell.row.original as IUser;
          return (
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{userInfo.email}</span>}
              placement="left">
              <Typography>{userInfo.email ?? '-'}</Typography>
            </Tooltip>
          );
        };
      }

      if (business > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[business].Cell = ({ cell }: CellProps<object, any>) => {
          const userInfo: IUser = cell.row.original as IUser;

          let name = '-';
          if (userInfo.businessName) {
            name =
              userInfo.businessName.length > 20
                ? userInfo.businessName.slice(0, 20 - 1) + '...'
                : userInfo.businessName;
          }

          return (
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{userInfo.businessName}</span>}
              placement="left">
              <Typography>{name}</Typography>
            </Tooltip>
          );
        };
      }
      if (optidx > -1) {
        // eslint-disable-next-line react/display-name
        defColTable[optidx].Cell = ({ cell }: CellProps<object, any>) => {
          const userInfo: IUser = cell.row.original as IUser;
          return (
            <Button
              variant="outlined"
              size="small"
              className={commonClasses.editButton}
              startIcon={<Image src={ViewBlackButton} sx={{ width: 12, height: 12 }} />}
              onClick={() => {
                setOpenViewUserForm(true);
                setUserInfo(userInfo);
              }}>
              View
            </Button>
          );
        };
      }
    }
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [currentOffset, currentKeyword, currentFilterParams, currentOrderBy]);

  const getAllUsers = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminPermitHolderSearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getPermitHolders(filters)).then((data) => {
      setIsFetchingUsers(false);
      return data;
    });

    if (usersData) {
      // const parsedUsers = parseUser(usersData.data?.response || []);
      setData(usersData.data?.response || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getPermitHoldersCounter(filters));
      setTotalUsers(total.data?.response || 0);
    }
  };

  async function changeUserStatus() {
    const formData = formEdit.getValues();

    const updateUser = await apiFetch(updatePermitHolders(formData));

    if (updateUser) {
      if (updateUser.data.errorCode.code === 0) {
        closeViewUser();
        getAllUsers();
        formEdit.reset();
      } else {
        if (updateUser.data.errorCode) {
          dispatch(
            updateSnackbarMessage({ show: true, message: updateUser.data.errorCode.message })
          );
        }
      }
    }
  }

  function closeViewUser() {
    setActionType('');
    setOpenViewUserForm(false);
    setUserInfo({});
  }

  return (
    <Container disableGutters sx={{ maxWidth: '100% !important', padding: '20px 0 10px 0' }}>
      <Grid container alignItems="center" spacing={2} rowSpacing={2}>
        <Grid item container alignItems="center">
          <ReactTableContainer
            primaryKey="id"
            columns={defColTable}
            data={data}
            isDataFetching={isFetchingUsers}
            module={localStorageKey}
            currSortBy={currentOrderBy}
            totalPage={totalUsers}
            onPageChange={(offset, keyword) => {
              setCurrentOffset(offset);
            }}
            onSearch={(offset, keyword) => {
              setCurrentKeyword(keyword ?? null);
            }}
            filtersData={(filters) => {
              setCurrentFilterParams(filters);
              setCurrentOffset(0);
            }}
            onChangeSort={(filter: string, isSortedDesc: boolean) => {
              const newSortValue = [{ id: filter, desc: isSortedDesc }];
              setCurrentOrderBy(newSortValue);
            }}
          />
        </Grid>
      </Grid>

      <FormDialog
        show={openViewUserForm}
        fullWidth
        initialData={{}}
        hasSaveAndNew={false}
        hasSave={true}
        form="user-form"
        actionType={actionType}
        title={'View User'}
        handleClose={() => {
          closeViewUser();
        }}
        handleSave={() => {
          changeUserStatus();
        }}>
        <ViewUserForm data={userInfo} form={formEdit} />
      </FormDialog>
    </Container>
  );
};

export default Users;
