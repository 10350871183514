import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import SinupLogo from 'common/images/Logo_Signup.png';
import useCommonStyles from 'common/styles/common.styles';
import { IFinishSignup } from './FinishSignup.props';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const FinishSignup: React.FC<IFinishSignup> = ({ onClose, ...props }) => {
  const commonClasses = useCommonStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const listItems = [
    'Login using the newly created credentials',
    'Add a new business',
    'Wait for a confirmation from our team'
  ];

  const paperStyle = {
    position: 'absolute',
    top: '10px',
    right: isMobile ? '10px' : '50px',
    width: isMobile ? 'calc(100vw - 80px)' : 550,
    height: 'auto',
    color: '#555',
    padding: isMobile ? '' : '20px'
  };

  return (
    <Box
      sx={{
        minHeight: '500px',
        position: 'relative'
      }}>
      {!isMobile && (
        <>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
              width: '85%',
              height: '525px',
              margin: '0 auto',
              mt: '50px',
              mr: '40px',
              borderRadius: '10px'
            }}
          />
          <Box
            component="img"
            sx={{
              height: 550,
              width: 534,
              position: 'absolute',
              top: '-17px',
              left: '100px'
            }}
            alt="DHEC"
            src={SinupLogo}
          />
        </>
      )}
      <Paper sx={paperStyle}>
        <Box
          sx={{
            margin: '50px 40px 0'
          }}>
          <Box
            sx={{
              marginBottom: '5px'
            }}>
            <Typography variant="h2">{`One more step.`}</Typography>
          </Box>
          <Box
            sx={{
              marginBottom: '15px'
            }}>
            <Typography
              variant="body2"
              color="#ACACAD">{`Please follow the instructions below to finish up registration`}</Typography>
          </Box>
          <Grid container rowSpacing={2}>
            <Grid item>
              <Typography variant="h6">{`In order to finish up the registration you will need to do the following:`}</Typography>
            </Grid>
            {listItems.map((item, index) => {
              return (
                <Grid item key={index} sx={{ marginLeft: '10px' }}>
                  <Typography variant="h6">{`• ${item}`}</Typography>
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              marginTop: '70px'
            }}>
            <Button
              className={commonClasses.blueButton}
              sx={{
                width: '100%!important'
              }}
              onClick={() => {
                props.cbModal(true, 'showLogin');
              }}>
              {`Proceed to Login`}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default FinishSignup;
