import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      '&.Mui-checked': {
        color: '#6A8C0D'
      },
      '&.Mui-disabled': {
        color: '#c2c2c2'
      }
    }
  }
});
