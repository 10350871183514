import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexDirection: 'column',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: theme.palette.common.white
    },
    page: {
      width: '100%',
      marginTop: '78px',
      marginBottom: '78px',
      minHeight: 'calc(100vh - 262px)', // 'calc(100vh - 220px)',
      overflow: 'auto'
    }
  })
);

export default useStyles;
