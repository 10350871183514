import AdminTabs from 'common/arrays/AdminTabs';
import AdminTabsEnum from 'common/enums/AdminTabsEnum';
import { ITabs, ITabsProps, UserRoles } from 'common/interfaces/ITabs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AdminView from './Admin.view';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import apiFetch from 'services/apiFetch';
import { getAllCounties, getAllRegionalOffice } from 'services/apiEndPoints';
import { store } from 'store';
import { selectAccount } from 'store/reducers/account';
import { selectAdmin, updateAdmin } from 'store/reducers/admin';

const AdminContainer: React.FC = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const app = store.getState();
  const admin = useAppSelector(selectAdmin);

  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('t');
  const [tabs, setTabs] = useState<ITabsProps[] | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const account = useAppSelector(selectAccount);

  useEffect(() => {
    const filterTabs = AdminTabs.filter((tab) => {
      if (account?.role === UserRoles.superAdmin) {
        return true;
      }

      if (account?.role === UserRoles.admin) {
        if (account?.permissions?.find((item) => item === tab.permissions)) {
          return true;
        }
      }

      return false;
    });
    setTabs(filterTabs);
  }, [account]);

  const handleChange = (event: React.SyntheticEvent, tab: AdminTabsEnum) => {
    if (tabs) {
      const idx = tabs.findIndex((p) => p?.type === tab);
      setSelectedTab(idx);
      navigate(`?t=${tabs[idx]?.query}`);
    }
  };

  const getRegionalOffice = async () => {
    const requests = [await apiFetch(getAllRegionalOffice()), await apiFetch(getAllCounties())];

    const res = await Promise.all(requests);

    if (res) {
      const regionItems = res[0].data.response || [];
      dispatch(
        updateAdmin({
          ...admin,
          regionalOfficeSettings: { data: regionItems, counties: res[1].data.response || [] }
        })
      );
    }
  };

  useEffect(() => {
    getRegionalOffice();
  }, []);

  useEffect(() => {
    if (query && tabs && tabs.length > 0) {
      const idx = tabs.findIndex((p) => p?.query === query);
      if (idx > -1) {
        setSelectedTab(idx);
      } else {
        navigate(`?t=${tabs[0]?.query}`);
      }
    }
  }, [navigate, query, tabs]);

  return (
    <>
      {tabs && (
        <AdminView
          tabs={tabs}
          selectedTab={selectedTab}
          handleChange={handleChange}
          adminData={app?.admin || null}
        />
      )}
    </>
  );
};

export default AdminContainer;
