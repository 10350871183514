import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      height: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    blueButton: {
      background: 'radial-gradient(276.12% 167.5% at 14.68% -22.5%, #0CACCF 0%, #126DA2 100%)',
      color: '#fff',
      margin: '5px',
      width: '110px',
      height: '40px',
      padding: '8px, 24px, 8px, 24px',
      boxShadow: '0px 4px 8px 3px rgba(14, 159, 198, 0.15), 0px 1px 3px rgba(16, 137, 183, 0.12)',
      borderRadius: '4px'
    },
    editButton: {
      height: '30px',
      minHeight: 'auto',
      border: 0,
      boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039)',
      fontWeight: '400',
      color: '#49494A'
    }
  };
});

export default useStyles;
