import { IRecipientsTabs } from 'interfaces/IRecipientsTabs';
import SelectRecipientsTabsEnum from '../enums/SelectRecipientsTabsEnum';

const RecipientsTabs: IRecipientsTabs[] = [
  {
    type: SelectRecipientsTabsEnum.GENERALSELECTION,
    title: 'General Selection'
  },
  {
    type: SelectRecipientsTabsEnum.ACCOUNTHOLDERS,
    title: 'Account Holders'
  }
];

export default RecipientsTabs;
