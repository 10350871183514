import React from 'react';
import SendMail from '../SendMail';
import { IMailboxView } from './MailboxView.props';

import { Dialog, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const STEPS = ['Select Recipients', 'Choose Template', 'Review & Send'];

const MailboxView: React.FC<IMailboxView> = ({ handleClose }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={() => {
        handleClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        id: 'removeTabIndex',
        style: {
          backgroundColor: '#FAFAFA',
          boxShadow: 'inherit',
          overflowY: 'auto'
        }
      }}>
      <Typography variant="h4" sx={{ padding: '16px 24px' }}>
        Send Email
      </Typography>

      <IconButton
        onClick={() => {
          handleClose?.();
        }}
        aria-label="dhec-close"
        sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
        <Close sx={{ width: 25, height: 25 }} />
      </IconButton>
      <SendMail handleClose={handleClose} steps={STEPS} />
    </Dialog>
  );
};

export default MailboxView;
