export enum SurveyQuestionTypes {
  TEXT = 'text',
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum SurveyQuestionTypesLabels {
  TEXT = 'Long answer',
  SINGLE = 'Single choice',
  MULTIPLE = 'Multiple choice'
}

export const QUESTION_TYPES = [
  {
    label: 'Open-ended Question',
    value: SurveyQuestionTypes.TEXT
  },
  {
    label: 'Single Choice Question',
    value: SurveyQuestionTypes.SINGLE
  },
  {
    label: 'Multiple Choice Question',
    value: SurveyQuestionTypes.MULTIPLE
  }
];
