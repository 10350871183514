import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      label: {
        '&.Mui-active': {
          color: '#fff'
        }
      }
    }
  };
};
