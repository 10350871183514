import { Container, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import CampaignTabs from 'components/snippets/Admin/Surveys/arrays/CampaignTabs';
import TabWithBadge from 'components/fragments/TabWithBadge';
import CampaignTabsEnum from 'components/snippets/Admin/Surveys/enums/CampaignTabsEnum';
import CampaignDetail from '../CampaignDetails';
import CampaignQuestions from '../CampaignQuestions';
import { useSelector } from 'react-redux';
import { surveysTemplateGetSelector } from '../../store/surveys-template-get/surveys-template-get.selector';

function displayTabContent(tab: CampaignTabsEnum, campaignForm: any) {
  switch (tab) {
    case CampaignTabsEnum.QUESTIONS:
      return (
        <CampaignQuestions
          templateForm={campaignForm}
          disableGutters={true}
          campaign={true}
          handleClose={() => {}}
        />
      );
    default:
      return <CampaignDetail campaignForm={campaignForm} />;
  }
}

const AddCampaignForm: React.FC<any> = ({ campaignForm }) => {
  const [selectedTab, setSelectedTab] = useState<CampaignTabsEnum>(
    CampaignTabs[0].type as CampaignTabsEnum
  );

  const handleChange = (event: React.SyntheticEvent, tab: CampaignTabsEnum) => {
    setSelectedTab(tab);
  };

  const a11yProps = (index: number) => {
    return {
      id: `dhec-tab-${index}`,
      'aria-controls': `dhec-tab-${index}`
    };
  };

  const surveyTemplates = useSelector(surveysTemplateGetSelector);

  useEffect(() => {
    const selectedTemplateId = campaignForm.getValues('survey.templateId');

    if (surveyTemplates && selectedTemplateId !== undefined) {
      const selectedTemplate = surveyTemplates[selectedTemplateId];
      if (selectedTemplate) {
        campaignForm.setValue('template', { ...selectedTemplate });
      }
    }
    return () => {};
  }, [campaignForm.watch('survey.templateId')]);

  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChange}>
            {CampaignTabs.map((tab) => (
              <Tab
                key={`tab-${tab.type}`}
                label={
                  tab.type === CampaignTabsEnum.RESPONSES ? (
                    <TabWithBadge title={`${tab.title}`} count={0} />
                  ) : (
                    `${tab.title}`
                  )
                }
                {...a11yProps(tab.type)}
              />
            ))}
          </Tabs>
        </Box>
        {displayTabContent(selectedTab, campaignForm)}
      </Box>
    </Container>
  );
};

export default AddCampaignForm;
