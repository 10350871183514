import { ErrorInterface } from '../../interfaces/genera.interface';
import { SurveyInterface } from '../../interfaces/survey.interface';

export interface SurveysListState {
  entities: SurveyInterface[];
  error: false | ErrorInterface;
  loading: boolean;
  update: boolean;
}

export const initialSurveysListState: SurveysListState = {
  entities: [],
  error: false,
  loading: false,
  update: false
};
