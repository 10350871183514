import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const config: { headers: AxiosRequestHeaders } & AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_PALMETTO_API,
  headers: {
    'Content-Type': 'application/json'
    // 'Access-Control-Allow-Origin': '*'
  },
  responseType: 'json'
};

export default config;
