import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import Typo from '../Typo';
import { FormGroupProps } from './FormGroup.props';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const FormGroup: React.FC<FormGroupProps> = ({
  label,
  color = 'textSecondary',
  error,
  fontWeight = 600,
  variant = 'body2',
  ...props
}: FormGroupProps) => {
  return (
    <Box {...props}>
      <Typo mb="5px" color={color} variant={variant} fontWeight={fontWeight}>
        {label}
      </Typo>
      {props.children}
      <Box
        sx={{
          display: `${error ? 'flex' : 'none'}`,
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '4px'
        }}>
        <ErrorOutlineOutlinedIcon color="error" sx={{ fontSize: '16px', mr: '6px' }} />
        <FormHelperText error={!!error} sx={{ mt: 0 }}>
          {error?.message}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default FormGroup;
