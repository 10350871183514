  const limitZipLength = (value: any, maxZipChar: number) => {
    if (!value) return '';
    const zipCode = value.toString().replace(/[^\d]/g, '');
    if (zipCode.length <= maxZipChar) {
      return zipCode
    } else {
      const result = Number(zipCode.slice(0,maxZipChar));
      return result
    }
  };

export default limitZipLength;
