import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, FormHelperText, Grid, Paper, Typography } from '@mui/material';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import { Container } from '@mui/system';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import LockIcon from 'common/images/lock_icon.png';
import { IEditUserForm } from './EditUserForm.props';
import _ from 'lodash';
import apiFetch from 'services/apiFetch';
import { changePassword, changePasswordEditProfile } from 'services/apiEndPoints';
import { useAppSelector } from 'app/hooks';
import { useFormContext, Controller, useForm } from 'react-hook-form';
import { selectAccount } from 'store/reducers/account';
import { formatPhone, limitZipLength } from 'common/Utils/String';
import { loadingStyle } from '../../../common/styles/loading.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { selectTokens } from '../../../store/reducers/token';

const MINIMUM_PASSWORD_LENGTH = 6;

const EditUserForm: React.FC<IEditUserForm> = (props: IEditUserForm) => {
  const {
    getValues,
    trigger,
    control,
    formState: { errors, isValid }
  }: any = useFormContext();

  const commonClasses = useCommonStyles();
  const account = useAppSelector(selectAccount);
  const tokenScope = useAppSelector(selectTokens).scope || null;
  const [security, setSecurity] = useState<any>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [secErrMsg, setSecErrMsg] = useState<string>('');
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [updatePasswordBtnDisabled, setUpdatePasswordBtnDisabled] = useState<boolean>(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<any>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState<string>('');
  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = useAppSelector(selectTokens) || null;
  useEffect(() => {
    if (
      security.confirmPassword.length < MINIMUM_PASSWORD_LENGTH &&
      security.confirmPassword.length >= 1
    ) {
      setConfirmPasswordErrMsg(`Password must be atleast ${MINIMUM_PASSWORD_LENGTH} characters.`);
    } else if (security.confirmPassword.length === 0) {
      setConfirmPasswordErrMsg('');
    } else if (security.confirmPassword.length >= 6) {
      setConfirmPasswordErrMsg('');
    }

    if (security.newPassword.length < MINIMUM_PASSWORD_LENGTH && security.newPassword.length >= 1) {
      setNewPasswordErrMsg(`Password must be atleast ${MINIMUM_PASSWORD_LENGTH} characters.`);
    } else if (security.newPassword.length === 0) {
      setNewPasswordErrMsg('');
    } else if (security.newPassword.length >= 6) {
      setNewPasswordErrMsg('');
    }

    if (
      security.oldPassword.length > 0 &&
      security.newPassword.length &&
      security.confirmPassword.length
    ) {
      setUpdatePasswordBtnDisabled(false);
    } else {
      setUpdatePasswordBtnDisabled(true);
    }
  }, [security]);

  const isMailVisible = account?.role === 'permitholder';

  const onChangeInputs = (field: string, value: string) => {
    setSecurity({ ...security, [field]: value });
  };

  const updatePassword = async () => {
    const toSave = { oldPassword: security?.oldPassword, password: security?.newPassword };

    const res = await apiFetch(changePasswordEditProfile(toSave, token.id));

    if (res) {
      setIsLoading(false);
      if (res.data.errorCode.code > 0) setSecErrMsg(res.data.errorCode.message);
      else {
        setIsLoading(false);
        setSecurity({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        setSecErrMsg('');
        setIsUpdateSuccess(true);
        setTimeout(() => setIsUpdateSuccess(false), 5000);
      }
    }
  };

  const onHandleSavePassword = () => {
    setUpdatePasswordBtnDisabled(true);
    if (Object.keys(security).length > 0) {
      if (!security.oldPassword) setSecErrMsg('Old password is required');
      if (_.isEqual(security?.newPassword, security?.confirmPassword)) {
        setIsLoading(true);
        updatePassword();
      } else setSecErrMsg('New password and confirm password did not match!');
    }
  };

  return (
    <Container>
      <Grid container spacing={1} rowSpacing={2}>
        {isLoading && (
          <Box sx={loadingStyle}>
            <CircularProgress />
          </Box>
        )}
        <Grid container item lg={12} alignItems="center" direction="row">
          <Grid container item alignItems="center" direction="row" lg={6}>
            <Typography variant="h6" color="textSecondary">
              General
            </Typography>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`First Name`}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue={props.firstName}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('firstName') || ''}
                          error={!!errors.firstName?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.firstName?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`Last Name`}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue={props.lastName}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('lastName') || ''}
                          error={!!errors.lastName?.message}
                          name={name}
                          onChange={onChange}
                          helperText={errors.lastName?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`Phone Number`}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={props.phoneNumber}
                    rules={{
                      required: 'Field is required.',
                      maxLength: { message: 'Value in this input is too long.', value: 45 },
                      pattern: {
                        value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
                        message: 'Invalid phone number.'
                      }
                    }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('phoneNumber') || ''}
                          error={!!errors.phoneNumber?.message}
                          name={name}
                          onChange={(e) => onChange(formatPhone(e.target.value))}
                          helperText={errors.phoneNumber?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormGroup label={`Email Address`}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={props.email}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange } }) => {
                      return (
                        <TextField
                          value={getValues('email') || ''}
                          error={!!errors.email?.message}
                          name={name}
                          disabled={true}
                          onChange={onChange}
                          helperText={errors.email?.message}
                          variant="standard"
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {isMailVisible && (
          <>
            <Grid container item lg={12} alignItems="center" columnSpacing={4}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Mailing Address
                </Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12}>
              <Paper sx={{ width: '100%', padding: 2 }}>
                <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FormGroup label={`Street Address`}>
                      <Controller
                        name="addressStreet"
                        control={control}
                        defaultValue={props.addressStreet}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressStreet') || ''}
                              error={!!errors.addressStreet?.message}
                              name={name}
                              onChange={onChange}
                              helperText={errors.addressStreet?.message}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FormGroup label={`Apartment, Suite, Unit, etc.`}>
                      <Controller
                        name="addressNumber"
                        control={control}
                        defaultValue={props.addressNumber}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressNumber') || ''}
                              error={!!errors.addressNumber?.message}
                              name={name}
                              onChange={onChange}
                              helperText={errors.addressNumber?.message}
                              variant="standard"
                              placeholder={'Optional'}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`State`}>
                      <Controller
                        name="addressState"
                        control={control}
                        defaultValue={props.addressState}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressState') || ''}
                              error={!!errors.addressState?.message}
                              name={name}
                              onChange={onChange}
                              helperText={errors.addressState?.message}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`County`}>
                      <Controller
                        name="addressCounty"
                        control={control}
                        defaultValue={props.addressCounty}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressCounty') || ''}
                              error={!!errors.addressCounty?.message}
                              name={name}
                              onChange={onChange}
                              helperText={errors.addressCounty?.message}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`City`}>
                      <Controller
                        name="addressCity"
                        control={control}
                        defaultValue={props.addressCity}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressCity') || ''}
                              error={!!errors.addressCity?.message}
                              name={name}
                              onChange={onChange}
                              helperText={errors.addressCity?.message}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Zip Code`}>
                      <Controller
                        name="addressZip"
                        control={control}
                        defaultValue={props.addressZip}
                        rules={{
                          required: 'Field is required.',
                          minLength: { value: 5, message: 'Zip code must be 5 digits' },
                          maxLength: { value: 5, message: '' }
                        }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <TextField
                              value={getValues('addressZip') || ''}
                              error={!!errors.addressZip?.message}
                              name={name}
                              onChange={(e) =>
                                onChange(limitZipLength(e.target.value, 5).toString())
                              }
                              helperText={errors.addressZip?.message}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
        <Grid lg={12} item container justifyContent="flex-end">
          <Button
            className={commonClasses.blueButton}
            // style={{ display: Object.keys(security).length > 0 ? '' : 'none' }}
            onClick={() => {
              const values = getValues();
              if (isValid) {
                props?.handleSaveForm(values);
              } else {
                trigger();
              }
            }}>
            {'Save'}
          </Button>
        </Grid>
        {tokenScope !== 'editProfile' && (
          <>
            <Grid container item lg={12} alignItems="center" direction="row">
              <Grid container item alignItems="center" direction="row" lg={6}>
                <Typography variant="h6" color="textSecondary">
                  Change password
                </Typography>
                <Avatar src={LockIcon} sx={{ width: 20, height: 20 }} />
              </Grid>
            </Grid>
            {secErrMsg && (
              <Grid container item lg={12}>
                <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                  {secErrMsg}
                </FormHelperText>
              </Grid>
            )}
            {isUpdateSuccess && (
              <Grid container item lg={12}>
                <FormHelperText sx={{ mt: 1, lineHeight: '12px', color: 'green' }}>
                  {`Update Successful`}!
                </FormHelperText>
              </Grid>
            )}
            <Grid container item lg={12}>
              <Paper sx={{ width: '100%', padding: 2 }}>
                <Grid container item lg={12} columnSpacing={1} rowSpacing={2} direction="column">
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FormGroup label={`Old Password`} id={'oldPassword123123'}>
                      <TextField
                        type="password"
                        value={security?.oldPassword || ''}
                        onChange={(e) => onChangeInputs('oldPassword', e.target.value)}
                        autoComplete="new-password"
                        inputProps={{ autoComplete: 'new-password' }}
                      />
                      <p style={errorTextStyle}>{passwordErrorMessage.oldPassword}</p>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FormGroup label="New Password">
                      <TextField
                        type="password"
                        value={security?.newPassword || ''}
                        onChange={(e) => onChangeInputs('newPassword', e.target.value)}
                        autoComplete="new-password"
                      />
                      <p style={errorTextStyle}>{newPasswordErrMsg}</p>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FormGroup label="Confirm Password">
                      <TextField
                        type="password"
                        value={security?.confirmPassword || ''}
                        onChange={(e) => onChangeInputs('confirmPassword', e.target.value)}
                        autoComplete="new-password"
                      />
                      <p style={errorTextStyle}>{confirmPasswordErrMsg}</p>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid lg={12} item container justifyContent="flex-end">
              <Button
                className={commonClasses.blueButton}
                style={{ width: '200px' }}
                disabled={updatePasswordBtnDisabled}
                onClick={() => onHandleSavePassword()}>
                Save new password
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default EditUserForm;
