import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import RegionalOfficeState from 'store/state/RegionalOfficeState';

const DEFAULT_STATE: RegionalOfficeState = {
  counties: [],
  data: []
};

export const regionalOfficeSlice = createSlice({
  name: 'regionalOffice',
  initialState: DEFAULT_STATE,
  reducers: {
    updateregionalOffice: (state, action: PayloadAction<RegionalOfficeState>) => {
      return action.payload || DEFAULT_STATE;
    },
    clearRegionalOffice: (state, action: PayloadAction<RegionalOfficeState>) => {
      return action.payload || DEFAULT_STATE;
    }
  }
});

export const { updateregionalOffice, clearRegionalOffice } = regionalOfficeSlice.actions;

export const selectRegionalOffice = (state: RootState) => state.regionalOffice;

export default regionalOfficeSlice.reducer;
