import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../services/apiFetch';
import { saveSurveyResponseByToken } from '../../services/survey.service';
import { SaveSurveyResponseDataInterface } from '../../interfaces/survey.interfaces';

export const saveSurveyResponseGuest = createAsyncThunk(
  'saveSurveyResponse',
  async (
    data: { token: string; response: SaveSurveyResponseDataInterface },
    { rejectWithValue }
  ) => {
    const res = await apiFetch(saveSurveyResponseByToken(data.token, data.response));
    if (res.data?.errorCode.code === 0) {
      return res.data?.response || null;
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
