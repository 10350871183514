import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import TokenState from 'store/state/TokenState';

const initialState: TokenState = {
  expiresIn: 0,
  createdAt: 0,
  id: '',
  ttl: 0,
  userID: '',
  user: undefined,
  scope: ''
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<TokenState>) => {
      return action.payload || initialState;
    },
    clearTokenState: (state, action: PayloadAction<TokenState>) => {
      localStorage.removeItem('persist:root');
      return action.payload || initialState;
    },
    updateMagicToken: (state, action: PayloadAction<{ token: string }>) => {
      state.id = action.payload.token || '';
      state.scope = 'editProfile';
    }
  }
});

export const { updateToken, clearTokenState, updateMagicToken } = tokenSlice.actions;

export const selectTokens = (state: RootState) => state.token;

export default tokenSlice.reducer;
