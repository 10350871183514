import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ErrorMessage } from '@hookform/error-message';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import SelectInput from 'components/primitives/SelectInput';
import TextArea from 'components/primitives/TextArea';
import DatePicker from 'components/primitives/DatePicker';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { surveysTemplateGetSelectorSelect } from '../../store/surveys-template-get/surveys-template-get.selector';
import { useAppDispatch } from '../../../../../../app/hooks';
import { getSurveyTemplate } from '../../store/surveys-template-get/surveys-template-get.trunk';
import useCommonStyles, { errorTextStyle } from '../../../../../../common/styles/common.styles';
import SurveyRecipientsFormDialog from '../../Fragments/SelectRecipientSurveyDialog/SelectRecipientSurveyDialog.view';

import {
  selectSurveysRecipients,
  selectSurveysRecipientsString
} from '../../store/surveys-recipients/surveys-recipients.selector';
import RecipientsTabsEnum from '../../../../../../common/enums/SelectRecipientsTabsEnum';
import RecipientsTabs from '../../../../../../common/arrays/RecipientsTabs';
import {
  selectSurveysCountiesLabel,
  selectSurveysCountiesValue
} from '../../store/survey-counties/surveys-counties.selector';

const CampaignDetail: React.FC<any> = ({ campaignForm }) => {
  const dispatch = useAppDispatch();
  const commonClasses = useCommonStyles();
  const [showRecipients, setShowRecipients] = useState<boolean>(false);
  const surveyTemplatesSelect = useSelector(surveysTemplateGetSelectorSelect);
  const selectedRecipients = useSelector(selectSurveysRecipients);
  const selectedRecipientsString = useSelector(selectSurveysRecipientsString);
  const selectedCountiesLabel = useSelector(selectSurveysCountiesLabel);
  const selectedCountiesValue = useSelector(selectSurveysCountiesValue);
  const [selectedRecipientsTab, setSelectedRecipientsTab] = useState<RecipientsTabsEnum>(
    RecipientsTabs[0].type as RecipientsTabsEnum
  );

  useEffect(() => {
    campaignForm.setValue('survey.recipients', selectedRecipients);
  }, [selectedRecipientsString]);

  useEffect(() => {
    campaignForm.setValue('survey.counties', selectedCountiesValue);
  }, [selectedCountiesValue]);

  useEffect(() => {
    dispatch(getSurveyTemplate(null));
    campaignForm.setValue('display', 0);
  }, []);

  return (
    <Container disableGutters>
      <Grid container spacing={1} rowSpacing={2} sx={{ marginTop: '16px' }}>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={'Select Template'}>
                  <Controller
                    name={'survey.templateId'}
                    control={campaignForm.control}
                    render={({ field: { onChange, value, name } }) => {
                      let dV = null;
                      if (
                        surveyTemplatesSelect &&
                        surveyTemplatesSelect.length &&
                        value !== undefined
                      ) {
                        dV = surveyTemplatesSelect.filter((option: any) => value === option.value);
                      }
                      return (
                        <SelectInput
                          defaultValue={dV}
                          placeholder={'Select Template'}
                          options={surveyTemplatesSelect}
                          onChange={(e) => {
                            campaignForm.setValue(name, e.value);
                            return onChange(e.value);
                          }}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={'Title'}>
                  <Controller
                    name="survey.title"
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <TextField
                            defaultValue={value}
                            error={!!campaignForm.formState.errors[name]?.message}
                            name={name}
                            placeholder={'Enter Campaign Title'}
                            onChange={onChange}
                            variant="standard"
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={'Description'}>
                  <Controller
                    name="survey.description"
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <TextArea
                            defaultValue={value}
                            error={!!campaignForm.formState.errors[name]?.message}
                            name={name}
                            placeholder={'Enter Campaign Description'}
                            onChange={onChange}
                            variant="standard"
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" color="textSecondary">
            Schedule Availability
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item columnSpacing={1} rowSpacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormGroup label={`Start Date`}>
                  <Controller
                    name={`survey.startDate`}
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, value } }) => {
                      let DV = value ? value : null;
                      return (
                        <>
                          <DatePicker
                            disablePast
                            inputFormat="MM-DD-YYYY"
                            value={DV}
                            onChange={(date: Date | null | any) => {
                              campaignForm.setValue(name, date);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            readOnly={false}
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormGroup label={`End Date`}>
                  <Controller
                    name={`survey.endDate`}
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, value } }) => {
                      let DV = value ? value : null;
                      return (
                        <>
                          <DatePicker
                            minDate={campaignForm.getValues('survey.startDate')}
                            disablePast
                            inputFormat="MM-DD-YYYY"
                            value={DV}
                            onChange={(date: Date | null | any) => {
                              campaignForm.setValue(name, date);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            readOnly={false}
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" color="textSecondary">
            Delivery & Recipients
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={'Subject'}>
                  <Controller
                    name="survey.subject"
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <TextField
                            defaultValue={value}
                            error={!!campaignForm.formState.errors[name]?.message}
                            name={name}
                            placeholder={'Enter subject'}
                            onChange={onChange}
                            variant="standard"
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={'Description'}>
                  <Controller
                    name="survey.content"
                    control={campaignForm.control}
                    rules={{ required: 'Field is required.' }}
                    render={({ field: { name, onChange, value } }) => {
                      return (
                        <>
                          <CKEditor
                            editor={ClassicEditor}
                            data={value}
                            config={{
                              toolbar: {
                                items: [
                                  'heading',
                                  '|',
                                  '|',
                                  'bold',
                                  'italic',
                                  '|',
                                  'link',
                                  '|',
                                  'outdent',
                                  'indent',
                                  '|',
                                  'bulletedList',
                                  'numberedList',
                                  '|',
                                  'insertTable'
                                ],
                                shouldNotGroupWhenFull: true
                              }
                            }}
                            onChange={(event: any, editor: any) => {
                              const data = editor.getData();
                              campaignForm.setValue(name, data);
                            }}
                          />
                          <p style={errorTextStyle}>
                            <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                          </p>
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item container xs={12} sm={12} md={12} lg={12}>
                {selectedCountiesValue?.length > 0 ? (
                  <Grid item sx={{ flexGrow: 1 }}>
                    <FormGroup label={'Counties'}>
                      <Controller
                        name={`survey.counties`}
                        control={campaignForm.control}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <>
                              <TextField
                                key={selectedCountiesValue}
                                defaultValue={selectedCountiesLabel}
                                error={!!campaignForm.formState.errors[name]?.message}
                                name={name}
                                onChange={onChange}
                                variant="standard"
                              />
                              <p style={errorTextStyle}>
                                <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                              </p>
                            </>
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                ) : (
                  <Grid item sx={{ flexGrow: 1 }}>
                    <FormGroup label={'Recipients'}>
                      <Controller
                        name={`survey.recipients`}
                        control={campaignForm.control}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange } }) => {
                          return (
                            <>
                              <TextField
                                key={selectedRecipientsString}
                                defaultValue={selectedRecipientsString}
                                error={!!campaignForm.formState.errors[name]?.message}
                                name={name}
                                onChange={onChange}
                                variant="standard"
                              />
                              <p style={errorTextStyle}>
                                <ErrorMessage errors={campaignForm.formState.errors} name={name} />
                              </p>
                            </>
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    className={commonClasses.addButton}
                    onClick={(e) => {
                      setShowRecipients(true);
                    }}>
                    <ControlPointIcon />
                  </Button>
                </Grid>

                {showRecipients && (
                  <SurveyRecipientsFormDialog
                    show={showRecipients}
                    title={'Select Survey Recipients'}
                    handleClose={() => {
                      setShowRecipients(false);
                    }}
                    handleSave={() => {
                      setShowRecipients(false);
                    }}
                    selectedTab={selectedRecipientsTab}
                    setSelectedTab={setSelectedRecipientsTab}></SurveyRecipientsFormDialog>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CampaignDetail;
