import React, { useState, useTransition } from 'react';
import { Container } from '@mui/system';
import _ from 'lodash';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import { IFilterColumns } from './FilterColumns.props';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { styled } from '@mui/styles';
import useStyles from './FilterColumns.styles';
import TextField from 'components/primitives/TextField';
import { getAllBusinessDirItemsForFilter, getItemsForFilter } from 'services/apiEndPoints';
import { Nullable } from 'common/Utils/Nullable';
import { Waypoint } from 'react-waypoint';
import apiFetch from 'services/apiFetch';
import { getAdminUsersItemsForFilter } from 'services/superAdminEndPoints';
import {
  getAdminStaffsForFilter,
  getAllBusinessesItemsForFilter,
  getAllStaffsForFilter
} from 'services/permitHolderEndPoints';
import { getAdminLibraryForFilter } from '../../../../services/apiAdminLibrary';
import { getAdminPermitNumberForFilter } from '../../../../services/apiAdminPermitNumbers';
import { getAdminMailboxForFilter } from '../../../../services/apiAdminMailbox';

const LIMIT_PER_PAGE = 10;
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const FilterColumns: React.FC<IFilterColumns> = ({
  title,
  subtitle = 'None Selected',
  module = '',
  items = [],
  selectedFilters = [],
  primaryKey = '',
  handleFilterChange,
  returnCountyNameById,
  customData
}) => {
  const classes = useStyles();
  const [isPending, startTransition] = useTransition();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [searchText, setSearchText] = useState<Nullable<string>>(null);
  const [searchData, setSearchData] = useState<any>([]);
  const [itemPage, setItemPage] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<any>(selectedFilters);
  const [pTotalPage, setPTotalPage] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [isFetchingFilters, setIsFetchingFilters] = useState<boolean>(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const fetchFilterItems = async (
    filter: string,
    keyword: string,
    offset: number = 0,
    isLoadMore: boolean = false
  ) => {
    let res = null;
    if (module === 'ADMIN_USERS' || module === 'ADMIN_MAILBOX_USERS') {
      res = await apiFetch(getItemsForFilter(filter, keyword, offset));
    } else if (module === 'ADMIN_USER_MGMNT') {
      res = await apiFetch(getAdminUsersItemsForFilter(filter, keyword, offset));
    } else if (module === 'PH_BUSINESSES') {
      res = await apiFetch(getAllBusinessesItemsForFilter(filter, keyword, offset));
    } else if (module === 'ADMIN_BUSINESS_DIRECTORY') {
      res = await apiFetch(getAllBusinessDirItemsForFilter(filter, keyword, offset));
    } else if (module === 'PH_STAFF_DIRECTORY') {
      res = await apiFetch(getAllStaffsForFilter(filter, keyword, offset));
    } else if (module === 'PH_STAFF_DIRECTORY_ADMIN') {
      res = await apiFetch(getAdminStaffsForFilter(filter, keyword, offset, customData.businessID));
    } else if (module === 'ADMIN_LIBRARY') {
      res = await apiFetch(getAdminLibraryForFilter(filter, keyword, offset));
    } else if (module === 'ADMIN_PERMIT_NUMBERS') {
      res = await apiFetch(getAdminPermitNumberForFilter(filter, keyword, offset));
    } else if (module === 'ADMIN_MAILBOX') {
      res = await apiFetch(getAdminMailboxForFilter(filter, keyword, offset));
    }

    setIsFetchingFilters(false);
    if (res && res.status === 200) {
      if (res.data.response.length < 10) {
        setDisableInfiniteScroll(true);
      }
      if (!isLoadMore) {
        if (keyword) {
          setData(res?.data?.response || []);
        } else {
          setData(res?.data?.response || []);
        }
      } else {
        if (keyword) {
          const newData = [...searchData, ...res.data.response];
          setSearchData(newData);
        } else {
          const newData = [...data, ...res.data.response];
          setData(newData);
        }
      }
    }
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setSearchText(null);
    setSearchData([]);
    if (newExpanded) {
      setOffset(0);
      setDisableInfiniteScroll(false);
      if (panel !== 'status') {
        setIsFetchingFilters(true);
        fetchFilterItems(panel, '');
      }
    }
    setExpanded(newExpanded ? panel : false);
  };

  const handleClearFilter = () => {
    const keys = Object.keys(selectedItems);
    const clonedObj: any = { ...selectedItems };

    if (keys.length > 0) {
      const res = keys.forEach((k) => {
        delete clonedObj[k];
      });

      setSelectedItems(clonedObj);
      if (handleFilterChange) handleFilterChange(clonedObj);
    }
  };

  return (
    <Container disableGutters maxWidth="lg">
      <Box className={classes.headerContainer}>
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 300 }}>
            {subtitle}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="small"
            style={{ display: !Object.keys(selectedItems).length ? 'none' : '' }}
            onClick={handleClearFilter}>
            Clear
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box>
        {items.map((f: ColumnsProperties, idx: number) => {
          let subItems: any[] = [];

          if (f.accessor === 'status') {
            subItems = [
              { id: 'active', status: 'Active' },
              { id: 'removed', status: 'Removed' }
            ];
          } else {
            subItems = _.uniqBy(data, f.accessor);
          }

          subItems = subItems.filter((item) => {
            return !(item && item[f.accessor] == null);
          });

          return (
            <Accordion
              key={idx}
              className={classes.accordionRoot}
              expanded={expanded === f.accessor}
              onChange={handleChange(f.accessor)}
              disabled={!f.isSelected}
              sx={{ mb: '0', display: f?.filterHidden ? 'none' : '' }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '12px' }} />}
                className={classes.accordionSummary}>
                <Typography>
                  {f.Header} ({(selectedItems[`${f.accessor}`] || []).length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                <TextField
                  size="small"
                  placeholder={`Search ${f.Header}`}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSearchText(value);
                    if (value.length === 0) {
                      setOffset(0);
                      setDisableInfiniteScroll(false);
                    }
                    setTimeout(() => {
                      fetchFilterItems(f.accessor, value, 0, false);
                    }, 500);
                  }}
                />
                {isPending && isFetchingFilters && (
                  <div className={classes.noDataContainer}>Loading...</div>
                )}
                {subItems.length === 0 ? (
                  <div className={classes.noDataContainer}>No Data</div>
                ) : (
                  subItems.map((a: any, i: number) => {
                    let countyName;
                    if (f.accessor === 'COU_CODE') {
                      if (returnCountyNameById) {
                        countyName = returnCountyNameById(a[f.accessor].toString());
                      }
                    }
                    const dIdx = (selectedItems[f.accessor] || []).findIndex((d: any) => {
                      return d.value === a[f.accessor];
                    });

                    const isChecked = dIdx > -1;

                    return (
                      <Stack key={i} direction="row" className={classes.listItemContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isChecked}
                              disableRipple
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const cloneObj: any = { ...selectedItems };
                                const items = cloneObj[f.accessor] || [];

                                let index = a.id ? a.id : i;

                                if (items.length > 0) {
                                  const idx = items.findIndex((itm: any) => itm.index === index);
                                  if (idx === -1) {
                                    items.push({ index: index, value: a[f.accessor] });
                                    cloneObj[f.accessor] = [...items];
                                  } else {
                                    items.splice(idx, 1);

                                    // if nothing is selected remove key
                                    if (items.length === 0) delete cloneObj[f.accessor];
                                  }
                                } else {
                                  items.push({ index: index, value: a[f.accessor] });
                                  cloneObj[f.accessor] = [...items];
                                }

                                setSelectedItems(cloneObj);

                                if (handleFilterChange) {
                                  handleFilterChange(cloneObj);
                                }
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              {f.accessor === 'COU_CODE' ? countyName : a[f.accessor]}
                            </Typography>
                          }
                          sx={{ ml: 0 }}
                        />
                        {i === subItems.length - 3 && !disableInfiniteScroll && (
                          <Waypoint
                            onEnter={() => {
                              if (expanded === f.accessor) {
                                startTransition(() => {
                                  const nOffset = offset + LIMIT_PER_PAGE;
                                  setOffset(nOffset);
                                  fetchFilterItems(f.accessor, searchText || '', nOffset, true);
                                });
                              }
                            }}
                          />
                        )}
                      </Stack>
                    );
                  })
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
};

export default FilterColumns;
