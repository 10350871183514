import React, { useEffect, useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { selectAccount } from 'store/reducers/account';
import { selectmyAccount } from 'store/reducers/myAccount';
import { Provider } from 'react-redux';

import MainLayoutView from 'components/layouts/MainLayout';
import AdminTabsEnum from 'common/enums/AdminTabsEnum';
import BusinessesDirectory from 'components/snippets/Admin/BusinessDirectory';
import SurveysView from 'components/snippets/Admin/Surveys';
import Users from 'components/snippets/Admin/Users';
import Library from 'components/snippets/Admin/Library';
import Mailbox from 'components/snippets/Admin/MailBox';
import { IAdmin } from './Admin.props';
import { ITabs } from 'common/interfaces/ITabs';
import AdminState from 'store/state/AdminState';
import { useAppSelector } from 'app/hooks';
import MyAccount from 'components/snippets/MyAccount';
import UserManagement from 'components/snippets/Admin/UserManagement';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>{children}</Box>}
    </div>
  );
}

function displayTabContent(tab: ITabs, adminData: AdminState) {
  switch (tab.type) {
    case AdminTabsEnum.MAILBOX:
      return <Mailbox />;
    case AdminTabsEnum.PERMIT_HOLDERS:
      return <Users users={adminData?.users || null} />;
    case AdminTabsEnum.LIBRARY:
      return <Library />;
    case AdminTabsEnum.SURVEYS:
      return <SurveysView />;
    case AdminTabsEnum.USERS:
      return <UserManagement />;
    default:
      return <BusinessesDirectory data={adminData?.businessDirectory || null} />;
  }
}

const AdminView: React.FC<IAdmin> = ({ tabs = [], adminData, selectedTab = null }) => {
  const account = useAppSelector(selectAccount);
  const myAccount = useAppSelector(selectmyAccount);
  const [value, setValue] = useState(selectedTab);
  const navigate = useNavigate();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    navigate(`?t=${tabs[newValue]?.query}`);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  return (
    <MainLayoutView>
      <Container maxWidth="xl" sx={{ height: '100%', padding: '20px 0' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {<MyAccount {...account} />}
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{ display: myAccount?.show ? 'none' : '' }}
            >
              {tabs.map((tab) => {
                return <Tab key={`tab-${tab.type}`} label={tab.locale || ''} />;
              })}
            </Tabs>

            {tabs.map((tab, index) => {
              return (
                <TabPanel key={index} value={value} index={index}>
                  {displayTabContent(tab, adminData)}
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      </Container>
    </MainLayoutView>
  );
};

export default AdminView;
