import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import useStyles from './RadioSelection.styles';
import { RadioSelectionProps } from './RadioSelection.props';
import { IRadioSelection } from 'interfaces/IRadioSelection';

const RadioSelection: React.FC<RadioSelectionProps> = ({
  selections = [],
  value = 0,
  handleChange,
  lgStyle = 4
}) => {
  const classes = useStyles();
  return (
    <RadioGroup row>
      <Grid container spacing={2}>
        {selections.length > 0 &&
          selections.map((item: IRadioSelection) => {
            const isSelected = value === item?.value;

            return (
              <Grid key={item.value} item lg={lgStyle}>
                <Box
                  onClick={() => handleChange(item?.value)}
                  className={classes.wrapper}
                  sx={{
                    borderColor: isSelected ? '#0CACCF' : 'rgba(48, 48, 49, 0.1)',
                    backgroundColor: isSelected ? '#0CACCF' : '#FAFAFA',
                    color: isSelected ? '#fff' : '#303031'
                  }}>
                  <Radio
                    checked={isSelected}
                    value={item.value}
                    name="radio-buttons"
                    disableRipple
                    classes={{ root: classes.radio, checked: classes.checked }}
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  <Typography variant="body1">{item.label}</Typography>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </RadioGroup>
  );
};

export default RadioSelection;
