import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Avatar,
  Typography,
  Grid,
  MenuItem,
  ListItemIcon,
  Menu as MuiMenu,
  ListItemText
} from '@mui/material';
import _ from 'lodash';
import useStyles from './Header.styles';
import AppLogo from 'common/images/logo.png';
import VideoIcon from 'common/images/video.png';
import DocumentIcon from 'common/images/Document.png';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'components/fragments/FormDialog';
import FormDialogEditProfile from 'components/fragments/FormDialogEditProfile';

import Signup from 'components/snippets/Signup';
import FinishSignup from 'components/snippets/FinishSignup';
import Menu from '../Menu';
import useCommonStyles from 'common/styles/common.styles';
import MenuPages from 'common/arrays/MenuPages';
import { HeaderProps } from './Header.props';
import AvatarIcon from 'common/images/avatar.png';
import Image from 'components/primitives/Image';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import UserMenu from '../UserMenu';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EditUserForm from '../EditUserForm';
import { IUser } from 'interfaces/IUser';
import apiFetch from 'services/apiFetch';
import { updateAccountInfo } from 'services/apiEndPoints';
import GoogleTranslate from '../GoogleTranslate/GoogleTranslate.view';
import HomepageModalsState from 'store/state/HomepageModalsState';
import { store } from 'store';
import {
  selecthomepageModals,
  selectHomepageModalsLogin,
  updatehomepageModals
} from 'store/reducers/homepageModals';
import { clearAccount, selectAccount, updateAccount } from 'store/reducers/account';
import { clearTokenState } from 'store/reducers/token';
import { updateSnackbarMessage } from '../../../store/reducers/snackbarMessage.reducer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header: React.FC<HeaderProps> = ({ isLoggedIn = false }) => {
  const dispatch = useAppDispatch();
  const state = store.getState();
  const { myAccount, account, homepageModals } = state;

  const showLogin = useAppSelector(selectHomepageModalsLogin);
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const navigate = useNavigate();
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [amAnchorEl, setAMAnchorEl] = useState<null | HTMLElement>(null);

  const [toUpdateUserInfo, setToUpdateUserInfo] = useState<IUser>({});

  const openMenu = Boolean(anchorEl);
  const openAccountMenu = Boolean(amAnchorEl);
  const defaultModal = {
    showLogin: false,
    showRegister: false,
    showContact: false,
    showResetPassword: false,
    showFinishSignup: false
  };
  const [modalStatus, setModalStatus] = useState<HomepageModalsState>(defaultModal);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setModalStatus(homepageModals);
  }, [homepageModals, modalStatus, showLogin]);

  const cbModal = (value: boolean, name: string) => {
    const payload = {
      ...defaultModal,
      [name]: value
    };
    setModalStatus(payload);
    dispatch(updatehomepageModals(payload));
  };

  useEffect(() => {
    setToUpdateUserInfo(account || {});
  }, [account]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInputChange = (field: string, value: any) => {
    const cloneObj = { ...toUpdateUserInfo };
    cloneObj[field] = value;

    setToUpdateUserInfo(cloneObj);
  };

  const handleSaveForm = async (values: any) => {
    try {
      setLoading(true);
      const res = await apiFetch(updateAccountInfo(values));
      setLoading(false);
      if (res) {
        dispatch(updateAccount(res.data.response));
        setShowEditProfile(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        dispatch(updateSnackbarMessage({ show: true, message: error.message }));
      } else {
        console.log('ERROR', error);
      }
    }
  };

  const handleLogout = async () => {
    dispatch(
      clearTokenState({
        expiresIn: 0,
        createdAt: 0,
        id: '',
        ttl: 0,
        userID: '',
        user: undefined
      })
    );
    dispatch(clearAccount());
    navigate('/');
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAMAnchorEl(null);
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        {!isLoggedIn && !isMobile && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: 'rgba(0, 0, 0, 1)' }}
            onClick={handleClick}>
            <MenuIcon />
          </IconButton>
        )}
        <div style={{ flexGrow: 1 }}>
          <Image
            sx={{
              height: 42,
              width: 122,
              cursor: 'pointer'
            }}
            alt="DHEC"
            src={AppLogo}
            onClick={() => navigate('/')}
          />
        </div>
        {isLoggedIn && <Menu pages={MenuPages} />}
        {!isLoggedIn && !isMobile ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                className={commonClasses.blueButton}
                onClick={() => {
                  cbModal(true, 'showLogin');
                }}>
                Login
              </Button>
              <Button
                className={commonClasses.greenButton}
                onClick={() => cbModal(true, 'showRegister')}>
                {'Signup'}
              </Button>
            </div>
            <div style={{ margin: '5px' }}>
              <GoogleTranslate></GoogleTranslate>
            </div>
          </div>
        ) : !isLoggedIn ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                className={commonClasses.blueButton}
                onClick={() => {
                  cbModal(true, 'showLogin');
                }}>
                Login
              </Button>
            </div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mx: 1, color: 'rgba(0, 0, 0, 1)' }}
              onClick={handleClick}>
              <MenuIcon />
            </IconButton>
          </>
        ) : (
          <Grid
            alignItems={'center'}
            justifyContent={'flex-end'}
            container
            spacing={3}
            sx={{ marginRight: '10px', flexGrow: 0 }}>
            {!isMobile && (
              <Grid item>
                <Typography
                  variant={'h6'}
                  sx={{
                    color: '#126DA2',
                    maxWidth: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {account.firstName && account.lastName
                    ? account?.firstName + ' ' + account.lastName
                    : ''}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <div onClick={(e) => setAMAnchorEl(e.currentTarget)}>
                <Avatar alt="avatar" src={AvatarIcon} />
              </div>
            </Grid>
          </Grid>
        )}
      </Toolbar>
      <FormDialog
        show={!!modalStatus?.showRegister}
        showActionButtons={false}
        backgroundTransparent
        fullWidth={true}
        noScrollY={true}
        maxWidth="lg"
        title=""
        handleClose={() => {
          cbModal(false, 'showRegister');
        }}
        handleSave={() => null}>
        <Signup onClose={() => null} cbModal={cbModal} />
      </FormDialog>

      <FormDialog
        show={!!modalStatus?.showFinishSignup}
        showActionButtons={false}
        backgroundTransparent
        fullWidth={true}
        noScrollY={true}
        maxWidth="lg"
        title=""
        handleClose={() => {
          cbModal(false, 'showFinishSignup');
        }}
        handleSave={() => null}>
        <FinishSignup onClose={() => null} cbModal={cbModal} />
      </FormDialog>

      <FormDialog
        show={modalStatus?.showLogin ? true : false}
        showActionButtons={false}
        backgroundTransparent
        fullWidth={true}
        noScrollY={false}
        overflowVissible={true}
        maxWidth="md"
        title=""
        handleClose={() => {
          cbModal(false, 'showLogin');
        }}
        handleSave={() => null}>
        <Login cbModal={cbModal} />
      </FormDialog>
      <FormDialog
        show={!!modalStatus?.showResetPassword}
        showActionButtons={false}
        backgroundTransparent
        fullWidth={true}
        noScrollY={false}
        overflowVissible={true}
        maxWidth="md"
        title=""
        handleClose={() => {
          cbModal(false, 'showResetPassword');
        }}>
        <ForgotPassword
          cbModal={cbModal}
          onClose={() => {
            null;
          }}
        />
      </FormDialog>

      <FormDialogEditProfile
        show={showEditProfile}
        fullWidth
        loading={loading}
        title={`Edit User`}
        hasSave={false}
        hasCancel={false}
        handleClose={() => setShowEditProfile(false)}>
        <EditUserForm
          {...toUpdateUserInfo}
          handleInputChange={handleInputChange}
          handleSaveForm={handleSaveForm}
        />
      </FormDialogEditProfile>
      <UserMenu
        anchorEl={amAnchorEl}
        open={openAccountMenu}
        onClose={handleCloseMenu}
        handleLogout={handleLogout}
        setShowEditProfile={setShowEditProfile}
        account={account}
      />
      <MuiMenu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={() => navigate('/')}>
          <ListItemIcon>
            <HomeOutlinedIcon sx={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText>{'Home'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/documents')}>
          <ListItemIcon>
            <Image alt="DHEC Videos" src={DocumentIcon} sx={{ height: 20 }} />
          </ListItemIcon>
          <ListItemText>{'Documents'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/videos')}>
          <ListItemIcon>
            <Image alt="DHEC Videos" src={VideoIcon} />
          </ListItemIcon>
          <ListItemText>{'Videos'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            cbModal(true, 'showContact');
          }}>
          <ListItemIcon>
            <InfoOutlinedIcon sx={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText>Request Information</ListItemText>
        </MenuItem>
        {isMobile && (
          <MenuItem>
            <div style={{ margin: '5px' }}>
              <GoogleTranslate></GoogleTranslate>
            </div>
          </MenuItem>
        )}
      </MuiMenu>
    </AppBar>
  );
};

export default Header;
