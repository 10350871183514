import { TextField } from '@mui/material';
import React from 'react';
import useStyles from './TextArea.styles';
import { TextFieldProps, BaseTextFieldProps } from '@mui/material';

const TextArea: React.FC<TextFieldProps | BaseTextFieldProps> = (
  props: TextFieldProps | BaseTextFieldProps
) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      variant="outlined"
      fullWidth
      multiline
      inputProps={{ className: classes.textarea }}
    />
  );
};

export default TextArea;
