import { ErrorInterface } from '../../interfaces/genera.interface';
import { SurveyTriggerOptionsInterface } from '../../interfaces/survey.interface';

export interface SurveysTriggerOptionsState {
  entities: SurveyTriggerOptionsInterface[];
  error: false | ErrorInterface;
  loading: boolean;
  update: boolean;
}

export const initialSurveysTriggerOptionsState: SurveysTriggerOptionsState = {
  entities: [],
  error: false,
  loading: false,
  update: false
};
