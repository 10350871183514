// @ts-nocheck
import React, { useState, useEffect, memo } from 'react';
import { Box, Container } from '@mui/system';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  Menu as MuiMenu
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MainLayoutView from 'components/layouts/MainLayout';
import PermitHolderTabsEnum from 'common/enums/PermitHolderTabsEnum';
import PersonalInformation from 'components/snippets/PermitHolders/PersonalInformation';
import StaffDirectory from 'components/snippets/PermitHolders/StaffDirectory';
import Businesses from 'components/snippets/PermitHolders/Businesses';
import { ITabs } from 'interfaces/ITabs';
import { useAppSelector } from 'app/hooks';
import PermitHolderTabs from 'common/arrays/PermitHolderTabs';
import { selectAccount } from 'store/reducers/account';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const PermitHolderView = () => {
  const [selectedTab, setSelectedTab] = useState<ITabs>(PermitHolderTabs[0]);
  const navigate = useNavigate();

  const account = useAppSelector(selectAccount) || null;
  let [searchParams] = useSearchParams();
  const query = searchParams.get('t');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleChange = (event: React.SyntheticEvent, tab: PermitHolderTabsEnum) => {
    const idx = PermitHolderTabs.findIndex((p) => p?.type === tab);
    setSelectedTab(PermitHolderTabs[idx]);
    navigate(`?t=${PermitHolderTabs[idx]?.query}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (query) {
      const idx = PermitHolderTabs.findIndex((p) => p?.query === query);
      if (idx > -1) {
        setSelectedTab(PermitHolderTabs[idx]);
      } else {
        navigate(`?t=${PermitHolderTabs[0]?.query}`);
      }
    } else {
      setSelectedTab(PermitHolderTabs[0]);
      navigate(`?t=${PermitHolderTabs[0]?.query}`);
    }
  }, [query]);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ p: isMobile ? '0' : '3' }}>{children}</Box>}
      </div>
    );
  }

  function MobileIconMenu() {
    return (
      <MuiMenu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {PermitHolderTabs.map((tab, index) => {
          return (
            <MenuItem
              key={index}
              label={tab.locale || ''}
              onClick={(e) => {
                handleChange(e, tab.type);
                handleCloseMenu();
              }}>
              <ListItemText>{tab.locale}</ListItemText>
            </MenuItem>
          );
        })}
      </MuiMenu>
    );
  }
  const containerStyle = {
    height: '100%',
    padding: isMobile ? '0 16px' : '20px 0'
  };
  return (
    <MainLayoutView>
      <Container maxWidth={isMobile ? 'false' : 'xl'} sx={containerStyle} id="CCCCCCCCC">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Container sx={{ padding: 0, display: 'flex' }}>
              {isMobile && (
                <>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, color: 'rgba(0, 0, 0, 1)' }}
                    onClick={handleClick}>
                    <MenuIcon />
                  </IconButton>
                  <Tabs value={false}>
                    {PermitHolderTabs.map((tab, index) => {
                      if (tab.type === selectedTab?.type)
                        return <Tab key={index} label={tab.locale || ''} />;
                    })}
                  </Tabs>
                  <MobileIconMenu />
                </>
              )}
              {!isMobile && (
                <Tabs value={selectedTab?.type} onChange={handleChange}>
                  {PermitHolderTabs.map((tab, index) => {
                    return <Tab key={index} label={tab.locale || ''} />;
                  })}
                </Tabs>
              )}
            </Container>
          </Box>
          <TabPanel value={selectedTab.type} index={0}>
            <PersonalInformation {...account} />
          </TabPanel>
          <TabPanel value={selectedTab.type} index={1}>
            <Businesses />
          </TabPanel>
          <TabPanel value={selectedTab.type} index={2}>
            <StaffDirectory businesses={[]} />
          </TabPanel>
        </Box>
      </Container>
    </MainLayoutView>
  );
};

export default memo(PermitHolderView);
