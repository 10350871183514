import React, { useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { useSelector } from 'react-redux';
import { useSurveyGuestDispatch } from '../../store';
import {
  selectSnackbarMessageSurveyMessage,
  selectSnackbarMessageSurveyShow
} from '../../store/snackbar-message/snackbar-message.selector';
import { updateSnackbarSurveyMessage } from '../../store/snackbar-message/snackbar-message.actions';

const SnackbarMessage: React.FC = () => {
  const dispatch = useSurveyGuestDispatch();

  const show = useSelector(selectSnackbarMessageSurveyShow);
  const message = useSelector(selectSnackbarMessageSurveyMessage);

  const closeMessage = () => {
    dispatch(updateSnackbarSurveyMessage({ show: false, message: null }));
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={() => {
        closeMessage();
      }}>
      <Alert
        onClose={() => {
          closeMessage();
        }}
        severity="error"
        sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
