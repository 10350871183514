import React, { useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { useAppDispatch } from '../../../../../../app/hooks';
import { useSelector } from 'react-redux';
import {
  selectSnackbarMessageSurveyMessage,
  selectSnackbarMessageSurveyShow,
  updateSnackbarSurveyMessage
} from '../../store/snackbar-message/snackbar-message.reducer';

const SnackbarMessage: React.FC = () => {
  const show = useSelector(selectSnackbarMessageSurveyShow);
  const message = useSelector(selectSnackbarMessageSurveyMessage);

  const dispatch = useAppDispatch();

  const closeMessage = () => {
    dispatch(updateSnackbarSurveyMessage({ show: false, message: null }));
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={() => {
        closeMessage();
      }}>
      <Alert
        onClose={() => {
          closeMessage();
        }}
        severity="error"
        sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
