import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Paper, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ErrorMessage } from '@hookform/error-message';

import TextField from 'components/primitives/TextField';
import { errorTextStyle } from 'common/styles/common.styles';

const MultipleChoiceQuestionItem: React.FC<any> = (props) => {
  return (
    <Grid container alignItems="center">
      <Grid item lg={1}>
        <Checkbox readOnly={true} disabled={true} />
      </Grid>

      <Grid item container lg={11} key={props.itemField.id} alignItems="center">
        <Grid lg={11} item>
          <Controller
            name={`template.surveyQuestions[${props.questionIndex}].surveyQuestionsOptions[${props.itemIndex}].question`}
            control={props.control}
            rules={{ required: 'Field is required.' }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
              return (
                <>
                  <TextField
                    defaultValue={value}
                    name={name}
                    onChange={onChange}
                    error={Boolean(error)}
                    variant="standard"
                    placeholder="Enter Option"
                  />
                  <p style={errorTextStyle}>
                    <ErrorMessage errors={props.errors} name={name} />
                  </p>
                </>
              );
            }}
          />
        </Grid>

        <Grid container item lg={1} justifyContent="flex-end">
          <DeleteOutlinedIcon
            sx={{
              width: 20,
              height: 20,
              cursor: 'pointer'
            }}
            onClick={() => props.itemRemove(props.itemIndex)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MultipleChoiceQuestionItem;
