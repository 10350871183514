import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import useCommonStyles from 'common/styles/common.styles';
import ReactTableContainer from 'components/fragments/ReactTable';
import MailboxHeaders from 'common/arrays/MailboxHeaders';

import MailBoxEmptyView from './EmptyMailBox/MailBoxEmpty.view';

import apiFetch from '../../../../services/apiFetch';
import { IMailbox } from 'interfaces/IMailbox';
import {
  ApiAdminMailboxSearch,
  getMailboxAdmin,
  getMailboxAdminCounter
} from '../../../../services/apiAdminMailbox';
import MailboxView from './MailboxView';
import parsTableHeader from 'common/Utils/parseTableHeader';

const localStorageKey = 'ADMIN_MAILBOX';
const STEPS = ['Select Recipients', 'Choose Template', 'Review & Send'];

const Mailbox: React.FC = (props) => {
  const localCols = localStorage?.getItem(localStorageKey) || null;
  const defColTable = localCols
  ? parsTableHeader(JSON.parse(localCols), MailboxHeaders)
  : MailboxHeaders;
  const commonClasses = useCommonStyles();

  const [openSendMail, setOpenSendMail] = useState<boolean>(false);
  const [selectedItem, setSelectItem] = useState<IMailbox>();

  const [data, setData] = useState<Partial<IMailbox>[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  // START filters
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  const [currentFilterParams, setCurrentFilterParams] = useState<any>('');
  const [currentOrderBy, setCurrentOrderBy] = useState<{ id: string; desc: boolean }[]>([
    { id: 'createdAt', desc: true }
  ]);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>('');
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  // END filters

  useEffect(() => {
    getAllMailboxAdmin();
  }, [currentOffset, currentKeyword, currentFilterParams, currentOrderBy, openSendMail]);

  const getAllMailboxAdmin = async (getTotalCount: boolean = true) => {
    let filters: ApiAdminMailboxSearch = {
      limit: process.env.LIST_LIMIT ? parseInt(process.env.LIST_LIMIT) : 10,
      offset: currentOffset,
      orderBy: `${currentOrderBy[0].id} ${currentOrderBy[0].desc ? 'DESC' : 'ASC'}`
    };

    if (currentKeyword) {
      filters.keyword = currentKeyword;
    }

    if (currentFilterParams) {
      filters = { ...filters, ...currentFilterParams };
    }

    const usersData = await apiFetch(getMailboxAdmin(filters)).then((data) => {
      setIsFetchingData(false);
      return data;
    });

    if (usersData) {
      setData(usersData.data?.response || []);
    }

    if (getTotalCount) {
      const total = await apiFetch(getMailboxAdminCounter(filters));
      setTotalItems(total.data?.response || 0);
    }
  };

  return (
    <Container
      disableGutters
      maxWidth="lg"
      sx={{ maxWidth: '100%!important', padding: '20px 0 10px 0' }}>
      <Grid container alignItems="center" spacing={2} rowSpacing={2}>
        <Grid item container alignItems="center">
          {!isFetchingData &&
          data.length === 0 &&
          currentKeyword?.length === 0 &&
          currentFilterParams.length === 0 ? (
            <MailBoxEmptyView
              onClick={() => {
                setOpenSendMail(true);
              }}></MailBoxEmptyView>
          ) : (
            <ReactTableContainer
              columns={defColTable}
              primaryKey="id"
              data={data}
              isDataFetching={isFetchingData}
              module={localStorageKey}
              hasCheckSelection={false}
              totalPage={totalItems}
              currSortBy={currentOrderBy}
              onPageChange={(offset, keyword) => {
                setCurrentOffset(offset);
              }}
              onSearch={(offset, keyword) => {
                setCurrentKeyword(keyword);
                setCurrentOffset(0);
              }}
              filtersData={(filters) => {
                setCurrentFilterParams(filters);
                setCurrentOffset(0);
              }}
              onChangeSort={(filter: string, isSortedDesc: boolean) => {
                const newSortValue = [{ id: filter, desc: isSortedDesc }];
                setCurrentOrderBy(newSortValue);
              }}
              suffix={
                <Button
                  className={commonClasses.blueButton}
                  sx={{ width: '200px!important' }}
                  startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
                  onClick={() => {
                    setOpenSendMail(true);
                  }}>
                  Send Email
                </Button>
              }
            />
          )}
        </Grid>
      </Grid>
      {openSendMail && (
        <MailboxView
          item={selectedItem}
          handleClose={() => {
            setOpenSendMail(false);
          }}
          handleSave={() => {
            setOpenSendMail(false);
          }}></MailboxView>
      )}
    </Container>
  );
};

export default Mailbox;
