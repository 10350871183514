import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, Grid, Paper, Stack, Typography } from '@mui/material';
import LoginLogo from 'common/images/Login_logo.png';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import useCommonStyles from 'common/styles/common.styles';
import apiFetch from 'services/apiFetch';
import { changePassword, setPassword } from 'services/apiEndPoints';
import _ from 'lodash';
import HomepageModalsState from '../../../store/state/HomepageModalsState';
import { selecthomepageModals, updatehomepageModals } from '../../../store/reducers/homepageModals';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';

const InfoErrMessage = {
  MISSMATCH: 'New password and confirm password did not match!',
  INVALID: 'You have entered an invalid email',
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const ChangePassword: React.FC<{ token: string; handleClose?: () => void }> = ({
  token,
  handleClose
}) => {
  const commonClasses = useCommonStyles();
  const [security, setSecurity] = useState<any>({});
  const [secErrMsg, setSecErrMsg] = useState<string>('');
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

  const onChangeInputs = (field: string, value: string) => {
    setSecErrMsg('');
    const cloneObj = { ...security };

    if (value && value.length > 0) cloneObj[field] = value;
    else delete cloneObj[field];

    setSecurity(cloneObj);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homepageModals = useAppSelector(selecthomepageModals);

  const defaultModal = {
    showLogin: false,
    showRegister: false,
    showContact: false,
    showResetPassword: false,
    showFinishSignup: false
  };
  const [modalStatus, setModalStatus] = useState<HomepageModalsState>(defaultModal);

  useEffect(() => {
    setModalStatus(homepageModals);
  }, [homepageModals, modalStatus]);

  const cbModal = (value: boolean, name: string) => {
    const payload = {
      ...defaultModal,
      [name]: value
    };
    setModalStatus(payload);
    dispatch(updatehomepageModals(payload));
  };

  const updatePassword = async () => {
    try {
      const toSave = { access_token: token, password: security?.newPassword };
      const res = await apiFetch(setPassword(toSave));

      if (res) {
        if (res.data.errorCode.code > 0) setSecErrMsg(res.data.errorCode.message);
        else {
          setSecurity({});
          setSecErrMsg('');
          setIsUpdateSuccess(true);
          setTimeout(() => setIsUpdateSuccess(false), 5000);
          navigate(`/`);
          cbModal(true, 'showLogin');
          handleClose?.();
        }
      }
    } catch (e) {
      setSecErrMsg(InfoErrMessage.SERVER);
    }
  };

  const onHandleSavePassword = () => {
    if (Object.keys(security).length > 0) {
      // if (!security.oldPassword) return setSecErrMsg(InfoErrMessage.MISSING);
      if (_.isEqual(security?.newPassword, security?.confirmPassword)) {
        updatePassword();
      } else setSecErrMsg(InfoErrMessage.MISSMATCH);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative'
      }}>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
          width: '95%',
          height: '400px',
          margin: '0 auto',
          mt: '50px',
          mr: '40px',
          borderRadius: '10px'
        }}
      />
      <Box
        sx={{
          background: '#126DA2',
          borderRadius: '32px',
          transform: 'rotate(-32.16deg)',
          width: '320px',
          height: '320px',
          position: 'absolute',
          top: '37px',
          left: '50px'
        }}
      />
      <Paper
        sx={{
          position: 'absolute',
          top: '10px',
          right: '50px',
          width: 550,
          color: '#555',
          padding: '20px 30px',
          minHeight: '380px'
        }}>
        <Typography variant="h5">{`Change Password`}</Typography>
        <div style={{ marginTop: '20px', position: 'relative' }}>
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            style={{
              position: 'absolute',
              left: 0,
              zIndex: 20,
              width: '100%'
            }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormGroup label={'New Password'}>
                <TextField
                  type="password"
                  value={security?.newPassword || ''}
                  onChange={(e) => onChangeInputs('newPassword', e.target.value)}
                  fullWidth
                  autoComplete="new-password"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormGroup label={'Confirm New Password'}>
                <TextField
                  type="password"
                  value={security?.confirmPassword || ''}
                  onChange={(e) => onChangeInputs('confirmPassword', e.target.value)}
                  fullWidth
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                className={commonClasses.blueButton}
                sx={{ width: '100%!important' }}
                onClick={() => onHandleSavePassword()}>
                {'Save'}
              </Button>
            </Grid>
            {secErrMsg && (
              <Grid container item lg={12} sx={{ justifyContent: 'center', width: '100%' }}>
                <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                  {secErrMsg}
                </FormHelperText>
              </Grid>
            )}
            {isUpdateSuccess && (
              <Grid container item lg={12} sx={{ justifyContent: 'center', width: '100%' }}>
                <FormHelperText sx={{ mt: 1, lineHeight: '12px', color: 'green' }}>
                  {`Update Successful`}!
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </div>
      </Paper>
      <Box
        component="img"
        sx={{
          width: 450,
          height: 480,
          position: 'absolute',
          top: '-55px',
          left: '-50px'
        }}
        alt="DHEC"
        src={LoginLogo}
      />
    </Box>
  );
};

export default ChangePassword;
