import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const errorTextStyle = {
  fontWeight: '400',
  marginTop: '3px',
  marginRight: '14px',
  fontSize: '0.75rem',
  color: '#d32f2f'
};

const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    blueButton: {
      background: 'radial-gradient(276.12% 167.5% at 14.68% -22.5%, #0CACCF 0%, #126DA2 100%)',
      color: '#fff',
      margin: '5px',
      width: '110px',
      height: '40px',
      padding: '8px, 24px, 8px, 24px',
      boxShadow: '0px 4px 8px 3px rgba(14, 159, 198, 0.15), 0px 1px 3px rgba(16, 137, 183, 0.12)',
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        margin: '0'
      }
    },
    blueOutlinedBtn: {
      borderColor: 'linear-gradient(257.69deg, #0CACCF 27.63%, #126DA2 113.43%)'
    },
    greenButton: {
      background: 'radial-gradient(177.24% 211.09% at 15.65% -66.25%, #ADD953 0%, #6A8C0D 100%)',
      color: '#fff',
      margin: '5px',
      width: '110px',
      height: '40px',
      padding: '8px, 24px, 8px, 24px',
      boxShadow: '0px 2px 8px rgba(18, 109, 162, 0.32);',
      borderRadius: '4px'
    },
    whiteButton: {
      background: '#fff',
      color: '#linear-gradient(257.69deg, #0CACCF 27.63%, #126DA2 113.43%)',
      width: '110px',
      height: '40px',
      padding: '8px, 24px, 8px, 24px',
      boxShadow: '0px 4px 8px 3px rgba(14, 159, 198, 0.15), 0px 1px 3px rgba(16, 137, 183, 0.12)',
      borderRadius: '4px'
    },
    iconButton: {
      border: '1px solid rgba(48, 48, 49, 0.08)',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        minWidth: '40px'
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none'
      },
      color: theme.palette.text.secondary
    },
    settingsBtn: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        justifyContent: 'flex-end'
      }
    },
    customTextArea: {
      backgroundColor: '#FAFAFA',
      color: theme.palette.text.secondary,
      borderColor: 'rgba(48, 48, 49, 0.08)',
      borderRadius: '4px',
      '&:focus-visible': {
        borderColor: `${theme.palette.primary.light}!important`
      }
    },
    suffixWrapper: {
      width: '100$',
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    editButton: {
      height: '30px',
      minHeight: 'auto',
      border: 0,
      boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039)',
      fontWeight: '400',
      color: '#49494A'
    },
    viewButton: {
      height: '30px',
      minHeight: 'auto',
      border: 0,
      boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039)',
      fontWeight: '400',
      color: '#49494A'
    },
    addButton: {
      height: '40px',
      minHeight: '40px',
      width: '40px',
      minWidth: '40px',
      marginLeft: '8px',
      backgroundColor: '#FAFAFA',
      marginTop: '23px',
      border: '1px solid rgba(48, 48, 49, 0.08)',
      borderRadius: '4px',
      boxShadow: 'none',
      fontWeight: '400',
      color: '#49494A'
    }
  })
);

export default useCommonStyles;
