import React from 'react';
import { ReactTablePublicProps } from './ReactTable.props';
import ReactTableView from './ReactTable.view';

const ReactTableContainer: React.FC<ReactTablePublicProps> = (props: ReactTablePublicProps) => {
  const combinedProps: ReactTablePublicProps = {
    ...props
  };
  return <ReactTableView {...combinedProps} />;
};

export default ReactTableContainer;
