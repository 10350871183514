import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, Grid, FormControlLabel, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextField from 'components/primitives/TextField';
import { Box } from '@mui/system';
import SearchInput from 'components/primitives/SearchInput';
import { IMultipleSelector } from './MultipleSelector.props';
import { ICheckBoxItem } from 'interfaces/CheckBoxItem';
import { CountyProps } from 'interfaces/ICounty';
// import { IRegionalSettingsCounties } from 'components/snippets/RegionalSettingsItem/RegionalSettingsItem.props';

const MultipleSelector: React.FC<IMultipleSelector> = ({
  items = [],
  region = null,
  selectedCounties = [],
  selectAll = false,
  setSelectAll,
  disabled = true,
  disabledSelectAll = false,
  onSelectItem
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<CountyProps[]>(items);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const value = selectedCounties.reduce((arr: string[], o: CountyProps) => {
    arr.push(o.name);
    return arr;
  }, []);

  useEffect(() => {
    setFilteredItems([]);
    const filter = items.filter((item) =>
      item.name.toLowerCase().includes(searchFilter.toLowerCase())
    );
    setFilteredItems(filter);
  }, [items, searchFilter]);

  return (
    <React.Fragment>
      <Box>
        <TextField
          value={value.join(', ')}
          disabled={disabled}
          InputProps={{
            sx: { paddingRight: '50px' },
            endAdornment: !disabled && (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} disableRipple>
                  {isCollapsed ? (
                    <ExpandLessIcon style={{ color: '#000' }} />
                  ) : (
                    <ExpandMoreIcon style={{ color: '#000' }} />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      {!disabled && (
        <Box style={{ padding: '5px', display: isCollapsed ? 'block' : 'none' }}>
          <Grid container item lg={12} xl={12} columnSpacing={2}>
            <Grid item lg={disabledSelectAll ? 12 : 10} xl={disabledSelectAll ? 12 : 10}>
              <SearchInput
                value={searchFilter}
                fullWidth
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </Grid>
            {!disabledSelectAll && (
              <Grid item lg={2} xl={2}>
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      disableRipple
                      checked={selectAll}
                      onChange={(e) => setSelectAll(e.target.checked)}
                    />
                  }
                  label="Select All"
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ maxHeight: '390px', overflow: 'auto', overflowX: 'hidden' }}>
            {(searchFilter ? filteredItems : items).map((item, idx) => {
              const cIdx = selectedCounties.findIndex((c) => c.id === item.id);

              return (
                <FormControlLabel
                  key={idx}
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      checked={cIdx > -1}
                      disableRipple
                      onChange={(e) => {
                        const newItem = { ...item };
                        onSelectItem(region, newItem);
                      }}
                    />
                  }
                  label={item?.name}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default MultipleSelector;
