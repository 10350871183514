import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface ISelectRegion {
  counties: string[];
}

function useSelectRegionForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        counties: yup.array().min(1, 'Select at least one county')
      }),
    []
  );

  const selectRegionForm = useForm<ISelectRegion>({
    defaultValues: {
      counties: []
    },
    resolver: yupResolver(validationSchema)
  });

  return selectRegionForm;
}

export default useSelectRegionForm;
