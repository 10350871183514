import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SurveysCountiesState, { initialSurveysCountiesState } from './surveys-counties.state';

export const surveysCountiesStateSlice = createSlice({
  name: 'surveysCounties',
  initialState: initialSurveysCountiesState,
  reducers: {
    updateSurveysCounties: (state, action: PayloadAction<SurveysCountiesState>) => {
      return action.payload || initialSurveysCountiesState;
    },
    clearSurveysCounties: (state, action: PayloadAction<SurveysCountiesState>) => {
      return initialSurveysCountiesState;
    }
  }
});

export const { updateSurveysCounties, clearSurveysCounties } = surveysCountiesStateSlice.actions;

export default surveysCountiesStateSlice.reducer;
