/* eslint-disable react/display-name */
import React, { ChangeEvent, useEffect, useState, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  CellProps,
  HeaderProps,
  useFlexLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { Checkbox } from '@mui/material';

import { ColumnsProperties, ReactTablePublicProps } from './ReactTable.props';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import useStyles from './ReactTable.styles';
import SortASC from 'common/images/sort_asc.png';
import SortDESC from 'common/images/sort_desc.png';
import SortNeutral from 'common/images/sort_neutral.png';
import SearchInput from 'components/primitives/SearchInput';
import useCommonStyles from 'common/styles/common.styles';
import ManageColumns from '../Popovers/ManageColumns';
import SortCards from '../Popovers/SortCards';
import FilterColumns from '../Popovers/FilterColumns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DocumentButtons from '../DocumentsButtons';
import CardIcon from 'common/images/mobile_unit.png';
import EditBlackIcon from '../../../common/images/edit_black_icon.png';
import Image from '../../primitives/Image';

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh'
};

const PAGE_LIMIT = 10;
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      {/* <CheckBox ref={resolvedRef} {...rest} /> */}
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const ReactTableView: React.FC<ReactTablePublicProps> = ({
  columns = [],
  data = [],
  module = 'PH_GENERAL',
  setSelectedRow,
  setCheckAll,
  onPageChange,
  onChangeSort,
  onSearch,
  filtersData,
  onChangeFilter,
  currSortBy = [],
  suffix,
  primaryKey,
  hasCheckSelection = false,
  customValue = false,
  totalPage = 0,
  isDataFetching,
  customData,
  selectedCheckAll = false,
  setSelectedCheckAll,
  filters = true,
  renderEditButton,
  returnCountyNameById,
  ...props
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [searchInput, setSearchInput] = useState<string | null>('');
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [openSortPopover, setOpenSortPopover] = useState<boolean>(false);
  const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false);
  const [stateColumns, setStateColumns] = useState<ColumnsProperties[]>(columns);
  const [columnsTable, setColumnsTable] = useState<ColumnsProperties[]>(columns);
  const [stateFilter] = useState<any[]>(data);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [dataTable, setDataTable] = useState<any[]>(data);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [pTotalpage, setPTotalPage] = useState<number>(totalPage);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterParamsString, setFilterParamsString] = useState<string>('');
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'));
  const [cardSortField, setCardSortField] = useState<string>('corporateName');
  const [cardOrderDesc, setCardOrderDesc] = useState<boolean>(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds }
  } = useTable(
    {
      columns: columnsTable,
      data: dataTable,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        }
      },
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            sortBy: currSortBy
          }),
          [state]
        );
      }
    },
    useGlobalFilter,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    setDataTable(data);
    setPTotalPage(data.length === 0 ? 0 : totalPage);
  }, [totalPage, data]);

  useEffect(() => {
    const newColTable = stateColumns.reduce((arr: ColumnsProperties[], o) => {
      if (o?.isSelected) arr.push(o);

      return arr;
    }, []);

    setColumnsTable(newColTable);
  }, [stateColumns]);

  useEffect(() => {
    if (hasCheckSelection) {
      if (setSelectedRow) {
        const parsedSelectedIds: any[] = [];
        const selectedIdsLen = Object.keys(selectedRowIds)?.length || 0;

        for (let i = 0; i < selectedIdsLen; i++) {
          const idx = Object.keys(selectedRowIds)[i];
          parsedSelectedIds.push(dataTable[Number(idx)]);
        }

        setSelectedRow(parsedSelectedIds);
      }
    }
  }, [selectedRowIds]);

  useEffect(() => {
    const delayKeyPressFn = setTimeout(() => {
      if (onSearch) {
        onSearch(0, searchInput, filterParamsString);
      }
    }, 1000);

    return () => clearTimeout(delayKeyPressFn);
  }, [searchInput]);

  const handleChangeCheckBox = (value: boolean, id: string) => {
    const newList = stateColumns.map((obj: ColumnsProperties) =>
      obj.accessor === id ? { ...obj, isSelected: value } : obj
    );

    const newColTable = newList.reduce((arr: ColumnsProperties[], o) => {
      if (o?.isSelected) arr.push(o);
      return arr;
    }, []);

    // save to localstorage
    localStorage.setItem(module, JSON.stringify(newList));

    setStateColumns(newList);
    setColumnsTable(newColTable);
  };

  const handleClickSortCards = (value: string) => {
    if (onChangeSort) {
      if (value !== cardSortField) {
        onChangeSort(value, true, filterParamsString);
        setCardOrderDesc(true);
        setCardSortField(value);
      } else {
        onChangeSort(value, !cardOrderDesc, filterParamsString);
        setCardOrderDesc((prevState) => !prevState);
      }
    }
  };

  const handleFilterChangeCheckBox = (filters: any) => {
    setSelectedFilters(filters);
    if (filtersData) {
      filtersData(filters);
    }
    if (!!Object.keys(filters).length) {
      const f = Object.keys(filters).reduce((str, o) => {
        const catItem = filters[o];
        for (let i = 0; i < catItem.length; i++) {
          str += `&${o}=${catItem[i].value}`;
        }
        return str.replace('+', '%2B');
      }, '');
      setFilterParamsString(f);
      onChangeFilter?.(0, searchInput, f);
    } else {
      setFilterParamsString('');
      onChangeFilter?.(0, searchInput, '');
      setDataTable(data);
      setPTotalPage(totalPage);
      setCurrentPage(1);
    }
  };

  const handleOpenPopOver = (event: React.MouseEvent<HTMLButtonElement>, type: string) => {
    setAnchorEl(event.currentTarget);

    if (type === 'column') setOpenPopover(true);
    else if (type === 'sort') setOpenSortPopover(true);
    else setOpenFilterPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
    setOpenSortPopover(false);
    setOpenFilterPopover(false);
    setAnchorEl(null);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    setSearchInput(target.value);
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid container item sm={8} md={8} lg={8} xl={8}>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <SearchInput
              fullWidth
              value={searchInput}
              onChange={(data) => handleSearchChange(data)}
            />
          </Grid>
          <Grid item className={commonClasses.settingsBtn} md={6} lg={6} xl={4}>
            <Grid item xs={4} md={3} sx={{ marginLeft: isMobile ? '0' : '15px' }}>
              {filters && (
                <Badge
                  color="error"
                  variant="dot"
                  invisible={!Object.keys(selectedFilters).length}
                  sx={{ width: '100%', fontSize: '16px' }}>
                  <Button
                    className={commonClasses.iconButton}
                    onClick={(e) => handleOpenPopOver(e, 'filter')}>
                    <FilterAltOutlinedIcon />
                  </Button>
                </Badge>
              )}
            </Grid>
            {isMobile && (
              <Grid item xs={4} sx={{ marginLeft: isMobile ? '0' : '15px' }}>
                <Button
                  className={commonClasses.iconButton}
                  onClick={(e) => {
                    handleOpenPopOver(e, 'sort');
                  }}>
                  <FilterListOutlinedIcon />
                </Button>
              </Grid>
            )}
            <Grid item xs={4} md={3} sx={{ marginLeft: isMobile ? '0' : '15px' }}>
              <Button
                className={commonClasses.iconButton}
                onClick={(e) => {
                  handleOpenPopOver(e, 'column');
                }}>
                <SettingsOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          {isSmView && (
            <Grid container item alignItems="center" xs={12} sm={3} sx={{ paddingTop: '16px' }}>
              {suffix && <>{suffix}</>}
            </Grid>
          )}
        </Grid>
        {!isSmView && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="flex-end"
            xs={12}
            sm={4}
            md={4}
            lg={4}>
            {suffix && <>{suffix}</>}
          </Grid>
        )}
      </Grid>
      <Grid container item alignItems="center" lg={12} sx={{ marginTop: '16px' }}>
        {isDataFetching ? (
          <Grid container justifyContent="center">
            <Box sx={loadingStyle}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : dataTable?.length > 0 ? (
          !isMobile ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} className={classes.tableContainer} {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup) => {
                    const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                    return (
                      <TableRow key={key} {...restHeaderGroupProps} style={{ display: 'flex' }}>
                        {headerGroup.headers.map((column, index) => {
                          const { width } = column;
                          const { key, ...restColumn } = column.getHeaderProps(
                            column.getSortByToggleProps()
                          );

                          return (
                            <TableCell
                              key={key}
                              {...restColumn}
                              className={classes.tableHeader}
                              onClick={() => {
                                if (column?.canSort) {
                                  if (onChangeSort) {
                                    onChangeSort(
                                      column.id,
                                      !column.isSortedDesc,
                                      filterParamsString
                                    );
                                  }
                                }
                              }}>
                              <>
                                {hasCheckSelection && index === 0 && (
                                  <Checkbox
                                    disableRipple
                                    checked={selectedCheckAll}
                                    onChange={(event, checked) => {
                                      setSelectedCheckAll?.(checked);
                                      setCheckAll?.({ checked });
                                    }}
                                  />
                                )}

                                <span style={{ float: 'left' }}>{column.render('Header')}</span>

                                {column.disableSortBy}
                                {!column.disableSortBy && (
                                  <span
                                    style={{
                                      float: 'left',
                                      display: column?.id === 'selection' ? 'none' : 'block'
                                    }}>
                                    {![
                                      'phoneNumber',
                                      'options',
                                      'permitNumber',
                                      'employessNumber',
                                      'countyName'
                                    ].includes(column.id) &&
                                      (column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <Avatar src={SortDESC} className={classes.sortIcon} />
                                        ) : (
                                          <Avatar src={SortASC} className={classes.sortIcon} />
                                        )
                                      ) : (
                                        <Avatar src={SortNeutral} className={classes.sortIcon} />
                                      ))}
                                  </span>
                                )}
                              </>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();

                    return (
                      <TableRow key={key} {...restRowProps}>
                        {row.cells.map((cell, idx) => {
                          const { key, ...restCellProps } = cell.getCellProps();
                          return (
                            <TableCell key={key} {...restCellProps}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container spacing={2} sx={{ mt: '10px' }} justifyContent="center">
              {data.map((dataItem) => {
                return (
                  <Grid item key={dataItem.id} xs={12} sm={6}>
                    <Card
                      sx={{ width: '100%', height: '100%', boxShadow: 0, maxWidth: '450px' }}
                      className={classes.card}
                      key={dataItem.id}>
                      <CardContent
                        sx={{
                          padding: 0,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}>
                        <>
                          {stateColumns.map((column, index) => {
                            if (column.accessor === 'permitNumbers' && column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.cardTitle}>
                                    <Image src={CardIcon} sx={{ width: 24, height: 24 }} />
                                    {`${column.Header}: `}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.description}>
                                    {dataItem['permitNumbers'].length > 0
                                      ? dataItem['permitNumbers'].map(
                                          (permit: any, index: number) => {
                                            return (
                                              permit.permit.PERMIT_NUMBER +
                                              (index === dataItem['permitNumbers'].length - 1
                                                ? null
                                                : ', ')
                                            );
                                          }
                                        )
                                      : 'N/A'}
                                  </Typography>
                                </Box>
                              );
                            }
                            if (column.accessor === 'county' && column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.cardTitle}>
                                    {`${column.Header}: `}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.description}>
                                    {dataItem['county']?.name ? dataItem['county']?.name : 'N/A'}
                                  </Typography>
                                </Box>
                              );
                            }
                            if (column.accessor === 'regionalOffice' && column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.cardTitle}>
                                    {`${column.Header}: `}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.description}>
                                    {dataItem['county']['region'].name}
                                  </Typography>
                                </Box>
                              );
                            }
                            if (column.accessor === 'options' && column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}
                                  sx={{ display: 'flex', justifyContent: 'center' }}>
                                  {renderEditButton && <>{renderEditButton(dataItem)}</>}
                                </Box>
                              );
                            }
                            if (column.accessor === 'status' && column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.cardTitle}>
                                    {`${column.Header}: `}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.description}
                                    sx={{
                                      display: 'inline',
                                      color:
                                        dataItem[column.accessor] === 'accepted'
                                          ? 'green !important'
                                          : '#E48833 !important'
                                    }}>
                                    {dataItem[column.accessor]?.toUpperCase()}
                                  </Typography>
                                </Box>
                              );
                            } else if (column.isSelected) {
                              return (
                                <Box
                                  className={classes.cardContainer}
                                  key={`${dataItem.id}${column.accessor}${index}`}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.cardTitle}>
                                    {`${column.Header}: `}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.description}>
                                    {dataItem[column.accessor]}
                                  </Typography>
                                </Box>
                              );
                            }
                          })}
                        </>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : searchInput ? (
          <p>No results found.</p>
        ) : null}

        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <ManageColumns
            title={isMobile ? 'Manage Fields' : `Manage Columns`}
            subtitle={`${
              columnsTable.filter((c: ColumnsProperties) => c.isSelected)?.length || 0
            } Selected`}
            items={stateColumns}
            handleChange={handleChangeCheckBox}
          />
        </Popover>

        <Popover
          open={openSortPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <SortCards
            title={'Sort Cards'}
            items={stateColumns}
            handleClick={handleClickSortCards}
            cardSortField={cardSortField}
            cardOrderDesc={cardOrderDesc}
          />
        </Popover>

        <Popover
          open={openFilterPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <FilterColumns
            title={isMobile ? 'Filter Cards' : `Filter Columns`}
            module={module}
            subtitle=""
            items={stateColumns}
            data={stateFilter}
            customData={customData}
            primaryKey={primaryKey}
            selectedFilters={selectedFilters}
            handleChange={handleChangeCheckBox}
            handleFilterChange={handleFilterChangeCheckBox}
            returnCountyNameById={returnCountyNameById}
          />
        </Popover>
      </Grid>
      <Grid item container lg={12} justifyContent={isMobile ? 'center' : 'flex-end'}>
        <Pagination
          variant="outlined"
          shape="rounded"
          page={currentPage}
          sx={{ pt: 2, display: totalPage === 0 ? 'none' : 'block' }}
          count={Math.ceil(pTotalpage / PAGE_LIMIT)}
          onChange={(event: React.ChangeEvent<any>, p: number) => {
            if (currentPage !== p) {
              if (onPageChange) {
                onPageChange((p - 1) * 10, searchInput, filterParamsString);
              }
              setCurrentPage(p);
            }
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

export default memo(ReactTableView);
