import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import StoreConfig from 'store';
import { Provider } from 'react-redux';
import { store, persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import AppTheme from './theming/theme';
import Pages from './pages';
declare let google: any;
import './App.css';

const App: React.FC = () => {
  useEffect(() => {
    document.cookie = 'googtrans=; Max-Age=-99999999;';
    handleGoogleTranslateElementInit();
  }, []);

  const handleGoogleTranslateElementInit = () => {
    try {
      googleTranslateElementInit();
    } catch (e) {
      setTimeout(function () {
        googleTranslateElementInit();
      }, 500);
    }
  };

  function googleTranslateElementInit() {
    new google.translate.TranslateElement(
      { pageLanguage: 'en', autoDisplay: false },
      'google_translate_element'
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <BrowserRouter>
              <Pages />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
