import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '8px 0',
      padding: '16px'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    dropDownContainer: {
      display: 'flex',
      // opacity: 0,
      height: '0px',
      overflow: 'hidden',
      transition: 'height ease 0.3s'
    },
    showDownContainer: {
      // display: 'flex',
      // opacity: 1,
      height: 'auto'
      // overflow: 'hidden',
      // transition: 'height ease 0.3s'
    },
    hideDownContainer: {
      opacity: 0,
      height: 0,
      transition: 'all 0.3s ease'
    }
  };
});

export default useStyles;
