import { SurveyState } from '../index';
import { IUser } from 'interfaces/IUser';

export const selectSurveysRecipients = (state: SurveyState) => state.surveysRecipients.users;
export const selectSurveysRecipientsString = (state: SurveyState) => {
  if (state.surveysRecipients.users && state.surveysRecipients.users.length > 0) {
    return state.surveysRecipients.users.map((user: IUser) => {
      return user.firstName + ' ' + user.lastName;
    });
  } else {
    return '';
  }
};
