import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    tableContainer: {
      '& th': {
        height: '48px',
        color: '#126DA2',
        backgroundColor: '#ECF8FB',
        fontWeight: 600,
        fontSize: '12px',
        display: 'flex',
        lineHeight: '1.3',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: '10px'
        }
      },
      '& td': {
        minHeight: '40px',
        height: 'auto',
        color: 'rgba(48, 48, 49, 0.88)',
        fontWeight: 300,
        display: 'flex',
        alignItems: 'center',
        // whiteSpace: 'nowrap',
        textOverlow: 'ellipsis'
        // overflow: 'hidden'
      }
    },
    tableHeader: {
      paddingTop: '15px',
      position: 'relative'
    },
    tableIcon: {
      fontSize: '14px',
      position: 'absolute',
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 0,
      marginRight: 0,
      right: 18,
      top: 0,
      bottom: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '10px'
      }
    },
    sortIcon: {
      width: '18px',
      height: '18px',
      marginLeft: '10px'
    },
    card: {
      borderRadius: '0',
      borderWidth: '0',
      borderBottom: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      paddingTop: '16px',
      borderBottomColor: 'rgba(0, 0, 0, 0.12)'
    },
    cardContainer: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      fontSize: '16px',
      width: '100%'
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      color: '#7B7B7B'
    },
    description: {
      fontSize: '16px'
    }
  };
});

export default useStyles;
