import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { ILibrary } from 'interfaces/ILibrary';

export interface ApiAdminLibrarySearch {
  keyword?: string;
  category?: { index: number; value: string }[];
  type?: { index: number; value: string }[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminLibraryFilters {
  keyword?: string;
  category?: string[];
  type?: string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getLibrary = (filters: ApiAdminLibrarySearch): IEndpoint => {
  const params = setLibraryParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/Library`,
    params: params
  };
};

export const getLibraryCounter = (filters: ApiAdminLibrarySearch): IEndpoint => {
  const params = setLibraryParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/library/counter`,
    params: params
  };
};

export const updateLibrary = (id: number, params: ILibrary): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `api/admin/library/${id}`,
    data: params
  };
};

export const createLibrary = (params: ILibrary): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `api/admin/library/`,
    data: params
  };
};

export const deleteLibrary = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `api/admin/library/${id}`
  }
}

export const getAdminLibraryForFilter = (
  filter: string,
  keyword: string,
  offset?: number,
  limit: number = 10
): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/library/filters/${filter}`,
    params: {
      limit: limit,
      offset: offset,
      keyword: keyword
    }
  };
};

function setLibraryParams(filters: ApiAdminLibrarySearch, counter = false): ApiAdminLibraryFilters {
  const params: ApiAdminLibraryFilters = {};

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.category && filters.category.length > 0) {
    params.category = filters.category.map((data) => data.value);
  }
  if (filters.type && filters.type.length > 0) {
    params.type = filters.type.map((data) => data.value);
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
