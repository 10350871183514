import React, { useState, useRef, useEffect } from 'react';
import { Paper, Typography, Stack, Box, Grid, Button } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import FormGroup from 'components/primitives/FormGroup';
import useStyles from './RegionalSettingsItem.styles';
import MultipleSelector from 'components/fragments/MultipleSelector';
import { IRegionalSettingsItem } from './RegionalSettingsItem.props';
import { CountyProps, ICounty, IRegion } from 'interfaces/ICounty';
import { Nullable } from 'common/Utils/Nullable';

// listRegionOffice,
// listCounties,
// selectedRegion,
// setSelectedRegion,
// updateCounties,
// region
// canEditRegions

const RegionalSettingsItem: React.FC<IRegionalSettingsItem> = ({
  canEditRegions,
  regionOffice,
  listCounties,
  selectedRegion,
  setSelectedRegion,
  updateCounties
}) => {
  const classes = useStyles();
  const parentRef = useRef<HTMLDivElement>();

  const [countiesList, setCounties] = useState<ICounty[]>(listCounties);

  const [selectedCounties, setSelectedCounties] = useState<CountyProps[]>(
    regionOffice.counties || []
  );

  const onSelectItem = (region: Nullable<IRegion>, item: CountyProps) => {
    const cloneItem = item;

    const newSelectedCounties = [...selectedCounties];
    const idx = newSelectedCounties.findIndex((c) => c.id === item.id);

    if (idx === -1) {
      cloneItem.region = region;
      newSelectedCounties.push(cloneItem);
    } else newSelectedCounties.splice(idx, 1);

    // Check if all county is selected

    setSelectedCounties(newSelectedCounties);

    if (updateCounties) {
      updateCounties(region, item, newSelectedCounties);
    }
  };

  // const onSelectAll = () => {
  //   setSelectedCounties(counties);
  //   setSelectAll(!selectAll);
  // };

  const displayVal = (arr: CountyProps[]) => {
    let value = '';

    if (arr.length > 0) {
      const vals = arr.reduce((arr: string[], o: CountyProps) => {
        arr.push(o.name);
        return arr;
      }, []);

      value = vals.join(', ');
    }

    return value;
  };

  // useEffect(() => {
  //   setSelectedCounties(counties);
  // }, [counties]);

  return (
    <Paper className={classes.itemContainer}>
      <Stack className={classes.headerContainer}>
        <Typography>{regionOffice.name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ paddingRight: '8px' }}>
            {selectedCounties.length > 2
              ? `${selectedCounties[0]?.name}, ${selectedCounties[1].name}`
              : displayVal(selectedCounties)}
          </Typography>
          <Typography color="#ACACAD">
            {selectedCounties.length > 2 ? `+${selectedCounties.length - 2} more` : ''}
          </Typography>
          <Button
            disableRipple
            onClick={() => (setSelectedRegion ? setSelectedRegion(regionOffice.id) : '')}
          >
            {selectedRegion === regionOffice.id ? (
              <UnfoldLessIcon sx={{ width: 16, height: 16 }} style={{ color: '#CDCDCE' }} />
            ) : (
              <UnfoldMoreIcon sx={{ width: 16, height: 16 }} style={{ color: '#CDCDCE' }} />
            )}
          </Button>
        </Box>
      </Stack>
      <Stack
        className={classes.dropDownContainer}
        ref={parentRef}
        style={{
          height: selectedRegion === regionOffice.id ? 'auto' : '0',
          maxHeight: '500px',
          transition: selectedRegion === regionOffice.id ? 'max-height 1s' : ''
        }}
      >
        {/* // style={showInput ? { height: parentRef?.current?.scrollHeight + 'px' } : { height: '0px' }}> */}
        <FormGroup label="Select designated counties">
          <MultipleSelector
            region={{
              id: regionOffice.id,
              name: regionOffice.name,
              email: regionOffice.email
            }}
            items={countiesList}
            disabled={!canEditRegions}
            selectedCounties={selectedCounties}
            selectAll={false}
            disabledSelectAll={true}
            setSelectAll={() => {}}
            onSelectItem={onSelectItem}
          />
        </FormGroup>
      </Stack>
    </Paper>
  );
};

export default RegionalSettingsItem;
