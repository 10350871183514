import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSurveyGuestDispatch } from '../../store';
import { getSurveyGuest } from '../../store/survey/survey.trunk';
import { surveySelector, surveysError, surveysLoading } from '../../store/survey/survey.selector';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SurveyQuestion from '../SurveyQuestion';
import SurveyAnswer from '../SurveyAnswer';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { loadingStyle } from './SurveyContainer.styles';
import { SurveyQuestionTypes } from '../../constants/survey.constants';
import {
  SaveSurveyResponseDataInterface,
  SurveyAnswerInterface,
  SurveyResponseDataInterface
} from '../../interfaces/survey.interfaces';
import { saveSurveyResponseGuest } from '../../store/save-survey-response/save-survey-response.trunk';
import {
  saveSurveysResponseError,
  saveSurveysResponseLoading,
  saveSurveysResponseSuccess
} from '../../store/save-survey-response/save-survey-response.selector';

export interface QuestionOptionsInterface {
  action: SurveyQuestionActionTypes;
  createdAt: number;
  id: string;
  order: number;
  question: string;
  surveyQuestionsId: string;
  triggerActionQuestionUUID: string;
  triggerOptionID: string;
}

import SnackbarMessage from '../SnackbarMessage';
import {
  clearSnackbarSurveyMessage,
  updateSnackbarSurveyMessage
} from '../../store/snackbar-message/snackbar-message.actions';
import SurveyQuestionActionTypes from '../../../Admin/Surveys/enums/SurveyQuestionActionTypesEnum';

const SurveyContainer: React.FC = () => {
  const dispatch = useSurveyGuestDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const item = useSelector(surveySelector);
  const loadingGetSurvey = useSelector(surveysLoading);
  const error = useSelector(surveysError);

  const saveSuccess = useSelector(saveSurveysResponseSuccess);
  const saveLoading = useSelector(saveSurveysResponseLoading);
  const saveError = useSelector(saveSurveysResponseError);

  let [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';

  const [campaign, setCampaign] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);

  const [index, setIndex] = useState<number>(0);
  const [percentageCompleted, setPercentageCompleted] = useState<number>(1);
  const [surveyAnswers, setSurveyAnswers] = useState<{
    save: boolean;
    next: boolean;
    data: SurveyResponseDataInterface[];
  }>({
    save: false,
    next: false,
    data: []
  });
  const [surveyFinished, setSurveyFinished] = useState<boolean>(false);
  const [surveyAlreadyTaken, setSurveyAlreadyTaken] = useState<boolean>(false);
  const [currentItems, setCurrentItems] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<any>();

  useEffect(() => {
    const hasAlreadyCompletedSurvey = document.cookie.indexOf('surveyCompleted=true') > -1;
    if (hasAlreadyCompletedSurvey) {
      setSurveyAlreadyTaken(true);
    }
  }, []);

  useEffect(() => {
    if (item) {
      setCampaign(item.campaign);
      setQuestions(item?.campaign.template.surveyQuestions);
      setTotalItems(item?.campaign.template.surveyQuestions.length);
      setCurrentQuestion(item?.campaign.template.surveyQuestions[index]);
    }
  }, [item]);

  useEffect(() => {
    dispatch(getSurveyGuest({ token: token }));
  }, []);

  const form = useForm<SurveyAnswerInterface>({
    mode: 'onChange',
    shouldFocusError: true
  });

  useEffect(() => {
    setCurrentQuestion(questions[index]);
  }, [index]);

  useEffect(() => {
    if (totalItems) {
      let percentageCalculate = getPercent(index, totalItems);
      setPercentageCompleted(percentageCalculate);
    }
  }, [index, totalItems]);

  useEffect(() => {
    if (saveSuccess) {
      setSurveyFinished(true);
      document.cookie = 'surveyCompleted=true; path=/';
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (saveError) {
      if (saveError.code === 4003) {
        setSurveyFinished(true);
      } else {
        if (saveError.message) {
          dispatch(updateSnackbarSurveyMessage({ show: true, message: saveError.message }));
        } else {
          dispatch(updateSnackbarSurveyMessage({ show: true, message: 'Something went wrong' }));
        }
      }
    }
  }, [saveError]);

  useEffect(() => {
    setLoading(saveLoading);
  }, [saveLoading]);

  useEffect(() => {
    setLoading(loadingGetSurvey);
  }, [loadingGetSurvey]);

  useEffect(() => {
    return () => {
      dispatch(clearSnackbarSurveyMessage({ show: false, message: null }));
    };
  }, []);

  useEffect(() => {
    if (surveyAnswers.save) {
      submitSaveSurvey();
    }
    if (surveyAnswers.next) {
      nextSurveyQuestion();
    }
  }, [surveyAnswers]);

  const formSubmitHandler: SubmitHandler<any> = (formData: any) => {
    const currentSurveyAnswer: SurveyAnswerInterface = formData;
    populateSurveyAnswer(currentSurveyAnswer, currentQuestion);
  };

  function submitSaveSurvey() {
    const data: SaveSurveyResponseDataInterface = {
      token: token,
      questions: surveyAnswers.data
    };

    dispatch(saveSurveyResponseGuest({ token: token, response: data }));
  }

  function populateSurveyAnswer(
    currentSurveyAnswer: SurveyAnswerInterface,
    currentQuestion: any
  ): void {
    let data: SurveyResponseDataInterface = {
      questionId: currentQuestion.id,
      type: currentQuestion.type,
      questionOptionId:
        currentQuestion.type === SurveyQuestionTypes.TEXT ? null : currentSurveyAnswer.answer,
      questionText:
        currentQuestion.type === SurveyQuestionTypes.TEXT ? currentSurveyAnswer.answerText : null
    };

    setSurveyAnswers({
      save: false,
      next: true,
      data: [...surveyAnswers.data, data]
    });
  }

  function nextSurveyQuestion(): void {
    const currentAnswer = surveyAnswers.data.slice(-1)[0];

    if (currentQuestion.type === SurveyQuestionTypes.SINGLE) {
      const selectedOption = currentQuestion.surveyQuestionsOptions.find(
        (questionOptions: QuestionOptionsInterface) => {
          return currentAnswer.questionOptionId === questionOptions.id;
        }
      );

      if (!selectedOption) {
        nextQuestionOrStopSurvey();
      }

      if (selectedOption?.action === SurveyQuestionActionTypes.NEXT) {
        nextQuestionOrStopSurvey();
      }
      if (selectedOption?.action === SurveyQuestionActionTypes.QUESTION) {
        goToSpecificQuestion(selectedOption.triggerActionQuestionUUID);
      }
      if (selectedOption?.action === SurveyQuestionActionTypes.STOP) {
        stopSurvey();
      }
    } else {
      if (currentQuestion.triggerActionType === SurveyQuestionActionTypes.NEXT) {
        nextQuestionOrStopSurvey();
      }
      if (currentQuestion.triggerActionType === SurveyQuestionActionTypes.QUESTION) {
        goToSpecificQuestion(currentQuestion.triggerActionQuestionUUID);
      }
      if (currentQuestion.triggerActionType === SurveyQuestionActionTypes.STOP) {
        stopSurvey();
      }
    }
  }

  function goToSpecificQuestion(questionId: string): void {
    const findQuestion = questions.findIndex((question) => {
      return questionId === question.uuid;
    });
    setNewIndexQuestion(findQuestion, false);
  }

  function nextQuestionOrStopSurvey(): void {
    if (index !== questions.length - 1) {
      form.reset();
      setNewIndexQuestion(index);
    } else {
      stopSurvey();
    }
  }

  function setNewIndexQuestion(index: number, increment: boolean = true) {
    if (increment) {
      setIndex(index + 1);
      setCurrentItems(index + 2);
    } else {
      setIndex(index);
      setCurrentItems(index + 1);
    }
  }

  function stopSurvey(): void {
    setSurveyAnswers({
      save: true,
      next: false,
      data: [...surveyAnswers.data]
    });
  }

  function getPercent(current: number, maxValue: number): number {
    return Math.ceil((current * 100) / maxValue);
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        padding: '20px 0',
        flexGrow: '1',
        overflow: 'auto',
        backgroundColor: '#FAFAFA'
      }}>
      {loading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      {campaign && !surveyFinished && !surveyAlreadyTaken && (
        <Box sx={{ width: '100%' }}>
          <Grid spacing={1} container>
            <Grid item lg={12}>
              <Typography variant="h1" color="#126DA2">
                {campaign.title}
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography variant="body1">{campaign.description}</Typography>
            </Grid>
          </Grid>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmitHandler)}>
              <Paper
                key={questions[index].id}
                elevation={1}
                sx={{ width: '100%', padding: '24px', marginTop: 3 }}>
                <Grid container sx={{ height: '100%' }}>
                  <SurveyQuestion
                    key={questions[index].id}
                    index={index}
                    question={questions[index].question}
                    answerRequired={questions[index].required}
                  />
                  <Grid item lg={12}>
                    <Divider sx={{ marginBottom: '24px', marginTop: '24px' }} />
                  </Grid>
                  <SurveyAnswer
                    index={index}
                    type={questions[index].type}
                    options={questions[index].surveyQuestionsOptions}
                    isRequired={questions[index].required}
                  />
                </Grid>
              </Paper>
              <Grid
                container
                sx={{
                  display: 'flex',
                  height: '100%',
                  marginTop: '32px',
                  marginBottom: '32px',
                  gap: '24px'
                }}>
                <Grid
                  item
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <Typography
                    variant="h6"
                    color="#49494A"
                    sx={{ display: 'inline-flex', width: '160px' }}>
                    Question {currentItems} / {totalItems}
                  </Typography>
                  <LinearProgress
                    sx={{
                      width: '100%',
                      background: 'rgba(48, 48, 49, 0.08)',
                      borderRadius: '5px',
                      '> span': {
                        background: 'linear-gradient(263.94deg, #ADD953 0%, #6A8C0D 102.73%)',
                        borderRadius: '5px'
                      }
                    }}
                    variant="determinate"
                    value={percentageCompleted}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    flexGrow: 0,
                    display: 'flex',
                    gap: '12px'
                  }}>
                  {/*<Button*/}
                  {/*  sx={{*/}
                  {/*    backgroundColor: '#EEEEEF',*/}
                  {/*    padding: '8px 45px'*/}
                  {/*  }}*/}
                  {/*  onClick={() => {}}*/}
                  {/*  color="secondary">*/}
                  {/*  Back*/}
                  {/*</Button>*/}
                  <Button
                    sx={{
                      padding: '8px 45px',
                      background:
                        'radial-gradient(276.12% 167.5% at 14.68% -22.5%, #0CACCF 0%, #126DA2 100%)',
                      color: 'white',
                      boxShadow:
                        '0px 4px 8px 3px rgba(14, 159, 198, 0.15), 0px 1px 3px rgba(16, 137, 183, 0.12);',
                      '&.Mui-disabled': {
                        background: '#CDCDCE',
                        color: '#49494A',
                        boxShadow: 'none'
                      }
                    }}
                    onClick={() => {}}
                    color="primary"
                    disabled={!form.formState.isValid}
                    type={'submit'}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      )}
      {error && (
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Grid spacing={1} container>
            <Grid item lg={12}>
              <Typography variant="h1" color="#126DA2" textAlign={'center'}>
                Survey missing or expired!
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography variant="body1" textAlign={'center'}>
                It seems that this survey is no longer active.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {surveyFinished && (
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Grid spacing={1} container>
            <Grid item lg={12}>
              <Typography variant="h1" color="#126DA2" textAlign={'center'}>
                Survey submitted!
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography variant="body1" textAlign={'center'}>
                Thank you for taking the time to fill out the survey. Our team will reach out if
                needed.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {surveyAlreadyTaken && (
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Grid spacing={1} container>
            <Grid item lg={12}>
              <Typography variant="h1" color="#126DA2" textAlign={'center'}>
                Survey already filled!
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography variant="body1" textAlign={'center'}>
                Thank you for taking the time to fill out the survey. Our team will reach out if
                needed.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <SnackbarMessage></SnackbarMessage>
    </Container>
  );
};

export default SurveyContainer;
