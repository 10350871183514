import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SurveysRecipientsState, { initialSurveysRecipientsState } from './surveys-recipients.state';

export const surveysRecipientsStateSlice = createSlice({
  name: 'surveysRecipients',
  initialState: initialSurveysRecipientsState,
  reducers: {
    updateSurveysRecipients: (state, action: PayloadAction<SurveysRecipientsState>) => {
      return action.payload || initialSurveysRecipientsState;
    },
    clearSurveysRecipients: (state, action: PayloadAction<SurveysRecipientsState>) => {
      return initialSurveysRecipientsState;
    }
  }
});

export const { updateSurveysRecipients, clearSurveysRecipients } =
  surveysRecipientsStateSlice.actions;

export default surveysRecipientsStateSlice.reducer;
