import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { IUserMenu } from './UserMenu.props';
import AccountIcon from 'common/images/account_icon.png';
import HelpIcon from 'common/images/help_icon.png';
import Image from 'components/primitives/Image';
import GoogleTranslate from '../GoogleTranslate/GoogleTranslate.view';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const UserMenu: React.FC<IUserMenu> = ({
  anchorEl,
  open = false,
  onClose,
  handleLogout,
  setShowEditProfile,
  account
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      // PaperProps={{ style: { width: '200px' } }}
      PaperProps={{
        elevation: 0,
        style: { width: '200px' },
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      {/* <MenuItem onClick={() => dispatch(myAccountActions.update({ ...myAccount, show: !myAccount?.show }))}> */}
      {isMobile && (
        <MenuItem>
          <ListItemIcon>
            <Typography
              variant={'h6'}
              sx={{
                color: '#126DA2',
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {account.firstName && account.lastName
                ? account?.firstName + ' ' + account.lastName
                : ''}
            </Typography>
          </ListItemIcon>
        </MenuItem>
      )}
      <MenuItem onClick={() => setShowEditProfile(true)}>
        <ListItemIcon>
          <Image alt="DHEC Videos" src={AccountIcon} sx={{ height: 20 }} />
        </ListItemIcon>
        <ListItemText>Account</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <Image alt="DHEC Videos" src={HelpIcon} sx={{ height: 20 }} />
        </ListItemIcon>
        <ListItemText>Help</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem disableRipple>
        <div style={{ width: '100%' }}>
          <GoogleTranslate></GoogleTranslate>
        </div>
      </MenuItem>
      <Divider />
      <MenuItem disableRipple>
        <Button variant="outlined" color="primary" fullWidth onClick={handleLogout}>
          Logout
        </Button>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
