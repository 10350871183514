import DefaultPaletteOptions from '../defaultPalette';
import theme from '../theme';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        minHeight: 40,
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: 600,
        color: 'common.white',
        textTransform: 'initial',
        '& .MuiButton-endIcon': {
          position: 'absolute',
          right: '10px'
        }
      },
      outlined: {
        borderColor: palette.primary?.dark,
        color: palette.primary?.dark
      },
      outlinedSecondary: {
        borderColor: palette.primary?.dark,
        backgroundColor: 'common.white'
      },
      sizeSmall: {
        minHeight: 38,
        fontSize: 12
      },
      sizeLarge: {
        minHeight: 46,
        fontSize: 16
      }
    }
  };
};
