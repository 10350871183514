import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '322px'
      // marginBottom: '16px'
    },
    listItemContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      fontWeight: 300,
      fontSize: '12px',
      padding: '0 10px',
      '& .MuiCheckbox-root': {
        paddingLeft: 0
      }
    }
  };
});

export default useStyles;
