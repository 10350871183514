import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';

export interface ApiAdminStaffDirectorySearch {
  keyword?: string;
  businessID?: string;
  facility?: { index: number; value: string }[];
  firstName?: { index: number; value: string }[];
  jobTitle?: { index: number; value: string }[];
  email?: { index: number; value: string }[];
  phoneNumber?: { index: number; value: string }[];
  lastName?: { index: number; value: string }[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminStaffDirectoryFilters {
  keyword?: string;
  businessID?: string;
  facility?: string[];
  firstName?: string[];
  jobTitle?: string[];
  email?: string[];
  phoneNumber?: string[];
  lastName?: string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getStaffDirectoryAdmin = (filters: ApiAdminStaffDirectorySearch): IEndpoint => {
  const params = setBusinessParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/staff`,
    params: params
  };
};

export const getStaffDirectoryAdminCounter = (filters: ApiAdminStaffDirectorySearch): IEndpoint => {
  const params = setBusinessParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/staff/counter`,
    params: params
  };
};

function setBusinessParams(
  filters: ApiAdminStaffDirectorySearch,
  counter = false
): ApiAdminStaffDirectoryFilters {
  const params: ApiAdminStaffDirectoryFilters = {};

  if (filters.businessID && filters.businessID.length > 0) {
    params.businessID = filters.businessID;
  }

  if (filters.facility && filters.facility.length > 0) {
    params.facility = filters.facility.map((data) => data.value.toString());
  }
  if (filters.firstName && filters.firstName.length > 0) {
    params.firstName = filters.firstName.map((data) => data.value.toString());
  }
  if (filters.lastName && filters.lastName.length > 0) {
    params.lastName = filters.lastName.map((data) => data.value.toString());
  }
  if (filters.jobTitle && filters.jobTitle.length > 0) {
    params.jobTitle = filters.jobTitle.map((data) => data.value.toString());
  }
  if (filters.email && filters.email.length > 0) {
    params.email = filters.email.map((data) => data.value.toString());
  }
  if (filters.phoneNumber && filters.phoneNumber.length > 0) {
    params.phoneNumber = filters.phoneNumber.map((data) => data.value.toString());
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
