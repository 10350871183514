import { useTheme } from '@mui/material';

const SelectRegionStyles = () => {
  const theme = useTheme();
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      padding: `16px ${theme.spacing(2)}`,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    mainWrapper: {
      height: '100%',
      overflow: 'scroll',
      [theme.breakpoints.up('md')]: {
        flex: 1,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }
    },
    detailItem: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        '& .MuiInputBase-multiline': {
          height: 'auto'
        }
      }
    }
  };
};

export default SelectRegionStyles;
