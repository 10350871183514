import DefaultPaletteOptions from '../defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      '.MuiSwitch-switchBase.Mui-checked': {
        color: '#6A8C0D'
      },
      '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#6A8C0D'
      }
    }
  }
});
