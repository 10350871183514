import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, FormHelperText, Grid, Paper, Stack, Typography } from '@mui/material';
import LoginLogo from 'common/images/Login_logo.png';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import useCommonStyles from 'common/styles/common.styles';
import { ILogin } from './Login.props';
import { loadingStyle } from '../SurveysResponse/components/SurveyContainer/SurveyContainer.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Login: React.FC<ILogin> = ({
  errMessage,
  login,
  handleChange,
  isLoading,
  handleLogin,
  ...props
}) => {
  const commonClasses = useCommonStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [divHeight, setDivHeight] = useState(0);
  const [hasEntered, setHasEntered] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const getElementHeight = () => {
      let height = 0;
      if (ref?.current?.clientHeight !== undefined && ref?.current?.clientHeight > 0) {
        height = ref?.current?.clientHeight - 20;
      }
      setDivHeight(height);
    };
    getElementHeight();
  }, [isMobile]);

  const loginDisabled = !login.username || !login.password;
  const onFieldChange = (e: any) => {
    handleChange(e.target.name, e.target.value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !hasEntered) {
      setHasEntered(true);
      handleLogin();
    }
  };
  const handlePreLogin = () => {
    if (!hasEntered) {
      setHasEntered(true);
      handleLogin();
    }
  };
  const paperStyle = {
    position: 'absolute',
    top: '10px',
    right: isMobile ? '10px' : '50px',
    width: isMobile ? 'calc(100vw - 80px)' : 550,
    height: isMobile ? '395px' : divHeight,
    color: '#555',
    padding: '20px',
    zIndex: 15,
    overflowY: 'visible'
  };
  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        overflowY: 'visible !important'
      }}>
      {isLoading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      {!isMobile && (
        <>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
              width: '92%',
              height: errMessage ? '435px' : '415px',
              margin: '0 auto',
              // mt: '50px',
              // mr: '40px',
              borderRadius: '10px'
            }}
          />
          <Box
            sx={{
              background: '#126DA2',
              borderRadius: '32px',
              transform: 'rotate(-32.16deg)',
              width: '320px',
              height: '320px',
              position: 'absolute',
              top: '55px',
              left: '30px'
            }}
          />
        </>
      )}

      <Paper sx={paperStyle}>
        <Typography variant="h5">{`Login`}</Typography>
        <Box style={{ width: '100%', height: '100%' }}>
          <div style={{ marginTop: '20px', position: 'relative' }}>
            <Grid
              container
              columnSpacing={1}
              rowSpacing={2}
              style={{
                position: 'absolute',
                left: 0,
                zIndex: 20,
                width: '100%'
              }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={`Email Address`}>
                  <TextField
                    name="username"
                    value={login?.username || ''}
                    onChange={onFieldChange}
                    onKeyPress={onKeyPress}
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup label={`Password`}>
                  <TextField
                    name="password"
                    value={login?.password || ''}
                    type="password"
                    onChange={onFieldChange}
                    onKeyPress={onKeyPress}
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ pt: '10px!important' }}
                justifyContent="flex-end">
                <Button
                  disableRipple
                  onClick={() => {
                    props.handleModalChange(true, 'showResetPassword');
                  }}>
                  {`Forgot Password`}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {errMessage && (
                  <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                    {errMessage}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  className={commonClasses.blueButton}
                  sx={{ width: '100%!important' }}
                  disabled={loginDisabled}
                  onClick={() => handlePreLogin()}>
                  {`Login`}
                </Button>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ pt: '10px!important' }}>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  <Typography variant="h6">{`Need an account?`}</Typography>
                  <Button
                    onClick={() => {
                      props.handleModalChange(true, 'showRegister');
                    }}>
                    {`Signup`}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Paper>
      {!isMobile && (
        <Box
          component="img"
          sx={{
            width: 450,
            height: 480,
            position: 'absolute',
            top: '-35px',
            left: '-70px'
          }}
          alt="DHEC"
          src={LoginLogo}
        />
      )}
    </Box>
  );
};

export default Login;
