import { SurveyState } from '../index';

export const selectSurveysCountiesValue = (state: SurveyState) =>
  state.surveysCounties.counties.map((county: { label: string; value: string }) => county.value);

export const selectSurveysCountiesLabel = (state: SurveyState) => {
  if (state.surveysCounties.counties?.length > 0) {
    return state.surveysCounties.counties.map(
      (county: { label: string; value: string }) => ' ' + county.label
    );
  }
};
