import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { SelectRecipientSurveyDialogProps } from './SelectRecipientSurveyDialog.props';
import useCommonStyles from 'common/styles/common.styles';
import { useAppDispatch } from '../../../../../../app/hooks';
import SelectRecipientSurvey from '../SelectRecepientSurvey';
import { useSelector } from 'react-redux';
import { selectSurveysRecipients } from '../../store/surveys-recipients/surveys-recipients.selector';
import { IUser } from 'interfaces/IUser';
import {
  clearSurveysRecipients,
  updateSurveysRecipients
} from '../../store/surveys-recipients/surveys-recipients.reducer';
import {
  clearSurveysCounties,
  updateSurveysCounties
} from '../../store/survey-counties/surveys-counties.reducer';
import RecipientsTabsEnum from '../../../../../../common/enums/SelectRecipientsTabsEnum';
import SelectRegionView from '../../../MailBox/SendMail/SelectRegion/SelectRegion.view';
import { Container } from '@mui/system';
import RecipientsTabs from '../../../../../../common/arrays/RecipientsTabs';
import useSelectRegionForm, {
  ISelectRegion
} from '../../../MailBox/SendMail/SelectRegion/hooks/useSelectRegionForm';
import { selectSurveysCountiesValue } from '../../store/survey-counties/surveys-counties.selector';
import { ISelectProp } from 'interfaces/ISelectProp';
import { useFetchCounties } from '../../../../../../hooks';
import { RegionName } from '../../../../../../common/enums/EnumRegionName';

const SurveyRecipientsFormDialog: React.FC<SelectRecipientSurveyDialogProps> = ({
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'lg',
  noScrollY = false,
  overflowVisible = false,
  handleClose,
  backgroundTransparent = false,
  selectedTab = 0,
  setSelectedTab
}) => {
  const commonClasses = useCommonStyles();
  const dispatch = useAppDispatch();
  const templateForm = useForm();
  const countiesForm = useSelectRegionForm();
  const [countyList, setCountyList] = useState<{ label: string; value: string }[]>([]);
  const peeDeeCounties = useFetchCounties(RegionName.PEEDEECOUNTIES);
  const lowCountryCounties = useFetchCounties(RegionName.LOWCOUNTRYCOUNTIES);
  const midlandsCounties = useFetchCounties(RegionName.MIDLANDSCOUNTIES);
  const upstateCounties = useFetchCounties(RegionName.UPSTATECOUNTIES);
  const selectedRecipients = useSelector(selectSurveysRecipients);
  const [newRecipients, setNewRecipients] = useState<(IUser & { id: string; select: boolean })[]>(
    []
  );
  const [selectList, setSelectList] = useState<ISelectProp[]>([]);
  const selectedCountiesValue = useSelector(selectSurveysCountiesValue);
  const a11yProps = (index: number) => {
    return {
      id: `dhec-tab-${index}`,
      'aria-controls': `dhec-tab-${index}`
    };
  };

  const onSubmitRecipients = (data: any) => {
    const formData = templateForm.getValues();
    dispatch(clearSurveysCounties(null));
    dispatch(updateSurveysRecipients({ users: newRecipients }));
    handleClose?.();
  };

  const onSubmitCounties: SubmitHandler<any> = (data: any) => {
    const selectedCounties = selectList.filter((item) => data.counties.includes(item.value));
    dispatch(clearSurveysRecipients(null));
    dispatch(updateSurveysCounties({ counties: selectedCounties }));
    handleClose?.();
  };

  const handleTabChange = (event: React.SyntheticEvent, tab: RecipientsTabsEnum) => {
    if (setSelectedTab) setSelectedTab(tab);
  };

  useEffect(() => {
    let tempCounties: {
      label: string;
      value: string;
    }[] = [];
    tempCounties = tempCounties.concat(peeDeeCounties);
    tempCounties = tempCounties.concat(lowCountryCounties);
    tempCounties = tempCounties.concat(midlandsCounties);
    tempCounties = tempCounties.concat(upstateCounties);

    const selectList = tempCounties.map((item) => ({ label: item.label, value: item.value }));
    setSelectList(selectList);
  }, [peeDeeCounties, lowCountryCounties, midlandsCounties, upstateCounties]);

  useEffect(() => {
    const updateCountyList = () => {
      if (selectedCountiesValue.length > 0) {
        const filteredCounties: { label: string; value: string }[] = selectList?.filter((item) =>
          selectedCountiesValue.includes(item.value)
        );
        setCountyList(filteredCounties);
      } else {
        setCountyList(selectList);
      }
    };
    updateCountyList();
  }, [selectedCountiesValue]);

  useEffect(() => {
    if (selectedCountiesValue?.length > 0) {
      countiesForm.reset({ counties: selectedCountiesValue });
    }
  }, [selectedCountiesValue]);

  function displayTabContent(
    tab: RecipientsTabsEnum,
    form: any,
    setNewRecipients: any,
    selectedRecipients: (IUser & { id: string })[]
  ) {
    switch (tab) {
      case RecipientsTabsEnum.GENERALSELECTION:
        return (
          <FormProvider {...form}>
            <form>
              <SelectRegionView />
            </form>
          </FormProvider>
        );
      default:
        return (
          <DialogContent
            sx={{
              padding: '0',
              minHeight: '70vh',
              overflowY: noScrollY ? 'hidden' : overflowVisible ? 'visible' : 'auto',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <SelectRecipientSurvey
              selectedData={selectedRecipients}
              setNewRecipients={setNewRecipients}></SelectRecipientSurvey>
          </DialogContent>
        );
    }
  }

  const recipientTabs = () => {
    return (
      <Container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {RecipientsTabs.map((tab) => (
                <Tab key={`tab-${tab.type}`} label={tab.title} {...a11yProps(tab.type)} />
              ))}
            </Tabs>
          </Box>
          {displayTabContent(selectedTab, countiesForm, setNewRecipients, selectedRecipients)}
        </Box>
      </Container>
    );
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVisible ? 'visible' : 'auto'
        }
      }}>
      {title && (
        <>
          <Typography variant="h4" sx={{ padding: '16px 24px', textTransform: 'capitalize' }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              handleClose?.();
            }}
            aria-label="dhec-close"
            sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
            <Close sx={{ width: 25, height: 25 }} />
          </IconButton>
        </>
      )}
      {recipientTabs()}
      <DialogActions
        style={{
          display: 'inline-flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          gap: '12px'
        }}>
        <Button
          autoFocus
          color="secondary"
          onClick={() => {
            handleClose?.();
          }}>
          Cancel
        </Button>
        <Button
          className={commonClasses.blueButton}
          color="primary"
          onClick={
            selectedTab === 0
              ? countiesForm.handleSubmit(onSubmitCounties)
              : templateForm.handleSubmit(onSubmitRecipients)
          }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurveyRecipientsFormDialog;
