import { ITabsSurvey } from '../interfaces/ITabs';
import CampaignTabsEnum from '../enums/CampaignTabsEnum';

const CampaignTabs: ITabsSurvey[] = [
  {
    type: CampaignTabsEnum.DETAILS,
    title: 'Details'
  },
  {
    type: CampaignTabsEnum.QUESTIONS,
    title: 'Questions'
  }
];

export default CampaignTabs;
