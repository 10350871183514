import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import SnackbarMessageState from "./snackbar-message.state";
import { SurveyState } from "../index";

const initialState: SnackbarMessageState = {
  show: false,
  message: null
};

export const snackbarMessageSurveyStateSlice = createSlice({
  name: 'snackbarMessageSurvey',
  initialState,
  reducers: {
    updateSnackbarSurveyMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    },
    clearSnackbarSurveyMessage: (state, action: PayloadAction<SnackbarMessageState>) => {
      return action.payload || initialState;
    }
  }
});

export const { updateSnackbarSurveyMessage, clearSnackbarSurveyMessage } = snackbarMessageSurveyStateSlice.actions;

export const selectSnackbarMessageSurveyShow = (state: SurveyState) => state.snackbarMessageSurvey.show;
export const selectSnackbarMessageSurveyMessage = (state: SurveyState) => state.snackbarMessageSurvey.message;

export default snackbarMessageSurveyStateSlice.reducer;
