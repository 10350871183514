import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import AdminState from 'store/state/AdminState';

const DEFAULT_STATE: AdminState = {
  businessDirectory: null,
  regionalOfficeSettings: null,
  userManagement: [],
  users: []
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState: DEFAULT_STATE,
  reducers: {
    updateAdmin: (state, action: PayloadAction<AdminState>) => {
      return action.payload || DEFAULT_STATE;
    },
    clearAdmin: (state, action: PayloadAction<AdminState>) => {
      return action.payload || DEFAULT_STATE;
    }
  }
});

export const { updateAdmin, clearAdmin } = adminSlice.actions;
export const selectAdmin = (state: RootState) => state.admin;

export default adminSlice.reducer;
