import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSurveysListState } from './surveys-list.state';
import { getSurveyList } from './surveys-list.trunk';
import { SurveyInterface } from '../../interfaces/survey.interface';

export const surveyListSlice = createSlice({
  name: 'surveyList',
  initialState: initialSurveysListState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSurveyList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSurveyList.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(getSurveyList.fulfilled, (state, action: PayloadAction<SurveyInterface[]>) => {
        state.loading = false;
        state.error = false;
        state.entities = action.payload;
      });
  }
});
