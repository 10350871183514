import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSurveysTriggerQuestionsState } from './surveys-trigger-options.state';
import { SurveyTriggerQuestionsInterface } from '../../interfaces/survey.interface';

export const surveyTriggerQuestionsSlice = createSlice({
  name: 'surveyTriggerQuestions',
  initialState: initialSurveysTriggerQuestionsState,
  reducers: {
    updateTriggerQuestions: (state, action: PayloadAction<SurveyTriggerQuestionsInterface[]>) => {
      state.entities = action.payload;
    },
    clearTriggerQuestions: (state) => {
      state.entities = [];
    }
  }
});
