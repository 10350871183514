import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Nullable } from 'common/Utils/Nullable';
import { IUser } from 'interfaces/IUser';

const initialState: Nullable<IUser> = {
  role: undefined
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccount: (state, action: PayloadAction<IUser>) => {
      return action.payload;
    },
    clearAccount: () => {
      return initialState;
    }
  }
});

export const { updateAccount, clearAccount } = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account;
export const selectAccountRole = (state: RootState) => state.account.role;

export default accountSlice.reducer;
