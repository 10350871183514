import { useEffect, useState } from 'react';
import CountyList from 'data/Counties';
import { RegionName } from 'common/enums/EnumRegionName';
type IRegion = {
  label: string;
  value: string;
};
function useFetchCounties(region: RegionName) {
  const [countiesList, setCounties] = useState<IRegion[]>([]);
  const { PeeDeeCounties, LowCounty, MidlandsCounties, UpstateCounties } = CountyList;

  useEffect(() => {
    if (region) {
      let list = [];
      switch (region) {
        case RegionName.PEEDEECOUNTIES:
          list = PeeDeeCounties;
          break;
        case RegionName.LOWCOUNTRYCOUNTIES:
          list = LowCounty;
          break;
        case RegionName.MIDLANDSCOUNTIES:
          list = MidlandsCounties;
          break;
        default:
          list = UpstateCounties;
          break;
      }
      setCounties(list);
    }
  }, [region]); // eslint-disable-line

  return countiesList;
}

export default useFetchCounties;
