import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
      minWidth: '322px'
    },
    listItemContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      fontWeight: 300,
      fontSize: '12px',
      height: '30px',
      // paddingLeft: '10px',
      '& .MuiCheckbox-root': {
        paddingLeft: 0
      }
    },
    accordionRoot: {
      '& .MuiAccordion-root.Mui-expanded': {
        marginBottom: '0'
      }
    },
    accordionDetail: {
      padding: '5px',
      backgroundColor: '#f7f7f7',
      maxHeight: '350px',
      overflow: 'auto'
    },
    accordionSummary: {
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)!important',
        color: '#126DA2'
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: '10px'
      }
    },
    noDataContainer: {
      width: '100%',
      padding: '10px',
      textAlign: 'center'
    }
  };
});

export default useStyles;
