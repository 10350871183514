import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../../services/apiFetch';
import { getSurveyTemplatesAdmin } from '../../services/apiAdminSurvey';

export const getSurveyTemplate = createAsyncThunk(
  'survey/template/get',
  async (data: any, { rejectWithValue }) => {
    const res = await apiFetch(getSurveyTemplatesAdmin());
    if (res.data?.errorCode.code === 0) {
      return res.data?.response || ([] as any[]);
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
