import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import UserStatusEnum from '../common/interfaces/IUser';

export interface ApiAdminPermitHolderSearch {
  keyword?: string;
  firstName?: { index: number; value: string }[];
  lastName?: { index: number; value: string }[];
  businessName?: { index: number; value: string }[];
  phoneNumber?: { index: number; value: string }[];
  email?: { index: number; value: string }[];
  status?: UserStatusEnum;
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export interface ApiAdminPermitHolderFilters {
  keyword?: string;
  firstName?: string[];
  lastName?: string[];
  businessName?: string[];
  phoneNumber?: string[];
  email?: string[];
  status?: string | string[];
  orderBy?: string;
  offset?: number;
  limit?: number;
}

export const getPermitHolders = (filters: ApiAdminPermitHolderSearch): IEndpoint => {
  const params = setPermitHoldersParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/users`,
    params: params
  };
};

export const updatePermitHolders = (data: { id: string; status: UserStatusEnum }): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `api/admin/users/${data.id}`,
    data: data
  };
};

export const getPermitHoldersCounter = (filters: ApiAdminPermitHolderSearch): IEndpoint => {
  const params = setPermitHoldersParams(filters, true);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `api/admin/users/counter`,
    params: params
  };
};

function setPermitHoldersParams(
  filters: ApiAdminPermitHolderSearch,
  counter = false
): ApiAdminPermitHolderFilters {
  const params: ApiAdminPermitHolderFilters = {};

  if (filters.firstName && filters.firstName.length > 0) {
    params.firstName = filters.firstName.map((data) => data.value);
  }

  if (filters.phoneNumber && filters.phoneNumber.length > 0) {
    params.phoneNumber = filters.phoneNumber.map((data) => data.value);
  }
  if (filters.email && filters.email.length > 0) {
    params.email = filters.email.map((data) => data.value);
  }

  if (filters.lastName && filters.lastName.length > 0) {
    params.lastName = filters.lastName.map((data) => data.value);
  }

  if (filters.businessName && filters.businessName.length > 0) {
    params.businessName = filters.businessName.map((data) => data.value);
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.status) {
    if (Array.isArray(filters.status)) {
      params.status = filters.status.map((data) => data.value);
    } else {
      params.status = filters.status;
    }
  }

  if (!counter) {
    if (filters.orderBy) {
      params.orderBy = filters.orderBy;
    }

    if (filters.offset || filters.offset === 0) {
      params.offset = filters.offset;
    }

    if (filters.limit) {
      params.limit = filters.limit;
    }
  }

  return params;
}
