import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { MediaCardPubProps } from './MediaCard.props';
import { Link } from 'react-router-dom';
import useStyles from './MediaCard.styles';

const MediaCard: React.FC<MediaCardPubProps> = (props: MediaCardPubProps) => {
  const classes = useStyles();

  return (
    <Card sx={{ maxWidth: 345 }}>
      {props?.image && (
        <CardMedia
          component="img"
          alt={props?.alt || ''}
          height={props?.height || 60}
          image={props?.image}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" color="text.secondary" className={classes.linkTitle}>
          {props?.title || ''}
          <a href={props?.link} target="_blank" rel="noreferrer" className={classes.linkTitle}>
            <OpenInNewIcon sx={{ width: '18px', display: props?.isVideo ? '' : 'none' }} />
          </a>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props?.description || ''}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MediaCard;
