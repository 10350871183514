import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'common/Utils/Nullable';
import { RootState } from 'store';
import MyAccountState from 'store/state/MyAccountState';

const propDefault: MyAccountState = {
  show: false,
  name: null,
  email: null
};
const initialState: MyAccountState = {
  show: false,
  name: null,
  email: null
};

export const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    updateMyAccount: (state, action: PayloadAction<MyAccountState>) => {
      return action.payload || propDefault;
    },
    clearMyAccount: (state, action: PayloadAction<MyAccountState>) => {
      return action.payload || propDefault;
    }
  }
});

export const { updateMyAccount, clearMyAccount } = myAccountSlice.actions;

export const selectmyAccount = (state: RootState) => state.myAccount;

export default myAccountSlice.reducer;
