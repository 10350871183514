import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

const MailboxUsersHeader: ColumnsProperties[] = [
  {
    Header: '',
    accessor: 'id',
    isSelected: true,
    disableSortBy: true,
    filterHidden: true,
    disableColumnSettings: true,
    width: 30
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    isSelected: true
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    isSelected: true
  },
  {
    Header: 'Email',
    accessor: 'email',
    isSelected: true
  },
  {
    Header: 'Business Name',
    accessor: 'businessName',
    isSelected: true
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    isSelected: true,
    disableSortBy: true
  }
];

export default MailboxUsersHeader;
