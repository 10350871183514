import PermitHolderTabsEnum from 'common/enums/PermitHolderTabsEnum';
import AdminTabsEnum from 'common/enums/AdminTabsEnum';
import CampaignTabsEnum from 'components/snippets/Admin/Surveys/enums/CampaignTabsEnum';

export enum UserRoles {
  permitHolder = 'permitHolder',
  admin = 'admin',
  superAdmin = 'superadmin'
}

export enum UserPermissions {
  businessDirectory = 'businessDirectory',
  mailbox = 'mailbox',
  users = 'users',
  library = 'library',
  surveys = 'surveys',
  staffDirectory = 'staffDirectory'
}

export interface ITabsProps {
  type: PermitHolderTabsEnum | AdminTabsEnum | CampaignTabsEnum;
  query?: string;
  title: string;
  locale?: string;
  enabled: boolean;
  role: UserRoles;
  permissions?: UserPermissions;
}

export type ITabs = ITabsProps;
