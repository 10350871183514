import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      resize: 'both'
    }
  })
);

export default useStyles;
