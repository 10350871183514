import AdminTabsEnum from 'common/enums/AdminTabsEnum';
import { ITabs, UserPermissions, UserRoles } from 'common/interfaces/ITabs';

const AdminTabs: ITabs[] = [
  {
    type: AdminTabsEnum.BUSINESS_DIRECTORY,
    title: 'Business Directory',
    query: 'business-directory',
    locale: 'Business Directory',
    role: UserRoles.admin,
    permissions: UserPermissions.businessDirectory,
    enabled: true
  },
  {
    type: AdminTabsEnum.MAILBOX,
    title: 'Mailbox',
    query: 'mailbox',
    locale: 'Mailbox',
    role: UserRoles.admin,
    permissions: UserPermissions.mailbox,
    enabled: true
  },
  {
    type: AdminTabsEnum.PERMIT_HOLDERS,
    title: 'Permit Holders',
    query: 'permit-holders',
    locale: 'Permit Holders',
    role: UserRoles.admin,
    permissions: UserPermissions.users,
    enabled: true
  },
  {
    type: AdminTabsEnum.LIBRARY,
    title: 'Library',
    query: 'library',
    locale: 'Library',
    role: UserRoles.admin,
    permissions: UserPermissions.library,
    enabled: true
  },
  {
    type: AdminTabsEnum.SURVEYS,
    title: 'Surveys',
    query: 'surveys',
    locale: 'Surveys',
    role: UserRoles.admin,
    permissions: UserPermissions.surveys,
    enabled: true
  },
  {
    type: AdminTabsEnum.USERS,
    title: 'User Management',
    query: 'user-management',
    locale: 'User Management',
    role: UserRoles.superAdmin,
    enabled: true
  }
];

export default AdminTabs;
