import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

const UserManagementHeaders: ColumnsProperties[] = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    isSelected: true
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    isSelected: true
  },
  {
    Header: 'Email',
    accessor: 'email',
    isSelected: true
  },
  {
    Header: 'Access',
    accessor: 'permissions',
    isSelected: true
  },
  {
    Header: 'Date Updated',
    accessor: 'updatedAt',
    isSelected: true
  },
  {
    Header: 'Options',
    accessor: 'options',
    isSelected: true,
    disableSortBy: true,
    width: '10%'
  }
];

export default UserManagementHeaders;
