enum SurveyQuestionActionTypes {
  STOP = 'stop',
  NEXT = 'next',
  QUESTION = 'question'
}

export default SurveyQuestionActionTypes;

export const ACTIONS_OPTIONS = [
  {
    label: 'Stop survey',
    value: SurveyQuestionActionTypes.STOP
  },
  {
    label: 'Move to next question',
    value: SurveyQuestionActionTypes.NEXT
  },
  {
    label: 'Reveal question #',
    value: SurveyQuestionActionTypes.QUESTION
  }
];
