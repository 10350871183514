import React from 'react';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';

const BusinessesHeaders: ColumnsProperties[] = [
  {
    Header: 'Permit #',
    accessor: 'permitNumbers',
    isSelected: true,
    disableSortBy: true,
    hidden: true
  },
  {
    Header: 'Company Name',
    accessor: 'corporateName',
    isSelected: true
  },
  {
    Header: 'DBA Name',
    accessor: 'doingBusiness',
    isSelected: true,
    width: 120
  },
  {
    Header: 'Facility Type',
    accessor: 'facilityType',
    isSelected: true
  },
  {
    Header: 'County',
    accessor: 'county',
    isSelected: true,
    filterHidden: true,
    disableSortBy: true,
    Cell: (props: any) => {
      if (props.value) {
        return props.value.name;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Regional Office',
    accessor: 'regionalOffice',
    isSelected: true,
    filterHidden: true,
    disableSortBy: true,
    width: 200,
    Cell: (props: any) => {
      if (
        props.cell.row.original &&
        props.cell.row.original.county &&
        props.cell.row.original.county.region &&
        props.cell.row.original.county.region.name
      ) {
        return props.cell.row.original.county.region.name;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Employees',
    accessor: 'employessNumber',
    isSelected: true,
    disableSortBy: true,
    filterHidden: true,
    width: 100
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSelected: true,
    width: 100
  },
  {
    Header: 'Options',
    accessor: 'options',
    disableSortBy: true,
    isSelected: true,
    filterHidden: true,
    categoryHidden: true,
    width: 100
  }
];

export default BusinessesHeaders;
