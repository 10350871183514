import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      height: '500px',
      display: 'flex',
      alignItems: 'center'
    }
  };
});

export default useStyles;
