import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Switch } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Controller, useFieldArray } from 'react-hook-form';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';

import FormGroup from 'components/primitives/FormGroup';
import SelectInput from 'components/primitives/SelectInput';
import TextArea from 'components/primitives/TextArea';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import { surveysTriggerOptionsSelector } from '../../../store/surveys-trigger-options/surveys-trigger-options.selector';
import SingleChoiceQuestionItem from './SingleChoiceQuestionItem.view';
import { SurveyQuestionTypes } from '../../../enums/SurveyQuestionTypesEnum';

const SingleChoiceFormView: React.FC<any> = (props) => {
  const commonClasses = useCommonStyles();

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control: props.control,
    name: `template.surveyQuestions[${props.index}].surveyQuestionsOptions`
  });

  useEffect(() => {
    props.setValue(`template.surveyQuestions[${props.index}].type`, SurveyQuestionTypes.SINGLE);
  }, []);

  const TRIGGER_OPTIONS = useSelector(surveysTriggerOptionsSelector);

  return (
    <Grid container item lg={12}>
      <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
        <Grid item lg={12}>
          <FormGroup label={'Question'}>
            <Controller
              name={`template.surveyQuestions[${props.index}].question`}
              control={props.control}
              rules={{ required: 'Field is required.' }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <>
                    <TextArea
                      defaultValue={value}
                      name={name}
                      onChange={onChange}
                      error={Boolean(error)}
                      variant="standard"
                      placeholder="Enter Question"
                    />
                    <p style={errorTextStyle}>
                      <ErrorMessage errors={props.errors} name={name} />
                    </p>
                  </>
                );
              }}
            />
          </FormGroup>
        </Grid>
        {TRIGGER_OPTIONS && TRIGGER_OPTIONS.length > 0 && (
          <Grid item lg={12}>
            <FormGroup label={'Email Notification'}>
              <Controller
                name={`template.surveyQuestions[${props.index}].triggerOptionID`}
                control={props.control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  let dV = undefined;
                  if (TRIGGER_OPTIONS && TRIGGER_OPTIONS.length && value) {
                    dV = TRIGGER_OPTIONS.find((item: any) => item.value === value);
                  }
                  return (
                    <SelectInput
                      defaultValue={dV}
                      placeholder={'Select Email'}
                      error={Boolean(error)}
                      options={TRIGGER_OPTIONS}
                      onChange={(e) => {
                        if (e === null) {
                          props.setValue(name, null);
                          return onChange(null);
                        }
                        props.setValue(name, e.value);
                        return onChange(e.value);
                      }}
                      isClearable={true}
                    />
                  );
                }}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item lg={12}>
          {fields.map((field, index) => (
            <div key={field.id}>
              <SingleChoiceQuestionItem
                getValues={props.getValues}
                questionIndex={props.index}
                control={props.control}
                itemIndex={index}
                itemRemove={remove}
                itemField={field}
                errors={props.errors}
                setValue={props.setValue}
              />
            </div>
          ))}
        </Grid>
        <Grid container item lg={12} justifyContent="center">
          <Button
            className={commonClasses.blueButton}
            size="small"
            startIcon={<AddCircleOutlineRoundedIcon sx={{ width: 16, height: 16 }} />}
            onClick={() => {
              // const data = {
              //   label: `Option ${optionForms.length + 1}`,
              //   triggerAutoEmail: false,
              //   revealQuestion: TRIGGER_OPTIONS[0].label
              // };
              append({});
            }}>
            Add New
          </Button>
        </Grid>
        <Grid item lg={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginTop: '12px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px'
        }}>
        <Grid
          item
          sx={{
            flexGrow: '1'
          }}>
          <FormGroup label={'Required'}>
            <Controller
              name={`template.surveyQuestions[${props.index}].required`}
              control={props.control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                let dV = !!value;
                return (
                  <Switch
                    checked={dV}
                    value={true}
                    name={name}
                    onChange={(e) => {
                      props.setValue(name, e.target.checked ? 1 : 0);
                    }}
                  />
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <DeleteOutlinedIcon
            sx={{
              width: 20,
              height: 20,
              cursor: 'pointer'
            }}
            onClick={() => props.remove(props.index)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleChoiceFormView;
