import AuthProtect from 'common/enums/AuthProtect';
import { Nullable } from 'common/Utils/Nullable';
import { IEndpoint } from 'interfaces/IEndPoint';
import { IUser, IUserProps } from 'interfaces/IUser';

const PAGE_LIMIT = 20;

export const getAdminUsers: (offset?: number, sortBy?: Nullable<string>) => IEndpoint = (
  offset = 0,
  sortBy = 'email ASC'
) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/superAdmin/users?limit=${PAGE_LIMIT}&offset=${offset}${
    sortBy ? `&orderBy=${sortBy}` : ''
  }`
});

export const getAdminUsersWithFilter: (
  filter: string,
  offset?: number,
  sortBy?: Nullable<string>
) => IEndpoint = (filter = '', offset = 0, sortBy = 'email ASC') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/superAdmin/users?limit=${PAGE_LIMIT}&${filter}&offset=${offset}${
    sortBy ? `&orderBy=${sortBy}` : ''
  }`
});

export const getAdminUsersCounter: (
  keyword?: string | null,
  offset?: number,
  sortBy?: Nullable<string>
) => IEndpoint = (keyword = '', offset = 0, sortBy = 'email ASC') => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/superAdmin/users/counter?offset=${offset}&keyword=${keyword}`
});

export const getAdminUsersItemsForFilter: (
  filter: string,
  keyword: string,
  offset?: number
) => IEndpoint = (filter = '', keyword = '', offset = 0) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/superAdmin/users/filters/${filter}?limit=${PAGE_LIMIT}&offset=${offset}${
    keyword ? `&keyword=${keyword}` : ''
  }`
});

export const getAdminUsersForSearch: (
  keyword: string,
  orderBy: string,
  offset: number
) => IEndpoint = (keyword = '', orderBy = 'email', offset = 0, sortBy = null) => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `api/superAdmin/users?limit=${PAGE_LIMIT}&offset=${offset}${`&keyword=${keyword}`}${
    sortBy ? `&orderBy=${sortBy}` : ''
  }`
});

export const createNewAdminAccount: (data: IUser) => IEndpoint = (data: IUser) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/superAdmin/users`,
    method: 'POST',
    data: data
  };
};

export const updateAdminInfo: (data: IUser) => IEndpoint = (data: IUser) => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `api/superAdmin/users/${data.id}`,
    method: 'PUT',
    data: data
  };
};

export const deleteAdminAccount = (data: IUser): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `api/superAdmin/users/${data.id}`
  };
};
