import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSurveysCampaignCreateState } from './surveys-campaign.state';
import { saveSurveyCampaignCreate } from './surveys-campaign.trunk';
import { SurveyInterface } from '../../interfaces/survey.interface';

export const surveyCampaignCreateSlice = createSlice({
  name: 'surveyCampaignCreate',
  initialState: initialSurveysCampaignCreateState,
  reducers: {
    clearSurveyCampaignCreate: () => {
      return initialSurveysCampaignCreateState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(saveSurveyCampaignCreate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveSurveyCampaignCreate.rejected, (state, action) => {
        const error = {
          code: 0,
          message: 'Something went wrong!'
        };

        if (action.error.message) {
          error.message = action.error.message;
        }
        state.success = false;
        state.loading = false;
        state.error = error;
      })
      .addCase(
        saveSurveyCampaignCreate.fulfilled,
        (state, action: PayloadAction<SurveyInterface>) => {
          state.loading = false;
          state.error = false;
          state.entities = action.payload;
          state.success = true;
        }
      );
  }
});
