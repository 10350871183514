import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useStyles from './LandingPageLayout.styles';
import Header from 'components/snippets/Header';
import Footer from 'components/snippets/Footer';
import FormDialog from 'components/fragments/FormDialog';
import ChangePassword from 'components/snippets/ChangePassword';
import SnackbarMessage from '../../fragments/SnackbarMessage';

const LandingPageLayout: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [passwordToken, setPasswordToken] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const changePassword = !!searchParams.get('changePassword');
    const token = !!searchParams.get('token');
    if (changePassword && token) {
      setShowChangePassword(true);
      setPasswordToken(searchParams.get('token'));
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.page}>{props.children}</div>
      <Footer setShowChangePassword={setShowChangePassword} />
      {passwordToken && showChangePassword && (
        <FormDialog
          show={showChangePassword}
          showActionButtons={false}
          backgroundTransparent
          fullWidth={true}
          noScrollY={false}
          overflowVissible={true}
          maxWidth="md"
          title=""
          handleClose={() => {
            setShowChangePassword(false);
          }}
        >
          <ChangePassword
            token={passwordToken}
            handleClose={() => {
              setShowChangePassword(false);
            }}
          />
        </FormDialog>
      )}
      <SnackbarMessage></SnackbarMessage>
    </div>
  );
};

export default LandingPageLayout;
