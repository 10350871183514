import React, { useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

import { IViewBusinessForm } from './ViewBusinessForm.props';
import FormGroup from 'components/primitives/FormGroup';
import TextField from 'components/primitives/TextField';
import DatePicker from '../../../../primitives/DatePicker';
import moment from 'moment/moment';
import { formatPhone } from '../../../../../common/Utils/String';

const ViewBusinessForm: React.FC<IViewBusinessForm> = ({ businessInfo }) => {
  useEffect(() => {}, []);

  return (
    <Grid container rowSpacing={2} sx={{ marginTop: 0 }}>
      <Grid item lg={12} alignItems="center" columnSpacing={12}>
        <Typography variant="h6" color="textSecondary">
          General
        </Typography>
      </Grid>

      <Grid container item lg={12}>
        <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <FormGroup label={`Facility Type`}>
                <TextField
                  value={businessInfo?.facilityType || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <FormGroup label={`Process`}>
                <TextField
                  value={businessInfo?.process || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <FormGroup label={`Employees`}>
                <TextField
                  value={businessInfo?.employessNumber || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Corporate Name`}>
                <TextField
                  value={businessInfo?.corporateName || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={`Doing Business As:`}>
                <TextField
                  value={businessInfo?.doingBusiness || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`County`}>
                <TextField
                  value={businessInfo?.county?.name || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`Regional Office`}>
                <TextField
                  value={businessInfo?.county?.region?.name || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`Phone Number`}>
                <TextField
                  inputProps={{ readOnly: true }}
                  value={formatPhone(businessInfo?.phone) || ''}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={`Email Address`}>
                <TextField
                  value={businessInfo?.email || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item lg={12}>
        <Typography variant="h6" color="textSecondary">
          Physical Address
        </Typography>
      </Grid>
      <Grid container item lg={12}>
        <Paper sx={{ width: '100%', padding: 2 }}>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={'Street Address'}>
                <TextField
                  value={businessInfo?.addressStreet || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                <TextField
                  value={businessInfo?.addressNumber || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'State'}>
                <TextField
                  value={businessInfo?.addressState || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'County'}>
                <TextField
                  value={businessInfo?.addressCounty || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'City'}>
                <TextField
                  value={businessInfo?.addressCity || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'Zip Code'}>
                <TextField
                  value={businessInfo?.addressZip || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container item lg={12} alignItems="center" columnSpacing={4}>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            Mailing Address
          </Typography>
        </Grid>
      </Grid>
      <Grid container item lg={12}>
        <Paper sx={{ width: '100%', padding: 2 }}>
          <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={'Street Address'}>
                <TextField
                  value={businessInfo?.mailingAddressStreet || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup label={'Apartment, Suite, Unit, etc.'}>
                <TextField
                  value={businessInfo?.mailingAddressNumber || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'State'}>
                <TextField
                  value={businessInfo?.mailingAddressState || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'County'}>
                <TextField
                  value={businessInfo?.mailingAddressCounty || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'City'}>
                <TextField
                  value={businessInfo?.mailingAddressCity || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormGroup label={'Zip Code'}>
                <TextField
                  value={businessInfo?.mailingAddressZip || ''}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item lg={12} alignItems="center" columnSpacing={4}>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            Certified Food Protection Manager
          </Typography>
        </Grid>
      </Grid>

      <Paper sx={{ width: '100%', padding: 2 }}>
        <Grid container item lg={12} rowSpacing={2}>
          {businessInfo?.certificateHolders && businessInfo?.certificateHolders.length > 0 ? (
            businessInfo?.certificateHolders.map((certificateHolder, index) => {
              return (
                <Grid
                  container
                  item
                  lg={12}
                  columnSpacing={1}
                  rowSpacing={2}
                  key={certificateHolder.id}>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`First Name`}>
                      <TextField
                        value={certificateHolder.firstName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Last Name`}>
                      <TextField
                        value={certificateHolder.lastName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Certificate Number`}>
                      <TextField
                        value={certificateHolder.certificateNumber}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Expiration Date`}>
                      <DatePicker
                        disablePast
                        inputFormat="MM-DD-YYYY"
                        value={
                          certificateHolder && certificateHolder?.expiredAt
                            ? moment.unix(certificateHolder.expiredAt).toISOString()
                            : moment().toISOString()
                        }
                        readOnly={false}
                        renderInput={(p) => <TextField {...p} />}
                        onChange={() => {}}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" color="textSecondary">
                  No Certified Food Protection Manager
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Grid container item lg={12}>
        <Typography variant="h6" color="textSecondary">
          Food Handler Certificate Holder
        </Typography>
      </Grid>
      <Paper sx={{ width: '100%', padding: 2 }}>
        <Grid container item lg={12} rowSpacing={2}>
          {businessInfo?.foodProtectionManagers &&
          businessInfo?.foodProtectionManagers.length > 0 ? (
            businessInfo?.foodProtectionManagers.map((foodManagers, index) => {
              return (
                <Grid container item lg={12} columnSpacing={1} rowSpacing={2} key={foodManagers.id}>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`First Name`}>
                      <TextField
                        value={foodManagers.firstName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Last Name`}>
                      <TextField
                        value={foodManagers.lastName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Certificate Number`}>
                      <TextField
                        value={foodManagers.certificateNumber}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Expiration Date`}>
                      <DatePicker
                        disablePast
                        inputFormat="MM-DD-YYYY"
                        value={
                          foodManagers && foodManagers?.expiredAt
                            ? moment.unix(foodManagers.expiredAt).toISOString()
                            : moment().toISOString()
                        }
                        readOnly={true}
                        renderInput={(p) => <TextField {...p} />}
                        onChange={() => {}}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" color="textSecondary">
                  No Food Handler
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Grid container item lg={12}>
        <Typography variant="h6" color="textSecondary">
          Points of Contact
        </Typography>
      </Grid>
      <Paper sx={{ width: '100%', padding: 2 }}>
        <Grid container item lg={12} rowSpacing={2}>
          {businessInfo?.contacts && businessInfo?.contacts.length > 0 ? (
            businessInfo?.contacts.map((contact, index) => {
              return (
                <Grid container item lg={12} columnSpacing={1} rowSpacing={2} key={contact.id}>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`First Name`}>
                      <TextField
                        value={contact.firstName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Last Name`}>
                      <TextField
                        value={contact.lastName}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Email`}>
                      <TextField
                        value={contact.email}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <FormGroup label={`Phone Number`}>
                      <TextField
                        value={contact.phoneNumber}
                        inputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid container item lg={12} columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" color="textSecondary">
                  No Points of Contact
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ViewBusinessForm;
