import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039)',
      borderRadius: '8px',
      borderWidth: '2px 2px 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      '&:hover': {
        borderStyle: 'solid',
        borderColor: '#126DA2'
      }
    },
    wrapper: {
      background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)'
    },
    linkTitle: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      color: '#126DA2;',
      textDecoration: 'none'
    },
    description: {
      marginTop: '12px',
      marginBottom: '12px'
    }
  })
);

export default useStyles;

// border-width: 2px 2px 2px 8px;
// border-style: solid;
// border-color: #126DA2;
// /* DHEC / Elevation / 1dp */
//
// box-shadow: 0px 0.5px 1.5px rgba(0, 0, 0, 0.19), 0px 0px 1px rgba(0, 0, 0, 0.039);
// border-radius: 8px;
