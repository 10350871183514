import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Paper,
  Grid,
  Alert,
  AlertTitle
} from '@mui/material';

import { FormDialogProps } from './ContactForm.props';
import useCommonStyles, { errorTextStyle } from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import FormGroup from '../../primitives/FormGroup';
import { ErrorMessage } from '@hookform/error-message';
import TextField from '../../primitives/TextField';
import SelectInput from 'components/primitives/SelectInput';
import TextArea from 'components/primitives/TextArea';
import { useAppSelector } from 'app/hooks';
import apiFetch from '../../../services/apiFetch';
import { IContactSubject, ISendContactSubject } from 'interfaces/IContact';
import { getContactSubjects, sendContactRequest } from '../../../services/apiGuestContact';
import { selectAccount } from 'store/reducers/account';
import { formatPhone } from '../../../common/Utils/String';
import { updateLibrary } from '../../../services/apiAdminLibrary';

const ContactForm: React.FC<FormDialogProps> = ({
  show = false,
  fullWidth = false,
  handleClose
}) => {
  const commonClasses = useCommonStyles();
  const account = useAppSelector(selectAccount);
  const [subjects, setSubjects] = useState<{ value: string; label: string }[]>([]);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<ISendContactSubject | null>(null);

  useEffect(() => {
    setAuthenticated(!!account.id);

    apiFetch(
      getContactSubjects({
        type: account.id ? 1 : 0
      })
    ).then((data) => {
      if (data && data.status === 200) {
        let values = data.data.response.map((item: IContactSubject) => {
          return { value: item.type, label: item.label, item: item };
        });
        setSubjects(values);
      }
    });
  }, []);

  const methods = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      phoneNumber: account?.phoneNumber,
      email: account?.email
    }
  });

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit = async (formData: any) => {
    const dataToSend: any = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      permitNumber: formData.permitNumber ?? null,
      subject: formData.subject,
      contactTemplateID: selectedSubject?.contactTemplateID ?? null,
      details: formData.details
    };

    // if (data && data.id) {
    const res = await apiFetch(sendContactRequest(dataToSend));

    // } else {
    //   res = await apiFetch(createLibrary(formData));
    // }

    if (res && res.status === 200) {
      handleClose?.();
    }
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={'md'}
      open={show}
      onClose={() => {
        reset({});
        handleClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <></>

      <DialogContent
        sx={{
          padding: '24px',
          minHeight: 'auto',
          overflowY: 'auto',
          background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%);'
        }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '28px'
              }}>
              <Typography variant="h4" sx={{ fontSize: '28px', padding: '0', color: 'white' }}>
                Request information
              </Typography>
              <IconButton
                onClick={() => {
                  reset({});
                  handleClose?.();
                }}
                aria-label="dhec-close"
                sx={{ width: 25, height: 25 }}>
                <Close sx={{ width: 25, height: 25, color: 'white' }} />
              </IconButton>
            </Grid>
            <Paper elevation={1} sx={{ width: '100%', padding: 2 }}>
              <Grid container item columnSpacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid title={'first name'} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'First Name'}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextField
                              defaultValue={value}
                              error={!!errors[name]?.message}
                              placeholder={'First Name'}
                              name={name}
                              onChange={onChange}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid title={'last name'} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'Last Name'}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextField
                              defaultValue={value}
                              error={!!errors[name]?.message}
                              name={name}
                              placeholder={'Last Name'}
                              onChange={onChange}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid title={'Phone number'} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'Phone number'}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextField
                              defaultValue={value}
                              error={!!errors[name]?.message}
                              name={name}
                              placeholder={'Phone number'}
                              value={formatPhone(value) || ''}
                              onChange={onChange}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid title={'Email'} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormGroup label={'Email'}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: 'Field is required.',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address'
                        }
                      }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextField
                              defaultValue={value}
                              error={!!errors[name]?.message}
                              name={name}
                              onChange={onChange}
                              placeholder={'Email'}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid container item columnSpacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid title={'Subject'} item xs={12} sm={12} md={12} lg={4} xl={6}>
                  <FormGroup label={'Subject'}>
                    <Controller
                      name="subject"
                      control={control}
                      rules={{ required: 'Field is required.' }}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <SelectInput
                              defaultValue={value}
                              error={!!errors.subject?.message}
                              options={subjects}
                              placeholder={`Subject`}
                              onChange={(e) => {
                                setValue('subject', e.value);
                                setSelectedSubject(e.item);
                                return onChange(e.value);
                              }}
                            />

                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
                {authenticated && (
                  <Grid title={'Permit number'} item xs={12} sm={12} md={12} lg={4} xl={6}>
                    <FormGroup label={'Permit number'}>
                      <Controller
                        name="permitNumber"
                        control={control}
                        rules={{ required: 'Field is required.' }}
                        render={({ field: { name, onChange, value } }) => {
                          return (
                            <>
                              <TextField
                                defaultValue={value}
                                error={!!errors[name]?.message}
                                name={name}
                                placeholder={'xxx xxx'}
                                onChange={onChange}
                                variant="standard"
                              />
                              <p style={errorTextStyle}>
                                <ErrorMessage errors={errors} name={name} />
                              </p>
                            </>
                          );
                        }}
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>

              {selectedSubject && selectedSubject?.fileUrl && (
                <Grid container item columnSpacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Alert severity="info" sx={{ marginBottom: '16px' }}>
                      {/*<AlertTitle>Info</AlertTitle>*/}
                      {selectedSubject?.faqText}
                      <a href={selectedSubject?.fileUrl} target="_blank" rel="noreferrer">
                        {selectedSubject?.fileUrl}
                      </a>
                    </Alert>
                  </Grid>
                </Grid>
              )}

              <Grid container item columnSpacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormGroup label={'Additional info'}>
                    <Controller
                      name="details"
                      control={control}
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <>
                            <TextArea
                              defaultValue={value}
                              error={!!errors[name]?.message}
                              name={name}
                              placeholder={'Add a short description of your issue..'}
                              onChange={onChange}
                              variant="standard"
                            />
                            <p style={errorTextStyle}>
                              <ErrorMessage errors={errors} name={name} />
                            </p>
                          </>
                        );
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button type="submit" className={commonClasses.blueButton}>
                  Submit
                </Button>
              </Grid>
            </Paper>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ContactForm;
