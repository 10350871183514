enum AccessAreaType {
  BUSINESS_DIRECTORY = 'businessDirectory',
  MAILBOX = 'mailbox',
  USERS = 'users',
  LIBRARY = 'library',
  SURVEYS = 'surveys',
  USER_MANAGEMENT = 'userManagement'
}

export default AccessAreaType;
