import React from 'react';
import { ColumnsProperties } from 'components/fragments/ReactTable/ReactTable.props';
import moment from 'moment/moment';

const LibraryHeaders: ColumnsProperties[] = [
  {
    Header: 'Title',
    accessor: 'title_EN',
    isSelected: true,
    width: 200,
    disableSortBy: true,
    filterHidden: true
  },
  {
    Header: 'Category',
    accessor: 'category',
    isSelected: true,
    width: 200
  },
  {
    Header: 'Type',
    accessor: 'type',
    isSelected: true,
    width: 200,
    Cell: (props: any) => {
      if (props.value) {
        return <span>{props.value.charAt(0).toUpperCase() + props.value.slice(1)}</span>;
      }
      return 'N/A';
    }
  },
  {
    Header: 'URl',
    accessor: 'url',
    isSelected: true,
    width: 200,
    disableSortBy: true,
    filterHidden: true
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    isSelected: true,
    disableSortBy: false,
    filterHidden: true,
    Cell: (props: any) => {
      if (props.value) {
        return <span>{moment.unix(Number(props.value)).format('MMMM Do YYYY')}</span>;
      }
      return 'N/A';
    }
  },
  {
    Header: 'Options',
    accessor: 'options',
    isSelected: true,
    filterHidden: true,
    width: 100
  }
];

export default LibraryHeaders;
