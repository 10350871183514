import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)'
    },
    container: {
      position: 'absolute',
      left: '10%',
      width: '90%',
      top: '40px',
      [theme.breakpoints.down('sm')]: {
        top: '120px',
        left: 0,
        padding: '15px'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textTitle: {
      fontSize: '3rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem'
      }
    },
    textDescription: {
      fontSize: '1rem'
    },
    btnGetStarted: {
      width: '114px',
      height: '40px',
      background: '#fff',
      color: '#0CACCF',
      borderRadius: '4px',
      marginTop: '10px',
      '&:hover': {
        backgroundColor: '#fff'
      }
    },
    infoTitle: {
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      },
      fontWeight: 600,
      lineHeight: '64px',
      color: 'rgba(48, 48, 49, 0.88)',
      textAlign: 'center'
    },
    infoDescription: {
      maxWidth: '800px',
      fontSize: '16px',
      margin: '0 auto',
      fontWeight: 400,
      lineHeight: '26px',
      color: 'rgba(48, 48, 49, 0.72)',
      textAlign: 'center'
    }
  })
);
export default useStyles;
