import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  CircularProgress,
  Box
} from '@mui/material';

import { FormDialogProps } from './FormDialogEditProfile.props';
import { Close } from '@mui/icons-material';
import { useForm, FormProvider } from 'react-hook-form';
import { loadingStyle } from '../../snippets/Admin/Surveys/styles/loading.style';

const FormDialog: React.FC<FormDialogProps> = ({
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'md',
  noScrollY = false,
  overflowVissible = false,
  handleClose,
  backgroundTransparent = false,
  showActionButtons = true,
  hasCancel = true,
  cancelLabel = 'Cancel',
  children,
  loading
}) => {
  const methods = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true
  });

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      onClose={() => {
        handleClose?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVissible ? 'visible' : 'auto'
        }
      }}>
      {title && (
        <>
          <Typography variant="h4" sx={{ padding: '16px 24px' }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              handleClose?.();
            }}
            aria-label="dhec-close"
            sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
            <Close sx={{ width: 25, height: 25 }} />
          </IconButton>
        </>
      )}

      {loading && (
        <Box sx={loadingStyle}>
          <CircularProgress />
        </Box>
      )}
      <DialogContent
        sx={{
          padding: '0 0 10px 0',
          minHeight: 'auto',
          overflowY: noScrollY ? 'hidden' : overflowVissible ? 'visible' : 'auto'
        }}>
        <FormProvider {...methods}>
          <form>{children}</form>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ display: showActionButtons ? '' : 'none' }}>
        {hasCancel && (
          <Button
            disabled={loading}
            onClick={() => {
              handleClose?.();
            }}
            autoFocus
            color="secondary">
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
