import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../../../../services/apiFetch';
import { getSurveyAdmin, getSurveyTriggerOptionsApi } from '../../services/apiAdminSurvey';
import { ApiAdminMailboxFilters, SurveyInterface } from '../../interfaces/survey.interface';
import { useEffect } from 'react';
import { getSurveyList } from '../surveys-list/surveys-list.trunk';

export const getSurveyTriggerOptions = createAsyncThunk(
  'survey/triggerOptions',
  async (filters: null, { rejectWithValue }) => {
    const res = await apiFetch(getSurveyTriggerOptionsApi());
    if (res.data?.errorCode.code === 0) {
      return res.data?.response || ([] as SurveyInterface[]);
    } else {
      return rejectWithValue(res.data.errorCode);
    }
  }
);
