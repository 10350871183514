import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      background: 'linear-gradient(180deg, #1ACFCF 0%, #0198C8 100%)',
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textTitle: {
      fontSize: '3rem'
    },
    textDescription: {
      fontSize: '1rem'
    },
    noResultsDiv: {
      minHeight: '40vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    titleNoResults: {
      fontWeight: 600,
      fontSize: '22px',
      color: '#303031'
    },
    descriptionNoResults: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#303031',
      textAlign: 'center',
      marginTop: '8px'
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50vh'
    }
  })
);

export default useStyles;
