export const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  backgroundColor: 'rgb(128 128 128 / 31%)',
  zIndex: 99,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};
