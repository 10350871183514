import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box
} from '@mui/material';

import { FormDialogProps } from './FormDialog.props';
import useCommonStyles from 'common/styles/common.styles';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import { loadingStyle } from '../../snippets/SurveysResponse/components/SurveyContainer/SurveyContainer.styles';
import CircularProgress from '@mui/material/CircularProgress';

const FormDialog: React.FC<FormDialogProps> = ({
  show = false,
  fullWidth = false,
  title,
  maxWidth = 'md',
  noScrollY = false,
  overflowVissible = false,
  handleClose,
  handleSave,
  handleDelete,
  handleSaveAndNew,
  handleSaveEdit,
  backgroundTransparent = false,
  showActionButtons = true,
  hasSaveAndNew = false,
  hasCancel = true,
  hasSave = true,
  hasDelete = false,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  deleteLabel = 'Delete',
  saveAndNewLabel = 'Save & Add New',
  children,
  initialData,
  actionType,
  form,
  loading
}) => {
  const commonClasses = useCommonStyles();

  const {
    control,
    getValues,
    trigger,
    clearErrors,
    setValue,
    watch,
    resetField,
    reset,
    formState: { errors, isValid }
  } = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true
  });

  useEffect(() => {
    if (actionType && actionType === 'edit') {
      if (initialData) {
        const fieldKeys = Object.keys(initialData);
        fieldKeys.forEach((i: string) => {
          if (i !== 'createdAt' && i !== 'updatedAt') {
            /// @ts-ignore: Unreachable code error
            setValue(i, initialData[`${i}`], { shouldValidate: true });
          }
          if (i === 'county' && form === 'business-form') {
            /// @ts-ignore: Unreachable code error

            setValue(
              'county',
              /// @ts-ignore: Unreachable code error
              initialData?.countyID,
              { shouldValidate: true }
            );
          }
          if (i === 'employessNumber' && form === 'business-form') {
            /// @ts-ignore: Unreachable code error
            setValue(
              'employessNumber',
              {
                /// @ts-ignore: Unreachable code error
                label: initialData?.employessNumber,
                /// @ts-ignore: Unreachable code error
                value: initialData?.employessNumber
              },
              { shouldValidate: true }
            );
          }
          if (i === 'facility' && form === 'staff-form') {
            /// @ts-ignore: Unreachable code error
            setValue(
              'businessID',
              {
                /// @ts-ignore: Unreachable code error
                label: initialData?.facility,
                /// @ts-ignore: Unreachable code error
                value: initialData?.businessID
              },
              { shouldValidate: true }
            );
          }
        });
      }
    } else if (actionType && actionType === 'add') {
      reset({});
    }
  }, [actionType]);
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { setValue, control, errors, getValues, watch, resetField });
    }
    return child;
  });

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={show}
      onClose={() => {
        handleClose?.();
        clearErrors();
        reset({});
        if (form === 'business-form') {
          setValue('employessNumber', '');
          setValue('county', '');
          setValue('certificateHolders', []);
          setValue('contacts', []);
          setValue('foodProtectionManagers', []);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: backgroundTransparent ? 'transparent' : '#FAFAFA',
          boxShadow: backgroundTransparent ? 'none' : 'inherit',
          overflowY: overflowVissible ? 'visible' : 'auto'
        }
      }}>
      {title && (
        <>
          <Typography variant="h4" sx={{ padding: '16px 24px', textTransform: 'capitalize' }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              handleClose?.();
              clearErrors();
              reset({});
              if (form === 'business-form') {
                setValue('employessNumber', '');
                setValue('county', '');
                setValue('certificateHolders', []);
                setValue('contacts', []);
                setValue('foodProtectionManagers', []);
              }
            }}
            aria-label="dhec-close"
            sx={{ width: 25, height: 25, position: 'absolute', top: '15px', right: '15px' }}>
            <Close sx={{ width: 25, height: 25 }} />
          </IconButton>
        </>
      )}

      <DialogContent
        id={'dialogContent'}
        // sx={{ padding: '0', maxHeight: '70vh', minHeight: fullWidth ? '70vh' : 'auto' }}>
        sx={{
          padding: '0 0 10px 0',
          minHeight: fullWidth ? '70vh' : 'auto',
          overflowY: noScrollY ? 'hidden' : overflowVissible ? 'visible' : 'auto'
        }}>
        {loading && (
          <Box sx={loadingStyle}>
            <CircularProgress />
          </Box>
        )}
        {childrenWithProps}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
          display: showActionButtons ? '' : 'none'
      }}>
        {hasDelete && (
          <Button
            disabled={loading}
            onClick={() => {
            handleDelete?.();
            clearErrors();
            reset({});
              if (form === 'business-form') {
                setValue('employessNumber', '');
                setValue('county', '');
                setValue('certificateHolders', []);
                setValue('contacts', []);
                setValue('foodProtectionManagers', []);
              }
            }
          }
            color="secondary">
            {deleteLabel}
          </Button>
        )}
        <div style={ { flex: '1 0 0' } } />
        {hasCancel && (
          <Button
            disabled={loading}
            onClick={() => {
              handleClose?.();
              clearErrors();
              reset({});
              if (form === 'business-form') {
                setValue('employessNumber', '');
                setValue('county', '');
                setValue('certificateHolders', []);
                setValue('contacts', []);
                setValue('foodProtectionManagers', []);
              }
            }}
            autoFocus
            color="secondary">
            {cancelLabel}
          </Button>
        )}

        {hasSaveAndNew && (
          <Button
            disabled={loading}
            onClick={() => {
              const formValues = getValues();
              if (!isValid) {
                trigger();
              } else {
                handleSaveAndNew?.(formValues, reset);
                clearErrors();
              }
            }}
            color="primary">
            {saveAndNewLabel}
          </Button>
        )}
        {hasSave && (
          <Button
            type="submit"
            disabled={loading}
            className={commonClasses.blueButton}
            onClick={() => {
              const formValues = getValues();
              if (actionType === 'edit') {
                if (!isValid) {
                  trigger();
                } else {
                  handleSaveEdit?.(formValues, reset);
                }
              } else {
                if (!isValid) {
                  trigger();
                } else {
                  handleSave?.(formValues, reset);
                }
              }
            }}>
            {saveLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
